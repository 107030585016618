import React, { Component } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { showToaster, toasterTypes } from "../../widgets";
import { plantService } from "../../service";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "../../widgets/button";
import {
  listPlantsProps,
  listPlantsStateProps,
  editRowDataModal,
} from "./interface";
import { treeRequestModal } from "../../model";
import { ActionRow } from "./partials/actionRow";
import { Validation } from "../../utils";
import Pagination from "../../widgets/pagination";
import Tablefeature from "../../widgets/tableFeature";

class ListPlant extends Component<listPlantsProps, listPlantsStateProps> {
  constructor(props: listPlantsProps) {
    super(props);
    this.state = {
      tableRecords: [],
      gridApi: "",
      modal: false,
      context: {
        componentParent: this,
      },
      plantName: "",
      plantDescription: "",
      plantType: null,
      plantTypeOptions: [],
      rowEditById: 0,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
    };
  }
  componentDidMount() {
    this.getAllPlantTypes();
  }
  handlePageClick = (
    data: { selected: number },
    pageSize: number = this.state.pagination.pageSize
  ) => {
    this.getTreeList(data.selected, pageSize);
  };

  handleSearch = (e: any) => {
    this.getTreeList(1, 10, e.target.value);
  };

  deletePlant = (data: any) => {
    plantService.deleteplant({ id: data.id, status: "deleted" }).subscribe(
      (data: any) => {
        if (data.status && data.statusCode === 200) {
          this.deleteToggle();
          this.reset();
          this.getTreeList();
          showToaster(toasterTypes.SUCCESS, data.message);
        } else {
          showToaster(toasterTypes.ERROR, data.message);
        }
      },
      (error: any) => {
        console.log(error);
        showToaster(toasterTypes.ERROR, error.message);
      }
    );
  };

  getAllPlantTypes = () => {
    try {
      plantService.getAllPlantType().subscribe(
        (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: { value: number; label: string }[] =
                  response.data.map((element: any) => ({
                    label: element.name,
                    value: element.id,
                  }));
                this.setState({ plantTypeOptions: rowData }, () => {
                  this.getTreeList();
                });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };
  getTreeList = (page: number = 1, pageSize: number = 10, key: string = "") => {
    try {
      plantService.getAllTreeList(page, pageSize, key).subscribe(
        (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              if (response.data && Array.isArray(response.data)) {
                let rowData: any = [];
                response.data.forEach((element: any) => {
                  const selectedOption = this.state.plantTypeOptions?.filter(
                    (data) => data?.value === element.item_type_id
                  );
                  rowData.push({
                    id: element.id,
                    plantType:
                      Array.isArray(selectedOption) && selectedOption.length > 0
                        ? selectedOption[0]?.label
                        : {},
                    plantName: element.name,
                    Description: element.description,
                    plantTypeId: element.item_type_id,
                  });

                  this.setState({
                    pagination: {
                      pageNumber: response.page || 1,
                      pageSize: response.size || 10,
                      totalPages: response.totalPages || 1,
                      totalElements: response.totalElements || 10,
                    },
                  });
                });
                this.state.gridApi.setRowData(rowData);
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  onGridReady = (params: any) => {
    this.getTreeList();
    this.setState({
      gridApi: params.api,
    });
  };

  handleOnChange = (event: any) => {
    try {
      const { name, value } = event.currentTarget;
      const state: any = { ...this.state };
      state[name] = value;
      this.setState(state);
    } catch (error) {
      console.log(error?.message);
    }
  };
  toggle = () => {
    if (this.state.modal === true) {
      this.reset();
    }
    this.setState({ modal: !this.state.modal });
  };
  reset = () => {
    this.setState({
      plantName: "",
      plantDescription: "",
      plantType: null,
      rowEditById: 0,
    });
  };

  editRowData = (data: editRowDataModal) => {
    try {
      const selectedOption = this.state.plantTypeOptions?.filter(
        (element) => element?.value === data.plantTypeId
      );
      this.setState({
        rowEditById: data.id,
        plantName: data.plantName,
        plantDescription: data.Description,
        plantType:
          Array.isArray(selectedOption) && selectedOption.length > 0
            ? selectedOption[0]
            : {},
      });
      this.toggle();
    } catch (error) {
      console.log(error?.message);
    }
  };

  deleteToggle = () => {
    this.setState({
      modal: false,
    });
  };
  onsubmit = () => {
    try {
      if (
        Validation.isRequiredForDropdown(
          this.state.plantType ? this.state.plantType : "",
          "plant type"
        )
      ) {
        return false;
      }
      if (Validation.isTextRequired(this.state.plantName, "plant name")) {
        return false;
      }
      if (
        Validation.isRequired(this.state.plantDescription, "plant description")
      ) {
        return false;
      }
      const { plantType, plantName, plantDescription } = this.state;
      const requestData: treeRequestModal = {
        name: plantName,
        description: plantDescription,
        item_type_id: +plantType?.value,
      };
      if (this.state.rowEditById > 0) {
        requestData.id = +this.state.rowEditById;
      }
      plantService.createTreeRequest(requestData).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            this.toggle();
            this.getTreeList();
            this.deleteToggle();
            showToaster(toasterTypes.SUCCESS, data.message);
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };
  handleChange = (plantType: any) => {
    this.setState({ plantType });
  };

  closeBtn = (
    <button className="close" title="Close" onClick={this.toggle}>
      &times;
    </button>
  );
  render() {
    const {
      plantName,
      plantDescription,
      plantType,
      plantTypeOptions,
      pagination,
    } = this.state;

    return (
      <main id="main">
        <section id="ViewProject" className="ViewProject">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters">
              <div className="col-lg-12 d-flex flex-column justify-content-center">
                <div className="d-flex align-items-center justify-content-start mb-4 ">
                  <h3 className="secondary-heading-normal m-0 table-header-label">
                    View Plants
                  </h3>
                  <Button
                    label="Create"
                    className="ml-2 btn btn-outline-secondary"
                    onClick={this.toggle}
                  />
                </div>
                <Tablefeature
                  totalElements={pagination.totalElements}
                  handleSearch={this.handleSearch}
                />
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                  <ModalHeader
                    className="modal_Bg"
                    close={this.closeBtn}
                    toggle={this.toggle}
                  >
                    <div className="modal_title">
                      {this.state.rowEditById > 0
                        ? "Update Plants"
                        : "Create Plants"}
                    </div>
                  </ModalHeader>
                  <ModalBody>
                    <FieldItem
                      onChange={this.handleChange}
                      label="Plant Type "
                      name="plantType"
                      value={plantType}
                      options={plantTypeOptions}
                      placeholder="Select Plant Type "
                      type={FIELD_TYPES.DROP_DOWN}
                      className="required"
                      required
                    />
                    <FieldItem
                      onChange={this.handleOnChange}
                      label="Plant Name "
                      name="plantName"
                      value={plantName}
                      placeholder="Plant Name "
                      type={FIELD_TYPES.TEXT}
                      className="form-control required"
                      required
                    />
                    <FieldItem
                      onTextAreaChange={this.handleOnChange}
                      label="Description "
                      name="plantDescription"
                      value={plantDescription}
                      placeholder="Description "
                      type={FIELD_TYPES.TEXT_AREA}
                      className="form-control required"
                      required
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      label="Cancel"
                      className="ml-2 btn btn-outline-secondary"
                      onClick={this.toggle}
                    />
                    <Button
                      label={this.state.rowEditById > 0 ? "Update" : "Save"}
                      className="ml-2 btn btn-theme-primary"
                      onClick={this.onsubmit}
                    />
                  </ModalFooter>
                </Modal>
                <div className="row m-0">
                  {/* <div className="col-lg-4 col-md-6 pro-3">
                        <div className="PlantsView_box">
                            <div className="Plants_img_h">
                                <img src="Img/ZSeXDC.jpg" />
                            </div>
                            <div className="Plants_Short_Dt">
                                <div className="Plants_Short_Dt_inner">
                                    <div className="Plants_Short_Dt_header pb-3">
                                        Project Name
                                        <h4>20<small>%</small></h4>
                                    </div>
                                    <div className="Plants_states">
                                        <ul>
                                            <li>
                                                state 1
                                            </li>
                                            <li>
                                                state 2
                                            </li>
                                            <li>
                                                state 3
                                            </li>
                                            <li>
                                                state 4
                                            </li>
                                            <li>
                                                state 5
                                            </li>
                                        </ul>
                                        <div className="State_counts">
                                            +5
                                        </div>
                                    </div>
                                </div>
                                <div className="Plants_Short_Dt_footer">
                                    <div className="Single-Project_Wise_data_count">
                                        <div className="planned w-100"> 100</div>
                                        <div className="plantted w-50"> 80</div>
                                        <div className="dead"> 02</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 pro-3">
                        <div className="PlantsView_box">
                            <div className="Plants_img_h">
                                <img src="Img/ZSeXDC.jpg" />
                            </div>
                            <div className="Plants_Short_Dt">
                                <div className="Plants_Short_Dt_inner">
                                    <div className="Plants_Short_Dt_header pb-3">
                                        Project Name
                                        <h4>20<small>%</small></h4>
                                    </div>
                                    <div className="Plants_states">
                                        <ul>
                                            <li>
                                                state 1
                                            </li>
                                            <li>
                                                state 2
                                            </li>
                                            <li>
                                                state 3
                                            </li>
                                            <li>
                                                state 4
                                            </li>
                                            <li>
                                                state 5
                                            </li>
                                        </ul>
                                        <div className="State_counts">
                                            +5
                                        </div>
                                    </div>
                                </div>
                                <div className="Plants_Short_Dt_footer">
                                    <div className="Single-Project_Wise_data_count">
                                        <div className="planned w-100"> 100</div>
                                        <div className="plantted w-50"> 80</div>
                                        <div className="dead"> 02</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 pro-3">
                        <div className="PlantsView_box">
                            <div className="Plants_img_h">
                                <img src="Img/ZSeXDC.jpg" />
                            </div>
                            <div className="Plants_Short_Dt">
                                <div className="Plants_Short_Dt_inner">
                                    <div className="Plants_Short_Dt_header pb-3">
                                        Project Name
                                        <h4>20<small>%</small></h4>
                                    </div>
                                    <div className="Plants_states">
                                        <ul>
                                            <li>
                                                state 1
                                            </li>
                                            <li>
                                                state 2
                                            </li>
                                            <li>
                                                state 3
                                            </li>
                                            <li>
                                                state 4
                                            </li>
                                            <li>
                                                state 5
                                            </li>
                                        </ul>
                                        <div className="State_counts">
                                            +5
                                        </div>
                                    </div>
                                </div>
                                <div className="Plants_Short_Dt_footer">
                                    <div className="Single-Project_Wise_data_count">
                                        <div className="planned w-100"> 100</div>
                                        <div className="plantted w-50"> 80</div>
                                        <div className="dead"> 02</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 pro-3">
                        <div className="PlantsView_box">
                            <div className="Plants_img_h">
                                <img src="Img/ZSeXDC.jpg" />
                            </div>
                            <div className="Plants_Short_Dt">
                                <div className="Plants_Short_Dt_inner">
                                    <div className="Plants_Short_Dt_header pb-3">
                                        Project Name
                                        <h4>20<small>%</small></h4>
                                    </div>
                                    <div className="Plants_states">
                                        <ul>
                                            <li>
                                                state 1
                                            </li>
                                            <li>
                                                state 2
                                            </li>
                                            <li>
                                                state 3
                                            </li>
                                            <li>
                                                state 4
                                            </li>
                                            <li>
                                                state 5
                                            </li>
                                        </ul>
                                        <div className="State_counts">
                                            +5
                                        </div>
                                    </div>
                                </div>
                                <div className="Plants_Short_Dt_footer">
                                    <div className="Single-Project_Wise_data_count">
                                        <div className="planned w-100"> 100</div>
                                        <div className="plantted w-50"> 80</div>
                                        <div className="dead"> 02</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 pro-3">
                        <div className="PlantsView_box">
                            <div className="Plants_img_h">
                                <img src="Img/ZSeXDC.jpg" />
                            </div>
                            <div className="Plants_Short_Dt">
                                <div className="Plants_Short_Dt_inner">
                                    <div className="Plants_Short_Dt_header pb-3">
                                        Project Name
                                        <h4>20<small>%</small></h4>
                                    </div>
                                    <div className="Plants_states">
                                        <ul>
                                            <li>
                                                state 1
                                            </li>
                                            <li>
                                                state 2
                                            </li>
                                            <li>
                                                state 3
                                            </li>
                                            <li>
                                                state 4
                                            </li>
                                            <li>
                                                state 5
                                            </li>
                                        </ul>
                                        <div className="State_counts">
                                            +5
                                        </div>
                                    </div>
                                </div>
                                <div className="Plants_Short_Dt_footer">
                                    <div className="Single-Project_Wise_data_count">
                                        <div className="planned w-100"> 100</div>
                                        <div className="plantted w-50"> 80</div>
                                        <div className="dead"> 02</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 pro-3">
                        <div className="PlantsView_box">
                            <div className="Plants_img_h">
                                <img src="Img/ZSeXDC.jpg" />
                            </div>
                            <div className="Plants_Short_Dt">
                                <div className="Plants_Short_Dt_inner">
                                    <div className="Plants_Short_Dt_header pb-3">
                                        Project Name
                                        <h4>20<small>%</small></h4>
                                    </div>
                                    <div className="Plants_states">
                                        <ul>
                                            <li>
                                                state 1
                                            </li>
                                            <li>
                                                state 2
                                            </li>
                                            <li>
                                                state 3
                                            </li>
                                            <li>
                                                state 4
                                            </li>
                                            <li>
                                                state 5
                                            </li>
                                        </ul>
                                        <div className="State_counts">
                                            +5
                                        </div>
                                    </div>
                                </div>
                                <div className="Plants_Short_Dt_footer">
                                    <div className="Single-Project_Wise_data_count">
                                        <div className="planned w-100"> 100</div>
                                        <div className="plantted w-50"> 80</div>
                                        <div className="dead"> 02</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                    <div className="col-lg-12 pt-3">
                        <div className="float-left Plants_perpage">
                            Project per page
                            <select className="form-control">
                                <option>10</option>
                                <option>20</option>
                                <option>30</option>
                            </select>
                        </div>
                        <div>
                            <ul className="pagination justify-content-center m-0">
                                <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                <li className="page-item"><a className="page-link" href="#">1</a></li>
                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                <li className="page-item"><a className="page-link" href="#">Next</a></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
                <div className="grid">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      domLayout="autoHeight"
                      enableBrowserTooltips={true}
                      context={this.state.context}
                      defaultColDef={{
                        resizable: true,
                        minWidth: 100,
                      }}
                      onGridReady={this.onGridReady}
                      rowData={this.state.tableRecords}
                      frameworkComponents={{
                        ActionColumn: ActionRow,
                      }}
                      suppressDragLeaveHidesColumns={true}
                    >
                      <AgGridColumn
                        field="plantType"
                        sortable={true}
                        flex={1}
                        filter={true}
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="plantName"
                        sortable={true}
                        flex={1}
                        filter={true}
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="Description"
                        sortable={true}
                        filter={true}
                        flex={2}
                        tooltipField="Description"
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="Action"
                        cellRenderer="ActionColumn"
                        suppressMovable={true}
                      />
                    </AgGridReact>
                    <div className="col-md-12 mt-2 ">
                      <Pagination
                        page={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        handlePageChange={this.handlePageClick}
                        totalPage={pagination.totalPages}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default ListPlant;
