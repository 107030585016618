import { fetchCall, requestMethod } from "../../utils/ajax";
import {
  procurementRequestModal,
  procurementChangeStatusRequestModal,
  deliveryRequestModal,
  deliveryChangeStatusRequestModal
} from "../../model";
import { paymentRequestModal } from "../../model/procurement/paymentModal";

const createProcurementRequest = (procurementData: procurementRequestModal) =>
  fetchCall("/procurement/save", requestMethod.POST, procurementData);
  const createPaymentRequest = (paymentData: paymentRequestModal) =>
  fetchCall("/procurement/add_payment", requestMethod.POST, paymentData);

const getProcurementDetailById = (id: number) =>
  fetchCall(`/procurement/detail?id=${id}`, requestMethod.GET);
  const getPaymentDetailById = (id: number) =>
  fetchCall(`/procurement/payment_detail?id=${id}`, requestMethod.GET);

const getAllProcurementList = (page:number=1, pageSize:number=10, key:string="") =>
  fetchCall(
    `/procurement/list?page=${page}&size=${pageSize}&sort=id&order=desc&status=active,inactive&keyword=${key}`,
    requestMethod.GET
  );

const procurementChangeStatus = (requestData: procurementChangeStatusRequestModal) =>
  fetchCall("/procurement/change_status", requestMethod.POST, requestData);

const createDeliveryRequest = (requestData: deliveryRequestModal) =>
  fetchCall("/procurement/save_delivery", requestMethod.POST, requestData);


const getAllDeliveryList = (id: any, page:number=1, pageSize:number=10, key:string="") =>
  fetchCall(
    `/procurement/list_delivery?page=${page}&size=${pageSize}&sort=delivered_date&order=desc&status=active,inactive&keyword=${key}&procurement_id=${id}`,
    requestMethod.GET
  );

  const getAllPaymentHistoryList = (id: any) =>
  fetchCall(
    `/procurement/payment_history?procurement_id=${id}`,
    requestMethod.GET
  );

  const getProcurementDeliveryDetailById = (id: any) =>
  fetchCall(`/procurement/detail_delivery?id=${id}`, requestMethod.GET);

  const DeliveryChangeStatus = (requestData: deliveryChangeStatusRequestModal) =>
  fetchCall("/procurement/change_status_delivery", requestMethod.POST, requestData);

const procurementService = {
  createProcurementRequest,
  createPaymentRequest,
  getAllProcurementList,
  getProcurementDetailById,
  getPaymentDetailById,
  procurementChangeStatus,
  createDeliveryRequest,
  getAllDeliveryList,
  getAllPaymentHistoryList,
  getProcurementDeliveryDetailById,
  DeliveryChangeStatus
};

export { procurementService };
