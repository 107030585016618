import React, { Component } from "react";

class CarbonViewWidget extends Component {
  render() {
    return (
      <div className="d-flex p-2">
        <div style={{ margin: "auto" }}>
          <div className="d-flex flex-row ">
            <div className="messageBox rightMessageBox text-center p-2">
              CO<sub>2</sub>
              <br />
              Absorbed 150 Tons
            </div>
            <img
              width="250px"
              src={`${process.env.PUBLIC_URL}/assets/img/carbonOverview.jpg`}
              alt="carbonOverview"
            />
             <div className="messageBox-top rightMessageBox  align-self-start text-center p-2">
              O<sub>2</sub>
              <br />
              Produced 100 Tons
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CarbonViewWidget;
