import React, { Component } from "react";
import DatePicker from "react-datepicker";
interface numericRowProps {
  context: any;
  data: any;
  handleChangeQty: () => void;
}
interface numericRowStateProps {
  date: Date;
}
export default class ReceivedDate extends Component<
  numericRowProps,
  numericRowStateProps
> {
  constructor(props: numericRowProps) {
    super(props);
    this.state = {
      date: new Date(),
    };
  }

  componentDidMount() {
    this.setState({ date: this.props.data.receiveDate });
  }

  formatDate(date: string) {
    const splittedDate = date.split("/");
    const formattedDate = new Date(
      +splittedDate[2],
      +splittedDate[1] - 1,
      +splittedDate[0]
    );
    return formattedDate;
  }

  handleChangeQty = (date: any) => {
    this.props.context.componentParent.setDate(this.props.data.id, date);
    this.setState({ date: date });
  };

  // isPopup(): boolean {
  //   return true;
  // }
  render() {
    return (
      <DatePicker
        onChange={this.handleChangeQty}
        dateFormat="dd/MM/yyyy"
        name="deliveryDate"
        selected={this.state.date}
        // minDate={this.formatDate(this.props.data.distributionDate)}
        placeholderText="Received Date"
        portalId="root"
        className="form-control required"
        required
      />
    );
  }
}
