import { fetchCall, requestMethod } from "../../utils/ajax";

// const getAllProjectList = () =>
//     fetchCall("/projects/list?page=1&size=1000&sort=id&order=desc&status=active,inactive&keyword=", requestMethod.GET, {});

// const getAllTreeList = () =>
//     fetchCall("/master/get_trees", requestMethod.GET, {}, false);

// const createProjectRequest = (userData: any) =>
//     fetchCall("/projects/save", requestMethod.POST, userData);

// const projectImageUploadRequest = (data: FormData) =>
//     fetchCall("/files/upload", requestMethod.POST, data, {}, true);

// const getprojectDetailById = (ProjectId: any) =>
//     fetchCall(`/projects/detail?project_id=${ProjectId}`, requestMethod.GET);

// const projectChangeStatus = (userData: any) =>
//     fetchCall("/projects/change_status", requestMethod.POST, userData);

const getAllSaplingData = (districtId: string = '') =>
  fetchCall(
    `/distribution/fetch_district_dispatches?district_id=${districtId}`,
    requestMethod.GET,
    {},
    false
  );

const saveSaplingData = (data: any) =>
  fetchCall(
    "/distribution/save_district_receivables",
    requestMethod.POST,
    data
  );

const saplingService = {
  getAllSaplingData,
  saveSaplingData,
};

export { saplingService };
