import React, { Component, Fragment } from "react";
import { FieldItem, FIELD_TYPES } from "../../../widgets/fields";
import Button from "../../../widgets/button";
import { showToaster, toasterTypes } from "../../../widgets";
import { procurementService, projectService } from "../../../service";
import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { Validation } from "../../../utils";
import { constants } from "../../../constants";
import { Col, Row } from "reactstrap";
import ConfirmationPopup from "../../../widgets/confirmationPopup";
import { EditPaymentProps, EditPaymentState } from "./interface";

const acceptedExtensions = [
  ".pdf",
  ".csv",
  ".docx",
  ".jpeg",
  ".png",
  ".jpg",
  "xlsx",
  "xls",
];
// const acceptedExtensions = [".jpeg", ".png", ".jpg"];docx|doc|csv|xls|xlsx
class EditPayment extends Component<EditPaymentProps, EditPaymentState> {
  constructor(props: EditPaymentProps) {
    super(props);
    this.state = {
      paidAmount: "",
      perSaplingRate: 0,
      manureCost: 0,

      labourCost: 0,
      otherCost: 0,
      remarks: "",
      paymentDate: new Date(),
      procurementType: null,
      // orderDate: "",
      deliveryDate: "",
      orderQuantity: "",
      deliverQuantity: "",
      estimatedAmount: 0,

      procurementTypeOptions: [
        { value: "seed", label: "Seed" },
        { value: "sapling", label: "Sapling" },
      ],
      isEdit: false,
      supplierName: "",
      attachment: [
        {
          selectedFile: null,
          fileDescription: "",
          isUploaded: true,
          tempUrl: "",
        },
      ],
      attachedFiles: [],
      isDeleteCurrentAttachmentPopupConfirmation: false,
      currentAttachmentDeleteIndex: 0,
      isDeletedAlreadyAttachedpopupConfirmation: false,
      alreadyAttchedDocumentDeleteIndex: 0,
      ispaidAmountGreaterThanEstimatedAmount: false,
    };
  }

  componentDidMount() {
    if (this.props.match.params.edit && this.props.match.params.edit > 0) {
      this.setState({ isEdit: true });
      this.getPaymenttDetail();
    }
  }

  getPaymenttDetail = () => {
    try {
      procurementService
        .getPaymentDetailById(this.props.match.params.edit)
        .subscribe(
          (response: any) => {
            if (response?.status && response?.statusCode === 200) {
              if (
                response.data
              ) {
                console.log(response)
                let paymentData: any = {
                  remarks: response.data.remarks,

                  paymentDate: new Date(response.data.payment_date),

                  paidAmount: response.data.paid_amount,
                };

                console.log(paymentData)
                this.setState({ ...paymentData });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error.message);
    }
  };

  onSubmit = () => {
    try {
      if (Validation.isRequired(this.state.paidAmount, "paid amount")) {
        return false;
      }
      if (Validation.isValidNumber(this.state.paidAmount, "Paid Amount")) {
        return false;
      }
      if (
        Validation.isRequired(this.state.paymentDate.toString(), "payment Date")
      ) {
        return false;
      } else {
        this.save();
      }
      // if (
      //   Validation.isRequired(this.state.orderDate, "order Date")
      // ) {
      //   return false;
      // }

      // if (Validation.isValidNumber(this.state.orderQuantity, "Order Quantity")) {
      //   return false;
      // }
      // if (Validation.isValidNumber(this.state.estimatedAmount, "Estimated Amount")) {
      //   return false;
      // }
      // if (Validation.isValidNumber(this.state.paidAmount, "Paid Amount")) {
      //   return false;
      // }
      /**
       * if image is there upload file and save else directly save
       */
      // this.uploadDocumentsAndSubmit();
    } catch (error) {
      console.error(error?.message);
    }
  };

  // uploadDocumentsAndSubmit = () => {
  //   if (!this.state.attachment[0].isUploaded) {
  //     Promise.all(this.uploadImages()).then((uploadFileResponse) => {
  //       if (
  //         uploadFileResponse?.length === this.state.attachment?.length ||
  //         this.state.isEdit
  //       ) {
  //         let status = true;
  //         const error: string[] = [];
  //         // validate all upload are success
  //         uploadFileResponse.forEach((response) => {
  //           if (!(response?.status && response.statusCode === 200)) {
  //             status = false;
  //             error.push(response?.message);
  //           }
  //         });
  //         if (status) {
  //           this.save(uploadFileResponse);
  //         } else {
  //           showToaster(toasterTypes.ERROR, error.join(", "));
  //         }
  //       } else {
  //         showToaster(
  //           toasterTypes.ERROR,
  //           "Unable to upload images, please try again letter"
  //         );
  //       }
  //     });
  //   } else {
  //     this.save();
  //   }
  // };

  //   /**
  //    * save project
  //    * @param uploadImagesResponse
  //    */

  save = () => {
    try {
      const {
        paidAmount,

        paymentDate,

        remarks,
      } = this.state;

      const requestData: any = {
        payment_date: paymentDate,
        paid_amount: paidAmount,
        procurement_id: this.props.match.params.procurementId,
        // procurement_type: procurementType?.value,
        // supplier_name: supplierName,
        // per_sapling_rate:perSaplingRate,
        // manure_cost:manureCost,
        // labour_cost:labourCost,
        // other_cost:otherCost,
        // order_date: orderDate,
        // order_quantity: orderQuantity,
        // estimated_amount: this.totalAmount(),
        // paid_amount: paidAmount,
        remarks: remarks,
        // documents: format
      };

      if (this.props.match.params.edit >= 0) {
        requestData.id = this.props.match.params.edit;
        requestData.procurement_id = this.props.match.params.procurementId;
        // let requestDocument = this.state.attachedFiles?.map(
        //   (element: any, index: number) => ({
        //     id: element?.id,
        //     file_description: element?.fileDescription,
        //     file_id: element?.fileId,
        //   })
        // );
        // requestData.documents = [...requestDocumentFormat, ...requestDocument];
      }
      procurementService.createPaymentRequest(requestData).subscribe(
        (data: any) => {
          if (data?.status && data?.statusCode === 200) {
            showToaster(toasterTypes.SUCCESS, data.message);
            this.props.history.push(
              `/listPaymentHistory/${this.props.match.params.procurementId}`
            );
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  // uploadImages = (): Promise<any>[] => {
  //   const uploadPromise: Promise<any>[] = [];
  //   this.state.attachment.forEach((file: any) => {
  //     if (!file.isUploaded) {
  //       const data: FormData = new FormData();
  //       data.append("file", file.selectedFile);
  //       const request = projectService
  //         .projectImageUploadRequest(data)
  //         .toPromise();
  //       uploadPromise.push(request);
  //     }
  //   });
  //   return uploadPromise;
  // };

  // addRow = () => {
  //   let row = [
  //     ...this.state.attachment,
  //     {
  //       selectedFile: null,
  //       fileDescription: "",
  //       isUploaded: false,
  //     },
  //   ];
  //   this.setState({ attachment: row });
  // };
  // removeRow = (index: number) => {
  //   try {
  //     let row = [...this.state.attachment];

  //     if (row.length >= 1) {
  //       row.splice(index, 1);
  //       this.setState({ attachment: row });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // removeAttachment = (index: number) => {
  //   try {
  //     let row = [...this.state.attachedFiles];
  //     if (row.length >= 1) {
  //       row.splice(index, 1);
  //       this.setState({ attachedFiles: row });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // handleDynamicChange = (event: any, index: number) => {
  //   try {
  //     const { name, value } = event.currentTarget;
  //     const state: any = [...this.state.attachment];
  //     if (event.currentTarget.name === "selectedFile") {
  //       try {
  //         const file = event.target.files[0];

  //         let isValidFile = false;
  //         if (!file) {
  //           // if its not a file skip the iteration
  //           return;
  //         } else {
  //           acceptedExtensions.forEach((extension) => {
  //             if (file["name"].toLowerCase().includes(extension)) {
  //               isValidFile = true;
  //             }
  //           });
  //         }
  //         if (isValidFile) {
  //           if (file.size && file.size / 1024 / 1024 > 10) {
  //             showToaster(
  //               toasterTypes.ERROR,
  //               "The File you are attempting to upload is larger than the permitted size of 10MB"
  //             );
  //             return true;
  //           }
  //         } else {
  //           showToaster(
  //             toasterTypes.ERROR,
  //             "File format .jpeg, .png, .jpg only supported"
  //           );
  //           return true;
  //         }

  //         state[index][name] = event.target.files[0];
  //         state[index]["isUploaded"] = false;
  //         var reader = new FileReader();
  //         var url = reader.readAsDataURL(file);
  //         const that = this;
  //         reader.onloadend = function (e: any) {
  //           state[index]["tempUrl"] = reader.result;
  //           that.setState(state);
  //         }.bind(this);

  //         this.setState(state);
  //       } catch (error) {
  //         console.log(error.message);
  //       }
  //     } else {
  //       state[index][name] = value;
  //       this.setState(state);
  //     }
  //   } catch (error) {
  //     console.log(error?.message);
  //   }
  // };
  handleOnChange = (event: any) => {
    try {
      const { name, value } = event.currentTarget;
      const state: any = { ...this.state };
      state[name] = value;
      this.setState(state);
    } catch (error) {
      console.log(error?.message);
    }
  };
  handleChange = (procurementType: any) => {
    this.setState({ procurementType });
  };
  changeprocurementDate = (paymentDate: any) => {
    this.setState({ paymentDate });
  };

  // changeOrderDate = (orderDate: any) => {
  //   this.setState({ orderDate });
  // };
  render() {
    const {
      handleOnChange,
      changeprocurementDate,
      state: {
        isEdit,
        paidAmount,

        remarks,
        paymentDate,
      },
    } = this;

    return (
      <main id="main">
        <section id="MyProfile" className="MyProfile">
          <div className="container" data-aos="fade-up">
            <div className="row ">
              <div className="col-lg-12 d-flex flex-column justify-content-center ">
                <div className="row ">
                  <div className="col-lg-8 ">
                    <Row>
                      <Col md={11}>
                        <h3 className="heading-secondary">
                          Create/Update Payment
                        </h3>
                      </Col>
                      <Col md={1}>
                        <Link
                          to={`/listPaymentHistory/${this.props.match.params.procurementId}`}
                          className="text-decoration-none text-dark"
                        >
                          <i className="fa fa-times float-right" id="close"></i>
                        </Link>
                        <UncontrolledTooltip target={`close`}>
                          Close
                        </UncontrolledTooltip>
                      </Col>
                    </Row>
                    <div className="row  mb-5">
                      <div className="col-lg-6 col-sm-12">
                        <FieldItem
                          onDateChange={changeprocurementDate}
                          label="Payment Date "
                          name="paymentDate"
                          value={paymentDate}
                          placeholder="Payment Date "
                          type={FIELD_TYPES.DATE_PICKER}
                          // minDate={
                          //   isEdit ? new Date(procurementDate) : new Date()
                          // }
                          className="form-control required"
                          required
                        />
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <FieldItem
                          onChange={handleOnChange}
                          label="Paid Amount "
                          name="paidAmount"
                          value={paidAmount}
                          placeholder="Paid Amount "
                          type={FIELD_TYPES.TEXT}
                          className="form-control required"
                          required
                        />
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <FieldItem
                          onTextAreaChange={handleOnChange}
                          label="Remarks "
                          name="remarks"
                          value={remarks}
                          placeholder="Remarks "
                          type={FIELD_TYPES.TEXT_AREA}
                          className="form-control"
                        />
                      </div>

                      <div className="col-lg-12 col-sm-12 text-right">
                        <Link to="/listProcurement">
                          <Button
                            label="Cancel"
                            className="mr-2 btn btn-outline-secondary"
                          />
                        </Link>
                        <Button
                          onClick={this.onSubmit}
                          className="btn btn-theme-primary"
                          label={isEdit ? "Update" : "Save"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default EditPayment;
