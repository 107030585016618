import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "../../../../widgets/button";
import { Redirect } from "react-router-dom";

interface editRowProps {
  context: any;
  data: any;
}
interface editRowStateProps {
  modal: boolean;
  rowEditById: number;
}
export class EditRow extends Component<editRowProps, editRowStateProps> {
  constructor(props: editRowProps) {
    super(props);
    this.state = {
      modal: false,
      rowEditById: 0,
    };
  }
  editRow = () => {
    this.setState({ rowEditById: this.props.data.id });
  };
  changeStatus = () => {
    this.props.context.componentParent.changeStatusHandler(this.props.data);
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  closeBtn = (
    <button className="close" title="Close" onClick={this.toggle}>
      &times;
    </button>
  );
  render() {
    if (this.state.rowEditById !== 0) {
      return <Redirect to={`/createProcurement/${this.state.rowEditById}`} />;
    }
    return (
      <>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader
            className="modal_Bg"
            close={this.closeBtn}
            toggle={this.toggle}
          >
            <div className="modal_title"> Confirmation Alert</div>
          </ModalHeader>
          <ModalBody>Are you sure to Delete?</ModalBody>
          <ModalFooter>
            <Button
              label="No"
              className="ml-2 btn btn-outline-secondary"
              onClick={this.toggle}
            />
            <Button
              label="Yes"
              className="ml-2 btn btn-theme-primary"
              onClick={this.changeStatus}
            />
          </ModalFooter>
        </Modal>
        <button
          className="Action_btn"
          onClick={() => this.editRow()}
          title="Edit"
        >
          <i className="bi bi-pencil-square"></i>
        </button>
        <button
          className="Action_btn"
          style={{ marginLeft: "16px" }}
          onClick={this.toggle}
          title="Delete"
        >
          <i className="bi bi-trash"></i>
        </button>
      </>
    );
  }
}

//  default EditRow;
