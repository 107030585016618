import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "../../../widgets/button";
import { FieldItem, FIELD_TYPES } from "../../../widgets/fields";
import { dispatchRowProps, dispatchRowStateProps } from "./interface";
import { showToaster, toasterTypes } from "../../../widgets";
import { distributionService } from "../../../service";
import { Validation } from "../../../utils";
import _ from "lodash";

export class AddDispatchPopup extends Component<
  dispatchRowProps,
  dispatchRowStateProps
> {
  constructor(props: dispatchRowProps) {
    super(props);
    this.state = {
      dispatchDate: "",
      dispatchQuantity: "",
      selectedUser: "",
      openListModal: false,
      listDispatchPage: false,
      remarks: "",
      type: "",
    };
  }

  getDispatchDetail = () => {
    try {
      distributionService
        .getDispatchDetailById(this.props.dispatchId)
        .subscribe(
          (response: any) => {
            try {
              if (response?.status && response?.statusCode === 200) {
                if (response.data) {
                  const selectedArea = this.props.usersOptions?.find(
                    (data: any) => data?.value === response?.data?.dispatched_to
                  );
                  this.setState({
                    dispatchDate: new Date(response.data.distribution_date),
                    dispatchQuantity: response.data.seed_quantity,
                    remarks: response.data.dispatch_remarks,
                    type: {
                      value: response.data.distribution_type,
                      label: _.capitalize(response.data.distribution_type),
                    },
                    selectedUser: selectedArea ? selectedArea : {},
                  });
                }
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error.message);
    }
  };

  handleOnChange = (event: any, fieldName: string = "") => {
    try {
      if (fieldName === "type") {
        this.setState({ type: event });
        return;
      }
      const { name, value } = event.currentTarget;
      const state: any = { ...this.state };
      state[name] = value;
      this.setState(state);
    } catch (error) {
      console.log(error?.message);
    }
  };
  handleChange = (selectedUser: any) => {
    this.setState({ selectedUser });
  };
  changeDispatchDate = (dispatchDate: any) => {
    this.setState({ dispatchDate });
  };
  reset = () => {
    this.setState({
      dispatchDate: "",
      dispatchQuantity: "",
      selectedUser: "",
      openListModal: false,
      listDispatchPage: false,
      remarks: "",
      type: "",
    });
  };
  cancel = () => {
    this.reset();
    this.props.toggle();
  };
  onSubmit = () => {
    try {
      if (Validation.isRequired(this.state.dispatchDate, "dispatch date")) {
        return false;
      }
      if (
        Validation.isRequired(this.state.dispatchQuantity, "dispatch quantity")
      ) {
        return false;
      }
      if (
        Validation.isValidNumber(
          this.state.dispatchQuantity,
          "Dispatch quantity"
        )
      ) {
        return false;
      }
      if (
        +this.props.totalQuantity.totalDelivered -
          +this.props.totalQuantity.totalDispatched <
          +this.state.dispatchQuantity &&
        !this.props.isEdit
      ) {
        showToaster(
          toasterTypes.ERROR,
          `Dispatch limit exceeded by ${
            +this.state.dispatchQuantity -
            (+this.props.totalQuantity.totalDelivered -
              +this.props.totalQuantity.totalDispatched)
          } quantity`
        );
        return false;
      }
      if (
        +this.props.totalQuantity.totalDelivered <
          +this.props.totalQuantity.totalDispatched -
            +this.props.totalQuantity.currentDispatchValue +
            +this.state.dispatchQuantity &&
        this.props.isEdit
      ) {
        showToaster(
          toasterTypes.ERROR,
          `Dispatch limit exceeded by ${
            +this.props.totalQuantity.totalDispatched -
            +this.props.totalQuantity.currentDispatchValue +
            +this.state.dispatchQuantity -
            +this.props.totalQuantity.totalDelivered
          } quantity`
        );
        return false;
      }

      if (Validation.isNotNull(this.state.selectedUser, "User")) {
        return false;
      }
      const { remarks, dispatchDate, dispatchQuantity, selectedUser, type } =
        this.state;

      const requestData: any = {
        distribution_date: dispatchDate,
        seed_quantity: dispatchQuantity,
        district_id: this.props.districtId,
        remarks: remarks,
        volunteer_id: selectedUser?.value,
        distribution_type: type?.value,
      };

      if (this.props.isEdit) {
        requestData.id = this.props.dispatchId;
      }
      distributionService.createDispatchRequest(requestData).subscribe(
        (data: any) => {
          try {
            if (data?.status && data?.statusCode === 200) {
              showToaster(toasterTypes.SUCCESS, data.message);
              this.props.toggle();
              this.props.refresh();
            } else {
              showToaster(toasterTypes.ERROR, data.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  closeBtn = (
    <button className="close" title="Close" onClick={this.cancel}>
      &times;
    </button>
  );

  render() {
    const {
      handleOnChange,
      handleChange,
      onSubmit,
      changeDispatchDate,
      state: { dispatchDate, dispatchQuantity, selectedUser, remarks, type },
      props: { isEdit },
    } = this;

    return (
      <>
        <Modal isOpen={this.props.modal} toggle={this.cancel}>
          <ModalHeader
            className="modal_Bg"
            toggle={this.cancel}
            close={this.closeBtn}
          >
            <div className="modal_title">
              {isEdit ? "Update" : "Add"} Dispatch
            </div>
          </ModalHeader>
          <ModalBody>
            <>
              <div className="row  mb-5">
                <div className="col-lg-12 col-sm-12">
                  <FieldItem
                    onDateChange={changeDispatchDate}
                    label="Dispatch Date "
                    name="dispatchDate"
                    value={dispatchDate}
                    placeholder="Dispatch Date "
                    type={FIELD_TYPES.DATE_PICKER}
                    // minDate={new Date()}
                    className="form-control required"
                    required
                  />
                </div>

                <div className="col-lg-12 col-sm-12">
                  <FieldItem
                    onChange={handleOnChange}
                    label="Dispatch Quantity "
                    name="dispatchQuantity"
                    value={dispatchQuantity}
                    placeholder="Dispatch Quantity "
                    type={FIELD_TYPES.TEXT}
                    className="form-control required"
                    required
                  />
                </div>

                <div className="col-lg-12 col-sm-12">
                  <FieldItem
                    onChange={(e) => handleOnChange(e, "type")}
                    label="Dispatch Type"
                    name="type"
                    value={type}
                    options={[
                      { value: "seed", label: "Seed" },
                      { value: "sapling", label: "Sapling" },
                    ]}
                    placeholder="Dispatch Type"
                    type={FIELD_TYPES.DROP_DOWN}
                    className="required"
                    required
                  />
                </div>

                <div className="col-lg-12 col-sm-12">
                  <FieldItem
                    onChange={handleChange}
                    label="User"
                    name="selectedUser"
                    value={selectedUser}
                    options={this.props.usersOptions}
                    placeholder="User"
                    type={FIELD_TYPES.DROP_DOWN}
                    className="required"
                    required
                  />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <FieldItem
                    onTextAreaChange={handleOnChange}
                    label="Remarks "
                    name="remarks"
                    value={remarks}
                    placeholder="Remarks "
                    type={FIELD_TYPES.TEXT_AREA}
                    className="form-control"
                  />
                </div>
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button
              label="Cancel"
              className="ml-2 btn btn-outline-secondary"
              onClick={this.cancel}
            />
            <Button
              onClick={onSubmit}
              label={isEdit ? "Update" : "Save"}
              className="ml-2 btn btn-theme-primary"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
