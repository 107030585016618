import React, { useState, useEffect } from "react";
import { FieldItem, FIELD_TYPES } from "../../../../widgets/fields";
import { showToaster, toasterTypes } from "../../../../widgets";
import { authenticationService } from "../../../../service";

interface Props {
  data: any;
  roleOptions: { value: string | number; label: string | number }[];
  isReadOnly: boolean;
  obj: any;
}

const RoleChangeRow: React.FunctionComponent<Props> = (props) => {
  const [role, setRole] =
    useState<{ value: string | number; label: string | number }>();
  const roleId = props.data.roleId;
  const roleOptions = props.roleOptions;
  useEffect(() => {
    try {
      const selectedData = roleOptions?.filter((obj) => obj.value === roleId);
      if (Array.isArray(selectedData) && selectedData.length > 0) {
        setRole(selectedData[0]);
      }
    } catch (error) {
      console.log(error?.message);
    }
  }, [roleId, roleOptions]);

  const handleChange = (data: any) => {
    try {
      setRole(data);
      changeRole(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const changeRole = (data: any) => {
    try {
      const requestData: any = {
        user_id: props.data.id,
        user_role_id: data?.value,
      };
      authenticationService.changeUsersRole(requestData).subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            showToaster(toasterTypes.SUCCESS, response.message);
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };
  return (
    <FieldItem
      onChange={handleChange}
      options={props.roleOptions}
      name="role"
      value={role}
      placeholder="Select the role"
      type={FIELD_TYPES.DROP_DOWN}
      menuPortalTarget={document.querySelector("body")}
    />
  );
};

RoleChangeRow.defaultProps = {};

export default RoleChangeRow;
