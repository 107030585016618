import React, { useEffect, useState } from "react";
import HomePage from "./components/home";
import Login from "./components/login";
import SignUp from "./components/signUp";
import ForgetPassword from "./components/forgetPassword";
import { Toaster, toasterPositions } from "./widgets";
import { BrowserRouter, Route, Switch, RouteProps } from "react-router-dom";
import Loader from "./widgets/spinner";
import { loaderObservable } from "./utils/subscriber";
import ErrorBoundary from "./ErrorBountry";
import AboutUs from "./components/aboutUs";
import Testimonials from "./components/testimonials";
import PrivacyPolicy from "./components/privacyPolicy";
import TermsAndCondition from "./components/termsAndCondition";
import ScrollToTop from "./widgets/scrollToTop";
function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    loaderObservable.subscribe({
      next: (value) => setLoading(value),
      complete: () => setLoading(false),
      error: (error) => setLoading(false),
    });
  }, []);

  return (
    <ErrorBoundary>
      <Loader isLoading={loading} />
      <Toaster position={toasterPositions["TOP-RIGHT"]} />
      <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
        <Switch>
          <Route
            exact
            path="/login"
            render={(props: RouteProps) => <Login />}
          />
          <Route
            exact
            path="/SignUp"
            render={(props) => <SignUp {...props} />}
          />
          <Route
            exact
            path="/register/referral/:refferalcode?"
            render={(props) => <SignUp {...props} />}
          />
          <Route
            exact
            path="/forgetpassword"
            render={(props: RouteProps) => <ForgetPassword />}
          />
          <Route
            exact
            path="/aboutUs"
            render={(props: RouteProps) => <AboutUs />}
          />
          <Route
            exact
            path="/testimonials"
            render={(props: RouteProps) => <Testimonials />}
          />
          <Route
            exact
            path="/privacyPolicy"
            render={(props: RouteProps) => <PrivacyPolicy />}
          />
          <Route
            exact
            path="/termsAndCondition"
            render={(props: RouteProps) => <TermsAndCondition />}
          />
          <Route path="/" render={(props) => <HomePage {...props} />} />
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
