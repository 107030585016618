import { Component } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import EditRow from "./partials/EditRow";
import { showToaster, toasterTypes } from "../../widgets";
import { CategorySettingProps, CategorySettingState } from "./interface";
import Pagination from "../../widgets/pagination";
import Tablefeature from "../../widgets/tableFeature";
import Button from "../../widgets/button";
import AddEditCategoryModal from "./_dialogues/add-edit-category";
import { CategoryService } from "../../service/category";
import StatusChange from "./partials/status-change";

class CategorySetting extends Component<CategorySettingProps, CategorySettingState> {
    constructor(props: CategorySettingProps) {
        super(props);
        this.state = {
            editCategory: null,
            tableRecords: [],
            dataTableKey: 0,
            pageSize: 10,
            page: 1,
            gridApi: "",
            context: {
                componentParent: this,
            },
            pagination: {
                pageNumber: 1,
                pageSize: 10,
                totalPages: 1,
                totalElements: 0,
            },
            categoryModal: false,
        };
    }

    reFreshTable = () => {
        this.setState({ dataTableKey: this.state.dataTableKey + 1 });
    };

    getReactContainerClasses() {
        return ["custom-tooltip"];
    }

    handlePageClick = (
        data: { selected: number },
        pageSize: number = this.state.pagination.pageSize
    ) => {
        this.getAllRowData(data.selected, pageSize);
    };

    handleSearch = (e: any) => {
        this.getAllRowData(1, 10, e.target.value);
    };

    getAllRowData = (
        page: number = 1,
        pageSize: number = 10,
        key: string = ""
    ) => {
        try {
            CategoryService.getAllCategories(page, pageSize, key).subscribe(
                (response: any) => {
                    try {
                        if (response.status && response.statusCode === 200) {
                            if (response.data && Array.isArray(response.data)) {
                                let rowData: any = [];
                                response.data.forEach((element: any) => {
                                    rowData.push({
                                        id: element.id,
                                        categoryName: element.name,
                                        status : element.status
                                    });
                                });

                                this.state.gridApi.setRowData(rowData);
                                this.setState({
                                    pagination: {
                                        pageNumber: response.page || 1,
                                        pageSize: response.size || 10,
                                        totalPages: response.totalPages || 1,
                                        totalElements: response.totalElements || 10,
                                    },
                                });
                            }
                        } else {
                            showToaster(toasterTypes.ERROR, response.message);
                        }
                    } catch (error) {
                        console.log(error?.message);
                    }
                },
                (error: any) => {
                    console.log(error);
                    showToaster(toasterTypes.ERROR, error.message);
                }
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    onEditButtonClicked = (category: any) => {
        this.setState({ editCategory: category })
        this.setState({ categoryModal: true })
    }

    onDeleteButtonClicked = (data : any , status :  any) => {
        try {
            const userData = {
              id: data.id,
              status: status.value
            };
            CategoryService.categoryChangeStatus(userData).subscribe(
              (data: any) => {
                if (data.status && data.statusCode === 200) {
                  this.getAllRowData();
                  showToaster(toasterTypes.SUCCESS, data.message);
                } else {
                  showToaster(toasterTypes.ERROR, data.message);
                }
              },
              (error: any) => {
                console.log(error);
                showToaster(toasterTypes.ERROR, error.message);
              }
            );
          } catch (error) {
            console.log(error?.message);
          }
    }


    onGridReady = (params: any) => {
        this.getAllRowData();
        this.setState({
            gridApi: params.api,
        });
    };

    render() {
        const {
            state: { pagination },
            handlePageClick,
        } = this;

        return (
            <main id="main">
                <section id="ViewProject" className="ViewProject">
                    <div className="container" data-aos="fade-up">
                        <div className="row no-gutters">
                            <div className="col-lg-12 d-flex align-items-center justify-content-between mb-4">
                                <div className=" d-flex align-items-center justify-content-start ">
                                    <h3 className="secondary-heading-normal m-0 table-header-label">
                                        Category Settings
                                    </h3>
                                    <Button
                                        label="Add Category"
                                        className="ml-2 btn btn-outline-secondary"
                                        onClick={() => this.setState({ categoryModal: true })}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-12 d-flex flex-column justify-content-center">
                                <Tablefeature
                                    totalElements={pagination.totalElements}
                                    handleSearch={this.handleSearch}
                                />
                                <AddEditCategoryModal modal={this.state.categoryModal}
                                    onSubmitSuccess={this.getAllRowData}
                                    setCategoryModel={(state) => this.setState({ categoryModal: state, editCategory: null })}
                                    editCategory={this.state.editCategory}
                                />
                                <div className="grid">
                                    <div className="ag-theme-alpine">
                                        <AgGridReact
                                            key={this.state.dataTableKey}
                                            domLayout={"autoHeight"}
                                            cacheBlockSize={10}
                                            context={this.state.context}
                                            rowData={this.state.tableRecords}
                                            enableCellChangeFlash={true}
                                            onGridReady={this.onGridReady}
                                            enableBrowserTooltips={true}
                                            frameworkComponents={{
                                                editColumn: EditRow,
                                                statusColumn : StatusChange
                                            }}
                                            suppressDragLeaveHidesColumns={true}
                                        >
                                            <AgGridColumn
                                                field="categoryName"
                                                sortable={true}
                                                flex={2}
                                                filter={true}
                                                tooltipField="Category Name"
                                                suppressMovable={true}
                                            />
                                            <AgGridColumn
                                                field="Status"
                                                flex={1}
                                                suppressMovable={true}
                                                cellRenderer="statusColumn"
                                                cellRendererParams={{
                                                    reFreshTable: this.state.dataTableKey,
                                                }}
                                            />

                                            <AgGridColumn
                                                field="Action"
                                                flex={1}
                                                suppressMovable={true}
                                                cellRenderer="editColumn"
                                                cellRendererParams={{
                                                    reFreshTable: this.state.dataTableKey,
                                                }}
                                            />
                                        </AgGridReact>
                                        <div className="col-md-12 mt-2">
                                            <Pagination
                                                page={pagination.pageNumber}
                                                pageSize={pagination.pageSize}
                                                handlePageChange={handlePageClick}
                                                totalPage={pagination.totalPages}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main >
        );
    }
}

export default CategorySetting;
