import React, { Component, Fragment } from "react";

import { Redirect } from "react-router-dom";
import { AddDeliveryPopup } from "../../component/addDeliveryPopup ";

interface editRowProps {
  data: any;
  context: any;
  totalProcured: string;
  totalReceived: string;
}
interface editRowStateProps {
  modal: boolean;
  deliveryPage: boolean;
}
export class AddEditDeliveryRow extends Component<
  editRowProps,
  editRowStateProps
> {
  constructor(props: editRowProps) {
    super(props);
    this.state = {
      modal: false,
      deliveryPage: false,
    };
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  refresh = () => {
    this.props.context.componentParent.getProcurementCreation();
    this.props.context.componentParent.getProcuredQuantity();
  };

  openListDelivery = () => {
    this.setState({ deliveryPage: true });
  };
  render() {
    const {
      state: { deliveryPage },
    } = this;

    if (deliveryPage) {
      return <Redirect to={`/listDelivery/${this.props.data.id}`} />;
    }
    return (
      <>
        <AddDeliveryPopup
          totalQuantity={{
            totalReceived: this.props.data.DeliveredQuantity,
            totalProcured: this.props.data.ProcurementQuantity,
          }}
          modal={this.state.modal}
          toggle={this.toggle}
          procurementId={this.props.data.id}
          refresh={this.refresh}
        />
        <div className="cursor_point d-flex justify-content-between">
          <span
            className="text-right mr-2 align_count"
            onClick={this.openListDelivery}
          >
            {this.props.data?.total_delivered_count}
          </span>
          <div>
            {" "}
            <button
              className="btn-sm  btn btn-outline-secondary addPopupButton"
              title="Add Delivery"
              onClick={this.toggle}
            >
              {" "}
              Add Delivery
            </button>{" "}
          </div>
        </div>
      </>
    );
  }
}

//  default EditRow;
