import { fetchCall, requestMethod } from "../../utils/ajax";

const ACTIVESTATUS = "active";
const INACTIVESTATUS = "inactive";
const BOTHSTATUS = "active,inactive";
const COMPLETED = "completed";

const getAllCategories = (
    page: number = 1,
    pageSize: number = 100,
    key: string = "",
    status: string = BOTHSTATUS,
) =>
    fetchCall(
        `/master/list_category?page=${page}&size=${pageSize}&sort=id&order=desc&status=${status}&keyword=${key}`,
        requestMethod.GET,
        {}
    );

const addCategories = (payload: any) => {
    return fetchCall("/master/save_category", requestMethod.POST, payload);
}

const addAreas = (payload: any) => {
    return fetchCall("/master/save_districts", requestMethod.POST, payload);
}

const addPincodes = (payload: any) => {
    return fetchCall("/master/save_pincodes", requestMethod.POST, payload);
}

const categoryChangeStatus = (statusData: any) =>
    fetchCall("/master/change_status_category", requestMethod.POST, statusData);

const areaChangeStatus = (statusData: any) =>
    fetchCall("/master/change_status_district", requestMethod.POST, statusData);

const pinCodeChangeStatus = (statusData: any) =>
    fetchCall("/master/change_status_pincode", requestMethod.POST, statusData);

const getAllAreas = (
    page: number = 1,
    pageSize: number = 100,
    key: string = "",
    stateId: string = '',
    status: string = BOTHSTATUS,
) =>
    fetchCall(
        `/master/list_area?page=${page}&size=${pageSize}&sort=id&order=desc&status=${status}&keyword=${key}&state_id=${stateId}`,
        requestMethod.GET,
        {}
    );

const getAllPinCodes = (
    page: number = 1,
    pageSize: number = 100,
    key: string = "",
    districtId: string = '',
    status: string = BOTHSTATUS,
) =>
    fetchCall(
        `/master/list_pin_codes?page=${page}&size=${pageSize}&sort=id&order=desc&status=${status}&keyword=${key}&district_id=${districtId}`,
        requestMethod.GET,
        {}
    );


const CategoryService = {
    getAllCategories,
    addCategories,
    categoryChangeStatus,
    areaChangeStatus,
    pinCodeChangeStatus,
    addAreas,
    addPincodes,
    getAllAreas,
    getAllPinCodes,
    ACTIVESTATUS,
    INACTIVESTATUS,
    BOTHSTATUS,
    COMPLETED
};

export { CategoryService };
