import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Button from "../../../widgets/button";
import { Validation } from "../../../utils";
import { showToaster, toasterTypes } from "../../../widgets";
import { FieldItem, FIELD_TYPES } from "../../../widgets/fields";
import { CategoryService } from "../../../service/category";


type Props = {
  setCategoryModel: (toggleState: boolean) => void,
  onSubmitSuccess: () => void,
  editCategory: {
    id: string;
    categoryName: string
  } | null
  modal: boolean
}

const AddEditCategoryModal = (props: Props) => {

  const [formState, setFormState] = useState({
    id: props?.editCategory?.id ? props?.editCategory.id : '',
    categoryName: props?.editCategory?.categoryName ? props?.editCategory.categoryName : ''
  })

  useEffect(() => {
    if (props.editCategory) {
      setFormState({
        id: props?.editCategory?.id ? props?.editCategory.id : '',
        categoryName: props?.editCategory?.categoryName ? props?.editCategory.categoryName : ''
      })
    }

  }, [props?.editCategory])

  const handleOnChange = (event: any) => {
    try {
      const { name, value } = event.currentTarget;
      setFormState({ ...formState, [name]: value });
    } catch (error) {
      console.log(error?.message);
    }
  };

  const onSubmit = () => {
    try {
      if (Validation.isTextRequired(formState.categoryName, "Category name")) {
        return false;
      }

      const payload: { id?: string, name: string } = {
        name: formState.categoryName
      };

      if (formState.id) {
        payload.id = formState.id;
      }


      CategoryService.addCategories(payload).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            props.onSubmitSuccess();
            deleteToggle();
            reset();
            showToaster(toasterTypes.SUCCESS, data.message);
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const deleteToggle = () => {
    props.setCategoryModel(false)
  };

  const toggle = () => {
    if (props.modal === true) {
      reset();
    }

    props.setCategoryModel(!props.modal)
  };

  const reset = () => {
    setFormState({ categoryName: '', id: '' })
  };

  const closeBtn = (
    <button className="close" title="Close" onClick={toggle}>
      &times;
    </button>
  );

  return <>
    <Modal isOpen={props.modal} toggle={toggle}>
      <ModalHeader
        className="modal_Bg"
        close={closeBtn}
        toggle={toggle}
      >
        <div className="modal_title">
          {formState.id
            ? "Update Category"
            : "Create Category"}
        </div>
      </ModalHeader>
      <ModalBody>
        <FieldItem
          onChange={handleOnChange}
          label="Category Name "
          name="categoryName"
          value={formState.categoryName}
          placeholder="Category Name "
          type={FIELD_TYPES.TEXT}
          className="form-control required"
          required
        />
      </ModalBody>
      <ModalFooter>
        <Button
          label="Cancel"
          className="ml-2 btn btn-outline-secondary"
          onClick={toggle}
        />
        <Button
          label={formState.id ? "Update" : "Save"}
          className="ml-2 btn btn-theme-primary"
          onClick={onSubmit}
        />
      </ModalFooter>
    </Modal></>
}

export default AddEditCategoryModal;