import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { AddDispatchPopup } from "../../components/AddDispatchPopup";
import { showToaster, toasterTypes } from "../../../../widgets";
import { authenticationService } from "../../../../service";
import { UserHelper } from "../../../../utils";
interface dispatchRowProps {
  data: any;
  context: any;
  totalDelivered: string;
  totalDispatched: string;
}
interface dispatchRowStateProps {
  modal: boolean;
  dispatchDate: string;
  dispatchQuantity: string;
  openListModal: boolean;
  listDispatchPage: boolean;
  usersOptions: any;
}
export class DispatchRow extends Component<
  dispatchRowProps,
  dispatchRowStateProps
> {
  constructor(props: dispatchRowProps) {
    super(props);
    this.state = {
      modal: false,
      dispatchDate: "",
      dispatchQuantity: "",
      openListModal: false,
      listDispatchPage: false,
      usersOptions: [],
    };
  }

  openListDispatch = () => {
    this.setState({ listDispatchPage: true });
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
    if (!this.state.modal) {
      this.getAllUserData();
    }
  };

  getAllUserData = () => {
    try {
      let listuserRole = UserHelper.getUserListId();
      authenticationService
        .getAllDistrictAdminList(
          listuserRole.toString(),
          this.props.data.DistrictId
        )
        .subscribe(
          (response: any) => {
            if (response.status && response.statusCode === 200) {
              if (
                Array.isArray(response.content) &&
                response.content?.length > 0
              ) {
                let rowData: any = response.content?.map((element: any) => ({
                  value: element.id,
                  label: element.first_name,
                }));
                this.setState({ usersOptions: rowData });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };

  refresh = () => {
    this.props.context.componentParent.getDistrictLevelDistribution();
  };
  render() {
    const {
      state: { listDispatchPage },
    } = this;
    if (listDispatchPage) {
      return <Redirect to={`/listDispatch/${this.props.data.DistrictId}`} />;
    }
    return (
      <>
        <AddDispatchPopup
          totalQuantity={{
            totalDelivered: this.props.totalDelivered,
            totalDispatched: this.props.totalDispatched,
          }}
          modal={this.state.modal}
          toggle={this.toggle}
          refresh={this.refresh}
          usersOptions={this.state.usersOptions}
          districtId={this.props.data.DistrictId}
        />

        <div className="cursor_point d-flex justify-content-between">
          <span
            className="text-right mr-1 align_count"
            onClick={this.openListDispatch}
          >
            {this.props.data.TotalDispatchCount}
          </span>
          <div>
            <button
              className="btn-sm  btn btn-outline-secondary addPopupButton"
              title="Add Dispatch"
              onClick={this.toggle}
            >
              Add Dispatch
            </button>
          </div>
        </div>
      </>
    );
  }
}
