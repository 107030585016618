import React from "react";

interface Props {
  label?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  type?: string;
  id?: string;
}

const Button: React.FunctionComponent<Props> = ({
  label,
  id,
  onClick,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      className={className}
      title={`${label}`}
    >
      {label}
    </button>
  );
};

Button.defaultProps = {
  label: "",
  onClick: () => {},
  className: "",
  type: "button",
};

export default Button;
