import { fetchCall, requestMethod } from "../../utils/ajax";
import {
  distributionRequestModal,
  pincodeLevelDistributionRequestModal,
  pincodeLevelChangeStatusRequestModal,
  dispatchRequestModal,
} from "../../model";

const getAllListDistrictPlan = () =>
  fetchCall("/distribution/list_district_plans", requestMethod.GET, {});

const saveDistrictPlan = (requestData: distributionRequestModal) =>
  fetchCall("/projects/save_district_plans", requestMethod.POST, requestData);

const createDispatchRequest = (requestData: dispatchRequestModal) =>
  fetchCall(
    "/distribution/save_district_dispatches",
    requestMethod.POST,
    requestData
  );

const getAllDispatchList = (
  districtId: any,
  page: number = 1,
  pageSize: number = 10,
  key: string = ""
) =>
  fetchCall(
    `/distribution/list_district_dispatches?page=${page}&size=${pageSize}&sort=id&order=desc&status=active,inactive&district_id=${districtId}&keyword=${key}`,
    requestMethod.GET,
    {}
  );

// const districtLevelDistributionChangeStatus = (requestData: districtLevelChangeStatusRequestModal) =>
//     fetchCall("/distribution/change_status_district", requestMethod.POST, requestData);

const getAllPincodeDistributionplan = (districtId: string = "") =>
  fetchCall(
    `/distribution/list_pincode_plans?district_id=${districtId}`,
    requestMethod.GET,
    {}
  );

const getAllPincodeDistributionCount = (districtId: string = "") =>
  fetchCall(
    `/distribution/fetch_total_received_dispatched_by_district?district_id=${districtId}`,
    requestMethod.GET,
    {}
  );

const savePincodePlan = (
  requestData: pincodeLevelDistributionRequestModal,
  districtId: string = ""
) =>
  fetchCall(
    `/projects/save_pincode_plans?district_id=${districtId}`,
    requestMethod.POST,
    requestData
  );

const getDispatchDetailById = (id: any, districtId: string = "") =>
  fetchCall(
    `/distribution/detail?id=${id}&district_id=${districtId}`,
    requestMethod.GET,
    {}
  );

const DistributionChangeStatus = (
  requestData: pincodeLevelChangeStatusRequestModal
) => fetchCall("/distribution/change_status", requestMethod.POST, requestData);

const createDistributionRequest = (
  requestData: distributionRequestModal,
  districtId: string = ""
) =>
  fetchCall(
    `/distribution/save_pincode_dispatches?district_id=${districtId}`,
    requestMethod.POST,
    requestData
  );

const getAllDistributionList = (
  pincodeId: any,
  districtId: string = "",
  page: number = 1,
  pageSize: number = 10,
  key: string = ""
) =>
  fetchCall(
    `/distribution/list_pincode_dispatches?page=${page}&district_id=${districtId}&size=${pageSize}&sort=id&order=desc&status=active,inactive&pincode_id=${pincodeId}&keyword=${key}`,
    requestMethod.GET,
    {}
  );

const getDistributionDetailById = (pincodeId: any) =>
  fetchCall(
    `/distribution/list_pincode_dispatches?page=1&size=1000&sort=id&order=desc&status=active,inactive&pincode_id=${pincodeId}&keyword=`,
    requestMethod.GET,
    {}
  );

const distributionService = {
  getAllListDistrictPlan,
  saveDistrictPlan,
  // districtLevelDistributionChangeStatus,
  getAllPincodeDistributionCount,
  createDispatchRequest,
  getAllDispatchList,
  getDispatchDetailById,

  getAllPincodeDistributionplan,
  savePincodePlan,
  DistributionChangeStatus,

  createDistributionRequest,
  getAllDistributionList,
  getDistributionDetailById,
};

export { distributionService };
