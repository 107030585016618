import React, { Component } from "react";
import {
  DistrictLevelDistributionProps,
  DistrictLevelDistributionState,
} from "./interface";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { commonService, distributionService } from "../../../service";
import { showToaster, toasterTypes } from "../../../widgets";
import { DispatchRow } from "./partials/dispatchRow";
import { UserHelper, Validation } from "../../../utils";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Button from "../../../widgets/button";

class DistrictLevelDistribution extends Component<
  DistrictLevelDistributionProps,
  DistrictLevelDistributionState
> {
  static defaultProps = {
    projectName: "",
  };
  constructor(props: DistrictLevelDistributionProps) {
    super(props);
    this.state = {
      modal: false,
      tableData: [],
      distributionQuantity: "",
      totalDispatched: "",
      remarks: "",
      distributionDate: "",
      gridApi: "",
      tableRecords: [],
      context: {
        componentParent: this,
      },
      rowEditById: 0,
      userData: {},
      districtOptions: [],
      selectedDistrict: null,
      totalQuantity: 0,
      projectId: "",
    };
  }

  componentDidMount() {
    const userDetails = UserHelper.getUserDetails();
    this.setState(
      {
        userData: userDetails,
        projectId: this.props.projectId,
      },
      () => {
        this.getProcuredQuantity();
      }
    );
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.projectId !== this.props.projectId) {
      this.setState(
        {
          projectId: this.props.projectId,
        },
        () => {
          this.getProcuredQuantity();
          this.getDistrictLevelDistribution();
        }
      );
    }
  }

  getProcuredQuantity = () => {
    sessionStorage.setItem(
      "distribution_data",
      JSON.stringify({
        projectId: this.state.projectId,
        stateId: this.state.userData.state_id,
      })
    );
    try {
      commonService
        .getTotalProcuredQuantity(
          this.state.projectId,
          this.state.userData.state_id
        )
        .subscribe(
          (response: any) => {
            if (response.status && response.statusCode === 200) {
              if (response.data) {
                this.setState({
                  totalQuantity: response.data.total_delivered,
                  totalDispatched: response.data.total_dispatched,
                });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };
  getDistrictLevelDistribution = () => {
    try {
      distributionService.getAllListDistrictPlan().subscribe(
        (response: any) => {
          try {
            let rowData = [];
            if (response.status && response.statusCode === 200) {
              if (
                Array.isArray(response.content) &&
                response.content?.length > 0
              ) {
                rowData = response.content.map((element: any) => ({
                  id: element.id,
                  DistrictId: element.district_id,
                  District: element.district_name,
                  PlannedQuantity: element.plan,
                  TotalDispatched: element.total_dispatched,
                  TotalReceived: element.total_received,
                  TotalDispatchCount: element.total_dispatched_count,
                }));
                this.setState({ tableRecords: rowData });
                this.onBtStartEditing();
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
            this.setState({ tableRecords: rowData });
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  onBtStartEditing() {
    this.state.gridApi.setFocusedCell(2, "PlannedQuantity");
    this.state.gridApi.startEditingCell({
      rowIndex: 2,
      colKey: "PlannedQuantity",
    });
  }

  cellValueChanged = (e: any) => {
    if (e.newValue?.length > 0) {
      if (!Validation.vaildNnumber(e.newValue)) {
        showToaster(toasterTypes.ERROR, "Invalid data");
        return e.oldValue;
      } else {
        return e.newValue;
      }
    } else {
      return 0;
    }
  };
  save = () => {
    try {
      this.state.gridApi.stopEditing();
      const requestData: any = [];
      console.log(this.state.tableRecords, "  this.state.tableRecords");
      this.state.tableRecords.forEach((element) => {
        if (element["PlannedQuantity"] !== null) {
          requestData.push({
            id: element["id"],
            district_id: element["DistrictId"],
            district_name: element["District"],
            plan: element["PlannedQuantity"],
          });
        }
      });
      distributionService.saveDistrictPlan(requestData).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            this.getDistrictLevelDistribution();
            showToaster(toasterTypes.SUCCESS, data.message);
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };
  onGridReady = (params: any) => {
    this.getDistrictLevelDistribution();
    this.setState({
      gridApi: params.api,
    });
  };

  render() {
    return (
      <section id="ViewProject" className="ViewProject">
        <div className="container" data-aos="fade-up">
          <div className="row no-gutters">
            <div className="col-lg-12 d-flex flex-column justify-content-center ">
              <div className="col-lg-12 d-flex flex-column justify-content-center ">
                <Row>
                  <Col md={8}>
                    <h3 className="heading-secondary p-0 m-0 mb-1">
                      District Level Distribution
                    </h3>
                  </Col>
                  <Col md={2} style={{ alignItems: "center", display: "flex" }}>
                    <Link to="/state-pincodeLevelDistribution">
                      <Button
                        label="Pin Code level distribution"
                        className="ml-2 btn btn-outline-secondary"
                      />
                    </Link>
                  </Col>
                  <Col md={2}>
                    <div className="mr-1 card p-1 text-center">
                      <b>{this.state.totalQuantity}</b>
                      <small>Total Delivered Quantity</small>
                    </div>
                  </Col>
                </Row>
                <h4 className="heading-tertiary">
                  {this.props.projectName} for {this.state.userData.state_name}
                </h4>
                <div className="section-title">
                  {/* <h3 className="secondary-heading-normal m-0 table-header-label">
                      Total Quantity : {this.state.totalQuantity}
                    </h3> */}
                  <div className="grid">
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        domLayout="autoHeight"
                        context={this.state.context}
                        rowData={this.state.tableRecords}
                        enableBrowserTooltips={true}
                        onGridReady={this.onGridReady}
                        frameworkComponents={{
                          DispatchColumn: DispatchRow,
                        }}
                        columnTypes={{
                          valueColumn: {
                            valueParser: "Number(newValue)",
                          },
                        }}
                        suppressDragLeaveHidesColumns={true}
                      >
                        <AgGridColumn
                          field="District"
                          sortable={true}
                          filter={true}
                          tooltipField="District"
                          suppressMovable={true}
                        />
                        <AgGridColumn
                          field="PlannedQuantity"
                          sortable={true}
                          filter={true}
                          editable={true}
                          valueParser={(value) => this.cellValueChanged(value)}
                          type="rightAligned"
                          tooltipField="PlannedQuantity"
                          suppressMovable={true}
                        />
                        <AgGridColumn
                          field="Dispatches"
                          cellRenderer="DispatchColumn"
                          cellRendererParams={{
                            totalDelivered: +this.state.totalQuantity,
                            totalDispatched: +this.state.totalDispatched,
                          }}
                          suppressMovable={true}
                          cellStyle={{ color: "blue" }}
                        />
                        <AgGridColumn
                          field="TotalDispatched"
                          sortable={true}
                          filter={true}
                          type="rightAligned"
                          tooltipField="TotalDispatched"
                          suppressMovable={true}
                        />
                        <AgGridColumn
                          field="TotalReceived"
                          sortable={true}
                          filter={true}
                          type="rightAligned"
                          tooltipField="TotalReceived"
                          suppressMovable={true}
                        />
                      </AgGridReact>
                    </div>
                  </div>
                  <div className="text-end p-2">
                    <button
                      onClick={this.save}
                      className="btn Btn_small btn-theme-primary"
                      title="save"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default DistrictLevelDistribution;
