import { BeatLoader } from "react-spinners";
interface Props {
  isLoading?: boolean;
}
const Loader: React.FunctionComponent<Props> = ({ isLoading }) => {
  return (
    <div className={isLoading ? "loader" : ""}>
      <BeatLoader color={"#fff"} loading={isLoading} size={35} margin={30} />
    </div>
  );
};
Loader.defaultProps = {
  isLoading: true,
};
export default Loader;
