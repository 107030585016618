import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AddDeliveryPopup } from "../../component/addDeliveryPopup ";
import Button from "../../../../widgets/button";
import { Redirect } from "react-router-dom";

interface editRowProps {
  data: any;
  context: any;
}
interface editRowStateProps {
  modal: boolean;
  isEdit: boolean;
  editData: any;
  deleteModal: boolean;
  rowEditById: number;
  procurementId: number;
}
export class ActionRow extends Component<editRowProps, editRowStateProps> {
  childRef: any;

  constructor(props: editRowProps) {
    super(props);
    this.state = {
      rowEditById: 0,
      procurementId: 0,
      modal: false,
      isEdit: false,
      editData: {},
      deleteModal: false,
    };
    this.childRef = React.createRef();
  }

  //   toggle = () => {
  //     this.setState({
  //       modal: !this.state.modal,
  //     });
  //     if (this.state.modal === false) {
  //       this.childRef.current.getDeliveryDetail();
  //     }
  //   };
  editRow = () => {
    this.setState({
      rowEditById: this.props.data.id,
      procurementId: this.props.data.procurementId,
    });
  };
  refresh = () => {
    this.props.context.componentParent.getPaymentHistoryList();
  };
  changeStatus = () => {
    this.props.context.componentParent.changeStatusHandler(this.props.data);
  };

  //   deleteToggle = () => {
  //     this.setState({
  //       deleteModal: !this.state.deleteModal,
  //     });
  //   };

  //   closeBtn = (
  //     <button className="close" title="Close" onClick={this.deleteToggle}>
  //       &times;
  //     </button>
  //   );
  render() {
    if (this.state.rowEditById !== 0) {
      return (
        <Redirect
          to={`/createPaymentHistoryprocurement/${this.state.procurementId}/${this.state.rowEditById}`}
        />
      );
    }
    return (
      <>
        {/* <AddDeliveryPopup
          isEdit={true}
          modal={this.state.modal}
          toggle={this.toggle}
          deliveryId={this.props.data.id}
          procurementId={this.props.data.procurementId}
          ref={this.childRef}
          refresh={this.refresh}
        /> */}
        {/* <Modal isOpen={this.state.deleteModal} toggle={this.deleteToggle}>
          <ModalHeader
            className="modal_Bg"
            toggle={this.deleteToggle}
            close={this.closeBtn}
          >
            <div className="modal_title"> Confirmation Alert</div>
          </ModalHeader>
          <ModalBody>Are you sure to Delete?</ModalBody>
          <ModalFooter>
            <Button
              label="No"
              className="ml-2 btn btn-outline-secondary"
              onClick={this.deleteToggle}
            />
            <Button
              label="Yes"
              className="ml-2 btn btn-theme-primary"
              onClick={this.changeStatus}
            />
          </ModalFooter>
        </Modal> */}
        <button
          className="Action_btn"
          onClick={() => this.editRow()}
          title="Edit"
        >
          <i className="bi bi-pencil-square"></i>
        </button>

        {/* <button
          onClick={() => this.deleteToggle()}
          title="Delete"
          className="Action_btn"
          style={{ marginLeft: "16px" }}
        >
          <i className="bi bi-trash"></i>
        </button> */}
      </>
    );
  }
}
