import { Component } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import EditRow from "./partials/EditRow";
import { showToaster, toasterTypes } from "../../widgets";
import { PINCodeSettingProps, PINCodeSettingState } from "./interface";
import Pagination from "../../widgets/pagination";
import Tablefeature from "../../widgets/tableFeature";
import Button from "../../widgets/button";
import AddEditPINCodeModal from "./_dialogues/add-edit-pincode";
import { CategoryService } from "../../service/category";
import StatusChange from "./partials/status-change";
import { Link } from "react-router-dom";

class PINCodeSetting extends Component<PINCodeSettingProps, PINCodeSettingState> {
    constructor(props: PINCodeSettingProps) {
        super(props);
        this.state = {
            editPinCode: null,
            districtName: '',
            tableRecords: [],
            dataTableKey: 0,
            pageSize: 10,
            page: 1,
            gridApi: "",
            context: {
                componentParent: this,
            },
            pagination: {
                pageNumber: 1,
                pageSize: 10,
                totalPages: 1,
                totalElements: 0,
            },
            pinCodeModal: false,
        };
    }

    componentDidMount() {
        if (this.props.match.params.districtId) {
            this.getAllRowData();
        } else if (this.props.match.params.categoryId) {
            this.props.history.push(`/area-settings/${this.props.match.params.categoryId}`);
        } else {
            this.props.history.push("/category-settings");
        }
    }

    reFreshTable = () => {
        this.setState({ dataTableKey: this.state.dataTableKey + 1 });
    };

    getReactContainerClasses() {
        return ["custom-tooltip"];
    }

    handlePageClick = (
        data: { selected: number },
        pageSize: number = this.state.pagination.pageSize
    ) => {
        this.getAllRowData(data.selected, pageSize);
    };

    handleSearch = (e: any) => {
        this.getAllRowData(1, 10, e.target.value);
    };

    getAllRowData = (
        page: number = 1,
        pageSize: number = 10,
        key: string = ""
    ) => {
        try {
            CategoryService.getAllPinCodes(page, pageSize, key, this.props.match.params.districtId).subscribe(
                (response: any) => {
                    try {
                        if (response.status && response.statusCode === 200) {
                            this.setState({ districtName: response.district_name })
                            if (response.data && Array.isArray(response.data)) {
                                let rowData: any = [];
                                response.data.forEach((element: any) => {
                                    rowData.push({
                                        id: element.id,
                                        pinCode: element.name,
                                        status: element.status
                                    });
                                });

                                this.state.gridApi.setRowData(rowData);
                                this.setState({
                                    pagination: {
                                        pageNumber: response.page || 1,
                                        pageSize: response.size || 10,
                                        totalPages: response.totalPages || 1,
                                        totalElements: response.totalElements || 10,
                                    },
                                });
                            }
                        } else {
                            showToaster(toasterTypes.ERROR, response.message);
                        }
                    } catch (error) {
                        console.log(error?.message);
                    }
                },
                (error: any) => {
                    console.log(error);
                    showToaster(toasterTypes.ERROR, error.message);
                }
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    onEditButtonClicked = (pincode: any) => {
        this.setState({ editPinCode: pincode })
        this.setState({ pinCodeModal: true })
    }

    onDeleteButtonClicked = (data: any, status: any) => {
        try {
            const userData = {
                id: data.id,
                status: status.value
            };
            CategoryService.pinCodeChangeStatus(userData).subscribe(
                (data: any) => {
                    if (data.status && data.statusCode === 200) {
                        this.getAllRowData();
                        showToaster(toasterTypes.SUCCESS, data.message);
                    } else {
                        showToaster(toasterTypes.ERROR, data.message);
                    }
                },
                (error: any) => {
                    console.log(error);
                    showToaster(toasterTypes.ERROR, error.message);
                }
            );
        } catch (error) {
            console.log(error?.message);
        }
    }


    onGridReady = (params: any) => {
        this.setState({
            gridApi: params.api,
        });
    };

    render() {
        const {
            state: { pagination },
            handlePageClick,
        } = this;

        return (
            <main id="main">
                <section id="ViewProject" className="ViewProject">
                    <div className="container" data-aos="fade-up">
                        <li className="forget-pass" style={{ marginBottom: '15px' }}>
                            <Link to="/category-settings" style={{ fontSize: '13px' }}> Category Setting </Link> <span style={{ fontSize: '13px' }}>/</span> 
                            <Link to={`/area-settings/${this.props.match.params.categoryId}`} style={{ fontSize: '13px' }}> Area Setting </Link> <span style={{ fontSize: '13px' }}>/</span> 
                            <Link to={`/pincode-settings/${this.props.match.params.categoryId}/${this.props.match.params.districtId}`} style={{ fontSize: '13px' }}> PIN Code Setting </Link>
                        </li>
                        <div className="row no-gutters">
                            <div className="col-lg-12 d-flex align-items-center justify-content-between mb-4">
                                <div className=" d-flex align-items-center justify-content-start ">
                                    <h3 className="secondary-heading-normal m-0 table-header-label">
                                        PIN Code Settings - {this.state.districtName}
                                    </h3>
                                    <Button
                                        label="Add PIN Code"
                                        className="ml-2 btn btn-outline-secondary"
                                        onClick={() => this.setState({ pinCodeModal: true })}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-12 d-flex flex-column justify-content-center">
                                <Tablefeature
                                    totalElements={pagination.totalElements}
                                    handleSearch={this.handleSearch}
                                />
                                <AddEditPINCodeModal modal={this.state.pinCodeModal}
                                    onSubmitSuccess={this.getAllRowData}
                                    setPINCodeModel={(state) => this.setState({ pinCodeModal: state, editPinCode: null })}
                                    editPinCode={this.state.editPinCode}
                                    districtId={this.props.match.params.districtId}
                                />
                                <div className="grid">
                                    <div className="ag-theme-alpine">
                                        <AgGridReact
                                            key={this.state.dataTableKey}
                                            domLayout={"autoHeight"}
                                            cacheBlockSize={10}
                                            context={this.state.context}
                                            rowData={this.state.tableRecords}
                                            enableCellChangeFlash={true}
                                            onGridReady={this.onGridReady}
                                            enableBrowserTooltips={true}
                                            frameworkComponents={{
                                                editColumn: EditRow,
                                                statusColumn: StatusChange
                                            }}
                                            suppressDragLeaveHidesColumns={true}
                                        >
                                            <AgGridColumn
                                                field="pinCode"
                                                sortable={true}
                                                flex={3}
                                                filter={true}
                                                tooltipField="PIN Code"
                                                suppressMovable={true}
                                            />
                                            <AgGridColumn
                                                field="Status"
                                                flex={1}
                                                suppressMovable={true}
                                                cellRenderer="statusColumn"
                                                cellRendererParams={{
                                                    reFreshTable: this.state.dataTableKey,
                                                }}
                                            />

                                            <AgGridColumn
                                                field="Action"
                                                flex={1}
                                                suppressMovable={true}
                                                cellRenderer="editColumn"
                                                cellRendererParams={{
                                                    reFreshTable: this.state.dataTableKey,
                                                }}
                                            />
                                        </AgGridReact>
                                        <div className="col-md-12 mt-2">
                                            <Pagination
                                                page={pagination.pageNumber}
                                                pageSize={pagination.pageSize}
                                                handlePageChange={handlePageClick}
                                                totalPage={pagination.totalPages}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main >
        );
    }
}

export default PINCodeSetting;
