import React, { Component, Fragment } from "react";

import { Redirect } from "react-router-dom";
import { AddDeliveryPopup } from "../../component/addDeliveryPopup ";

interface editRowProps {
  data: any;
  context: any;
}
interface editRowStateProps {
  modal: boolean;
  paymentHistoryPage: boolean;
}
export class AddPaymentDetailsRow extends Component<
  editRowProps,
  editRowStateProps
> {
  constructor(props: editRowProps) {
    super(props);
    this.state = {
      modal: false,
      paymentHistoryPage: false,
    };
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  refresh = () => {
    this.props.context.componentParent.getProcurementCreation();
    this.props.context.componentParent.getProcuredQuantity()
  };

  openListPaymenthistory = () => {
    this.setState({ paymentHistoryPage: true });
  };
  render() {
    const {
      state: { paymentHistoryPage },
    } = this;

    if (paymentHistoryPage) {
      return <Redirect to={`/listPaymentHistory/${this.props.data.id}`} />;
    }
    return (
      <>
        <AddDeliveryPopup
          modal={this.state.modal}
          toggle={this.toggle}
          procurementId={this.props.data.id}
          refresh={this.refresh}
        />
        <div className="cursor_point d-flex justify-content-between">
          <span
            className="text-right mr-2 align_count"
           
           
          >
            {this.props.data?.PaymentDetails}
          </span>
          <div>
          <button
          className="Action_btn"
        //   style={{ marginLeft: "16px" }}
        onClick={this.openListPaymenthistory}
          title="History"
        >
          <i className="bi bi-clock"></i>
        </button>
            {/* {" "}
            <button
              className="btn-sm  btn btn-outline-secondary addPopupButton"
              title ="Add Delivery"
              onClick={this.toggle}
            >
              {" "}
              Add Delivery
            </button>{" "} */}
          </div>
         
        </div>
      </>
    );
  }
}

//  default EditRow;
