import React, { Component } from "react";
import { Col, Row, UncontrolledTooltip } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { ActionRow } from "./partials/actionRow";
import { listDeliveryProps, listDeliveryState } from "./ineterface";
import {  procurementService } from "../../../service";
import { showToaster, toasterTypes } from "../../../widgets";
import Pagination from "../../../widgets/pagination";
import Tablefeature from "../../../widgets/tableFeature";

export class ListDelivery extends Component<
  listDeliveryProps,
  listDeliveryState
> {
  constructor(props: listDeliveryProps) {
    super(props);
    this.state = {
      modal: false,
      gridApi: "",
      tableRecords: [],
      context: {
        componentParent: this,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
    };
  }

  handlePageClick = (
    data: { selected: number },
    pageSize: number = this.state.pagination.pageSize
  ) => {
    this.getDeliveryList(data.selected, pageSize);
  };
  handleSearch = (e: any) => {
    this.getDeliveryList(1, 10, e.target.value);
  };

  getDeliveryList = (
    page: number = 1,
    pageSize: number = 10,
    key: string = ""
  ) => {
    try {
      procurementService
        .getAllDeliveryList(
          this.props.match.params.procurementId,
          page,
          pageSize,
          key
        )
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (
                  Array.isArray(response.content) &&
                  response.content?.length > 0
                ) {
                  let rowData: any = response.content.map((element: any) => ({
                    id: element.id,
                    DeliveryQuantity: element.delivered_quantity,
                    procuredQuantity: element.seed_quantity,
                    projectName: this.props.projectName,
                    supplierName: element.supplier_name,
                    Remark: element.remarks,
                    DeliveryDate: moment(element.delivered_date).format(
                      "DD-MM-YYYY"
                    ),
                    procurementId: this.props.match.params.procurementId,
                  }));
                  this.state.gridApi.setRowData(rowData);

                  this.setState({
                    tableRecords: rowData,
                    pagination: {
                      pageNumber: response.page || 1,
                      pageSize: response.size || 10,
                      totalPages: response.totalPages || 1,
                      totalElements: response.totalElements || 10,
                    },
                  });
                }
              } else {
                this.state.gridApi.setRowData([]);
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error?.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };

  onGridReady = (params: any) => {
    this.getDeliveryList();
    this.setState({
      gridApi: params.api,
    });
  };

  changeStatusHandler = (data: any) => {
    const requestData = {
      id: data.id,
      status: "deleted",
    };
    procurementService.DeliveryChangeStatus(requestData).subscribe(
      (data: any) => {
        if (data.status && data.statusCode === 200) {
          this.getDeliveryList();
          showToaster(toasterTypes.SUCCESS, data.message);
        } else {
          showToaster(toasterTypes.ERROR, data.message);
        }
      },
      (error: any) => {
        console.log(error);
        showToaster(toasterTypes.ERROR, error.message);
      }
    );
  };
  render() {
    const { pagination } = this.state;
    return (
      <>
        <section id="ViewProject" className="ViewProject">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters">
              <div className="col-lg-10 d-flex flex-column justify-content-center ">
                <Row>
                  <Col md={11}>
                    <h3 className="heading-secondary">List Deliveries</h3>
                  </Col>
                  <Col md={1}>
                    <Link to="/listProcurement">
                      <i
                        className="fa fa-times  text-dark float-right"
                        id="close"
                      ></i>
                    </Link>
                    <UncontrolledTooltip target={`close`}>
                      Close
                    </UncontrolledTooltip>
                  </Col>
                </Row>
                <Tablefeature
                  totalElements={pagination.totalElements}
                  handleSearch={this.handleSearch}
                />

                <div className="section-title">
                  <div className="grid">
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        domLayout="autoHeight"
                        enableBrowserTooltips={true}
                        onGridReady={this.onGridReady}
                        context={this.state.context}
                        rowData={this.state.tableRecords}
                        pagination={false}
                        defaultColDef={{
                          resizable: true,
                          minWidth: 100,
                        }}
                        paginationPageSize={10}
                        cacheBlockSize={10}
                        frameworkComponents={{
                          ActionColumn: ActionRow,
                        }}
                        suppressDragLeaveHidesColumns={true}
                      >
                        <AgGridColumn
                          field="projectName"
                          sortable={true}
                          filter={true}
                          flex={1}
                          tooltipField="Project Name"
                          suppressMovable={true}
                        />

                        <AgGridColumn
                          field="procuredQuantity"
                          sortable={true}
                          filter={true}
                          flex={1}
                          tooltipField="Procured Quantity"
                          suppressMovable={true}
                        />

                        <AgGridColumn
                          field="DeliveryDate"
                          sortable={true}
                          filter={true}
                          flex={1}
                          tooltipField="DeliveryDate"
                          suppressMovable={true}
                        />
                        <AgGridColumn
                          field="supplierName"
                          sortable={true}
                          filter={true}
                          flex={1}
                          tooltipField="Supplier Name"
                          suppressMovable={true}
                        />

                        <AgGridColumn
                          field="Remark"
                          sortable={true}
                          filter={true}
                          flex={1}
                          tooltipField="Remark"
                          suppressMovable={true}
                        />
                        <AgGridColumn
                          field="DeliveryQuantity"
                          sortable={true}
                          flex={1}
                          filter={true}
                          type="rightAligned"
                          tooltipField="DeliveryQuantity"
                          suppressMovable={true}
                        />
                        {/* <AgGridColumn /> */}
                        <AgGridColumn
                          field="Action"
                          cellRendererParams={{
                            totalDeliveredCount: this.state.tableRecords
                              .map((item: any) => item.DeliveryQuantity)
                              .reduce(
                                (prev: any, next: any) => +prev + +next,
                                0
                              ),
                          }}
                          flex={1}
                          cellRenderer="ActionColumn"
                          suppressMovable={true}
                        />
                      </AgGridReact>
                      <div className="col-md-12 mt-2">
                        <Pagination
                          page={pagination.pageNumber}
                          pageSize={pagination.pageSize}
                          handlePageChange={this.handlePageClick}
                          totalPage={pagination.totalPages}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

//  default EditRow;
