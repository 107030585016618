import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Button from "../../../widgets/button";
import { Validation } from "../../../utils";
import { showToaster, toasterTypes } from "../../../widgets";
import { FieldItem, FIELD_TYPES } from "../../../widgets/fields";
import { CategoryService } from "../../../service/category";


type Props = {
  setPINCodeModel: (toggleState: boolean) => void,
  districtId: any,
  onSubmitSuccess: () => void,
  editPinCode: {
    id: string;
    pinCode: string
  } | null
  modal: boolean
}

const AddEditPINCodeModal = (props: Props) => {

  const [formState, setFormState] = useState({
    id: props?.editPinCode?.id ? props?.editPinCode.id : '',
    pincode: props?.editPinCode?.pinCode ? props?.editPinCode.pinCode : ''
  })

  useEffect(() => {
    if (props.editPinCode) {
      setFormState({
        id: props?.editPinCode?.id ? props?.editPinCode.id : '',
        pincode: props?.editPinCode?.pinCode ? props?.editPinCode.pinCode : ''
      })
    }

  }, [props?.editPinCode])

  const handleOnChange = (event: any) => {
    try {
      const { name, value } = event.currentTarget;
      setFormState({ ...formState, [name]: value });
    } catch (error) {
      console.log(error?.message);
    }
  };

  const onSubmit = () => {
    try {
      if (Validation.isTextRequired(formState.pincode, "PIN Code")) {
        return false;
      }

      const payload: { id?: string, name: string, district_id: string } = {
        name: formState.pincode,
        district_id: props.districtId
      };

      if (formState.id) {
        payload.id = formState.id;
      }

      CategoryService.addPincodes(payload).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            props.onSubmitSuccess();
            deleteToggle();
            reset();
            showToaster(toasterTypes.SUCCESS, data.message);
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const deleteToggle = () => {
    props.setPINCodeModel(false)
  };

  const toggle = () => {
    if (props.modal === true) {
      reset();
    }

    props.setPINCodeModel(!props.modal)
  };

  const reset = () => {
    setFormState({ pincode: '', id: '' })
  };

  const closeBtn = (
    <button className="close" title="Close" onClick={toggle}>
      &times;
    </button>
  );

  return <>
    <Modal isOpen={props.modal} toggle={toggle}>
      <ModalHeader
        className="modal_Bg"
        close={closeBtn}
        toggle={toggle}
      >
        <div className="modal_title">
          {formState.id
            ? "Update PIN Code"
            : "Create PIN Code"}
        </div>
      </ModalHeader>
      <ModalBody>
        <FieldItem
          onChange={handleOnChange}
          label="PIN Code "
          name="pincode"
          value={formState.pincode}
          placeholder="PIN Code "
          type={FIELD_TYPES.TEXT}
          className="form-control required"
          required
        />
      </ModalBody>
      <ModalFooter>
        <Button
          label="Cancel"
          className="ml-2 btn btn-outline-secondary"
          onClick={toggle}
        />
        <Button
          label={formState.id ? "Update" : "Save"}
          className="ml-2 btn btn-theme-primary"
          onClick={onSubmit}
        />
      </ModalFooter>
    </Modal></>
}

export default AddEditPINCodeModal;