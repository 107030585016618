import React, { Component } from "react";
import SwitchButton from "../../../../widgets/switchButton";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "../../../../widgets/button";

interface listProjectProps {
  context: any;
  data: any;
}
interface listProjectStateProps {
  status: boolean;
  modal: boolean;
}
export class StatusRow extends Component<
  listProjectProps,
  listProjectStateProps
> {
  state: { status: boolean; modal: boolean };

  constructor(props: listProjectProps) {
    super(props);
    this.state = {
      status: this.props.data.StatusData === "active" ? true : false,
      modal: false,
    };
  }
  changeStatus = () => {
    this.props.context.componentParent.changeStatusHandler(this.props.data);
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  closeBtn = (
    <button className="close" title="Close" onClick={this.toggle}>
      &times;
    </button>
  );

  render() {
    return (
      <>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader
            className="modal_Bg"
            close={this.closeBtn}
            toggle={this.toggle}
          >
            <div className="modal_title"> Confirmation Alert</div>
          </ModalHeader>
          <ModalBody>Are you sure to change status?</ModalBody>
          <ModalFooter>
            <Button
              label="No"
              className="ml-2 btn btn-outline-secondary"
              onClick={this.toggle}
            />
            <Button
              label="Yes"
              className="ml-2 btn btn-theme-primary"
              onClick={this.changeStatus}
            />
          </ModalFooter>
        </Modal>
        <SwitchButton
          checked={this.state.status}
          onChangeHandler={this.toggle}
        />
      </>
    );
  }
}
