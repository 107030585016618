import React from "react";
// import "react-select/dist/react-select.css";
import Select from "react-select";
// import * as styles from "./styles.css";
interface Props {
  multi?: boolean;
  options?: any;
  value?: any;
  handleChange?: any;
  placeholder?: string;
}
const DropDown: React.FunctionComponent<Props> = (props) => {
  const options = props.multi
    ? [{ label: "Select All", value: "all" }, ...props.options]
    : props.options;
  return (
    <div className={`react-select-wrapper ${props.multi ? "multi" : ""}`}>
      <Select
        name="example"
        menuPlacement="top"
        options={options}
        placeholder={props.placeholder}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        isMulti={props.multi}
        value={props.value ? props.value : null}
        menuPortalTarget={document.body}
        onChange={(selected) => {
          props.multi &&
          selected.length &&
          selected.find((option: any) => option.value === "all")
            ? props.handleChange(options.slice(1))
            : !props.multi
            ? props.handleChange((selected && selected.value) || null)
            : props.handleChange(selected);
        }}
      />
    </div>
  );
};

export default DropDown;
