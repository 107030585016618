import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import { commonService, dashboardService } from "../../service";
import { showToaster, toasterTypes } from "../../widgets/toaster";
import { viewProjectsProps, viewProjectsStateProps } from "./interface";
import { GridApi } from "ag-grid-community";
import Carousel from "react-bootstrap/Carousel";
import ProjectStatGraph from "../adminDashboard/proj-stat-graph";

class ViewProjects extends Component<
  viewProjectsProps,
  viewProjectsStateProps
> {
  static defaultProps = {
    plantedTrees: 999557689,
    projectName: "Welcome to Vruksha",
    activeProjectName: " Give Today & Start Growing Your Own Forest",
    activeProjectDescription: ` Operating in 156 countries, The Art of Living is a 
    non-profit, educational and humanitarian organization 
    founded in 1981 by the world-renowned humanitarian and 
    spiritual teacher - Gurudev Sri Sri Ravi Shankar. All our 
    programs are guided by Gurudev’s philosophy: “Unless we 
    have a stress-free mind and a violence-free society, we 
    cannot achieve world peace.`,
  };
  constructor(props: viewProjectsProps) {
    super(props);
    this.state = {
      activeIndex: 0,
      animating: false,
      rowData: [],
      chartKey: 1,
      activeTab: 3,
      // ProjectCradData: []
      selectedRow: {},
      gridApi: GridApi,
      series: [
        {
          name: "Co2 Observed",
          data: [3, 5, 8, 4],
        },
      ],
      co2Chart: {
        yaxis: [
          {
            axisBorder: {
              show: true,
            },
          },
        ],
        chart: {
          type: "area",
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        labels: [10, 20, 30, 40],
        colors: ["#F7B1B1"],
        fill: {
          type: "gradient",
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
      },
      treesChart: {
        yaxis: [
          {
            axisBorder: {
              show: true,
            },
          },
        ],
        chart: {
          type: "area",
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        labels: ["Jan", "Feb", "Mar", "Apr"],
        colors: ["#2CF55A"],
        fill: {
          type: "gradient",
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
      },
    };
  }

  next = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === this.props.carouselImages.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setActiveIndex(nextIndex);
  };

  previous = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.carouselImages.length - 1
        : this.state.activeIndex - 1;
    this.setActiveIndex(nextIndex);
  };

  //   myfun = (ele)=>{
  //     let offsetTop  = document.getElementById(ele).offsetTop;
  //     // var right = document.getElementById("#right");
  //     window.scrollTo({
  //         top: offsetTop-100,
  //         behavior: "smooth"
  //     });
  // }

  // navigateTo = () => this.props.history.push("/signup");

  // HomeButton() {
  //   const history = useHistory();

  //   function handleClick() {
  //     history.push("/home");
  //   }

  goToIndex = (newIndex: number) => {
    if (this.state.animating) return;
    this.setActiveIndex(newIndex);
  };

  componentDidMount() {
    this.getGraphDetails();
  }

  getGraphDetails = (projectId: string = "") => {
    try {
      dashboardService.getGraphDetails(projectId).subscribe({
        next: (response: any) => {
          try {
            const rowData: any = [];
            if (
              response?.status &&
              response.statusCode === 200 &&
              Array.isArray(response.content)
            ) {
              response.content.forEach((element: any) => {
                const startDate = new Date(element.start_date);
                const endDate = new Date(element.end_date);

                // To calculate the time difference of two dates
                const Difference_In_Time =
                  endDate.getTime() - startDate.getTime();

                // To calculate the no. of days between two dates
                const Difference_In_Days =
                  Difference_In_Time / (1000 * 3600 * 24);

                const plantedCount = element.planted_quantity;

                const c02Released =
                  ((plantedCount * 24) / 365) * Math.floor(Difference_In_Days);
                const convertToTons = c02Released * 0.001102;
                const co2Count = convertToTons.toFixed(2);

                rowData.push({
                  ...element,
                  co2Count,
                });
              });
              this.setState({ selectedRow: response.content[0] });
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
            this.setState({ rowData });
          } catch (error) {
            console.log(error.message);
          }
        },
        error: (error: any) => {
          showToaster(toasterTypes.ERROR, error.message);
        },
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  setActiveIndex = (index: number) => {
    this.setState({ activeIndex: index });
  };

  setAnimating = (effect: boolean) => {
    this.setState({ animating: effect });
  };

  getRowStyle = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F7F7F7" };
    } else {
      return { background: "#FEFDFD" };
    }
  };

  getAllData = (event: any) => {
    // try {
    //   commonService.getAllCoutrystats().subscribe({
    //     next: (response: any) => {
    //       try {
    //         if (response.data && response.data.state_wise_planted?.length > 0) {
    //           const rowData = response.data.state_wise_planted.map(
    //             (data: any, id: any) => ({
    //               id: id + 1,
    //               state_id: data.state_id,
    //               name: data.state_name,
    //               planned: data.planted_count,
    //               actual: data.planted_count,
    //               total_trees_planted: data.planted_count,
    //               coordinator: data.coordinator
    //                 ? `${data.coordinator?.first_name} ${data.coordinator?.last_name}`
    //                 : "",
    //               totalVolunteers: data.total_volunteers,
    //               co2: data.planted_count,
    //             })
    //           );
    //           this.setState({ rowData, selectedRow: rowData[0] }, () => {
    //             let gridApi = this.state.gridApi;
    //             gridApi = event.api;
    //             setTimeout(() => {
    //               gridApi.forEachNode((node: any, index: any) => {
    //                 if (index === 0) {
    //                   node.setSelected(true);
    //                 }
    //               });
    //               this.setState({ gridApi });
    //             }, 0);
    //           });
    //           localStorage.setItem("stateList", JSON.stringify(rowData));
    //         } else {
    //           // showToaster(toasterTypes.ERROR, response.message);
    //           console.log(toasterTypes.ERROR, response.message);
    //         }
    //       } catch (error) {
    //         console.log(error.message);
    //       }
    //     },
    //     error: (error: any) => {
    //       showToaster(toasterTypes.ERROR, error.message);
    //     },
    //   });
    // } catch (error) {
    //   console.error(error.message);
    // }
  };

  getRowNodeId(data: any) {
    return data.id;
  }

  onFirstDataRendered(event: any) {
    this.getAllData(event);
  }

  responsiveMenu = () => {
    let navbarToggle = this.select(".mobile-nav-toggle");
    navbarToggle.classList.toggle("bi-list");
    navbarToggle.classList.toggle("bi-x");
    this.select("#navbar").classList.toggle("navbar-mobile");
  };

  closeMobileMenuNav = () => {
    if (this.select("#navbar").classList.contains("navbar-mobile text-black")) {
      let navbarToggle = this.select(".mobile-nav-toggle text-black");
      navbarToggle.classList.toggle("bi-list");
      navbarToggle.classList.toggle("bi-x");
      this.select("#navbar").classList.remove("navbar-mobile");
    }
  };

  select = (el: any, all = false) => {
    el = el.trim();
    return document.querySelector(el);
  };

  render() {
    const {
      state: { rowData, selectedRow },
    } = this;

    return (
      <div>
        {/* {Array.isArray(this.props.carouselImages) &&
        this.props.carouselImages?.length ? (
          <div>
            <style>
              {`.custom-tag {
              max-width: 100%;
              height: 500px;
              background: black;
            }`}
            </style>
            <Carousel
              activeIndex={this.state.activeIndex}
              next={() => {}}
              previous={this.previous}
            >
              <CarouselIndicators
                items={this.props.carouselImages}
                activeIndex={this.state.activeIndex}
                onClickHandler={this.goToIndex}
              />
              {this.props.carouselImages?.map((item) => {
                return (
                  <CarouselItem
                    onExiting={() => this.setAnimating(true)}
                    onExited={() => this.setAnimating(false)}
                    key={item.id}
                  >
                    <Row className="d-flex justify-content-between">
                      <Col md={3} className="d-flex justify-content-center ">
                        <div
                          className="text-white carouselCard bgVruksha d-flex justify-content-center"
                          style={{ wordBreak: "break-all" }}
                        >
                          <div>
                            <h2 className="animate__animated tree_stroke_h2 animate__fadeInDown name__overflow">
                              {this.props.projectName}
                            </h2>
                            <p className="animate__animated tree_stroke_p animate__fadeInUp">
                              {this.props.projectDescription}
                            </p>
                            <a
                              id="readmore"
                              href="#main"
                              className="btn-get-started animate__animated animate__fadeInUp scrollto bg-light colorVruksha"
                            >
                              Read More
                            </a>
                            <UncontrolledTooltip target={`readmore`}>
                              Read more
                            </UncontrolledTooltip>
                          </div>
                        </div>
                      </Col>
                      <Col md={9} className="">
                        <img
                          src={`${constants.app.baseURL}/files/download?file_id=${item.image_id}`}
                          alt={item.id}
                          width="100%"
                          height="475px"
                        />
                      </Col>
                    </Row>
                  </CarouselItem>
                );
              })}

              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={this.previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={this.next}
              />
            </Carousel>
          </div>
        ) : (
          <div></div>
        )} */}

        <main id="main">
          {/* <section id="Give_Today" className="Give_Today">
            <div className="container" data-aos="fade-up">
              <div className="row no-gutters">
                <div className="col-lg-12 d-flex flex-column justify-content-center about-content">
                  <div className="section-title">
                    {/* <h2>{this.props.activeProjectName}</h2> */}
          {/* <p>{this.props.activeProjectDescription}</p> */}
          {/* </div>
                </div>
              </div>
            </div>
          </section> */}

          <section className="bg section-bg">
            <header
              id="homeheaderNew"
              className="d-flex w-100 align-items-center"
            >
              <nav id="navbar" className="navbar w-100">
                <div className="homeheader-items">
                  <ul>
                    <li className="ml-2 c-hover d-flex align-items-center ">
                      <Link to="/" className="text-white">
                        Home
                      </Link>
                    </li>
                    <li className="ml-2 c-hover d-flex align-items-center">
                      <Link
                        to="/aboutUs"
                        className="text-white"
                        onClick={() => {
                          this.closeMobileMenuNav();
                        }}
                      >
                        About Us
                      </Link>
                    </li>
                    <li className="ml-2 c-hover d-flex align-items-center">
                      <Link
                        to="/testimonials"
                        className="text-white"
                        onClick={() => {
                          this.closeMobileMenuNav();
                        }}
                      >
                        Testimonials
                      </Link>
                    </li>

                    <li className="ml-2 c-hover d-flex align-items-center">
                      <Link
                        to="/"
                        className="text-white"
                        onClick={() => {
                          this.closeMobileMenuNav();
                        }}
                      >
                        Projects
                      </Link>
                    </li>
                    <li className="ml-2 c-hover d-flex align-items-center">
                      <Link
                        to="/login"
                        className="text-white"
                        // style={{ color: "white" }}
                        onClick={() => {
                          this.closeMobileMenuNav();
                        }}
                      >
                        Sign In
                      </Link>
                    </li>
                  </ul>
                  <div className="pr-5 mr-5">
                    {" "}
                    <i
                      className="bi bi-list mobile-nav-toggle  "
                      onClick={this.responsiveMenu}
                    ></i>
                  </div>
                </div>
              </nav>
            </header>
            <div className="row">
              <div
                className="col-sm-5 col-md-6 order-md-2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingLeft: "7%",
                }}
              >
                <div className="">
                  {/* <div className="rightside mt-7"> */}

                  <div className="homehead text-white ">Vruksha</div>
                  <div className="homesubhead text-white mt-4">
                    A Plant Today, A Life Tomorrow!
                  </div>
                  <div className="text-style text-white mt-3">
                    <Link to="/signup" className="leftBtn">
                      Register
                    </Link>
                    {/* <Button
                    label="Register"
                    // onClick={this.props.navigateTo}
                    className="leftBtn "
                  /> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-7 col-md-6 order-md-1">
                <div className="container ">
                  {/* <div className="leftside"> */}

                  <div className="leftsidebackground">
                    <div className="container">
                      <div className="text-style  text-white mt-3 ">
                        Trees are vital to our existence. They give us oxygen,
                        store carbon, stabilize the soil and give shelter to the
                        world’s wildlife. Forests increase rainfall, prevent
                        drought and Cool the temperature of the entire planet.
                        Since past few centuries, nearly 50% of the world’s
                        forests has been vanished. Currently, we are facing
                        distressing climate change and crisis.
                      </div>
                      <div className="text-style text-white mt-1">
                        One of the most efficient, cost effective and fastest
                        way to tackle climate change is by ‘Planting Trees’.
                        India has vowed to have one third of its total land
                        area, or 95 million hectares, under Forest & Tree cover
                        by 2030.
                      </div>
                      <div className="text-style text-white mt-1">
                        At the Art of Living, we strive towards a green planet
                        with our large scale tree plantation drives on a regular
                        basis. Since 2008, we have planted 81 million trees
                        worldwide.
                        {/* Through Mission Green Earth, a tree plantation campaign
                  started in 2008, we planted 9.6 million trees across the
                  globe. */}
                        In 2008, Tree plantation campaign was started under
                        Mission Green Earth initiative, where 9.6 million trees
                        were planted across the globe. We plant the appropriate
                        trees at the right locations to safeguard the
                        bio-diversity and fight climate change. The Art of
                        living portfolio of tree plantation projects targets 3
                        major issues of our planet- climate change, mass species
                        extinction and environmental degradation.
                      </div>
                      <div className="text-style-end text-white mt-4">
                        “A Person who plants a tree, Plants a hope.”
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg2 section-bg">
            <div className="row">
              <div className="col-sm-6 col-md-6 ">
                {/* <div className="container"> */}
                {/* <div className="splits lefts"> */}
                <img
                  src="/assets/img/1.png"
                  alt="impact"
                  className="leftimage"
                />
                {/* <div className="leftimage"></div> */}
                {/* </div> */}
              </div>
              <div className="col-sm-6 col-md-6">
                <div className="rightcontainer2 ">
                  <div className="rightsideb ">
                    {/* <div className="rightsideb mt-7"> */}
                    <div className="bg2head mt-5 ">Impact</div>
                    {/* <div className="row "> */}
                    {/* <div className="col-md-6"> */}
                    <div className="bg2num mt-3 ">60,000,000 </div>
                    {/* </div> */}
                    {/* <div className="col-md-1 col-sm-5">
                        <div className="bg2numsub2  mt-5 "></div>
                      </div> */}
                    {/* </div> */}
                    <div className="bg2numsub  mt-4">
                      Trees Planted All Over India
                    </div>
                    <div className="bg2desc  ">
                      At the end of the day, it’s results that count – how many
                      trees we have planted, how many acres we have brought
                      under plantation, how much carbon we have offset. In fact,
                      measuring provides important insights that not only
                      validate our progress, But also strengthens our effort in
                      making a difference to our planet’s health!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg3 section-bg">
            <div className="row">
              <div className="col-sm-6 col-md-5">
                <div className="rightcontainer3 ">
                  <div className="leftsideb ">
                    {/* <div className="corow">
                      <div className="cohead  ">Co</div>
                      <div className="headnum ">2 </div>
                    </div> */}

                    <div className="bg3head ">Extinguished</div>
                    {/* <div className="subcontainer"> */}
                    <div className="corow mt-5">
                      <span className="bg3num ">50</span>
                      <div className="bg3sub mt-4">TONS of CO2</div>
                      {/* <div className="bg3sub mt-4 px-3">of </div>
                        <div className="bg3sub mt-4"></div> */}
                      {/* </div> */}
                    </div>
                    {/* <div className="desccontainer"> */}
                    <div className="bg3desc">
                      We offset the carbon foot print by planting more trees.
                      Planting trees in proven to counter the climate change
                      issue. You can make a difference too, to fight climate
                      change through your donations to our Plantation Projects.
                      This helps to offset the CO2 emissions by restoring the
                      plantation on large scale to improve bio-diversity.
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-7">
                <img src="/assets/img/2.jpg" alt="Co2" className="rightimage" />
                {/* <div className="rightimage"></div> */}
              </div>
            </div>
          </section>
          {/* <section className="bg5 section-bg">
            <div className="container">
              <div className="splits leftso2 mr-3"></div>
              <div className="rightsided mt-7">
                <div className="corow mt-3">
                  <div className="ohead mt-4 ">O</div>
                  <div className="oheadnum ">2 </div>
                </div>
                <div className="bg2head mt-5">PRODUCED</div>
                <div className="corow mt-3">
                  <div className="obignum  ">100</div>
                  <div className="osub mt-4">Tons </div>
                </div>
                <div className="bg2desc text-white mt-5">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s
                </div>
              </div>
            </div> */}
          {/* <div className="splits2 rights3 mt-7">
                <div className="corow mt-3">
                  <div className="ohead mt-4 ">O</div>
                  <div className="oheadnum ">2 </div>
                </div>

                <div className="bg2head">PRODUCED</div>
                <div className="corow mt-3">
                  <div className="obignum ">100</div>
                  <div className="osub mt-3">Tons</div>
                </div>

                <div className="bg2desc">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s
                </div>
              </div> */}
          {/* </section> */}
          <section className="bgstatus">
            <div className="textstatus">Overall Country Stats</div>
            <div className="container mt-5">
              <div className="row">
                <div className="col-md-6 ">
                  <div className="grid">
                    <div
                      className=" ag-theme-alpine2 pb-3"
                      style={{
                        minHeight: "300px",
                        // height: `${rowData.length * 20}px`,
                        height: `600px`,
                      }}
                    >
                      <AgGridReact
                        gridOptions={{
                          rowHeight: 60,
                          rowStyle: { "border-top": "#FFFFFF 8px solid" },
                        }}
                        getRowStyle={this.getRowStyle}
                        rowData={rowData}
                        rowSelection={"single"}
                        suppressCellSelection={true}
                        onGridReady={(e) => this.onFirstDataRendered(e)}
                        getRowNodeId={(data) => this.getRowNodeId(data)}
                        // frameworkComponents={{
                        //   percentageRenderer: PercentageRenderer,
                        // }}
                        defaultColDef={{
                          flex: 1,
                          resizable: true,
                          wrapText: true,
                        }}
                        onRowClicked={(value) => {
                          this.setState({
                            selectedRow: value.data,
                            series: [
                              {
                                name: "Co2 Observed",
                                data: [3, 5, 8, 14],
                              },
                            ],
                          });
                        }}
                        suppressDragLeaveHidesColumns={true}
                      >
                        <AgGridColumn
                          headerClass="othercell"
                          headerName="Project name"
                          field="name"
                          flex={2}
                          suppressMovable={true}
                        ></AgGridColumn>

                        <AgGridColumn
                          headerClass="othercell ag-theme-custom-text-right"
                          headerName="No.of Trees Planted"
                          flex={1}
                          type="rightAligned"
                          field="planted_quantity"
                          suppressMovable={true}
                        ></AgGridColumn>

                        <AgGridColumn
                          headerClass="cocell ag-theme-custom-text-right"
                          headerName="Co2 Extinguished"
                          field="co2Count"
                          flex={1}
                          type="rightAligned"
                          suppressMovable={true}
                          // cellClass="cocell"
                        ></AgGridColumn>
                      </AgGridReact>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 ">
                  {selectedRow && (
                    <div className="selected-state">{selectedRow.name}</div>
                  )}
                  <div className="row">
                    <div className="col-xs-9">
                      <div
                        className="tab-pane fade show active"
                        id="StateWise"
                        role="tabpanel"
                        aria-labelledby="StateWise-tab"
                        style={{ width: "100%" }}
                      >
                        <ProjectStatGraph
                          graphTitle={"Co2 Extinguished"}
                          labels={
                            this.state.selectedRow?.monthly_stat?.map(
                              (x: any) => x.month
                            ) || []
                          }
                          hoverTextLabel="Co2 Observed"
                          values={
                            this.state.selectedRow?.monthly_stat?.map(
                              (x: any) => x.co2_value
                            ) || []
                          }
                          colors={["#F7B1B1"]}
                        />
                        {/* <div className="All_Graph_box">
                          {this.state.co2Chart.labels.length > 0 && (
                            <div>
                              <div className="chart-name">Co2 Extinguished</div>
                              <ReactApexChart
                                id="apexCharts"
                                options={this.state.co2Chart}
                                series={this.state.series}
                                type="area"
                                height={200}
                                width="100%"
                              />
                            </div>
                          )}
                        </div> */}
                      </div>
                    </div>
                    <div className="col-xs-9">
                      <div
                        className="tab-pane fade show active"
                        id="StateWise"
                        role="tabpanel"
                        aria-labelledby="StateWise-tab"
                        style={{ width: "100%" }}
                      >
                        <ProjectStatGraph
                          graphTitle={"Trees Planted"}
                          labels={
                            this.state.selectedRow?.monthly_stat?.map(
                              (x: any) => x.month
                            ) || []
                          }
                          hoverTextLabel="planted"
                          values={
                            this.state.selectedRow?.monthly_stat?.map(
                              (x: any) => x.plant_count
                            ) || []
                          }
                          colors={["#2CF55A"]}
                        />
                        {/* <div className="All_Graph_box">
                          {this.state.treesChart.labels.length > 0 && (
                            <div>
                              <div className="chart-name">Trees Planted</div>
                              <ReactApexChart
                                id="apexCharts"
                                options={this.state.treesChart}
                                series={this.state.series}
                                type="area"
                                height={200}
                                width="100%"
                              />
                            </div>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg4 section-bg" id="aboutUs">
            {/* <div className="bg4image "> */}
            {/* <div className="container"> */}
            <div
              className="row"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col-sm-6 col-md-6">
                <div className="rightcontainer4">
                  {/* <div className="leftsidec mt-7"> */}\
                  <div className="aboutussub">ABOUT US</div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6">
                <div className="rightcontainer5">
                  {/* <div className="rightsidec mt-7"> */}
                  <div className="text-style text-white  ">
                    {/* The Art of Living is a multi-faceted, non-profit
                    organization founded in 1981 by the world-renowned
                    humanitarian and spiritual leader, Gurudev Sri Sri Ravi
                    Shankar, with its international headquarters in Bangalore,
                    India and centers around the world. */}
                    The Art of Living is a multi-faceted, non-profit
                    organization with a global presence. Founded in 1981 by a
                    world renowned humanitarian and spiritual leader, Gurudev
                    Sri Sri Ravi Shankar ji. The International headquarters is
                    located in Bangalore, India and multiple centers across the
                    world. Our vision is to create a stress-free life and
                    violence free society.
                  </div>
                  <div className="text-style text-white mt-5">
                    {/* The deeply impactful educational and self-development
                    programs, social initiatives and service projects
                    facilitated by the organisation through one of the largest
                    volunteer bases in the world, have transformed the lives of
                    millions over the last four decades. The organisation is
                    guided by the Founder’s all-encompassing vision for humanity
                    - a stress-free, violence-free society!{" "} */}
                    The Art of Living is one of the largest voluntary based
                    organization in the world. Our educational,
                    self-developmental programs and service projects have
                    created an impact in the society which has helped to
                    transform millions of lives in the last four decades.
                  </div>
                  <div className="text-style text-white mt-3">
                    {/* 40 years | 156 Countries | 10,000+ Centers | 450 million
                    lives touched | 81 million trees planted */}
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
          </section>

          <section className="bgtestmonials ">
            <div className="testhead mb-4">Testimonials</div>
            <Carousel variant="dark">
              <Carousel.Item>
                <div className="d-flex justify-content-center">
                  <div className="tcontainer  ">
                    <img src="/assets/img/Volunter4.jpg" alt="Samantha" />
                    <p className="tdesc mt-4">
                      It's been a year since we commenced our journey with Grow
                      Trees to plant 50,000 trees . It is heart-warming to see
                      96% of trees planted survived by the end of the year. A
                      beautiful concept and well executed!
                    </p>
                    <p className="tname">Samantha</p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="d-flex justify-content-center">
                  <div className="tcontainer  ">
                    <img src="/assets/img/Volunter1.jpg" alt="John" />
                    <p className="tdesc mt-4">
                      It's been a year since we commenced our journey with Grow
                      Trees to plant 50,000 trees . It is heart-warming to see
                      96% of trees planted survived by the end of the year. A
                      beautiful concept and well executed!
                    </p>
                    <p className="tname">John</p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="d-flex justify-content-center">
                  <div className="tcontainer  ">
                    <img src="/assets/img/Volunter2.jpg" alt="Paul" />
                    <p className="tdesc mt-4">
                      It's been a year since we commenced our journey with Grow
                      Trees to plant 50,000 trees . It is heart-warming to see
                      96% of trees planted survived by the end of the year. A
                      beautiful concept and well executed!
                    </p>
                    <p className="tname">Paul</p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="d-flex justify-content-center">
                  <div className="tcontainer  ">
                    <img src="/assets/img/Volunter3.jpg" alt="Daisy" />
                    <p className="tdesc mt-4">
                      It's been a year since we commenced our journey with Grow
                      Trees to plant 50,000 trees . It is heart-warming to see
                      96% of trees planted survived by the end of the year. A
                      beautiful concept and well executed!
                    </p>
                    <p className="tname">Daisy</p>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </section>
        </main>
      </div>
    );
  }
}

export default ViewProjects;
