import { Component } from "react";
import { UserHelper } from "../../utils";
import { volunteerProps, volunteerState } from "./interface";
import { dashboardService } from "../../service";
import { showToaster, toasterTypes } from "../../widgets";
import AdminDashboard from "../adminDashboard";

class ExternalDashboard extends Component<volunteerProps, volunteerState> {
  constructor(props: volunteerProps) {
    super(props);
    this.state = {
      userData: {},
      topPerformer: [],
      ProjectId: "",
      totalPlantedCount: 0,
      totalLiveCount: 0,
      totalDeadCount: 0,
    };
  }

  componentDidMount() {
    const userDetails = UserHelper.getUserDetails();
    this.setState({
      userData: userDetails,
      ProjectId: this.props.projectId,
    });
    this.getPerformer();
    this.getStateData();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.projectId !== this.props.projectId) {
      this.setState({
        ProjectId: this.props.projectId,
      });
      this.getPerformer();
      this.getStateData();
    }
  }

  getStateData = (
    stateId: string = "",
    projectId: any = this.props.projectId
  ) => {
    try {
      dashboardService.getStateWiseData(stateId, projectId).subscribe({
        next: (response: any) => {
          try {
            if (
              response &&
              response?.statusCode &&
              response.statusCode === 200 &&
              response.data &&
              Array.isArray(response.data)
            ) {
              let planned = 0;
              let planted = 0;
              let dead = 0;
              response.data.forEach((obj: any) => {
                planned = planned + obj.planned_quantity;
                planted = planted + obj.planted_quantity;
                dead = dead + obj.dead_quantity;
              });
              this.setState({
                totalLiveCount: planned,
                totalPlantedCount: planted,
                totalDeadCount: dead,
              });
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        error: (error: any) => {
          showToaster(toasterTypes.ERROR, error.message);
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  getPerformer = (
    stateId: string = "",
    districtId: string = "",
    pincodeId: string = ""
  ) => {
    try {
      dashboardService
        .getVolunteerWiseData(stateId, districtId, pincodeId)
        .subscribe(
          (response: any) => {
            if (response.statusCode === 200) {
              this.setState({ topPerformer: response.data });
            }
          },
          (error: any) => {
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.error(error.message);
    }
  };

  render() {
    const {
      userData,
      topPerformer,
      totalPlantedCount,
      totalDeadCount,
      totalLiveCount,
    } = this.state;
    localStorage.setItem("pincode", userData.pincodes_name);

    return (
      <AdminDashboard {...this.props} />
      // <div className="container mt-3 mb-5 landingPage">
      //   <div className="row m-0 ">
      //     <div className="col-md-4">
      //       <UserCard
      //         name={`${userData.first_name} ${userData.last_name}`}
      //         email={userData.email_id}
      //         mobileNumber={userData.mobile_no_1}
      //         pincode={userData.pincodes_name}
      //         photoId={userData.profile_photo_id}
      //       />
      //     </div>
      //     <div className="col-md-8 mt-3">
      //       <div className="row gy-3 gx-3">
      //         <div className="col-md-12 flexbox-container">
      //           <div className="planned-card">
      //             <div className="planned-text">Total Planted</div>
      //             <div className="planned-count">{totalPlantedCount}</div>
      //           </div>
      //           <div className="planted-card">
      //             <div className="planned-text">Total Planned</div>
      //             <div className="planned-count">{totalLiveCount}</div>
      //           </div>
      //           <div className="dead-card">
      //             <div className="planned-text"> Total Dead</div>
      //             <div className="planned-count">{totalDeadCount}</div>
      //           </div>
      //         </div>
      //         <div className="col-md-12 mt-3 pro-3-">
      //           <TopPerformers topPerformer={topPerformer} />
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default ExternalDashboard;
