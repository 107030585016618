import { fetchCall, requestMethod } from "../../utils/ajax";

const getStateWiseData = (
  stateIds: string = "",
  projectId: string = "",
  date: string = ""
) =>
  fetchCall(
    `/statistics/state_wise_distributed_vs_planted?state_id_list=${stateIds}&project_id=${projectId}&filter_date=${date}`,
    requestMethod.GET
  );

const getDistrictWiseData = (
  stateIds: string = "",
  districtIds: string = "",
  projectId: string = "",
  date: string = ""
) =>
  fetchCall(
    `/statistics/district_wise_distributed_vs_planted?state_id_list=${stateIds}&district_id_list=${districtIds}&project_id=${projectId}&filter_date=${date}`,
    requestMethod.GET
  );

const getPincodeWiseData = (
  stateIds: string = "",
  districtIds: string = "",
  pincodeIds: string = "",
  projectId: string = "",
  date: string = ""
) =>
  fetchCall(
    `/statistics/pincode_wise_distributed_vs_planted?state_id_list=${stateIds}&district_id_list=${districtIds}&pincode_id_list=${pincodeIds}&project_id=${projectId}&filter_date=${date}`,
    requestMethod.GET
  );

const getVolunteerWiseData = (
  stateIds: string = "",
  districtIds: string = "",
  pincodeIds: string = ""
) =>
  fetchCall(
    `/statistics/tree_planted_top_volunteers?state_id_list=${stateIds}&district_id_list=${districtIds}&pincode_id_list=${pincodeIds}`,
    requestMethod.GET
  );

const getVolunteerAssignedData = (user_id: string = "") =>
  fetchCall(
    `/statistics/volunteer_assigned_count?user_id=${user_id}`,
    requestMethod.GET
  );

const getTopReferalsData = (
  stateIds: string = "",
  districtIds: string = "",
  pincodeIds: string = ""
) =>
  fetchCall(
    `/statistics/referral_top_volunteers?state_id_list=${stateIds}&district_id_list=${districtIds}&pincode_id_list=${pincodeIds}`,
    requestMethod.GET
  );

const getStateLevelDistributionOverview = (projectId: any) =>
  fetchCall(
    `/statistics/state_level_distribution?project_id=${projectId}`,
    requestMethod.GET
  );
const getDistrictLevelDistributionOverview = (stateId: any, projectId: any) =>
  fetchCall(
    `/statistics/district_level_distribution?state_id_list=${stateId}&project_id=${projectId}`,
    requestMethod.GET
  );

const getStatistics = (projectId: string) =>
  fetchCall(`/statistics?project_id=${projectId}`, requestMethod.GET);
const getAmountStatistics = () =>
  fetchCall(`/statistics/procurement_stat`, requestMethod.GET);

const getTopReferrals = (
  stateIds: string = "",
  districtIds: string = "",
  pincodeIds: string = ""
) =>
  fetchCall(
    `/statistics/referral_top_volunteers?state_id_list=${stateIds}&district_id_list=${districtIds}&pincode_id_list=${pincodeIds}`,
    requestMethod.GET
  );

const getGraphDetails = (projectId: string = "") =>
  fetchCall(
    `/statistics/project_stat_list?status=active&project_ids=${projectId}`,
    requestMethod.GET
  );

const getLeaderWiseData = (
  stateIds: string = "",
  districtIds: string = "",
  projectId: string = ""
) =>
  fetchCall(
    `/statistics/leader_wise_distribution?state_id_list=${stateIds}&district_id_list=${districtIds}&project_id=${projectId}`,
    requestMethod.GET
  );
const dashboardService = {
  getStateWiseData,
  getDistrictWiseData,
  getPincodeWiseData,
  getVolunteerWiseData,
  getVolunteerAssignedData,
  getTopReferalsData,
  getStateLevelDistributionOverview,
  getGraphDetails,
  getDistrictLevelDistributionOverview,
  getStatistics,
  getAmountStatistics,
  getTopReferrals,
  getLeaderWiseData,
};

export { dashboardService };
