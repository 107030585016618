import React, { Component } from "react";
import { inviteFriendsProps, inviteFriendsState } from "./interface";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import Button from "../../widgets/button";
import { Referal } from "../../widgets/referal";
import { showToaster, toasterTypes } from "../../widgets";
import { inviteFriendsRequestModal } from "../../model/index";
import { authenticationService } from "../../service";
import { Validation } from "../../utils";

class InviteFriends extends Component<inviteFriendsProps, inviteFriendsState> {
  constructor(props: inviteFriendsProps) {
    super(props);
    this.state = {
      friendName: "",
      mobileNumber: "",
      email: "",
    };
  }

  handleOnChange = (event: any) => {
    try {
      const { name, value } = event.currentTarget;
      const state: any = { ...this.state };
      state[name] = value;
      this.setState(state);
    } catch (error) {
      console.log(error.message);
    }
  };
  onSubmit = () => {
    try {
      if (Validation.isTextRequired(this.state.friendName, "name")) {
        return false;
      }
      if (Validation.isRequired(this.state.mobileNumber, "mobile number")) {
        return false;
      }
      if (
        Validation.isValidMobileNumber(this.state.mobileNumber, "mobile number")
      ) {
        return false;
      }

      if (Validation.isRequired(this.state.email, "email")) {
        return false;
      }
      if (this.state.email.length >= 0) {
        let pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(this.state.email)) {
          showToaster(
            toasterTypes.ERROR,
            "Please enter a valid email address."
          );
          return true;
        }
      }
      const { friendName, mobileNumber, email } = this.state;
      const requestData: inviteFriendsRequestModal = {
        name: friendName,
        mobile_number: mobileNumber,
        email_address: email,
      };
      authenticationService.inviteFriends(requestData).subscribe(
        (data: any) => {
          try {
            if (data.status && data.statusCode === 200) {
              this.setState({
                friendName: "",
                mobileNumber: "",
                email: "",
              });

              showToaster(toasterTypes.SUCCESS, data.message);
            } else {
              showToaster(toasterTypes.ERROR, data.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  render() {
    const {
      handleOnChange,
      onSubmit,
      state: { friendName, mobileNumber, email },
    } = this;
    return (
      <div>
        <main id="main">
          <section id="MyProfile" className="MyProfile">
            <div className="container" data-aos="fade-up">
              <div className="row no-gutters">
                <div className="col-lg-12 d-flex flex-column justify-content-center ">
                  <h3 className="heading-secondary">
                    Invite your friends to join
                  </h3>
                  <div className="row no-gutters">
                    <div className="col-lg-6">
                      <div className="card mb-4">
                        <div className="card-body p-5">
                          <h5 className="mb-4 form-label">
                            Share Your Referral Link
                          </h5>

                          <FieldItem
                            onChange={handleOnChange}
                            label="Name Of Your Friend"
                            name="friendName"
                            value={friendName}
                            placeholder="Name of your friend"
                            type={FIELD_TYPES.TEXT}
                            className="form-control required"
                            required
                          />

                          <FieldItem
                            onChange={handleOnChange}
                            label="Mobile Number"
                            name="mobileNumber"
                            value={mobileNumber}
                            placeholder="Mobile number"
                            type={FIELD_TYPES.TEXT}
                            className="form-control required"
                            required
                          />

                          <FieldItem
                            onChange={handleOnChange}
                            label="Email ID"
                            name="email"
                            value={email}
                            placeholder="Email ID"
                            type={FIELD_TYPES.TEXT}
                            className="form-control required"
                            required
                          />

                          <div className="d-grid gap-2">
                            <Button
                              onClick={onSubmit}
                              className="btn Btn_green"
                              label="Invite your friend"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <Referal />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default InviteFriends;
