import React, { Component } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import { EditRow } from "./partials/EditRow";
import { showToaster, toasterTypes } from "../../../widgets";
import { badgeservice as badgeService } from "../../../service";
import { listBadgeProps, listBadgeState } from "./interface";
import Pagination from "../../../widgets/pagination";
import Tablefeature from "../../../widgets/tableFeature";
import Button from "../../../widgets/button";

class ListBadge extends Component<listBadgeProps, listBadgeState> {
  constructor(props: listBadgeProps) {
    super(props);
    this.state = {
      tableRecords: [],
      gridApi: "",
      context: {
        componentParent: this,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
    };
  }

  handlePageClick = (
    data: { selected: number },
    pageSize: number = this.state.pagination.pageSize
  ) => {
    this.getAllBadgeList(data.selected, pageSize);
  };

  componentDidUpdate(prevProps: any) {
    if (prevProps.projectId !== this.props.projectId) {
      this.getAllBadgeList();
    }
  }


  onGridReady = (params: any) => {
    try {
      this.getAllBadgeList();
      this.setState({
        gridApi: params.api,
      });
    } catch (error) {
      console.log(error?.message);
    }
  };

  handleSearch = (e: any) => {
    this.getAllBadgeList(1, 10, e.target.value);
  };

  getAllBadgeList = (
    page: number = 1,
    pageSize: number = 10,
    key: string = ""
  ) => {
    try {
      badgeService.getAllBadgeList(page, pageSize, key).subscribe({
        next: (response: any) => {
          try {
            if (response && response.status && response.statusCode === 200) {
              if (response.content && Array.isArray(response.content)) {
                try {
                  let rowData: any = response.content?.map((element: any) => ({
                    id: element.id,
                    BadgeName: element.badge_name,
                    BadgeDetails: element.badge_details,
                    AchievementType: element.achievement_type,
                    achievementCount: element.achievement_count,
                    image_id: element.image_id,
                  }));
                  this.state.gridApi.setRowData(rowData);
                  this.setState({
                    pagination: {
                      pageNumber: response.page || 1,
                      pageSize: response.size || 10,
                      totalPages: response.totalPages || 1,
                      totalElements: response.totalElements || 10,
                    },
                  });
                } catch (error) {
                  console.log(error?.message);
                }
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        error: (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        },
      });
    } catch (error) {
      console.log(error?.message);
    }
  };

  changeStatusHandler = (data: any) => {
    try {
      const requestData = {
        id: data.id,
        status: "deleted",
      };
      badgeService.badgeChangeStatus(requestData).subscribe({
        next: (data: any) => {
          try {
            if (data?.status && data?.statusCode === 200) {
              this.getAllBadgeList();
              showToaster(toasterTypes.SUCCESS, data.message);
            } else {
              showToaster(toasterTypes.ERROR, data.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        error: (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        },
      });
    } catch (error) {
      console.log(error?.message);
    }
  };
  render() {
    const {
      state: { pagination },
      handlePageClick,
    } = this;
    return (
      <main id="main">
        <section id="ViewProject" className="ViewProject">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters">
              <div className="col-lg-12 d-flex flex-column justify-content-center">
                <div className="d-flex align-items-center justify-content-start mb-4 ">
                  <h3 className="secondary-heading-normal m-0 table-header-label">
                    View Badges
                  </h3>
                  <Link to="/createBadge">
                    <Button
                      label="Create"
                      className="ml-2 btn btn-outline-secondary"
                      // onClick={this.props.toggle}
                    />
                  </Link>
                </div>
                <Tablefeature
                  totalElements={pagination.totalElements}
                  handleSearch={this.handleSearch}
                />
                <div className="grid">
                  <div
                    className="ag-theme-alpine"
                    style={{ position: "unset" }}
                  >
                    <AgGridReact
                      domLayout="autoHeight"
                      cacheBlockSize={10}
                      context={this.state.context}
                      rowData={this.state.tableRecords}
                      defaultColDef={{
                        resizable: true,
                        minWidth: 100,
                      }}
                      onGridReady={this.onGridReady}
                      enableBrowserTooltips={true}
                      frameworkComponents={{
                        editColumn: EditRow,
                      }}
                      suppressDragLeaveHidesColumns={true}
                    >
                      <AgGridColumn
                        field="BadgeName"
                        sortable={true}
                        filter={true}
                        flex={1}
                        tooltipField="BadgeName"
                        suppressMovable= {true}
                      />
                      <AgGridColumn
                        field="BadgeDetails"
                        sortable={true}
                        flex={1}
                        filter={true}
                        tooltipField="BadgeDetails"
                        suppressMovable= {true}
                      />
                      <AgGridColumn
                        field="AchievementType"
                        sortable={true}
                        flex={1}
                        filter={true}
                        tooltipField="AchievementType"
                        suppressMovable= {true}
                      />
                      <AgGridColumn
                        field="achievementCount"
                        sortable={true}
                        flex={1}
                        filter={true}
                        type="rightAligned"
                        suppressMovable= {true}
                      />
                      <AgGridColumn
                        field="Action"
                        flex={1}
                        cellRenderer="editColumn"
                        suppressMovable= {true}
                      />
                    </AgGridReact>
                    <div className="col-md-12 mt-2 ">
                      <Pagination
                        page={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        handlePageChange={handlePageClick}
                        totalPage={pagination.totalPages}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default ListBadge;
