import React, { Component, Fragment } from "react";
import { Col, Row, UncontrolledTooltip } from "reactstrap";

import { Link } from "react-router-dom";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
// import { ActionRow } from "./partials/actionRow";
import { HistoryPlantsProps, HistoryPlantsState,  } from "./interface";
// import { distributionService } from "../../../service";
// import { showToaster, toasterTypes } from "../../../widgets";
import moment from "moment";
import Pagination from "../../widgets/pagination";
import { distributionService } from "../../service/distributionService";
import { showToaster, toasterTypes } from "../../widgets/toaster";
import { plantService } from "../../service";
// import Pagination from "../../../widgets/pagination";

export class HistoryPlants extends Component<
HistoryPlantsProps,
HistoryPlantsState
> {
  constructor(props: HistoryPlantsProps) {
    super(props);
    this.state = {
      modal: false,
      gridApi: "",
      tableRecords: [],
      context: {
        componentParent: this,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
    };
  }

//   handlePageClick = (data: { selected: number }, pageSize: number = this.state.pagination.pageSize) => {
//     this.getDistributionList(data.selected, pageSize);
//   };

  getHistoryList = (page:number=1, pageSize:number=10) => {
    try {
      plantService
        .getAllHistoryList(this.props.match.params.historyId)
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (
                  Array.isArray(response.content) &&
                  response.content?.length > 0
                ) {
                  let rowData: any = response.content.map((element: any) => ({
                    id: element.id,
                    DistributionQuantity: element.sapling_quantity,
                    Remark: element.remarks,
                    PlantCount:element.count,
                    ActivityDate: moment(element.date_of_activity).format(
                      "DD-MM-YYYY"
                    ),
                    PlantStatus:element.plant_status,
                    PincodeId: element.pincode_id,
                    distributionType: element.distribution_type
                  }));
                  this.state.gridApi.setRowData(rowData);
                  this.setState({
                    pagination: {
                      pageNumber: response.page || 1,
                      pageSize: response.size || 10,
                      totalPages: response.totalPages || 1,
                      totalElements: response.totalElements || 10,
                    }
                  });
                }
              } else {
                this.state.gridApi.setRowData([]);
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error?.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };
  onGridReady = (params: any) => {
    this.getHistoryList();
    this.setState({
      gridApi: params.api,
    });
  };

//   changeStatusHandler = (data: any) => {
//     try {
//       const requestData = {
//         id: data.id,
//         status: "deleted",
//       };
//       distributionService.DistributionChangeStatus(requestData).subscribe(
//         (data: any) => {
//           try {
//             if (data.status && data.statusCode === 200) {
//               this.getDistributionList();
//               showToaster(toasterTypes.SUCCESS, data.message);
//             } else {
//               showToaster(toasterTypes.ERROR, data.message);
//             }
//           } catch (error) {
//             console.log(error.message);
//           }
//         },
//         (error: any) => {
//           console.log(error);
//           showToaster(toasterTypes.ERROR, error.message);
//         }
//       );
//     } catch (error) {
//       console.log(error.message);
//     }
//   };
  render() {
    const {pagination} = this.state
    return (
      <>
        <section id="ViewProject" className="ViewProject">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters">
              
              <div className="col-lg-12 d-flex flex-column justify-content-center ">

                <Row>
                  <Col md={11}>
                    <h3 className="heading-secondary">Plant History</h3>
                  </Col>
                  <Col md={1}>
                    <Link to="/listPlants" >
                      <i className='fa fa-times  text-dark float-right' id="close"></i>
                    </Link>
                    <UncontrolledTooltip target={`close`}>
                      Close
                    </UncontrolledTooltip>
                  </Col>
                  
                </Row>

                <div className="section-title">
                  <div className="grid">
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        domLayout="autoHeight"
                        onGridReady={this.onGridReady}
                        context={this.state.context}
                        rowData={this.state.tableRecords}
                        pagination={false}
                        
                        cacheBlockSize={10}
                        enableBrowserTooltips={true}
                        frameworkComponents={{
                        //   ActionColumn: ActionRow,
                        }}
                        suppressDragLeaveHidesColumns={true}
                      >
                        <AgGridColumn
                          field="ActivityDate"
                          sortable={true}
                          filter={true}
                          tooltipField='ActivityDate'
                          suppressMovable= {true}
                          flex={1}
                        />
                        {/* <AgGridColumn
                          field="distributionType"
                          sortable={true}
                          filter={true}
                        /> */}
                        <AgGridColumn
                          field="PlantStatus"
                          sortable={true}
                          filter={true}
                        //   type='rightAligned'
                          tooltipField='PlantStatus'
                          suppressMovable= {true}
                          flex={1}
                        />
                        <AgGridColumn
                          field="PlantCount"
                          sortable={true}
                          filter={true}
                          tooltipField='PlantCount'
                          suppressMovable= {true}
                          flex={1}
                        />
                        <AgGridColumn
                          field="Remark"
                          sortable={true}
                          filter={true}
                          tooltipField='Remark'
                          suppressMovable= {true}
                          flex={1}
                        />
                        
                      </AgGridReact>
                      {/* <div className="col-md-12 mt-2">
                      <Pagination
                        page={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        handlePageChange={this.handlePageClick}
                        totalPage={pagination.totalPages}
                      />
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

//  default EditRow;
