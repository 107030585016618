import React, { PropsWithChildren, useState, useEffect } from "react";
import { Observable, isObservable, Subscription } from "rxjs";

type AsyncProps<T> = PropsWithChildren<{ observable?: Observable<T> }>;

export function Async<T = any>({
  observable,
  children,
}: AsyncProps<T>): JSX.Element {
  const [value, setValue] = useState<T | null>(null);
  useEffect(() => {
    let subscription: Subscription;
    if (isObservable(observable)) {
      subscription = observable.subscribe(setValue);
    } else if (isObservable(children)) {
      subscription = (children as Observable<T>).subscribe(setValue);
    }
    return () => subscription.unsubscribe();
  }, [observable, children]);
  return <>{value}</>;
}
