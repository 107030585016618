import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { userRoles } from "../../../constants";
import { authenticationService } from "../../../service";
import { UserHelper, Validation } from "../../../utils";
import { showToaster, toasterTypes } from "../../../widgets";
import Button from "../../../widgets/button";
import { FieldItem, FIELD_TYPES } from "../../../widgets/fields";
import DropDown from "../dropdown";

export type Props = {
  modal: boolean;
  isEdit?: boolean;
  existingUserId?: string;
  roleOptions: any;
  stateOptions: any;
  projects: any;
  toggle: () => void;
  refresh: () => void;
};

type option = {
  label: string;
  value: string;
};

type State = {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  address: string;
  selectedRole: option | null;
  selectedState: option | null;
  selectedDistrict: option | null;
  selectedPincode: option | null;
  selectedProjects: option[] | [];
  districtOptions: option[] | [];
  pincodeOptions: option[] | [];
};

const AddUser = (props: Props) => {
  const [state, setState] = useState<State>({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    address: "",
    selectedRole: { value: "4", label: "Volunteer" },
    selectedState: null,
    selectedDistrict: null,
    selectedPincode: null,
    selectedProjects: [],
    districtOptions: [],
    pincodeOptions: [],
  });

  const {
    toggle,
    modal,
    roleOptions,
    stateOptions,
    projects,
    isEdit,
    existingUserId,
    refresh,
  } = props;
  const {
    firstName,
    lastName,
    mobileNumber,
    email,
    address,
    selectedRole,
    selectedState,
    selectedDistrict,
    selectedPincode,
    selectedProjects,
    districtOptions,
    pincodeOptions,
  } = state;

  const currentUserRoleId = UserHelper.getUserDetails()?.user_role_id;

  useEffect(() => {
    if (selectedDistrict) getAllPincode(selectedDistrict.value);
  }, [selectedDistrict]);

  useEffect(() => {
    if (selectedState) {
      getDistrictByState(selectedState?.value);
    }
  }, [selectedState]);

  useEffect(() => {
    if (!modal) {
      reset();
    }
  }, [modal]);

  useEffect(() => {
    if (isEdit && modal) {
      getExistingUserDetails(existingUserId);
    } else if (currentUserRoleId && modal) {
      const userId = UserHelper.getUserDetails()?.id;
      getCurrentUserDetails(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, modal, currentUserRoleId]);

  const getCurrentUserDetails = (userId: string | undefined) => {
    if (userId) {
      authenticationService.getProfileDetails(userId).subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200 && response.data) {
            if (currentUserRoleId === (userRoles.STATE_ADMIN as any)) {
              setState((prevState) => ({
                ...prevState,
                selectedState: {
                  label: response?.data?.state_name,
                  value: response?.data?.state_id,
                },
              }));
            } else if (
              currentUserRoleId === (userRoles.DISTRICT_ADMIN as any)
            ) {
              setState((prevState) => ({
                ...prevState,
                selectedState: {
                  label: response?.data?.state_name,
                  value: response?.data?.state_id,
                },
                selectedDistrict: {
                  label: response?.data?.district_name,
                  value: response?.data?.district_id,
                },
              }));
            }
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    }
  };

  const getExistingUserDetails = (userId: string | undefined) => {
    if (userId) {
      authenticationService.getProfileDetails(userId).subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200 && response.data) {
            console.log(
              roleOptions.filter(
                (x: any) => x.value === response?.data?.user_role_id
              )
            );
            setState((prevState) => ({
              ...prevState,
              firstName: response?.data?.first_name,
              lastName: response?.data?.last_name,
              mobileNumber: response?.data?.mobile_no_1,
              email: response?.data?.email_id,
              address: response?.data?.address,
              selectedRole:
                roleOptions.filter(
                  (x: any) => x.value === response?.data?.user_role_id
                )[0] || null,
              selectedPincode: response?.data?.pincode_id
                ? {
                    label: response?.data?.pincodes_name,
                    value: response?.data?.pincode_id,
                  }
                : null,
              selectedState: response?.data?.state_id
                ? {
                    label: response?.data?.state_name,
                    value: response?.data?.state_id,
                  }
                : null,
              selectedDistrict: response?.data?.district_id
                ? {
                    label: response?.data?.district_name,
                    value: response?.data?.district_id,
                  }
                : null,
            }));
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    }
  };

  const closeBtn = (
    <button className="close" title="Close" onClick={toggle}>
      &times;
    </button>
  );

  const handleOnChange = (event: any) => {
    const { name, value } = event.currentTarget;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlestateChange = (selectedState: any) => {
    setState((prevState) => ({
      ...prevState,
      selectedState,
      districtOptions: [],
      selectedDistrict: null,
      pincodeOptions: [],
      selectedPincode: null,
    }));
  };

  const handleDistrictChange = (selectedDistrict: any) => {
    setState((prevState) => ({
      ...prevState,
      selectedDistrict,
      pincodeOptions: [],
      selectedPincode: null,
    }));
  };

  const handlePincodeChange = (selectedPincode: any) => {
    setState((prevState) => ({
      ...prevState,
      selectedPincode,
    }));
  };

  const handleRoleChange = (selectedRole: any) => {
    setState((prevState) => ({
      ...prevState,
      selectedRole,
    }));
  };

  const reset = () => {
    setState({
      firstName: "",
      lastName: "",
      mobileNumber: "",
      email: "",
      address: "",
      selectedRole: { value: "4", label: "Volunteer" },
      selectedState: null,
      selectedDistrict: null,
      selectedPincode: null,
      selectedProjects: [],
      districtOptions: [],
      pincodeOptions: [],
    });
  };

  const onSubmit = () => {
    if (Validation.isTextRequired(firstName, "first name")) {
      return false;
    }
    if (Validation.isTextRequired(lastName, "last name")) {
      return false;
    }
    if (Validation.isRequired(mobileNumber, "mobile number")) {
      return false;
    }

    if (Validation.isValidMobileNumber(mobileNumber, "mobile number")) {
      return false;
    }
    if (Validation.isRequired(email, "email ")) {
      return false;
    }
    if (email.length >= 0) {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        showToaster(toasterTypes.ERROR, "Please enter a valid email address.");
        return true;
      }
    }
    if (Validation.isRequired(address, "address")) {
      return false;
    }

    if (!selectedRole?.value) {
      showToaster(toasterTypes.ERROR, "Please Select Role.");
      return false;
    }

    if (
      !selectedState?.value &&
      (selectedRole?.value === (userRoles.STATE_ADMIN as any) ||
        selectedRole?.value === (userRoles.DISTRICT_ADMIN as any) ||
        currentUserRoleId === (userRoles.STATE_ADMIN as any) ||
        currentUserRoleId === (userRoles.DISTRICT_ADMIN as any))
    ) {
      showToaster(toasterTypes.ERROR, "Please Select Category.");
      return false;
    }

    if (
      !selectedDistrict?.value &&
      (selectedRole?.value === (userRoles.DISTRICT_ADMIN as any) ||
        currentUserRoleId === (userRoles.DISTRICT_ADMIN as any))
    ) {
      showToaster(toasterTypes.ERROR, "Please Select Area.");
      return false;
    }

    if (
      !selectedProjects.length &&
      selectedRole?.value !== (userRoles.SUPER_ADMIN as any) &&
      !isEdit
    ) {
      showToaster(toasterTypes.ERROR, "Please Select atleast one project.");
      return false;
    }

    save();
  };

  const save = () => {
    try {
      const userData: any = {
        first_name: firstName,
        last_name: lastName,
        mobile_number: mobileNumber,
        email_address: email,
        address: address,
        role: selectedRole?.value,
        pincode: selectedPincode?.value,
        stateid: selectedState?.value,
        districtid: selectedDistrict?.value,
        user_terms: true,
        projects: selectedProjects.map((x: any) => x.value),
        isAll:
          selectedRole?.value === (userRoles.SUPER_ADMIN as any) ? true : false,
      };

      if (isEdit) {
        userData.user_id = existingUserId;
      }

      authenticationService.addEditProfile(userData).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            refresh();
            toggle();
            reset();
            showToaster(toasterTypes.SUCCESS, data.message);
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const getDistrictByState = (stateId: string) => {
    try {
      authenticationService.getAllDistrictByStateId(stateId).subscribe(
        (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              if (response.data?.length > 0) {
                let rowData: any = response.data.map((element: any) => ({
                  label: element.name,
                  value: element.id,
                }));

                setState((prevState) => ({
                  ...prevState,
                  districtOptions: rowData,
                }));
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const getAllPincode = (districtId: string) => {
    try {
      authenticationService.getAllPincodeByDistictId(districtId).subscribe(
        (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              if (response.data?.length > 0) {
                let rowData: any = response.data.map((element: any) => ({
                  label: element.name,
                  value: element.id,
                }));

                setState((prevState) => ({
                  ...prevState,
                  pincodeOptions: rowData,
                }));
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  return (
    <Modal size="lg" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalHeader className="modal_Bg" close={closeBtn} toggle={toggle}>
        <div className="modal_title">{isEdit ? "Update" : "Create"} User</div>
      </ModalHeader>
      <ModalBody
        style={{
          height: "400px",
          overflowY: "auto",
          position: "relative",
        }}
      >
        <div className="row">
          <div className="col-md-6">
            <FieldItem
              onChange={handleOnChange}
              label="First name"
              name="firstName"
              value={firstName}
              placeholder="First name"
              type={FIELD_TYPES.TEXT}
              className="form-control required"
              required
            />
          </div>
          <div className="col-md-6">
            <FieldItem
              onChange={handleOnChange}
              label="Last name"
              name="lastName"
              value={lastName}
              placeholder="Last name"
              type={FIELD_TYPES.TEXT}
              className="form-control required"
              required
            />
          </div>
          <div className="col-md-6">
            <FieldItem
              onChange={handleOnChange}
              label="Mobile number"
              name="mobileNumber"
              value={mobileNumber}
              placeholder="Mobile number"
              type={FIELD_TYPES.TEXT}
              className="form-control required"
              required
            />
          </div>
          <div className="col-md-6">
            <FieldItem
              onChange={handleOnChange}
              label="Email address"
              name="email"
              value={email}
              placeholder="Email address"
              type={FIELD_TYPES.TEXT}
              className="form-control required"
              disabled={
                currentUserRoleId !== (userRoles.SUPER_ADMIN as any) && isEdit
              }
              required
            />
          </div>
          <div className="col-md-6">
            <FieldItem
              onChange={handleOnChange}
              label="Address"
              name="address"
              placeholder="Address"
              value={address}
              type={FIELD_TYPES.TEXT}
              className="form-control required"
              required
            />
          </div>
          <div className="col-md-6">
            <FieldItem
              onChange={handleRoleChange}
              label="Role"
              name="Role"
              value={selectedRole}
              options={roleOptions}
              placeholder="Select Role"
              type={FIELD_TYPES.DROP_DOWN}
              className="selectRequired"
              required
            />
          </div>
          <div className="col-md-6">
            <FieldItem
              onChange={handlestateChange}
              label="Category"
              name="Category"
              value={selectedState}
              options={stateOptions}
              placeholder="Select Category"
              type={FIELD_TYPES.DROP_DOWN}
              disabled={
                currentUserRoleId === (userRoles.STATE_ADMIN as any) ||
                currentUserRoleId === (userRoles.DISTRICT_ADMIN as any)
              }
              className={
                selectedRole?.value === (userRoles.STATE_ADMIN as any) ||
                selectedRole?.value === (userRoles.DISTRICT_ADMIN as any) ||
                currentUserRoleId === (userRoles.STATE_ADMIN as any) ||
                currentUserRoleId === (userRoles.DISTRICT_ADMIN as any)
                  ? "selectRequired"
                  : ""
              }
              required={
                selectedRole?.value === (userRoles.STATE_ADMIN as any) ||
                selectedRole?.value === (userRoles.DISTRICT_ADMIN as any) ||
                currentUserRoleId === (userRoles.STATE_ADMIN as any) ||
                currentUserRoleId === (userRoles.DISTRICT_ADMIN as any)
              }
            />
          </div>
          <div className="col-md-6">
            <FieldItem
              onChange={handleDistrictChange}
              label="Area"
              name="Area"
              value={selectedDistrict}
              options={districtOptions}
              placeholder="Select Area"
              type={FIELD_TYPES.DROP_DOWN}
              disabled={currentUserRoleId === (userRoles.DISTRICT_ADMIN as any)}
              className={
                selectedRole?.value === (userRoles.DISTRICT_ADMIN as any) ||
                currentUserRoleId === (userRoles.DISTRICT_ADMIN as any)
                  ? "selectRequired"
                  : ""
              }
              required={
                selectedRole?.value === (userRoles.DISTRICT_ADMIN as any) ||
                currentUserRoleId === (userRoles.DISTRICT_ADMIN as any)
              }
            />
          </div>
          <div className="col-md-6">
            <FieldItem
              onChange={handlePincodeChange}
              label="Pin Code"
              name="selectedPincode"
              value={selectedPincode}
              options={pincodeOptions}
              placeholder="Select Pin Code"
              type={FIELD_TYPES.DROP_DOWN}
              menuPlacement={"top"}
            />
          </div>

          {selectedRole?.value !== (userRoles.SUPER_ADMIN as any) && !isEdit && (
            <div className="col-md-12">
              <div className="form-group mb-3">
                <label className="form-label">
                  Select Projects
                  <label className="specialcharacter-required">*</label>
                </label>
                <DropDown
                  placeholder="Select projects"
                  value={selectedProjects}
                  options={projects}
                  handleChange={(value: any) =>
                    setState((prevState) => ({
                      ...prevState,
                      selectedProjects: value || [],
                    }))
                  }
                  multi={true}
                />
              </div>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          label="Cancel"
          className="ml-2 btn btn-outline-secondary"
          onClick={toggle}
        />
        <Button
          label={isEdit ? "Update " : "Save"}
          className="ml-2 btn btn-theme-primary"
          onClick={onSubmit}
        />
      </ModalFooter>
    </Modal>
  );
};

export default AddUser;
