import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import _ from "lodash";

interface tableProps {
  totalElements: string | number;
  handleSearch: (e: any) => {} | void;
  customClassName: string;
}

interface tableState {
  keyWord: string;
}

export default class Tablefeature extends Component<tableProps, tableState> {
  constructor(props: tableProps) {
    super(props);
    this.state = {
      keyWord: "",
    };
    this.setSearch = _.debounce(this.setSearch, 1000);
  }
  public static defaultProps = {
    customClassName: "",
  };

  onChange = (e: any) => {
    this.setState({ keyWord: e.target.value ? e.target.value : "" });
    this.setSearch(e);
  };

  setSearch = (e: any) => {
    this.setState({ keyWord: e.target.value }, () =>
      this.props.handleSearch(e)
    );
  };
  render() {
    const { totalElements } = this.props;
    return (
      <div>
        <div className="row align-items-center gy-1">
          <div className="col-md-9">
            <h3 className="secondary-heading-normal m-0 table-header-label">
              Total Entries | {totalElements}
            </h3>
          </div>
          <div className="col-md-3">
            <FieldItem
              // onChange={this.setSearch}
              onChange={(event) => this.onChange(event)}
              name="search"
              placeholder="Search"
              value={this.state.keyWord}
              type={FIELD_TYPES.SEARCH}
              clearSearch={() =>
                this.setState({ keyWord: "" }, () =>
                  this.props.handleSearch({ target: { value: "" } })
                )
              }
              className={`form-control ${
                this.props.customClassName ? this.props.customClassName : ""
              }`}
            />
          </div>
        </div>
      </div>
    );
  }
}
