import React, { Component } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import EditRow from "./partials/EditRow";
import { StatusRow } from "./partials/StatusRow";
import { showToaster, toasterTypes } from "../../widgets";
import moment from "moment";
import { projectService } from "../../service/projectService";
import { listProjectProps, listProjectState } from "./interface";
import Pagination from "../../widgets/pagination";
import Tablefeature from "../../widgets/tableFeature";
import Button from "../../widgets/button";
import {
  parentProjectSubscriber,
  projectChangeSubscriber,
  projectListSubscriber,
} from "../../utils/subscriber";
import StatusChangeRow from "./partials/StatusChangeRow";
import { authenticationService } from "../../service";
import { ChangeProjectRequestModal } from "../../widgets/layout/addressBar/interface/change-project-request";

class ListProject extends Component<listProjectProps, listProjectState> {
  constructor(props: listProjectProps) {
    super(props);
    this.state = {
      tableRecords: [],
      statusOptions: [],
      dataTableKey: 0,
      pageSize: 10,
      page: 1,
      gridApi: "",
      context: {
        componentParent: this,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
      modal: false,
    };
  }
  componentDidMount() {
    this.getProjectStatus();
  }

  reFreshTable = () => {
    this.setState({ dataTableKey: this.state.dataTableKey + 1 });
  };
  getReactContainerClasses() {
    return ["custom-tooltip"];
  }

  handlePageClick = (
    data: { selected: number },
    pageSize: number = this.state.pagination.pageSize
  ) => {
    this.getAllRowData(data.selected, pageSize);
  };

  handleSearch = (e: any) => {
    this.getAllRowData(1, 10, e.target.value);
  };

  getAllRowData = (
    page: number = 1,
    pageSize: number = 10,
    key: string = ""
  ) => {
    try {
      projectService.getAllProjectList(page, pageSize, key).subscribe(
        (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              if (response.content && Array.isArray(response.content)) {
                let rowData: any = [];
                response.content.forEach((element: any) => {
                  rowData.push({
                    id: element.id,
                    ProjectName: element.name,
                    NameOfPlant: element.plant_name,
                    Status: element.status,
                    statusId: element.status,
                    ParentProject: element.parent_name,

                    StartDate: moment(element.start_date).format("DD-MM-YYYY"),
                    EndDate: moment(element.end_date).format("DD-MM-YYYY"),
                  });
                });

                // const projectListOptionsForNavBar: any = response.content.map(
                //   (element: any) => {
                //     return { value: element.id, label: element.name };
                //   }
                // );

                // this.setState({ tableRecords: rowData });
                this.state.gridApi.setRowData(rowData);
                this.getProjectData(this.props.projectId);
                // projectListSubscriber.next(projectListOptionsForNavBar);
                this.setState({
                  pagination: {
                    pageNumber: response.page || 1,
                    pageSize: response.size || 10,
                    totalPages: response.totalPages || 1,
                    totalElements: response.totalElements || 10,
                  },
                });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error?.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  getProjectData = (projectId: string) => {
    try {
      projectService.getAllProjectListSelect().subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            try {
              if (
                Array.isArray(response.content) &&
                response.content?.length > 0
              ) {
                let rowData: any = [];
                response.content.forEach((element: any) => {
                  if (element.child_project.length > 0) {
                    let options: any = [];
                    element.child_project.forEach((subElement: any) => {
                      options.push({
                        label: subElement.name,
                        value: subElement.id,
                      });
                    });
                    rowData.push({
                      label: element.name,
                      value: element.id,
                      options,
                    });
                  } else {
                    rowData.push({
                      label: element.name,
                      value: element.id,
                      options: [],
                    });
                  }
                });

                let tempProject: any = null;
                rowData.forEach((obj: any) => {
                  if (obj.value === projectId) {
                    tempProject = obj;
                    return false;
                  } else if (obj.options?.length > 0) {
                    obj.options.forEach((subObj: any) => {
                      if (subObj.value === projectId) {
                        tempProject = subObj;
                        return false;
                      }
                    });
                  }
                });

                /** select default project */
                if (!tempProject) {
                  const subProjects = rowData.filter(
                    (x: any) => x.options.length > 0
                  );

                  if (subProjects.length > 0) {
                    const requestData: ChangeProjectRequestModal = {
                      project_id: subProjects[0].options[0].value,
                    };

                    const selectedProject = subProjects[0].options[0];
                    projectService.changeProjectOfUser(requestData).subscribe(
                      (data: any) => {
                        if (data.status && data.statusCode === 200) {
                          projectChangeSubscriber.next(selectedProject.label);
                          rowData.forEach((parent: any) => {
                            parent?.options?.forEach((sub: any) => {
                              if (sub.value === selectedProject.value) {
                                parentProjectSubscriber.next({
                                  label: parent.label,
                                  value: parent.value,
                                });
                              }
                            });
                          });
                          // window.location.reload();
                        } else {
                          showToaster(toasterTypes.ERROR, data.message);
                        }
                      },
                      (error: any) => {
                        console.log(error);
                        showToaster(toasterTypes.ERROR, error.message);
                      }
                    );
                  } else {
                    projectChangeSubscriber.next("");
                    parentProjectSubscriber.next({
                      label: rowData?.[0]?.label,
                      value: rowData?.[0]?.value,
                    });
                  }
                }
                if (tempProject) {
                  projectChangeSubscriber.next(tempProject.label);
                }

                projectListSubscriber.next(rowData);
              }
            } catch (error) {
              console.log(error?.message);
            }
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  getProjectStatus = () => {
    try {
      projectService.getStatus().subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            if (Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any) => ({
                label: element.value,
                value: element.id,
              }));
              this.setState({ statusOptions: rowData }, () => {
                this.getAllRowData();
              });
            }
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  onGridReady = (params: any) => {
    this.getAllRowData();
    this.setState({
      gridApi: params.api,
    });
  };
  changeStatusHandler = (data: any, deleteStatus: boolean = false) => {
    try {
      const userData = {
        id: data.id,
        status: deleteStatus
          ? "deleted"
          : data.StatusData === "active"
          ? "inactive"
          : "active",
      };
      projectService.projectChangeStatus(userData).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            this.getAllRowData();
            showToaster(toasterTypes.SUCCESS, data.message);
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  render() {
    const {
      state: { pagination },
      handlePageClick,
    } = this;
    return (
      <main id="main">
        <section id="ViewProject" className="ViewProject">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters">
              <div className="col-lg-12 d-flex align-items-center justify-content-between mb-4">
                <div className=" d-flex align-items-center justify-content-start ">
                  <h3 className="secondary-heading-normal m-0 table-header-label">
                    View Projects
                  </h3>
                  <Link to="/createProject">
                    <Button
                      label="Create"
                      className="ml-2 btn btn-outline-secondary"
                    />
                  </Link>
                </div>

                <Link to="/parent-project-list" style={{ marginRight: "2rem" }}>
                  <Button
                    label="View Parent Project List"
                    className="ml-2 btn btn-outline-secondary"
                  />
                </Link>
              </div>
              <div className="col-lg-12 d-flex flex-column justify-content-center">
                <Tablefeature
                  totalElements={pagination.totalElements}
                  handleSearch={this.handleSearch}
                />
                <div className="grid">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      key={this.state.dataTableKey}
                      domLayout={"autoHeight"}
                      cacheBlockSize={10}
                      context={this.state.context}
                      rowData={this.state.tableRecords}
                      enableCellChangeFlash={true}
                      onGridReady={this.onGridReady}
                      enableBrowserTooltips={true}
                      frameworkComponents={{
                        editColumn: EditRow,
                        statusColumn: StatusRow,
                        statusChangeColumn: StatusChangeRow,
                      }}
                      // gridOptions={{
                      //   rowHeight: 60,
                      //   rowStyle: { 'border-top': '#FFFFFF 8px solid' }
                      // }}
                      suppressDragLeaveHidesColumns={true}
                    >
                      <AgGridColumn
                        field="ProjectName"
                        sortable={true}
                        filter={true}
                        tooltipField="ProjectName"
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="NameOfPlant"
                        sortable={true}
                        filter={true}
                        tooltipField="NameOfPlant"
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="ParentProject"
                        sortable={true}
                        filter={true}
                        tooltipField="ParentProject"
                        suppressMovable={true}
                      />
                      {/* <AgGridColumn
                        field="Default"
                        cellRenderer="statusColumn"
                        suppressMovable={true}
                      /> */}
                      <AgGridColumn
                        field="Status"
                        minWidth={175}
                        cellRenderer="statusChangeColumn"
                        cellRendererParams={{
                          statusOptions: this.state.statusOptions,
                        }}
                        suppressMovable={true}
                      />
                      {/* <AgGridColumn
                        field="Status"
                        sortable={true}
                        filter={true}
                        tooltipField="Status"
                        suppressMovable={true}
                      /> */}
                      <AgGridColumn
                        field="StartDate"
                        sortable={true}
                        filter={true}
                        tooltipField="StartDate"
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="EndDate"
                        sortable={true}
                        filter={true}
                        tooltipField="EndDate"
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="Action"
                        suppressMovable={true}
                        // minWidth={175}
                        cellRenderer="editColumn"
                        cellRendererParams={{
                          reFreshTable: this.state.dataTableKey,
                        }}
                      />
                    </AgGridReact>
                    <div className="col-md-12 mt-2">
                      <Pagination
                        page={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        handlePageChange={handlePageClick}
                        totalPage={pagination.totalPages}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default ListProject;
