import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';

export default function DistrictWiseDistribution(props: any) {

    const [selectedRow, setselectedRow] = useState(null);

    useEffect(() => {
      if(!props.selectedDistrictWise) {
        setselectedRow(null);
      }
    }, [props.selectedDistrictWise])
    
    function onSelectRow(obj: any, index: any) {
        setselectedRow(index);
        props.onSelectDistrict(obj, index);
    }
    
    return (
        <div className='distribution-table'>
            <Row className='distribution-header m-0'>
                <Col xs={3} className="distribution-col">
                    <div className='distribution-header-cell'>District</div>
                </Col>
                <Col xs={3} className="distribution-col">
                    <div className='distribution-header-cell'>Total Dispatched Qty</div>
                </Col>
                <Col xs={3} className="distribution-col">
                    <div className='distribution-header-cell'>Total Received Qty</div>
                </Col>
                <Col xs={3} className="distribution-col">
                    <div className='distribution-header-cell'>Total Sapling Qty</div>
                </Col>
            </Row>
            <div className='distribution-body'>
                {props.districtList.map((obj: any, index: number) => (
                    <>
                        <Row key={index} className={`${selectedRow === index ? 'selected' : ''} distribution-row m-0`} onClick={() => onSelectRow(obj, index)}>
                            <Col xs={3} className="distribution-col">
                                <div className='distribution-body-cell'>{obj.name}</div>
                            </Col>
                            <Col xs={3} className="distribution-col">
                                <div className='distribution-body-cell'>{obj.deliveredQuantity}</div>
                            </Col>
                            <Col xs={3} className="distribution-col">
                                <div className='distribution-body-cell'>{obj.receivedQuantity}</div>
                            </Col>
                            <Col xs={3} className="distribution-col">
                                <div className='distribution-body-cell'>{obj.saplingQuantity}</div>
                            </Col>
                        </Row>
                        {selectedRow === index &&
                            <>
                                <div className='row m-0 justify-content-between'>
                                    <div className='state-data p-0'>Leader wise Distribution</div>
                                    <div className='clear-text c-pointer p-0' onClick={() => setselectedRow(null)}>Clear</div>
                                </div>
                                <div className='leader-container'>
                                    {obj.leaderWise?.length === 0 ?
                                        <div className='no-data'>No Data</div> :
                                        <>
                                            <Row className='leader-header'>
                                                <Col>
                                                    <div className='leader-header-cell'>Distribution Date</div>
                                                </Col>
                                                <Col>
                                                    <div className='leader-header-cell'>Received Date</div>
                                                </Col>
                                                <Col>
                                                    <div className='leader-header-cell'>Distributed Qty</div>
                                                </Col>
                                                <Col>
                                                    <div className='leader-header-cell'>Received Qty</div>
                                                </Col>
                                                <Col>
                                                    <div className='leader-header-cell'>Received By</div>
                                                </Col>
                                            </Row>
                                            <div className='leader-scroll'>
                                                {obj.leaderWise?.map((subObj: any, subIndex: any) => (
                                                    <Row className='leader-header' key={subIndex}>
                                                        <Col>
                                                            <div className='leader-header-cell'>{subObj.distributedDate}</div>
                                                        </Col>
                                                        <Col>
                                                            <div className='leader-header-cell'>{subObj.receivedDate}</div>
                                                        </Col>
                                                        <Col>
                                                            <div className='leader-header-cell'>{subObj.distributedQuantity}</div>
                                                        </Col>
                                                        <Col>
                                                            <div className='leader-header-cell'>{subObj.receivedQuantity}</div>
                                                        </Col>
                                                        <Col>
                                                            <div className='leader-header-cell'>{subObj.receivedBy}</div>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </div>
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </>
                ))}
            </div>
        </div>
    )
}
