import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { showToaster, toasterTypes } from "../../../../../widgets";
import Button from "../../../../../widgets/button";

type Props = {
  projects: any[];
  selectedElement: {
    isAll: boolean;
    projects: any[] | undefined;
  };
  modal: boolean;
  toggle: () => void;
  onSavedEvent: (payload: { projects: string[]; isAll: boolean }) => void;
};

const CheckboxTreeModal = (props: Props) => {
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [mode, setMode] = useState("true");

  useEffect(() => {
    if (props.selectedElement?.isAll) {
      setMode("true");
    } else {
      setMode("false");
    }

    if (props?.selectedElement?.projects) {
      setSelectedProjects(props.selectedElement?.projects || []);
    }
  }, [props?.selectedElement]);

  const handleRadioChange = (e: any) => {
    setMode(e.target.value);
  };

  const handleCheckBoxChange = (e: any) => {
    const existingValues: string[] = [...selectedProjects];
    if (e.target.checked) {
      if (!existingValues.includes(e.target.value)) {
        existingValues.push(e.target.value);
      }
    } else {
      const index = existingValues.findIndex((x) => x === e.target.value);
      if (index > -1) {
        existingValues.splice(index, 1);
      }
    }
    setSelectedProjects(existingValues);
  };

  const onsubmit = () => {
    /** Validate */
    if (mode === "false" && selectedProjects.length === 0) {
      showToaster(
        toasterTypes.ERROR,
        "Atleast one Project needs to be selected"
      );
      return;
    }

    const payload = {
      projects: selectedProjects,
      isAll: mode === "true",
    };
    props.onSavedEvent(payload);
    props.toggle();
  };

  const closeBtn = (
    <button className="close" title="Close" onClick={props.toggle}>
      &times;
    </button>
  );

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader className="modal_Bg" toggle={props.toggle} close={closeBtn}>
        <div className="modal_title">Select Projects</div>
      </ModalHeader>
      <ModalBody style={{ maxHeight: "450px", overflowY: "auto" }}>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            checked={mode === "true"}
            onChange={handleRadioChange}
            id="inlineRadio1"
            value="true"
          />
          <label htmlFor="inlineRadio1" className="form-check-label">
            All
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            checked={mode === "false"}
            name="inlineRadioOptions"
            onChange={handleRadioChange}
            id="inlineRadio2"
            value="false"
          />
          <label htmlFor="inlineRadio2" className="form-check-label">
            Choose Projects
          </label>
        </div>

        {mode === "false" && (
          <div className="row mt-4">
            {props.projects.map((proj: any, headIndex) => {
              return (
                <div key={`head-${headIndex}`}>
                  {proj.options.length > 0 && (
                    <div>
                      <label className="form-label">{proj.label}</label>
                    </div>
                  )}

                  {proj.options.map((x: any, index: number) => {
                    return (
                      <div
                        key={`dist-${headIndex}-${index}`}
                        style={{ paddingLeft: "10px" }}
                        className="col-lg-12 col-sm-12"
                      >
                        <div className="form-group  mb-2">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            value={x.value}
                            checked={selectedProjects.includes(x.value)}
                            onChange={handleCheckBoxChange}
                            id={`checkbox-${headIndex}-${index}`}
                            onClick={(
                              event: React.FormEvent<HTMLInputElement>
                            ) => {}}
                          />
                          <label
                            htmlFor={`checkbox-${headIndex}-${index}`}
                            className="form-check-label px-3"
                          >
                            {x.label}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}

        {mode === "true" && (
          <div className="row mt-4">
            {props.projects.map((proj: any, headIndex) => {
              return (
                <div key={`head-${headIndex}`}>
                  {proj.options.length > 0 && (
                    <div>
                      <label className="form-label">{proj.label}</label>
                    </div>
                  )}

                  {proj.options.map((x: any, index: number) => {
                    return (
                      <div
                        key={`dist-${headIndex}-${index}`}
                        style={{ paddingLeft: "10px" }}
                        className="col-lg-12 col-sm-12"
                      >
                        <div className="form-group  mb-2">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            value={x.value}
                            checked={true}
                            disabled={true}
                            onChange={handleCheckBoxChange}
                            id={`checkbox-${headIndex}-${index}`}
                          />
                          <label
                            htmlFor={`checkbox-${headIndex}-${index}`}
                            className="form-check-label px-3"
                          >
                            {x.label}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
        
      </ModalBody>
      <ModalFooter>
        <Button
          label="Cancel"
          className="ml-2 btn btn-outline-secondary"
          onClick={props.toggle}
        />
        <Button
          label="Save"
          className="ml-2 btn btn-theme-primary"
          onClick={onsubmit}
        />
      </ModalFooter>
    </Modal>
  );
};

export default CheckboxTreeModal;
