import React, { Component } from "react";
// import { loginProps, loginState } from "./interface";
import Button from "../../widgets/button";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import { Redirect, Link } from "react-router-dom";
import { showToaster, toasterTypes } from "../../widgets";
import { authenticationService } from "../../service";
import { changePasswordRequestModal } from "../../model";
import { forgetPasswordState, forgetPasswordProps } from "./interface";
import { Validation } from "../../utils";

class ForgetPassword extends Component<
  forgetPasswordProps,
  forgetPasswordState
> {
  constructor(props: forgetPasswordProps) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      conformPassword: "",
      toLogin: false,
      userName: "",
      toChangePasswordPage: false,
    };
  }

  handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    const state: any = { ...this.state };
    state[name] = value;
    this.setState(state);
  };

  onSubmit = () => {
    try {
      if (this.state.oldPassword.length <= 0) {
        showToaster(
          toasterTypes.ERROR,
          "Please enter the password send in email"
        );
        return true;
      }
      if (this.state.newPassword.length <= 0) {
        showToaster(toasterTypes.ERROR, "Please enter the new password");
        return true;
      }
      if (this.state.conformPassword.length <= 0) {
        showToaster(toasterTypes.ERROR, "Please enter the confirm password");
        return true;
      }
      if (this.state.newPassword !== this.state.conformPassword) {
        showToaster(toasterTypes.ERROR, "Password mismatch. Please try again.");
        return true;
      }
      const { oldPassword, newPassword, conformPassword } = this.state;
      const requestData: changePasswordRequestModal = {
        current_password: oldPassword,
        new_password: newPassword,
        retry_password: conformPassword,
      };
      // TODO Validation
      authenticationService.changePasswordRequest(requestData).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            showToaster(toasterTypes.SUCCESS, data.message);
            this.setState({
              toLogin: true,
            });
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, "You have entered an invalid credentials. Please try again.");
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };
  forgetPassword = () => {
    try {
      if (Validation.isRequired(this.state.userName, "email") || Validation.isValidEmail(this.state.userName)) {
        return false;
      }
      const { userName } = this.state;
      const requestData = {
        login_id: userName,
      };
      // TODO Validation
      authenticationService.forgetPasswordRequest(requestData).subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            this.setState({ toLogin: true });
            showToaster(toasterTypes.SUCCESS, response.message);
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };
  render() {
    const {
      handleOnChange,
      onSubmit,
      forgetPassword,
      state: {
        toChangePasswordPage,
        userName,
        oldPassword,
        newPassword,
        conformPassword,
        toLogin,
      },
    } = this;
    if (toLogin) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <section id="Login">
          <div className="Container">
            <div className="Login_card">
              <div className="card">
                <div className="text-center Company_Logo mb-3">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/img/Vruksha_logo.png`}
                    alt="logo"
                  />
                </div>

                {!toChangePasswordPage ? (
                  <>
                    <h5 className="mb-4">Forgot Password</h5>

                    <div className="email-login">
                      <form>
                        <FieldItem
                          onChange={handleOnChange}
                          value={userName}
                          name="userName"
                          placeholder="Email ID"
                          type={FIELD_TYPES.TEXT}
                          className="form-control"
                        />
                      </form>
                    </div>
                    <span>
                      <Button
                        type="button"
                        onClick={forgetPassword}
                        className="btn cta-btn"
                        label="Submit"
                      />
                    </span>
                    <Link to="/login" className="cursor-pointer">
                      <span className='d-flex justify-content-center'><i className='fa fa-arrow-left d-flex align-items-center'></i> <span className="pl-3pg">back to login</span> </span>
                    </Link>
                  </>
                ) : (
                  <>
                    <div className="email-login">
                      <form>
                        <FieldItem
                          onChange={handleOnChange}
                          value={oldPassword}
                          name="oldPassword"
                          placeholder="Password send in Email"
                          type={FIELD_TYPES.TEXT}
                          className="form-control"
                        />
                        <FieldItem
                          onChange={handleOnChange}
                          value={newPassword}
                          name="newPassword"
                          placeholder="New Password"
                          type={FIELD_TYPES.PASSWORD}
                          className="form-control"
                        />
                        <FieldItem
                          onChange={handleOnChange}
                          value={conformPassword}
                          name="conformPassword"
                          placeholder="Confirm Password"
                          type={FIELD_TYPES.PASSWORD}
                          className="form-control"
                        />
                      </form>
                    </div>
                    <span>
                      <Button
                        type="button"
                        onClick={onSubmit}
                        className="btn cta-btn"
                        label="Submit"
                      />
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ForgetPassword;
