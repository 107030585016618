import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import Button from "../button";

interface Props {
  isOpen: boolean;
  message?: React.ReactNode;
  confirmedEvent?: () => any;
  toggle?: () => any;
}

const ConfirmationPopup: React.FunctionComponent<Props> = (props: Props) => {
  const closeBtn = (
    <button className="close" title="Close" onClick={props.toggle}>
      &times;
    </button>
  );
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <ModalHeader className="modal_Bg" close={closeBtn} toggle={props.toggle}>
        <div className="modal_title"> Confirmation Alert</div>
      </ModalHeader>
      <ModalBody>{props.message}</ModalBody>
      <ModalFooter>
        <Button
          label="No"
          className="ml-2 btn btn-outline-secondary"
          onClick={props.toggle}
        />
        <Button
          label="Yes"
          className="ml-2 btn btn-theme-primary"
          onClick={props.confirmedEvent}
        />
      </ModalFooter>
    </Modal>
  );
};

ConfirmationPopup.defaultProps = {
  isOpen: true,
  message: "",
  confirmedEvent: () => {},
  toggle: () => {},
};

export default ConfirmationPopup;
