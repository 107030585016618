import React, { useState, useEffect } from "react";
// import { FieldItem, FIELD_TYPES } from "../../../../widgets/fields";

import { authenticationService } from "../../../service";
import { Validation } from "../../../utils";
import { showToaster, toasterTypes } from "../../../widgets";
import { FieldItem, FIELD_TYPES } from "../../../widgets/fields";

interface Props {
  data: any;
  context: any;
  statusOptions: { value: string | number; label: string | number }[];
  isReadOnly: boolean;
  obj: any;
}

const StatusChangeRow: React.FunctionComponent<Props> = (props) => {
  const [status, setStatus] = useState<{
    value: string | number;
    label: string | number;
  }>();
  const statusId = props.data.statusId;
  const statusOptions = props.statusOptions;
  useEffect(() => {
    try {
      const selectedData = statusOptions?.filter(
        (obj) => obj.value === statusId
      );
      if (Array.isArray(selectedData) && selectedData.length > 0) {
        setStatus({
          label: Validation.capitalizeFirstLetter(
            selectedData[0]?.label as any
          ),
          value: selectedData[0]?.value,
        });
      }
    } catch (error) {
      console.log(error?.message);
    }
  }, [statusId, statusOptions]);

  const handleChange = (data: any) => {
    try {
      setStatus(data);
      changeStatus(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const changeStatus = (data: any) => {
    try {
      const UserData: any = {
        id: props.data.id,
        status: data?.value,
      };
      authenticationService.changeStatus(UserData).subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            props.context.componentParent.getAllRowData();
            showToaster(toasterTypes.SUCCESS, response.message);
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };
  return (
    <FieldItem
      onChange={handleChange}
      options={props.statusOptions}
      name="status"
      value={status}
      placeholder="Select Status"
      type={FIELD_TYPES.DROP_DOWN}
      menuPortalTarget={document.querySelector("body")}
    />
  );
};

StatusChangeRow.defaultProps = {};

export default StatusChangeRow;
