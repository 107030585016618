import React, { Component } from "react";
import {
  PincodeLevellDistributionProps,
  PincodeLevellDistributionState,
} from "./interface";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { saplingService, distributionService } from "../../../service";
import { showToaster, toasterTypes } from "../../../widgets";
import { UserHelper } from "../../../utils";
import { DistributionRow } from "./partials/distributionRow";
import { NumericEditor } from "../../../utils/validation/numeric-cell-editor-validation";
import { Row, Col } from "reactstrap";
import Button from "../../../widgets/button";
import { Link } from "react-router-dom";

class PincodeLevellDistribution extends Component<
  PincodeLevellDistributionProps,
  PincodeLevellDistributionState
> {
  static defaultProps = {
    projectName: "",
  };
  constructor(props: PincodeLevellDistributionProps) {
    super(props);
    this.state = {
      modal: false,
      tableData: [],
      distributionQuantity: "",
      remarks: "",
      distributionDate: "",
      gridApi: "",
      tableRecords: [],
      context: {
        componentParent: this,
      },
      rowEditById: 0,
      userData: {},
      pincodeOptions: [],
      selectedPincode: null,
      projectId: "",
      totalQuantity: [],
    };
  }
  componentDidMount() {
    const userDetails = UserHelper.getUserDetails();
    this.setState({
      userData: userDetails,
      projectId: this.props.projectId,
    });
    this.getAllSaplingList();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.projectId !== this.props.projectId) {
      this.setState(
        {
          projectId: this.props.projectId,
        },
        () => {
          this.getPincodeLevellDistribution();
          this.getAllSaplingList();
        }
      );
    }
  }

  getAllSaplingList = () => {
    saplingService.getAllSaplingData().subscribe(
      (response: any) => {
        try {
          let rowData: any = [];
          if (response.status && response.statusCode === 200) {
            if (response.data?.length > 0) {
              response.data.forEach((element: any) => {
                rowData.push({
                  id: element.id,
                  distributionQty: element.sapling_quantity,
                });
              });
              this.setState({ totalQuantity: rowData });
            }
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        } catch (error) {
          console.log(error.message);
        }
      },
      (error: any) => {
        showToaster(toasterTypes.ERROR, error.message);
        this.setState({ totalQuantity: [] });
      }
    );
  };

  getPincodeLevellDistribution = () => {
    try {
      distributionService.getAllPincodeDistributionplan().subscribe(
        (response: any) => {
          try {
            let rowData: any = [];
            if (response.status && response.statusCode === 200) {
              if (
                Array.isArray(response.content) &&
                response.content?.length > 0
              ) {
                rowData = response.content.map((element: any) => ({
                  id: element.id,
                  PincodeId: element.pincode_id,
                  Pincode: element.pincode_name,
                  PlannedQuantity: element.plan,
                  TotalDistributed: element.total_distributed,
                  TotalPlanted: element.total_planted,
                  TotalDistributedCount: element.total_distributed_count,
                }));
                this.setState({ tableRecords: rowData });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
            this.setState({ tableRecords: rowData });
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  onGridReady = (params: any) => {
    this.getPincodeLevellDistribution();
    this.setState({
      gridApi: params.api,
    });
  };

  save = () => {
    this.state.gridApi.stopEditing();
    const requestData: any = [];
    let sumOfPlanned = this.state.tableRecords.reduce(function (prev, current) {
      return prev + +current["PlannedQuantity"];
    }, 0);
    let totalQty = this.state.totalQuantity?.reduce(
      (a: any, b: any) => +a + +b["distributionQty"],
      0
    );
    if (totalQty < sumOfPlanned) {
      showToaster(
        toasterTypes.ERROR,
        "Total Planned Quantity cannot exceed total quantity"
      );
      return false;
    }
    this.state.tableRecords.forEach((element: any) => {
      if (element["PlannedQuantity"] !== null) {
        requestData.push({
          id: element["id"],
          pincode_id: element["PincodeId"],
          pincode_name: element["Pincode"],
          plan: element["PlannedQuantity"],
        });
      }
    });

    distributionService.savePincodePlan(requestData).subscribe(
      (data: any) => {
        if (data.status && data.statusCode === 200) {
          this.getPincodeLevellDistribution();
          this.getAllSaplingList();
          showToaster(toasterTypes.SUCCESS, data.message);
        } else {
          showToaster(toasterTypes.ERROR, data.message);
        }
      },
      (error: any) => {
        console.log(error);
        showToaster(toasterTypes.ERROR, error.message);
      }
    );
  };

  cellStyle = (params: any) => {
    return { textAlign: "right" };
  };
  render() {
    return (
      <section id="ViewProject" className="ViewProject">
        <div className="container" data-aos="fade-up">
          <div className="row no-gutters">
            <div className="col-lg-12 d-flex flex-column justify-content-center ">
              <div className="col-lg-12 d-flex flex-column justify-content-center ">
                <Row>
                  <Col md={10}>
                    <h3 className="heading-secondary p-0 m-0 mb-1">
                      Pin Code Level Distribution
                    </h3>
                  </Col>
                  <Col md={2}>
                    <div className="mr-1 card p-1 text-center">
                      <b>
                        {" "}
                        {this.state.totalQuantity?.reduce(
                          (a: any, b: any) => +a + +b["distributionQty"],
                          0
                        )}
                      </b>
                      <small>Total Quantity</small>
                    </div>
                  </Col>
                </Row>
                <h4 className="heading-tertiary">
                  {this.props.projectName} for{" "}
                  {this.state.userData.district_name},
                  {this.state.userData.state_name}.{" "}
                </h4>
                <Link to="/sapling">
                  <Button
                    className="btn-sm  btn btn-theme-primary w-12"
                    label={"Sapling"}
                  />
                </Link>
                <div className="section-title">
                  <div className="d-flex align-items-center justify-content-end mb-2 ">
                    {/* <h3 className="secondary-heading-normal m-0 table-header-label">
                      Total Quantity :{" "}
                      {this.state.totalQuantity?.reduce(
                        (a: any, b: any) => +a + +b["distributionQty"],
                        0
                      )}
                    </h3> */}
                  </div>
                  <div className="grid">
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        domLayout="autoHeight"
                        singleClickEdit={true}
                        context={this.state.context}
                        rowData={this.state.tableRecords}
                        onGridReady={this.onGridReady}
                        frameworkComponents={{
                          DistributedColumn: DistributionRow,
                          numericEditor: NumericEditor,
                        }}
                        enableBrowserTooltips={true}
                        columnTypes={{
                          valueColumn: {
                            valueParser: "Number(newValue)",
                          },
                        }}
                        suppressDragLeaveHidesColumns={true}
                      >
                        <AgGridColumn
                          field="Pincode"
                          headerName="Pin Code"
                          sortable={true}
                          filter={true}
                          tooltipField="Pin Code"
                          suppressMovable={true}
                        />
                        <AgGridColumn
                          field="PlannedQuantity"
                          sortable={true}
                          filter={true}
                          editable={true}
                          cellStyle={this.cellStyle}
                          tooltipField="PlannedQuantity"
                          type="rightAligned"
                          cellEditor="numericEditor"
                          suppressMovable={true}
                        />
                        <AgGridColumn
                          field="Distributions"
                          headerName="Your distributions"
                          cellRenderer="DistributedColumn"
                          cellRendererParams={{
                            projectId: this.props.projectId,
                            totalDistributed: this.state.tableRecords?.reduce(
                              (a: any, b: any) => +a + +b["TotalDistributed"],
                              0
                            ),
                            totalQuantity: this.state.totalQuantity?.reduce(
                              (a: any, b: any) => +a + +b["distributionQty"],
                              0
                            ),
                          }}
                          suppressMovable={true}
                          cellStyle={{ color: "blue" }}
                        />
                        <AgGridColumn
                          field="TotalDistributed"
                          headerName="Total distributed by you"
                          sortable={true}
                          filter={true}
                          type="rightAligned"
                          tooltipField="TotalDistributed"
                          suppressMovable={true}
                        />

                        <AgGridColumn
                          field="TotalPlanted"
                          headerName="Total planted"
                          sortable={true}
                          filter={true}
                          type="rightAligned"
                          tooltipField="TotalPlanted"
                          suppressMovable={true}
                        />
                      </AgGridReact>
                    </div>
                  </div>
                  <div className="text-end p-2">
                    <button
                      onClick={this.save}
                      className="btn Btn_small btn-theme-primary"
                      title="Save"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PincodeLevellDistribution;
