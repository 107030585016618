import React, { Component } from "react";
import { AddDistributionPopup } from "../../components/AddDistributionPopup";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "../../../../widgets/button";

interface editRowProps {
  context: any;
  data: any;
  totalQuantity: string;
  totalDistributed: string;
  userOptions: any;
  districtId?: any;
}
interface editRowStateProps {
  modal: boolean;
  usersOptions: any;
  deleteModal: boolean;
}
export class ActionRow extends Component<editRowProps, editRowStateProps> {
  childRef: any;
  constructor(props: editRowProps) {
    super(props);
    this.state = {
      modal: false,
      usersOptions: [],
      deleteModal: false,
    };
    this.childRef = React.createRef();
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });

    if (!this.state.modal) {
      this.childRef.current.getDistributionDetail();
    }
  };

  changeStatus = () => {
    this.props.context.componentParent.changeStatusHandler(this.props.data);
    this.deleteToggle();
  };
  refresh = () => {
    this.props.context.componentParent.getAllUserData();
    this.props.context.componentParent.getDistributionCount();
    this.props.context.componentParent.getDistributionList();
  };
  deleteToggle = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };
  closeBtn = (
    <button className="close" title="Close" onClick={this.deleteToggle}>
      &times;
    </button>
  );

  render() {
    return (
      <>
        <AddDistributionPopup
          totalQuantity={{
            totalDistributed: this.props.totalDistributed,
            totalDispatched: this.props.totalQuantity,
            currentDistributed: this.props.data.DistributionQuantity,
          }}
          districtId={this.props.districtId}
          usersOptions={this.props.userOptions}
          modal={this.state.modal}
          toggle={this.toggle}
          isEdit={true}
          ref={this.childRef}
          refresh={this.refresh}
          distributionId={this.props.data.id}
          pincodeId={this.props.data.PincodeId}
        />

        <button
          className="Action_btn"
          onClick={() => this.toggle()}
          title="Edit"
        >
          <i className="bi bi-pencil-square"></i>
        </button>
        <button
          onClick={() => this.deleteToggle()}
          title="Delete"
          className="Action_btn"
          style={{ marginLeft: "16px" }}
        >
          <i className="bi bi-trash"></i>
        </button>
        <Modal isOpen={this.state.deleteModal} toggle={this.deleteToggle}>
          <ModalHeader
            className="modal_Bg"
            close={this.closeBtn}
            toggle={this.deleteToggle}
          >
            <div className="modal_title"> Confirmation Alert</div>
          </ModalHeader>
          <ModalBody>Are you sure to Delete?</ModalBody>
          <ModalFooter>
            <Button
              label="No"
              className="ml-2 btn btn-outline-secondary"
              onClick={this.deleteToggle}
            />
            <Button
              label="Yes"
              className="ml-2 btn btn-theme-primary"
              onClick={this.changeStatus}
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
