import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

interface confirmationProps {
  toggle: () => void;
  removeImages: () => void;
  isOpen: boolean;
}
interface confirmationStateProps {}

export default class Confirmation extends Component<
  confirmationProps,
  confirmationStateProps
> {
  closeBtn = (
    <button className="close" title="Close" onClick={this.props.toggle}>
      &times;
    </button>
  );
  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader
          className="modal_Bg"
          toggle={this.props.toggle}
          close={this.closeBtn}
        >
          <div className="modal_title"> Confirmation Alert</div>
        </ModalHeader>
        <ModalBody>Are you sure want to Delete?</ModalBody>
        <ModalFooter>
          <Button
            className="ml-2 btn btn-outline-secondary"
            onClick={this.props.toggle}
          >
            No
          </Button>
          <Button
            className="ml-2 btn btn-theme-primary"
            onClick={() => this.props.removeImages()}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
