import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "../../../../widgets/button";

interface editRowProps {
  context: any;
  data: any;
}
interface editRowStateProps {
  rowEditById: number;
  historyId:number;
  modal: boolean;
}
export class EditRow extends Component<editRowProps, editRowStateProps> {
  constructor(props: editRowProps) {
    super(props);
    this.state = {
      rowEditById: 0,
      historyId:0,
      modal: false,
    };
  }

  changeStatus = () => {
    this.props.context.componentParent.changeStatusHandler(this.props.data);
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  closeBtn = (
    <button className="close" title="Close" onClick={this.toggle}>
      &times;
    </button>
  );

  render() {
    if (this.state.rowEditById !== 0) {
      return (
        <Redirect
          to={{
            pathname: `/createPlant/${this.state.rowEditById}`,
            state: this.props.data.editData,
          }}
        />
      );

      // return <Redirect to={`/createPlant/${this.state.rowEditById}`} />
    }

    if (this.state.historyId !== 0) {
      return (
        <Redirect
          to={{
            pathname: `/historyPlants/${this.state.historyId}`,
            
          }}
        />
      );
        }
    return (
      <>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader className="modal_Bg" toggle={this.toggle} close={this.closeBtn}>
            <div className="modal_title">Confirmation Alert</div>
          </ModalHeader>
          <ModalBody>Are you sure to Delete?</ModalBody>
          <ModalFooter>
            <Button
              label="No"
              className="ml-2 btn btn-outline-secondary"
              onClick={this.toggle}
            />
            <Button
              label="Yes"
              className="ml-2 btn btn-theme-primary"
              onClick={this.changeStatus}
            />
          </ModalFooter>
        </Modal>
        <button
          className="Action_btn"
          title = "Edit"
          onClick={() => this.setState({ rowEditById: this.props.data.id })}
        >
          <i className="bi bi-pencil-square"></i>
        </button>
        <button
          className="Action_btn"
          title = "Delete"
          style={{ marginLeft: "16px" }}
          onClick={this.toggle}
        >
          <i className="bi bi-trash"></i>
        </button>
        <button
          className="Action_btn"
          title = "History"
          style={{ marginLeft: "16px" }}
          onClick={() => this.setState({ historyId: this.props.data.id })}
        >
          <i className="bi bi-clock"></i>
        </button>
      </>
    );
  }
}
