import React from "react";
import { Link } from "react-router-dom";

interface footerProps {
  mobileNumber: string;
  email: string;
  links?: navLink[];
}

interface navLink {
  name: string;
  link: string;
}
interface footerProps {
  mobileNumber: string;
  email: string;
  about_art_of_living?: string;
  about_founder?: string;
  twitterAccountLink?: string;
  facebookAccountLink?: string;
  linkedInAccountLink?: string;
  instagramAccountLink?: string;
  toggle?: () => void;
}

const defaultprops: footerProps = {
  mobileNumber: "",
  email: "",
  links: [
    { name: "Terms & Conditions", link: "" },
    { name: "References", link: "" },
    { name: "Contact Us", link: "" },
  ],
};
const Footer: React.FunctionComponent<footerProps> = (props) => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 px-4 footer-info">
              <h4>About Us</h4>
              <img
                src={`${process.env.PUBLIC_URL}/assets/img/Vruksha_logo_white.png`}
                alt="logo"
              />
              <p className="mt-3">
                Operating In 156 Countries, The Art Of Living Is A Non-Profit,
                Educational And Humanitarian Organization Founded In 1981
                {/* {props.about_art_of_living} */}
                {/* Operating in 156 countries, The Art of Living is a non-profit, educational and humanitarian organization founded in 1981 by the world-renowned humanitarian and spiritual teacher - Gurudev Sri Sri Ravi Shankar. All our programs are guided by Gurudev’s philosophy: “Unless we have a stress-free mind and a violence-free society, we cannot achieve world peace.” */}
              </p>
            </div>

            <div className="col-lg-6 col-md-6 px-4 footer-links">
              <h4>About Founder</h4>
              <p className="mt-3">
                {props.about_founder}
                {/* Gurudev Sri Sri Ravi Shankar has united people of different races, traditions, economic and social status, and nationalities. This community, spanning 156 countries, has created a one-world spiritual family. Gurudev’s message is simple: “Love and wisdom can prevail over hatred and violence.” This message is not just a slogan, but through The Art of Living has been and continues to be translated into action and results. */}
              </p>
            </div>

            <div className="col-lg-3 col-md-6 px-4 footer-links">
              {props.links?.map((link: navLink, index: number) => (
                <h4 key={index}>{link.name}</h4>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          <div className="container d-flex justify-content-center justify-content-md-between">
            <div className="contact-info d-flex align-items-center">
              Contact : {props.mobileNumber}, {props.email}
            </div>
            <div className="social-links d-none d-md-block mt-2">
              &copy; Copyright{" "}
              <strong>
                <span>Vruksha</span>
              </strong>
              . All Rights Reserved
            </div>
            <section id="topbar" className="d-flex align-items-center">
              <div className="social-links d-none d-md-block mt-half">
                <Link
                  target={"_blank"}
                  to={props.twitterAccountLink || "/"}
                  className="twitter"
                >
                  <i className="bi bi-twitter"></i>
                </Link>
                <Link
                  to={props.facebookAccountLink || "/"}
                  className="facebook"
                >
                  <i className="bi bi-facebook"></i>
                </Link>
                <Link
                  to={props.instagramAccountLink || "/"}
                  className="instagram"
                >
                  <i className="bi bi-instagram"></i>
                </Link>
                <Link
                  to={props.linkedInAccountLink || "/"}
                  className="linkedin"
                >
                  <i className="bi bi-linkedin"></i>
                </Link>
              </div>
            </section>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.defaultProps = defaultprops;

export { Footer };
