import React, { Component } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import { EditRow } from "./partials/EditRow";
import { showToaster, toasterTypes } from "../../../widgets";
import { plantService } from "../../../service";
import { listPlantsProps, listPlantsState } from "./interface";
import moment from "moment";
import Pagination from "../../../widgets/pagination";
import Tablefeature from "../../../widgets/tableFeature";
import Button from "../../../widgets/button";

class ListPlants extends Component<listPlantsProps, listPlantsState> {
  constructor(props: listPlantsProps) {
    super(props);
    this.state = {
      tableRecords: [],
      gridApi: "",
      context: {
        componentParent: this,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
    };
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.projectId !== this.props.projectId) {
      this.getAllPlantList();
    }
  }

  handlePageClick = (
    data: { selected: number },
    pageSize: number = this.state.pagination.pageSize
  ) => {
    this.getAllPlantList(data.selected, pageSize);
  };

  handleSearch = (e: any) => {
    this.getAllPlantList(1, 10, e.target.value);
  };

  getAllPlantList = (
    page: number = 1,
    pageSize: number = 10,
    key: string = ""
  ) => {
    try {
      plantService.getAllPlantList(0, 0, 0, page, pageSize, key).subscribe(
        (response: any) => {
          try {
            let rowData = [];
            if (response.status && response.statusCode === 200) {
              if (response.content && Array.isArray(response.content)) {
                rowData = response.content.map((element: any) => ({
                  id: element.id,
                  Pincode: element.pin_code,
                  pincode_data: element.pincode_data,
                  PlantedDate: moment(element.date_of_planting).format(
                    "DD-MM-YYYY"
                  ),
                  PlantStatus: element.plant_status,
                  totatPlantCount: element.total_plants_count,
                  totalDeadCount: element.dead_plants_count,
                  livePlantCount: element.live_plants_count,
                  Remark: element.remarks,
                  editData: element,
                }));
                this.state.gridApi.setRowData(rowData);
                this.setState({
                  pagination: {
                    pageNumber: response.page || 1,
                    pageSize: response.size || 10,
                    totalPages: response.totalPages || 1,
                    totalElements: response.totalElements || 10,
                  },
                });
              }
            } else {
              this.state.gridApi.setRowData([]);
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  onGridReady = (params: any) => {
    this.getAllPlantList();
    this.setState({
      gridApi: params.api,
    });
  };

  changeStatusHandler = (data: any) => {
    try {
      const requestData = {
        id: data.id,
        status: "deleted",
      };
      plantService.plantChangeStatus(requestData).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            this.getAllPlantList();
            showToaster(toasterTypes.SUCCESS, data.message);
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };
  render() {
    const {
      state: { pagination },
      handlePageClick,
    } = this;
    return (
      <main id="main">
        <section id="ViewProject" className="ViewProject">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters">
              <div className="col-lg-12 d-flex flex-column justify-content-center">
                <div className="d-flex align-items-center justify-content-start mb-4 ">
                  <h3 className="secondary-heading-normal m-0 table-header-label">
                    View Plant
                  </h3>
                  <Link to="/createPlant">
                    <Button
                      label="Create"
                      className="ml-2 btn btn-outline-secondary"
                    />
                  </Link>
                </div>
                <Tablefeature
                  totalElements={pagination.totalElements}
                  handleSearch={this.handleSearch}
                />
                <div className="grid">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      domLayout="autoHeight"
                      // rowData={rowData}
                      pagination={false}
                      context={this.state.context}
                      onGridReady={this.onGridReady}
                      rowData={this.state.tableRecords}
                      frameworkComponents={{
                        editColumn: EditRow,
                      }}
                      suppressDragLeaveHidesColumns={true}
                    >
                      <AgGridColumn
                        field="Pincode"
                        headerName="Pin Code"
                        sortable={true}
                        filter={true}
                        flex={1}
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="PlantedDate"
                        sortable={true}
                        filter={true}
                        flex={1}
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="totatPlantCount"
                        sortable={true}
                        filter={true}
                        flex={1}
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="livePlantCount"
                        sortable={true}
                        filter={true}
                        flex={1}
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="totalDeadCount"
                        sortable={true}
                        filter={true}
                        flex={1}
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="Action"
                        minWidth={100}
                        cellRenderer="editColumn"
                        flex={1}
                        suppressMovable={true}
                      />
                    </AgGridReact>
                    <div className="col-md-12 mt-2">
                      <Pagination
                        page={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        handlePageChange={handlePageClick}
                        totalPage={pagination.totalPages}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default ListPlants;
