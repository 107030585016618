import React, { Component } from "react";

interface createProjectProps {
  // google:any
}
interface createProjectState {}
export class MainMap extends Component<createProjectProps, createProjectState> {
  componentDidMount() {
    this.initMap();
  }
  initMap = (): void => {
    const originalMapCenter = new google.maps.LatLng(-25.363882, 131.044922);
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 4,
        center: originalMapCenter,
      }
    );

    var infowindow = new google.maps.InfoWindow({
      content: "Change the zoom level",
      position: originalMapCenter,
    });
    infowindow.open(map);

    map.addListener("click", (mapsMouseEvent) => {
      // Close the current InfoWindow.
      infowindow.close();
      // Create a new InfoWindow.
      infowindow = new google.maps.InfoWindow({
        position: mapsMouseEvent.latLng,
      });
      infowindow.setContent(
        JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)
      );
      infowindow.open(map);
    });

    map.addListener("zoom_changed", () => {
      infowindow.setContent("Zoom: " + map.getZoom()!);
    });
  };

  render() {
    return <div id="map" style={{ height: 500, width: 500 }}></div>;
  }
}
