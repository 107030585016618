import React, { Component } from "react";
import { FieldItem, FIELD_TYPES } from "../../../widgets/fields";
import Button from "../../../widgets/button";
import { showToaster, toasterTypes } from "../../../widgets";
import { projectService, badgeservice } from "../../../service";
import { UncontrolledTooltip } from "reactstrap";
import { createBadgeProps, createBadgeState } from "./interface";
import { Link } from "react-router-dom";
import { constants } from "../../../constants";
import { Validation } from "../../../utils";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Row, Col } from "reactstrap";

const acceptedExtensions = [".jpeg", ".png", ".jpg"];
class CreateBadge extends Component<createBadgeProps, createBadgeState> {
  imageFileRef: React.RefObject<any>;
  constructor(props: createBadgeProps) {
    super(props);
    this.imageFileRef = React.createRef();
    this.state = {
      badgeName: "",
      achievementCount: "",
      badgeDetails: "",
      achievementType: "",
      selectedFile: [],
      images: [],
      imageRequestData: [],
      isEdit: false,
      achiverTyeOption: [
        { value: "Tree planting", label: "Tree planting" },
        // { value: "Sapling growing", label: "Sapling growing" },
      ],
      deleteImageModal: false,
    };
  }
  componentDidMount() {
    if (this.props.match.params.edit && this.props.match.params.edit > 0) {
      this.setState({ isEdit: true });
      this.getBadgeData();
    }
  }

  getBadgeData = () => {
    try {
      const responseData: any = this.props.location.state;
      const selectedData = this.state.achiverTyeOption?.filter(
        (data: any) => data?.value === responseData.AchievementType
      );
      let badgeData: any = {
        badgeName: responseData.BadgeName,
        achievementCount: responseData.achievementCount,
        badgeDetails: responseData.BadgeDetails,
        achievementType:
          Array.isArray(selectedData) && selectedData.length > 0
            ? selectedData[0]
            : {},
        selectedFile: [
          {
            file: responseData.image_id,
            isUploaded: true,
            url: "",
          },
        ],
      };
      this.setState({ ...badgeData });
    } catch (error) {
      console.log(error?.message);
    }
  };

  handleOnChange = (event: any) => {
    try {
      // handle file change
      if (event.currentTarget.name === "selectedFile") {
        try {
          const files = [];
          // for (let i = 0; i < event.target.files.length; i++) {
          const file = event.target.files[0];

          let isValidFile = false;
          if (!file) {
            // if its not a file skip the iteration
            // continue;
            return;
          } else {
            acceptedExtensions.forEach((extension) => {
              if (file["name"].toLowerCase().includes(extension)) {
                isValidFile = true;
              }
            });
          }
          if (isValidFile) {
            if (file.size && file.size / 1024 / 1024 > 10) {
              showToaster(
                toasterTypes.ERROR,
                "The File you are attempting to upload is larger than the permitted size of 10MB"
              );
              return true;
            }
          } else {
            showToaster(
              toasterTypes.ERROR,
              "File format .jpeg, .png, .jpg only supported"
            );
            return true;
          }
          files.push({
            file: file,
            isUploaded: false,
            url: URL.createObjectURL(file),
            name: file.name,
          });
          // }
          // }
          this.setState({ selectedFile: [...files] });
        } catch (error) {
          console.log(error?.message);
        }
      } else {
        let pattern = new RegExp(/^[0-9]*$/); // accept only numbers
        const { name, value } = event.currentTarget;
        if (
          name === "achievementCount" &&
          !pattern.test(event.currentTarget.value)
        ) {
          return false;
        }

        const state: any = { ...this.state };
        state[name] = value;
        this.setState(state);
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  onSubmit = () => {
    try {
      if (Validation.isTextRequired(this.state.badgeName, "badge name")) {
        return false;
      }
      if (
        Validation.isRequired(this.state.achievementCount, "achievement count")
      ) {
        return false;
      }
      if (
        Validation.isValidNumber(
          this.state.achievementCount,
          "achievement count"
        )
      ) {
        return false;
      }
      if (
        Validation.isRequiredForDropdown(
          this.state.achievementType,
          "achievement type"
        )
      ) {
        return false;
      }

      if (
        this.state.selectedFile.length <= 0 &&
        this.state.imageRequestData?.length <= 0
      ) {
        showToaster(toasterTypes.ERROR, "Please select the image");
        return false;
      }

      /**
       * if image is there upload images and save else directly save
       */
      if (this.state.selectedFile?.length > 0) {
        try {
          Promise.all(this.uploadImages()).then((uploadImagesResponse) => {
            if (
              uploadImagesResponse?.length ===
                this.state.selectedFile?.length ||
              this.state.isEdit
            ) {
              try {
                let status = true;
                const error: string[] = [];
                // validate all upload are success
                uploadImagesResponse.forEach((response) => {
                  if (!(response?.status && response.statusCode === 200)) {
                    status = false;
                    error.push(response?.message);
                  }
                });
                if (status) {
                  this.save(uploadImagesResponse);
                } else {
                  showToaster(toasterTypes.ERROR, error.join(", "));
                }
              } catch (error) {
                console.error(error?.message);
              }
            } else {
              showToaster(
                toasterTypes.ERROR,
                "Unable to upload images, please try again later"
              );
            }
          });
        } catch (error) {
          console.error(error?.message);
        }
      } else {
        this.save();
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  save = (uploadImagesResponse: any[] = []) => {
    try {
      const { badgeName, achievementCount, badgeDetails, achievementType } =
        this.state;

      const requestImageFormat = uploadImagesResponse?.map(
        (element: any, index: number) => ({
          image_id: element?.file_id,
        })
      );
      const requestData: any = {
        badge_name: badgeName,
        achievement_count: achievementCount,
        badge_details: badgeDetails,
        achievement_type: achievementType?.value,
        // image_id: requestImageFormat[0]?.image_id
      };

      // update images and project id
      if (this.props.match.params.edit >= 0) {
        requestData.id = this.props.match.params.edit;
        if (
          Array.isArray(this.state.selectedFile) &&
          this.state.selectedFile?.length > 0
        ) {
          this.state.selectedFile.forEach((file: any) => {
            if (file.isUploaded) {
              requestData.image_id = file.file;
            } else {
              if (
                Array.isArray(requestImageFormat) &&
                requestImageFormat?.length > 0
              ) {
                requestData.image_id = requestImageFormat[0].image_id;
              }
            }
          });
        }
      } else {
        if (
          Array.isArray(requestImageFormat) &&
          requestImageFormat?.length > 0
        ) {
          requestData.image_id = requestImageFormat[0].image_id;
        }
      }

      badgeservice.createBadgeRequest(requestData).subscribe(
        (data: any) => {
          if (data?.status && data?.statusCode === 200) {
            showToaster(toasterTypes.SUCCESS, data.message);
            this.props.history.push("/listBadge");
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  removeImages = (index: number) => {
    try {
      const selectedFile = [...this.state.selectedFile];
      selectedFile.splice(index, 1);
      this.setState({ selectedFile });
      this.imageFileRef.current.value = null;
    } catch (error) {
      console.log(error?.message);
    }
  };

  uploadImages = (): Promise<any>[] => {
    const uploadPromise: Promise<any>[] = [];
    try {
      if (
        Array.isArray(this.state.selectedFile) &&
        this.state.selectedFile?.length > 0
      ) {
        this.state.selectedFile?.forEach((file: any) => {
          if (!file.isUploaded) {
            const data: FormData = new FormData();
            data.append("file", file.file);
            const request = projectService
              .projectImageUploadRequest(data)
              .toPromise();
            uploadPromise.push(request);
          }
        });
      }
    } catch (error) {
      console.log(error?.message);
    }
    return uploadPromise;
  };

  deleteToggle = () => {
    this.setState({
      deleteImageModal: !this.state.deleteImageModal,
    });
  };

  handleChange = (achievementType: any) => {
    this.setState({ achievementType });
  };
  render() {
    const {
      handleOnChange,
      onSubmit,
      handleChange,
      state: {
        isEdit,
        badgeName,
        achievementCount,
        badgeDetails,
        achievementType,
        achiverTyeOption,
      },
    } = this;

    return (
      <main id="main">
        <section id="MyProfile" className="MyProfile">
          <div className="container  " data-aos="fade-up">
            {/* <div className="badgeContainer"> */}
              <div className="row ">
                <div className="col-lg-12 d-flex flex-column justify-content-center  ">
                  <div className="row ">
                    <div className="col-lg-8 ">
                      <Row>
                        <Col md={11}>
                          <h3 className="heading-secondary">
                            Create/Update a Badge
                          </h3>
                        </Col>
                        <Col md={1}>
                          <Link
                            to="/listBadge"
                            className="text-decoration-none text-dark"
                          >
                            <i
                              className="fa fa-times float-right"
                              id="close"
                            ></i>
                          </Link>
                          <UncontrolledTooltip target={`close`}>
                            Close
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                      <div className="row  mb-5">
                        <div className="col-lg-6 col-sm-12">
                          <FieldItem
                            onChange={handleOnChange}
                            name="badgeName"
                            value={badgeName}
                            label="Badge Name"
                            placeholder="Badge name"
                            type={FIELD_TYPES.TEXT}
                            className="form-control required"
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <FieldItem
                            label="Achievement Count"
                            onChange={handleOnChange}
                            name="achievementCount"
                            value={achievementCount}
                            placeholder="achievement count"
                            type={FIELD_TYPES.TEXT}
                            className="form-control required"
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <FieldItem
                            label="Achievement type"
                            onChange={handleChange}
                            name="achievementType"
                            options={achiverTyeOption}
                            value={achievementType}
                            placeholder="Achievement type"
                            type={FIELD_TYPES.DROP_DOWN}
                            className="required"
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group mb-3">
                            <label className="form-label">Image</label>
                            <label className="specialcharacter-required">
                              *
                            </label>
                            <input
                              type="file"
                              // multiple
                              accept="image/*"
                              name="selectedFile"
                              className="form-control required"
                              onChange={handleOnChange}
                              ref={this.imageFileRef}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-12">
                          <FieldItem
                            label="Badge details"
                            onTextAreaChange={handleOnChange}
                            name="badgeDetails"
                            value={badgeDetails}
                            placeholder="Badge details"
                            type={FIELD_TYPES.TEXT_AREA}
                            className="form-control"
                          />
                        </div>
                        <div className="col-lg-12 col-sm-12 text-right">
                          <Link to="/listBadge">
                            <Button
                              label="Cancel"
                              className="mr-2 btn btn-outline-secondary"
                              // onClick={this.props.toggle}
                            />
                          </Link>
                          <Button
                            onClick={onSubmit}
                            // onClick={this.save}
                            className="btn btn-theme-primary"
                            label={isEdit ? "Update" : "Save"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="planted_ImgBox">
                        <ul>
                          {this.state.selectedFile?.map(
                            (image: any, index: number) => (
                              <li key={index}>
                                <div className="planted_ImgBox_view">
                                  <img
                                    id={`renderFile${index}`}
                                    src={
                                      image.isUploaded
                                        ? `${constants.app.baseURL}/files/download?file_id=${image.file}`
                                        : image?.url
                                    }
                                    alt="project img"
                                  />
                                  <button
                                    id={`removeImage${index}`}
                                    className="Action_btn"
                                    onClick={() => this.deleteToggle()}
                                  >
                                    <i className="bi bi-trash"></i>
                                  </button>
                                  <UncontrolledTooltip
                                    target={`removeImage${index}`}
                                  >
                                    Delete
                                  </UncontrolledTooltip>

                                  <Modal
                                    isOpen={this.state.deleteImageModal}
                                    toggle={this.deleteToggle}
                                  >
                                    <ModalHeader
                                      className="modal_Bg"
                                      toggle={this.deleteToggle}
                                    >
                                      <div className="modal_title">
                                        {" "}
                                        Confirmation Alert
                                      </div>
                                    </ModalHeader>
                                    <ModalBody>
                                      Are you sure to Delete?
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button
                                        label="No"
                                        className="ml-2 btn btn-outline-secondary"
                                        onClick={this.deleteToggle}
                                      />
                                      <Button
                                        label="Yes"
                                        className="ml-2 btn btn-theme-primary"
                                        onClick={() => {
                                          this.removeImages(index);
                                          this.deleteToggle();
                                        }}
                                      />
                                    </ModalFooter>
                                  </Modal>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/* </div> */}
        </section>
      </main>
    );
  }
}

export default CreateBadge;
