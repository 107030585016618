import React, { Component } from "react";
import { Col, Row, UncontrolledTooltip } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { ActionRow } from "./partials/actionRow";
import { listDispatchProps, listDispatchState } from "./interface";
import { commonService, distributionService } from "../../../service";
import { showToaster, toasterTypes } from "../../../widgets";
import Pagination from "../../../widgets/pagination";
import Tablefeature from "../../../widgets/tableFeature";

export class ListDispatch extends Component<
  listDispatchProps,
  listDispatchState
> {
  constructor(props: listDispatchProps) {
    super(props);
    this.state = {
      modal: false,
      gridApi: "",
      tableRecords: [],
      totalQuantity: 0,
      totalDispatched: "",
      context: {
        componentParent: this,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
    };
  }
  onGridReady = (params: any) => {
    this.getDispatchList();
    this.setState({
      gridApi: params.api,
    });
  };

  componentDidMount() {
    this.getProcuredQuantity();
  }

  handlePageClick = (
    data: { selected: number },
    pageSize: number = this.state.pagination.pageSize
  ) => {
    this.getDispatchList(data.selected, pageSize);
  };

  handleSearch = (e: any) => {
    this.getDispatchList(1, 10, e.target.value);
  };

  getProcuredQuantity = () => {
    const sessionedData = sessionStorage.getItem("distribution_data");
    const detail = sessionedData ? JSON.parse(sessionedData) : null;

    if (detail) {
      try {
        commonService
          .getTotalProcuredQuantity(detail.projectId, detail.stateId)
          .subscribe(
            (response: any) => {
              if (response.status && response.statusCode === 200) {
                if (response.data) {
                  this.setState({
                    totalQuantity: response.data.total_delivered,
                    totalDispatched: response.data.total_dispatched,
                  });
                }
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            },
            (error: any) => {
              console.log(error);
              showToaster(toasterTypes.ERROR, error.message);
            }
          );
      } catch (error) {
        console.log(error?.message);
      }
    }
  };

  getDispatchList = (
    page: number = 1,
    pageSize: number = 10,
    key: string = ""
  ) => {
    try {
      distributionService
        .getAllDispatchList(
          this.props.match.params.districtId,
          page,
          pageSize,
          key
        )
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (
                  Array.isArray(response.content) &&
                  response.content?.length > 0
                ) {
                  try {
                    let rowData: any = response.content.map((element: any) => ({
                      id: element.id,
                      DispatchQuantity: element.seed_quantity,
                      Remark: element.dispatch_remarks,
                      DispatchDate: moment(element.distribution_date).format(
                        "DD-MM-YYYY"
                      ),
                      DispatchType: element.distribution_type,
                      DistrictId: element.district_id,
                      districtName: element.district_name,
                      userName: element.user_name,
                    }));
                    this.state.gridApi.setRowData(rowData);
                    this.setState({ tableRecords: rowData });
                    this.setState({
                      pagination: {
                        pageNumber: response.page || 1,
                        pageSize: response.size || 10,
                        totalPages: response.totalPages || 1,
                        totalElements: response.totalElements || 10,
                      },
                    });
                  } catch (error) {
                    console.log(error?.message);
                  }
                }
              } else {
                this.state.gridApi.setRowData([]);
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };
  changeStatusHandler = (data: any) => {
    const requestData = {
      id: data.id,
      status: "deleted",
    };
    distributionService.DistributionChangeStatus(requestData).subscribe(
      (data: any) => {
        if (data.status && data.statusCode === 200) {
          this.getDispatchList();
          this.getProcuredQuantity();
          showToaster(toasterTypes.SUCCESS, data.message);
        } else {
          showToaster(toasterTypes.ERROR, data.message);
        }
      },
      (error: any) => {
        console.log(error);
        showToaster(toasterTypes.ERROR, error.message);
      }
    );
  };
  render() {
    const { pagination } = this.state;
    return (
      <section id="ViewProject" className="ViewProject">
        <div className="container" data-aos="fade-up">
          <div className="row no-gutters">
            <div className="col-lg-10 d-flex flex-column justify-content-center ">
              <Row>
                <Col md={11}>
                  <h3 className="heading-secondary">List Dispatch</h3>
                </Col>
                <Col md={1}>
                  <Link to="/districtLevelDistribution">
                    <i
                      className="fa fa-times  text-dark float-right"
                      id="close"
                    ></i>
                  </Link>
                  <UncontrolledTooltip target={`close`}>
                    Close
                  </UncontrolledTooltip>
                </Col>
              </Row>

              <Tablefeature
                totalElements={pagination.totalElements}
                handleSearch={this.handleSearch}
                customClassName="marginLeft7prtg"
              />
              <div className="section-title">
                <div className="grid">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      domLayout="autoHeight"
                      // rowData={tableRecords}
                      onGridReady={this.onGridReady}
                      context={this.state.context}
                      rowData={this.state.tableRecords}
                      pagination={false}
                      paginationPageSize={10}
                      cacheBlockSize={10}
                      frameworkComponents={{
                        ActionColumn: ActionRow,
                      }}
                      defaultColDef={{
                        resizable: true,
                        minWidth: 100,
                      }}
                      suppressDragLeaveHidesColumns={true}
                    >
                      <AgGridColumn
                        field="districtName"
                        sortable={true}
                        filter={true}
                        flex={1}
                        tooltipField="districtName"
                        suppressMovable={true}
                      />

                      <AgGridColumn
                        field="DispatchDate"
                        sortable={true}
                        filter={true}
                        flex={1}
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="DispatchType"
                        sortable={true}
                        filter={true}
                        flex={1}
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="DispatchQuantity"
                        sortable={true}
                        filter={true}
                        flex={1}
                        type="rightAligned"
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="userName"
                        headerName="User"
                        sortable={true}
                        filter={true}
                        flex={1}
                        tooltipField="userName"
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="Remark"
                        sortable={true}
                        flex={1}
                        filter={true}
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="Action"
                        flex={1}
                        cellRenderer="ActionColumn"
                        cellRendererParams={{
                          totalDelivered: +this.state.totalQuantity,
                          totalDispatched: +this.state.totalDispatched,
                        }}
                        suppressMovable={true}
                      />
                    </AgGridReact>
                    <div className="col-md-12 mt-2 ">
                      <Pagination
                        page={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        handlePageChange={this.handlePageClick}
                        totalPage={pagination.totalPages}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

//  default EditRow;
