import React, { Component } from "react";
import AddUser from "../../add-user";

interface editRowProps {
  context: any;
  data: any;
  roleOptions: any;
  stateOptions: any;
  projects: any;
}
interface editRowStateProps {
  modal: boolean;
}
export class EditRow extends Component<editRowProps, editRowStateProps> {
  constructor(props: editRowProps) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  changeStatus = () => {
    this.props.context.componentParent.changeStatusHandler(this.props.data);
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  closeBtn = (
    <button className="close" title="Close" onClick={this.toggle}>
      &times;
    </button>
  );

  render() {
    return (
      <>
        <AddUser
          modal={this.state.modal}
          toggle={this.toggle}
          isEdit={true}
          existingUserId={this.props.data.id}
          roleOptions={this.props.roleOptions}
          stateOptions={this.props.stateOptions}
          projects={this.props.projects}
          refresh={() => {
            this.props.context.componentParent.getRowData();
          }}
        />
        <button
          className="Action_btn"
          title="Edit"
          onClick={() => this.setState({ modal: true })}
        >
          <i className="bi bi-pencil-square"></i>
        </button>
      </>
    );
  }
}
