import React from "react";
import { useHistory } from "react-router-dom";
// import { location } from 'history';
import Button from "../../../widgets/button";

interface Props {
  data: any;
  context: any;
}

const EditRow: React.FunctionComponent<Props> = (props) => {

  const history = useHistory();

  const onViewButtonClicked = ({ id, districtId }: any) => {
    history.push(`/pincode-settings/${districtId}/${id}`);
  }


  const onEditButtonClicked = () => {
    props.context.componentParent.onEditButtonClicked(props.data);
  };


  return (
    <>
      <Button
        label="View Pincodes"
        className="ml-2 btn btn-outline-secondary"
        onClick={() => onViewButtonClicked(props.data)}
      />

      <button
        className="Action_btn"
        title="Edit"
        style={{ marginLeft: "16px" }}
        onClick={() => onEditButtonClicked()}
      >
        <i className="bi bi-pencil-square"></i>
      </button>
    </>
  );
};

EditRow.defaultProps = {};

export default EditRow;
