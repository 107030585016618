import React, { Component } from "react";
import { SaplingProps, SaplingState } from "./interface";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { showToaster, toasterTypes } from "../../widgets";
import { saplingService } from "../../service/saplingService";
import moment from "moment";
import { UserHelper, Validation } from "../../utils";
import ReceivedDate from "./partials/NumericEditor";
import { NumericEditor } from "../../utils/validation/numeric-cell-editor-validation";
import { projectChangeSubscriber } from "../../utils/subscriber";
import { Async } from "../../utils/pipes/async.pipe";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Button from "../../widgets/button";



class Sapling extends Component<SaplingProps, SaplingState> {
  constructor(props: SaplingProps) {
    super(props);
    this.state = {
      tableData: [],
      gridApi: "",
      tableRecords: [],
      context: {
        componentParent: this,
      },
      userData: {},
      district: "",
      saplingStatus: "",
      saplingQty: "",
      // components: { datePicker: getDatePicker() }
    };
  }

  componentDidMount() {
    this.getAllSaplingList();
    this.setState({
      userData: UserHelper.getUserDetails(),
    });
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.projectId !== this.props.projectId) {
      this.getAllSaplingList();
    }
  }

  getAllSaplingList = () => {
    saplingService.getAllSaplingData().subscribe(
      (response: any) => {
        try {
          let rowData: any = [];
          if (response.status && response.statusCode === 200) {
            if (response.data?.length > 0) {
              this.setState({ tableData: response.data });
              response.data.forEach((element: any) => {
                rowData.push({
                  id: element.id,
                  nameOfItem: element.id,
                  distributionDate: moment(element.distribution_date).format(
                    "DD/MM/YYYY"
                  ),
                  distributionQty: element.seed_quantity,
                  receiveDate:
                    element.received_date !== null
                      ? new Date(element.received_date)
                      : "",
                  receivedQty: element.received_quantity,
                  saplingQty: element.sapling_quantity,
                  dispatchType: element.distribution_type,
                });
              });
              this.setState({ tableRecords: rowData });
            }
          } else {
            this.setState({ tableRecords: [] });
            showToaster(toasterTypes.ERROR, response.message);
          }
        } catch (error) {
          this.setState({ tableRecords: [] });
          console.log(error.message);
        }
      },
      (error: any) => {
        this.setState({ tableRecords: [] });
        showToaster(toasterTypes.ERROR, error.message);
      }
    );
  };

  onGridReady = (params: any) => {
    this.setState({
      gridApi: params.api,
    });
  };

  cellValueChangedForReceivedQuantity = (e: any) => {
    if (e.newValue) {
      if (+e.newValue > +e.data.distributionQty) {
        showToaster(
          toasterTypes.ERROR,
          "The quantity doesn't exeed more than distribution quantity."
        );
        return +e.oldValue;
      } else {
        return e.newValue;
      }
    } else {
      return 0;
    }
  };

  cellValueChangedForSaplingQuantity = (e: any) => {
    if (e.newValue) {
      if (+e.newValue > +e.data.receivedQty) {
        showToaster(
          toasterTypes.ERROR,
          "The quantity doesn't exeed more than distribution quantity."
        );
        return +e.oldValue;
      } else {
        return e.newValue;
      }
    } else {
      return 0;
    }
  };

  setDate = (id: number, date: any) => {
    try {
      let data = this.state.tableRecords;
      let commentIndex = data?.findIndex(function (c: any) {
        return c.id === id;
      });
      data[commentIndex].receiveDate = date;
      this.setState({ tableRecords: data });
    } catch (error) {
      console.log(error?.message);
    }
  };
  save = () => {
    try {
      this.state.gridApi.stopEditing();
      // var data: { id: string, sapling_quantity: string }[] = []
      var data: any = [];
      let noErrorReceived,
        noErrorSapling = true;
      noErrorReceived = this.state.tableRecords.every((e: any) => {
        return parseInt(e.distributionQty) >= parseInt(e.receivedQty);
      });
      noErrorSapling = this.state.tableRecords.every((e: any) => {
        return parseInt(e.receivedQty) >= parseInt(e.saplingQty);
      });
      if (!noErrorReceived) {
        showToaster(
          toasterTypes.ERROR,
          "Received quantity  cannot exceed distribution quantity "
        );
        return;
      }
      if (!noErrorSapling) {
        showToaster(
          toasterTypes.ERROR,
          "Sapling quantity  cannot exceed received quantity "
        );
        return;
      }
      // console.log('d');
      // return;

      this.state.tableRecords.forEach((element: any) => {
        data.push({
          id: +element["id"],
          received_date: moment(element.receiveDate).format("YYYY-MM-DD"),
          received_quantity: element["receivedQty"],
          sapling_quantity: element["saplingQty"],
        });
      });

      saplingService.saveSaplingData(data).subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            showToaster(toasterTypes.SUCCESS, response.message);
          }
        },
        (error: any) => {
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  render() {
    const { tableRecords, userData } = this.state;
    return (
      <section id="ViewProject" className="ViewProject">
        <div className="container" data-aos="fade-up">
          <div className="row no-gutters">
            <div className="col-lg-12 d-flex flex-column justify-content-center ">
              <div className="col-lg-12 d-flex flex-column justify-content-center ">
                <div className="row">
                <div className="col-lg-4 ">
                        <h3 className="heading-secondary">Saplings</h3>
                 </div>
                 <div className="col-lg-6"> 
                      <Link to="/pincodeLevelDistribution" className='text-decoration-none text-dark'>
                        <i className='fa fa-times float-right' id="close"></i>
                      </Link>
                    </div>
                  {/* <h3 className="heading-secondary">Saplings</h3> */}
                  <div className="row">
                    <div className="col-lg-6 col-md-12 ">
                      <h6 className="heading-tertiary">
                        {" "}
                        <Async observable={projectChangeSubscriber} />
                      </h6>
                    </div>
                    <div className="col-lg-2 col-md-12 d-flex flex-row justify-content-end">
                      <div className="Procurement_details_box mb-2 ">
                        <h6 className="heading-tertiary">{userData.district_name}</h6>
                      </div>
                    </div>
                    <div className="grid">
                      <div className="col-md-10 ag-theme-alpine sapling-grid">
                        <AgGridReact
                          domLayout="autoHeight"
                          pagination={false}
                          context={this.state.context}
                          rowData={tableRecords}
                          headerHeight={60}
                          onGridReady={this.onGridReady}
                          singleClickEdit={true}
                          defaultColDef={{
                            sortable: true,
                            minWidth: 100,
                            filter: true,
                            resizable: true,
                            width: 300,
                          }}
                          columnTypes={{
                            valueColumn: {
                              valueParser: "Number(newValue)",
                            },
                          }}
                          frameworkComponents={{
                            numericCellEditor: ReceivedDate,
                            numericEditor: NumericEditor,
                          }}
                          suppressDragLeaveHidesColumns={true}
                        >
                          <AgGridColumn
                            field="distributionDate"
                            headerName="Dispatched Date"
                            // maxWidth="90"
                            flex={1}
                            suppressMovable= {true}
                          />
                          <AgGridColumn field="dispatchType" flex={1} />
                          <AgGridColumn
                            field="distributionQty"
                            headerName="Dispatched Quantity"
                            type="rightAligned"
                            flex={1}
                            suppressMovable= {true}
                          />
                          {/* <AgGridColumn
                            field="distributionDate"
                            headerName='Received Date'
                            editable={true}
                            flex={1}
                            cellEditor='datePicker'
                          /> */}
                          <AgGridColumn
                          suppressMovable= {true}
                            field="receiveDate"
                            headerName="Received Date"
                            // height='200'
                            // editable={true}
                            flex={1}
                            cellRenderer="numericCellEditor"
                            // cellEditor='numericCellEditor'
                            // cellEditorSelector={function (params) {
                            //   return {
                            //     component: 'numericCellEditor'
                            //   };
                            // }}

                            // valueParser={(value) => this.cellValueChanged(value)}
                          />
                          <AgGridColumn
                          suppressMovable= {true}
                            field="receivedQty"
                            headerName="Received Quantity"
                            type="rightAligned"
                            editable={true}
                            flex={1}
                            // valueSetter={(params) => {
                            //   params.data.receivedQty =
                            //     this.cellValueChangedForReceivedQuantity(
                            //       params
                            //     );
                            //   return true;
                            // }}
                            cellEditor="numericEditor"
                          />
                          <AgGridColumn
                          suppressMovable= {true}
                            field="saplingQty"
                            headerName="Sapling Quantity"
                            type="rightAligned"
                            editable={true}
                            flex={1}
                            // valueSetter={(params) => {
                            //   params.data.saplingQty =
                            //     this.cellValueChangedForSaplingQuantity(params);
                            //   return true;
                            // }}
                            cellEditor="numericEditor"
                          />
                        </AgGridReact>
                      </div>
                    </div>
                    <Row md={10} className="mt-2 w-100 total-quantity-large">
                        <Col md={2} className="text-end">
                          <span className="customAgTextSapling">Total :</span>
                        </Col>
                        <Col md={3} className="h-5 text-end">
                          <strong>
                            {this.state.tableRecords?.reduce(
                              (a: any, b: any) => +a + +b["distributionQty"],
                              0
                            )}
                          </strong>
                        </Col>

                        <Col md={4} className="h-5 text-end">
                          <strong className='mr-16'>
                            {this.state.tableRecords?.reduce(
                              (a: any, b: any) => +a + +b["receivedQty"],
                              0
                            )}
                          </strong>
                        </Col>
                        <Col md={1} className="h-5 text-end">
                          <strong>
                            {this.state.tableRecords?.reduce(
                              (a: any, b: any) => +a + +b["saplingQty"],
                              0
                            )}
                          </strong>
                        </Col>
                    </Row>
                      {/*  responsive */}
                      <div className="col-md-8 total-quantity-small">
                      <div className="row d-flex mt-2 w-100 justify-content-end ">
                        {/* <div className="col-md-6"></div> */}
                        <div className="col-md-12">
                          <span className="customAgTextSapling">
                            Total Dispatched Quantity :{" "}
                          </span>
                          <span className="ml-2">
                            {" "}
                            {this.state.tableRecords?.reduce(
                              (a: any, b: any) => +a + +b["distributionQty"],
                              0
                            )}
                          </span>
                        </div>

                        <div className="col-md-12">
                          <span className="customAgTextSapling">
                            Total Received Quantity :{" "}
                          </span>
                          <span className="ml-2">
                            {" "}
                            {this.state.tableRecords?.reduce(
                              (a: any, b: any) => +a + +b["receivedQty"],
                              0
                            )}
                          </span>
                        </div>

                        <div className="col-md-12">
                          <span className="customAgTextSapling">
                            Total Sapling Quantity :{" "}
                          </span>
                          <span className="ml-2">
                            {this.state.tableRecords?.reduce(
                              (a: any, b: any) => +a + +b["saplingQty"],
                              0
                            )}
                          </span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                
                <div className="col-md-8 d-flex justify-content-end">
                  <button
                    className="ml-2 btn btn-theme-primary"
                    onClick={() => this.save()}
                    title="Save"
                  >
                    Save
                  </button>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Sapling;
