import React, { useEffect, useState } from "react";
import Button from "../../../../widgets/button";
import { authenticationService, projectService } from "../../../../service";
import { showToaster, toasterTypes } from "../../../../widgets";
import CheckboxTreeModal from "./checkbox-tree";
import { userRoles } from "../../../../constants";

function AssignProject(props: any) {
  const [projectModal, setProjectModal] = useState(false);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (props.data.id && projectModal) {
      getProjectList();
    }
  }, [props.data.id, projectModal]);

  const getProjectList = () => {
    try {
      projectService.getAllProjectListSelect().subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            try {
              if (
                Array.isArray(response.content) &&
                response.content?.length > 0
              ) {
                let rowData: any = [];
                response.content.forEach((element: any) => {
                  if (element.child_project.length > 0) {
                    let options: any = [];
                    element.child_project.forEach((subElement: any) => {
                      options.push({
                        label: subElement.name,
                        value: subElement.id,
                      });
                    });
                    rowData.push({
                      label: element.name,
                      value: element.id,
                      options,
                    });
                  } else {
                    rowData.push({
                      label: element.name,
                      value: element.id,
                      options: [],
                    });
                  }
                });
                setProjects(rowData);
              }
            } catch (error) {
              console.log(error?.message);
            }
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const updateProjects = (payload: any) => {
    try {
      const requestData: any = {
        user_id: props.data.id,
        ...payload,
      };

      authenticationService.changeProjectDetailsForUser(requestData).subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            showToaster(toasterTypes.SUCCESS, response.message);
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  return (
    <>
      {props?.data?.roleId !== userRoles.SUPER_ADMIN && (
        <Button
          label="Assign Projects"
          className="ml-2 btn btn-outline-secondary"
          onClick={() => setProjectModal(true)}
        />
      )}
      <CheckboxTreeModal
        projects={projects}
        selectedElement={props.data.selectedProject}
        modal={projectModal}
        toggle={() => {
          setProjectModal(false);
        }}
        onSavedEvent={(payload) => {
          updateProjects(payload);
        }}
      ></CheckboxTreeModal>
    </>
  );
}

export default AssignProject;
