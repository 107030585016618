import React, { Component } from "react";

interface locationProps {}
interface locationStateProps {}
class Location extends Component<locationProps, locationStateProps> {
  constructor(props: locationProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <main id="main">
        <section id="MyProfile" className="MyProfile">
          <div className="container mt-4" data-aos="fade-up">
            <div className="row ">
              <div className="col-lg-6 col-md-12">
                <div className="section-title pb-3">
                  <h5 className="m-0">PLANTED LOCATION -KERALA</h5>
                </div>
                <div className="card kerala_Map">
                  <div className="card-body">
                    <img
                      src="assets/img/kerala-map-png-3.png"
                      alt="kerala map"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="section-title pb-3">
                  <h5 className="m-0">Thiruvananthapuram</h5>
                </div>
                <div className="card kerala_Map">
                  <div className="card-body p-0">
                    <iframe
                      title="google map"
                      key="google-map"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d46955.13082241688!2d76.95509877944299!3d8.525087669492299!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1619976242736!5m2!1sen!2sin"
                      width="600"
                      height="450"
                      style={{ border: 0 }}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default Location;
