import React, { useEffect, useState } from "react";
import { userService } from "../../service";

interface referalProps {
  referedFriend?: Number ;
  activeFriend?: Number;
  keepRefering?: String;
}

const defaultProps: referalProps = {
  referedFriend: 0,
  activeFriend: 0,
};

const Referal: React.FunctionComponent<referalProps> = (props) => {
  const [invitedCount, SetInvitedCount] = useState(0);

  useEffect(() => {
    getInvitesCount();
  }, []);

  const getInvitesCount = () => {
    userService.getUserInvites().subscribe((response: any) => {
      if (response.status && response.statusCode === 200) {
        if (response.content && Array.isArray(response.content)) {
          SetInvitedCount(response.totalElements);
        }
      }
    });
  };
  return (
    <div className="card referred_Card">
      <div className="card-body">
        <img src="assets/img/Referred_icon.png" alt="referral logo" />
        <p>
          You have referred your {invitedCount} friends <br />
          as volunteers and they are active now!
          <br />
        </p>
        <p className="mt-5">Keep referring....</p>
      </div>
    </div>
  );
};

Referal.defaultProps = defaultProps;
export { Referal };
