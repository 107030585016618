import React, { useState } from "react";
import { Redirect } from "react-router-dom";
// import { location } from 'history';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "../../../widgets/button";

interface Props {
  data: any;
  context: any;
}

const EditRow: React.FunctionComponent<Props> = (props) => {
  const [rowEditById, setRowEditById] = useState<number>(0);
  const [modal, setModal] = useState<boolean>(false);

  if (rowEditById !== 0) {
    return <Redirect to={`/createProject/${rowEditById}`} />;
  }
  const toggle = () => {
    setModal(!modal);
  };
  const changeStatus = () => {
    props.context.componentParent.changeStatusHandler(props.data, true);
  };

  const closeBtn = (
    <button className="close" title="Close" onClick={toggle}>
      &times;
    </button>
  );

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader className="modal_Bg" toggle={toggle} close={closeBtn}>
          <div className="modal_title"> Confirmation Alert</div>
        </ModalHeader>
        <ModalBody>Are you sure to Delete?</ModalBody>
        <ModalFooter>
          <Button
            label="No"
            className="ml-2 btn btn-outline-secondary"
            onClick={toggle}
          />
          <Button
            label="Yes"
            className="ml-2 btn btn-theme-primary"
            onClick={changeStatus}
          />
        </ModalFooter>
      </Modal>
      <button
        className="Action_btn"
        title="Edit"
        onClick={() => setRowEditById(props.data.id)}
      >
        <i className="bi bi-pencil-square"></i>
      </button>
      <button
        className="Action_btn"
        title="Delete"
        style={{ marginLeft: "16px" }}
        onClick={toggle}
      >
        <i className="bi bi-trash"></i>
      </button>
    </>
  );
};

EditRow.defaultProps = {};

export default EditRow;
