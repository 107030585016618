import React, { Component } from "react";
import { procurementProps, procurementState } from "./interface";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import { showToaster, toasterTypes } from "../../../widgets";
import {
  commonService,
  procurementService,
  projectService,
} from "../../../service";
import { EditRow } from "./partials/EditRow";
import { AddEditDeliveryRow } from "./partials/AddEditDeliveryRow";
import moment from "moment";
import { UserHelper, Validation } from "../../../utils";
import Pagination from "../../../widgets/pagination";
import Tablefeature from "../../../widgets/tableFeature";
import Button from "../../../widgets/button";
import { AddPaymentDetailsRow } from "./partials/AddPaymentDetailsRow";

class ListProcurement extends Component<procurementProps, procurementState> {
  constructor(props: procurementProps) {
    super(props);
    this.state = {
      modal: false,
      tableData: [],
      procurementQuantity: "",
      remarks: "",
      procurementDate: "",
      gridApi: "",
      tableRecords: [],
      context: {
        componentParent: this,
      },
      treeName: "",
      rowEditById: 0,
      userData: {},
      projectId: "",
      totalProcuredQuantity: 0,
      totalDispatchQuantity: 0,
      totalReceivedQuantity: 0,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
    };
  }
  componentDidMount() {
    const userDetails = UserHelper.getUserDetails();
    this.setState(
      {
        userData: userDetails,
        projectId: this.props.projectId,
      },
      () => {
        this.getprojectDetailById();
      }
    );
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.projectId !== this.props.projectId) {
      this.setState(
        {
          projectId: this.props.projectId,
        },
        () => {
          this.getprojectDetailById();
        }
      );
    }
  }

  getprojectDetailById = () => {
    try {
      if (!this.props.projectId) {
        return;
      }

      projectService.getprojectDetailById(this.props.projectId).subscribe({
        next: (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              if (response.data && Array.isArray(response?.data?.activities)) {
                if (response?.data?.activities?.length > 0) {
                  const tree = response?.data?.activities[0].tree_name || "";
                  this.setState({ treeName: tree });
                  this.getProcuredQuantity();
                  this.getProcurementCreation();
                }
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        error: (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        },
      });
    } catch (error) {
      console.log(error?.message);
    }
  };

  getProcuredQuantity = () => {
    try {
      commonService
        .getTotalProcuredQuantity(
          this.state.projectId,
          this.state.userData.state_id
        )
        .subscribe(
          (response: any) => {
            if (response.status && response.statusCode === 200) {
              if (response.data) {
                this.setState({
                  totalProcuredQuantity: response.data.total_procured,
                  totalDispatchQuantity: response.data.total_dispatched,
                  totalReceivedQuantity: response.data.total_delivered,
                });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };

  handlePageClick = (
    data: { selected: number },
    pageSize: number = this.state.pagination.pageSize
  ) => {
    this.getProcurementCreation(data.selected, pageSize);
  };

  handleSearch = (e: any) => {
    this.getProcurementCreation(1, 10, e.target.value);
  };

  getProcurementCreation = (
    page: number = 1,
    pageSize: number = 10,
    key: string = ""
  ) => {
    try {
      procurementService.getAllProcurementList(page, pageSize, key).subscribe(
        (response: any) => {
          try {
            let rowData = [];
            if (response.status && response.statusCode === 200) {
              if (response.content && Array.isArray(response.content)) {
                try {
                  rowData = response.content.map((element: any) => ({
                    id: element.id,
                    ProcurementQuantity: element.seed_quantity,
                    OrderQuantity: element.order_quantity,
                    DeliveredQuantity: element.total_delivered_quantity,
                    RemainingQuantity: element.remaining_quantity,
                    SupplierName: element.supplier_name,
                    ProcurementType: element.type,
                    EstimatedAmount: element.estimated_amount,
                    PaymentDetails: element.paid_amount,
                    Remarks: element.remarks,
                    StatusData: element.status,
                    total_delivered_count: element.total_delivered_count,
                    treeName: this.state.treeName,
                    ProcurementDate: moment(element.procurement_date).format(
                      "DD-MM-YYYY"
                    ),
                  }));
                  this.state.gridApi.setRowData(rowData);
                  this.setState({
                    pagination: {
                      pageNumber: response.page || 1,
                      pageSize: response.size || 10,
                      totalPages: response.totalPages || 1,
                      totalElements: response.totalElements || 10,
                    },
                  });
                } catch (error) {
                  console.log(error?.message);
                }
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
            this.state.gridApi.setRowData(rowData);
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };
  toggle = () => {
    if (this.state.modal === true) {
      this.reset();
    }
    this.setState({ modal: !this.state.modal });
  };
  handleOnChange = (event: any) => {
    const { name, value } = event.currentTarget;
    const state: any = { ...this.state };
    state[name] = value;
    this.setState(state);
  };

  reset = () => {
    this.setState({
      procurementQuantity: "",
      remarks: "",
      procurementDate: "",
      rowEditById: 0,
    });
  };

  onGridReady = (params: any) => {
    this.setState({
      gridApi: params.api,
    });
  };

  changeStatusHandler = (data: any) => {
    const requestData = {
      id: data.id,
      status: "deleted",
    };
    procurementService.procurementChangeStatus(requestData).subscribe(
      (data: any) => {
        if (data.status && data.statusCode === 200) {
          this.getProcurementCreation();
          this.getProcuredQuantity();
          showToaster(toasterTypes.SUCCESS, data.message);
        } else {
          showToaster(toasterTypes.ERROR, data.message);
        }
      },
      (error: any) => {
        console.log(error);
        showToaster(toasterTypes.ERROR, error.message);
      }
    );
  };

  editRowData = (data: any) => {
    this.toggle();
    this.setState({
      procurementQuantity: data.ProcurementQuantity,
      remarks: data.Remarks,
      procurementDate: moment(data.unformatedDate).format("YYYY-MM-DD"),
      rowEditById: data.id,
    });
  };

  render() {
    const {
      state: { pagination },
      handlePageClick,
    } = this;

    return (
      <section id="ViewProject" className="ViewProject">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-12 d-flex flex-column justify-content-center ">
              <div className="w-100 row">
                <h3 className="heading-secondary col-lg-8">
                  View Procurements
                </h3>
                <div className="col-lg-4">
                  <div className="d-flex justify-content-between">
                    <div className="col-md-3 mr-1 card p-1 text-center">
                      <b>{this.state.totalProcuredQuantity}</b>
                      <small> Procured</small>
                    </div>

                    <div className="col-md-3 mr-1 card p-1 text-center">
                      <b>{this.state.totalReceivedQuantity}</b>
                      <small> Delivered</small>
                    </div>
                    <div className="col-md-3 mr-1 card p-1 text-center">
                      <b>{this.state.totalDispatchQuantity}</b>
                      <small> Dispatched</small>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section-title mt-3">
                <div className="d-flex align-items-center justify-content-start mb-3">
                  <h3 className="secondary-heading-normal m-0 table-header-label">
                    Create Procurement
                  </h3>

                  <Link to="/createProcurement">
                    <Button
                      label="Create"
                      className="ml-2 btn btn-outline-secondary"
                    />
                  </Link>
                </div>
                <Tablefeature
                  totalElements={pagination.totalElements}
                  handleSearch={this.handleSearch}
                />
                <div className="procurement-list-grid ag-theme-alpine">
                  <AgGridReact
                    domLayout="autoHeight"
                    cacheBlockSize={10}
                    context={this.state.context}
                    rowData={this.state.tableRecords}
                    onGridReady={this.onGridReady}
                    headerHeight={60}
                    defaultColDef={{
                      sortable: true,
                      flex: 1,
                      minWidth: 170,
                      filter: true,
                      resizable: true,
                      width: 300,
                    }}
                    enableBrowserTooltips={true}
                    frameworkComponents={{
                      ActionColumn: EditRow,
                      DeliveryColumn: AddEditDeliveryRow,
                      PaymentColumn: AddPaymentDetailsRow,
                    }}
                    suppressDragLeaveHidesColumns={true}
                  >
                    <AgGridColumn
                      field="ProcurementDate"
                      sortable={true}
                      filter={true}
                      tooltipField="ProcurementDate"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="ProcurementQuantity"
                      sortable={true}
                      filter={true}
                      type="rightAligned"
                      tooltipField="ProcurementQuantity"
                      minWidth={150}
                      suppressMovable={true}
                    />

                    <AgGridColumn
                      field="SupplierName"
                      sortable={true}
                      filter={true}
                      tooltipField="SupplierName"
                      suppressMovable={true}
                    />

                    <AgGridColumn
                      field="treeName"
                      sortable={true}
                      filter={true}
                      tooltipField="Tree Name"
                      suppressMovable={true}
                    />

                    <AgGridColumn
                      field="Deliveries"
                      cellRenderer="DeliveryColumn"
                      suppressMovable={true}
                      cellStyle={{ color: "blue" }}
                    />
                    <AgGridColumn
                      field="DeliveredQuantity"
                      sortable={true}
                      filter={true}
                      type="rightAligned"
                      tooltipField="DeliveredQuantity"
                      suppressMovable={true}
                    />

                    <AgGridColumn
                      field="RemainingQuantity"
                      sortable={true}
                      filter={true}
                      type="rightAligned"
                      minWidth={200}
                      tooltipField="RemainingQuantity"
                      suppressMovable={true}
                    />

                    <AgGridColumn
                      field="ProcurementType"
                      suppressMovable={true}
                      valueGetter={(params) => {
                        return Validation.capitalizeFirstLetter(
                          params.data.ProcurementType
                        );
                      }}
                      sortable={true}
                      filter={true}
                      tooltipField="ProcurementType"
                    />
                    <AgGridColumn
                      field="EstimatedAmount"
                      sortable={true}
                      filter={true}
                      type="rightAligned"
                      tooltipField="EstimatedAmount"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="Payment Details"
                      cellRenderer="PaymentColumn"
                      suppressMovable={true}
                      cellStyle={{ color: "blue" }}
                    />

                    <AgGridColumn
                      field="Action"
                      cellRenderer="ActionColumn"
                      suppressMovable={true}
                    />
                  </AgGridReact>
                  <div className="col-md-12 mt-2">
                    <Pagination
                      page={pagination.pageNumber}
                      pageSize={pagination.pageSize}
                      handlePageChange={handlePageClick}
                      totalPage={pagination.totalPages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ListProcurement;
