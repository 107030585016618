import React , {useState} from 'react'
import Button from "../../../../widgets/button";
import { authenticationService } from "../../../../service";
import { showToaster, toasterTypes } from "../../../../widgets";
import ConfirmationPopup from "../../../../widgets/confirmationPopup";

function ResetPassword(props:any) {
  const [resetMail, setresetMail] = useState(false)

  const forgetPassword = (userName:string) => {
    try {
      const requestData = {
        login_id: userName,
      };
      // TODO Validation
      authenticationService.forgetPasswordRequest(requestData).subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            showToaster(toasterTypes.SUCCESS, `Password reset link sent to ${userName}` );
            setresetMail(false)
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  return (
    <>
    <Button
      label="Reset"
      className="ml-2 btn btn-outline-secondary"
      onClick={() => setresetMail(true)}
    />
    <ConfirmationPopup
      message={`Are you sure want to reset?`}
      isOpen={ resetMail }
      confirmedEvent={() => forgetPassword(props.data.Email) }
      toggle={() => {
        setresetMail(!resetMail)
      }}
    ></ConfirmationPopup>
    </>
  )
}

export default ResetPassword
