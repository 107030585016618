import React from "react";
import styles from "./styles/switch-button.module.css";


interface Props {
  checked?: boolean;
  onChangeHandler?: any;
  name?: string;
}

const SwitchButton: React.FunctionComponent<Props> = ({
  checked,
  onChangeHandler,
  name,

}) => {
  // const { checked, onChangeHandler, name, tooltip } = props;
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.switch_box} ${styles.box_1}`}>
        <input
          type="checkbox"
          name={name ? name : ""}
          className={styles.switch_1}
          onChange={() => onChangeHandler(!checked)}
          checked={checked ? checked : false}
        />

      </div>
    </div>
  );
}


SwitchButton.defaultProps = {
  checked:false
};

export default SwitchButton;