import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import Button from "../../widgets/button";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { projectService } from "../../service";
import {
  parentProjectSubscriber,
  projectChangeSubscriber,
} from "../../utils/subscriber";
import Pagination from "../../widgets/pagination";
import { showToaster, toasterTypes } from "../../widgets/toaster";
import { UserHelper, Validation } from "../../utils";
import { constants, userRoles } from "../../constants";
import { useHistory } from "react-router-dom";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledCarousel,
} from "reactstrap";
import { _ } from "ag-grid-community";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import { debounce } from "lodash";
import { Subscription } from "rxjs";

function ProjectListDashboard() {
  const [counts, setCounts] = useState({
    1: {
      plannedCount: 0,
      plantedCount: 0,
      budgetAmount: 0,
      paidAmount: 0,
    },
    2: {
      plannedCount: 0,
      plantedCount: 0,
      budgetAmount: 0,
      paidAmount: 0,
    },
    3: {
      plannedCount: 0,
      plantedCount: 0,
      budgetAmount: 0,
      paidAmount: 0,
    },
    4: {
      plannedCount: 0,
      plantedCount: 0,
      budgetAmount: 0,
      paidAmount: 0,
    },
  });
  const [activeTab, setActiveTab] = useState("1");
  const [keyword, setKeyword] = useState("");
  const [totalProjectCount, settotalProjectCount] = useState(0);
  const [completedProjectCount, setcompletedProjectCount] = useState(0);
  const [activeProjects, setactiveProjects] = useState([]);
  const [inActiveProjects, setinActiveProjects] = useState([]);
  const [totalProjects, settotalProjects] = useState([]);
  const [completedProjects, setcompletedProjects] = useState([]);
  const [totalActiveProjectCount, settotalActiveProjectCount] = useState(0);
  const [totalInactiveProjectCount, settotalInactiveProjectCount] = useState(0);
  const history = useHistory();
  const [modal, setModal] = useState<boolean>(false);
  const [bannerImage, setbannerImage] = useState<string>("");
  const [isLoadingBannerImage, setIsLoadingBannerImage] =
    useState<boolean>(false);

  const [activePagination, setActivePagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
    totalElements: 10,
  });

  const [inActivePagination, setInactivePagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
    totalElements: 10,
  });

  const [totalPagination, setTotalPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
    totalElements: 10,
  });

  const [completedPagination, setCompletedPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
    totalElements: 10,
  });

  useEffect(() => {
    const subscription: Subscription = parentProjectSubscriber.subscribe(
      (res: any) => {
        if (res.value) {
          getAllActiveprojects(1, 10, "", res.value);
          getAllInActiveprojects(1, 10, "", res.value);
          getAllTotalprojects(1, 10, "", res.value);
          getAllCompletedprojects(1, 10, "", res.value);
          setIsLoadingBannerImage(true);
          projectService
            .getprojectDetailById(res.value)
            .subscribe((projectDetail: any) => {
              setIsLoadingBannerImage(false);
              setbannerImage(
                projectDetail?.data?.banner_image_id
                  ? projectDetail?.data?.banner_image_id
                  : ""
              );
            });
        } else {
          getAllActiveprojects();
          getAllInActiveprojects();
          getAllTotalprojects();
          getAllCompletedprojects();
        }
      }
    );

    return () => subscription.unsubscribe();
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const closeBtn = (
    <button className="close" title="Close" onClick={toggle}>
      &times;
    </button>
  );

  const changeStatus = () => {
    // props.context.componentParent.changeStatusHandler(props.data, true);
  };

  const handlePageClickForActiveProjects = (
    data: { selected: number },
    pageSize: number = activePagination.pageSize
  ) => {
    getAllActiveprojects(data.selected, pageSize);
  };

  const handlePageClickForTotalProjects = (
    data: { selected: number },
    pageSize: number = totalPagination.pageSize
  ) => {
    getAllTotalprojects(data.selected, pageSize);
  };

  const handlePageClickForInActiveProjects = (
    data: { selected: number },
    pageSize: number = inActivePagination.pageSize
  ) => {
    getAllInActiveprojects(data.selected, pageSize);
  };

  const handlePageClickForCompletedProjects = (
    data: { selected: number },
    pageSize: number = completedPagination.pageSize
  ) => {
    getAllCompletedprojects(data.selected, pageSize);
  };

  const handleSearch = (e: any) => {
    getAllActiveprojects(1, 10, e);
    getAllInActiveprojects(1, 10, e);
    getAllTotalprojects(1, 10, e);
    getAllCompletedprojects(1, 10, e);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceLoadData = useCallback(debounce(handleSearch, 1000), []);
  const onChange = (e: any) => {
    setKeyword(e.target.value ? e.target.value : "");
    debounceLoadData(e.target.value);
  };

  const redirectToDashboard = (id: string | number, label: string) => {
    try {
      let route: string;
      projectService.changeProjectOfUser({ project_id: id }).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            projectChangeSubscriber.next(label);
            if (Validation.isUserLogged()) {
              const role: any = UserHelper.getUserDetails()?.user_role_id;
              switch (role) {
                case userRoles.VOLUNTEER:
                  route = "/volunteerDashboard";
                  break;

                case userRoles.EXTERNAL:
                  route = "/external-dashboard";
                  break;

                case userRoles.STATE_ADMIN:
                case userRoles.SUPER_ADMIN:
                case userRoles.DISTRICT_ADMIN:
                  route = "/adminDashboard";
                  break;
                default:
                  return history.push("/");
              }
            }
            history.push(route);
            window.location.reload();
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const getAllActiveprojects = (
    page: number = 1,
    pageSize: number = 10,
    key: string = "",
    parent: string = ""
  ) => {
    try {
      projectService
        .getAllProjectList(
          page,
          pageSize,
          key,
          projectService.ACTIVESTATUS,
          parent
        )
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (response.content && Array.isArray(response.content)) {
                  let rowData: any = [];
                  let imageRow: any = [];
                  response.content.forEach((element: any) => {
                    imageRow = element.images?.map(
                      (element: any, index: number) => ({
                        key: index,
                        src: `${constants.app.baseURL}/files/download?file_id=${element.image_id}`,
                      })
                    );
                    rowData.push({
                      id: element.id,
                      projectName: element.name,
                      nameofPlant: element.plant_name,
                      planned: element.planned_quantity,
                      planted: element.planted_quantity,
                      statusData: _.capitalise(element.status),
                      startDate: moment(element.start_date).format(
                        "DD-MM-YYYY"
                      ),
                      endDate: moment(element.end_date).format("DD-MM-YYYY"),
                      images: imageRow,
                    });
                  });

                  setactiveProjects(rowData);
                  const count = {
                    plannedCount: response?.content?.reduce(
                      (currentValue: any, value: any) =>
                        +currentValue + +value.planned_quantity,
                      0
                    ),
                    plantedCount: response?.content?.reduce(
                      (currentValue: any, value: any) =>
                        +currentValue + +value.planted_quantity,
                      0
                    ),
                    budgetAmount: response?.content?.reduce(
                      (currentValue: any, value: any) =>
                        +currentValue + +value.estimated_amount,
                      0
                    ),
                    paidAmount: response?.content?.reduce(
                      (currentValue: any, value: any) =>
                        +currentValue + +value.total_paid,
                      0
                    ),
                  };
                  setCounts((prevState) => ({ ...prevState, 1: count }));
                  settotalActiveProjectCount(response.totalElements);
                  setActivePagination({
                    pageNumber: response.page || 1,
                    pageSize: response.size || 10,
                    totalPages: response.totalPages || 1,
                    totalElements: response.totalElements || 10,
                  });
                }
              } else {
                setactiveProjects([]);
                const count = {
                  plannedCount: 0,
                  plantedCount: 0,
                  budgetAmount: 0,
                  paidAmount: 0,
                };
                setCounts((prevState) => ({ ...prevState, 1: count }));
                settotalActiveProjectCount(0);
                setActivePagination({
                  pageNumber: response.page || 1,
                  pageSize: response.size || 10,
                  totalPages: response.totalPages || 1,
                  totalElements: 0 || 10,
                });
                // showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error?.message);
            }
          },
          (error: any) => {
            console.log(error);
            // showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const getAllInActiveprojects = (
    page: number = 1,
    pageSize: number = 10,
    key: string = "",
    parent: string = ""
  ) => {
    try {
      projectService
        .getAllProjectList(
          page,
          pageSize,
          key,
          projectService.INACTIVESTATUS,
          parent
        )
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (response.content && Array.isArray(response.content)) {
                  let rowData: any = [];
                  let imageRow: any = [];
                  response.content.forEach((element: any) => {
                    imageRow = element.images?.map(
                      (element: any, index: number) => ({
                        key: index,
                        src: `${constants.app.baseURL}/files/download?file_id=${element.image_id}`,
                      })
                    );
                    rowData.push({
                      id: element.id,
                      projectName: element.name,
                      nameofPlant: element.plant_name,
                      planned: element.planned_quantity,
                      planted: element.planted_quantity,
                      statusData: _.capitalise(element.status),
                      startDate: moment(element.start_date).format(
                        "DD-MM-YYYY"
                      ),
                      endDate: moment(element.end_date).format("DD-MM-YYYY"),
                      images: imageRow,
                    });
                  });

                  setinActiveProjects(rowData);
                  const count = {
                    plannedCount: response?.content?.reduce(
                      (currentValue: any, value: any) =>
                        +currentValue + +value.planned_quantity,
                      0
                    ),
                    plantedCount: response?.content?.reduce(
                      (currentValue: any, value: any) =>
                        +currentValue + +value.planted_quantity,
                      0
                    ),
                    budgetAmount: response?.content?.reduce(
                      (currentValue: any, value: any) =>
                        +currentValue + +value.estimated_amount,
                      0
                    ),
                    paidAmount: response?.content?.reduce(
                      (currentValue: any, value: any) =>
                        +currentValue + +value.total_paid,
                      0
                    ),
                  };
                  setCounts((prevState) => ({ ...prevState, 2: count }));
                  settotalInactiveProjectCount(response.totalElements);
                  setInactivePagination({
                    pageNumber: response.page || 1,
                    pageSize: response.size || 10,
                    totalPages: response.totalPages || 1,
                    totalElements: response.totalElements || 10,
                  });
                }
              } else {
                setinActiveProjects([]);
                const count = {
                  plannedCount: 0,
                  plantedCount: 0,
                  budgetAmount: 0,
                  paidAmount: 0,
                };
                setCounts((prevState) => ({ ...prevState, 2: count }));
                settotalInactiveProjectCount(0);
                setInactivePagination({
                  pageNumber: response.page || 1,
                  pageSize: response.size || 10,
                  totalPages: response.totalPages || 1,
                  totalElements: 0 || 10,
                });
                // showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error?.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const getAllTotalprojects = (
    page: number = 1,
    pageSize: number = 10,
    key: string = "",
    parent: string = ""
  ) => {
    try {
      projectService
        .getAllProjectList(
          page,
          pageSize,
          key,
          projectService.BOTHSTATUS,
          parent
        )
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (response.content && Array.isArray(response.content)) {
                  let rowData: any = [];
                  let imageRow: any = [];
                  response.content.forEach((element: any) => {
                    imageRow = element.images?.map(
                      (element: any, index: number) => ({
                        key: index,
                        src: `${constants.app.baseURL}/files/download?file_id=${element.image_id}`,
                      })
                    );
                    rowData.push({
                      id: element.id,
                      projectName: element.name,
                      nameofPlant: element.plant_name,
                      planned: element.planned_quantity,
                      planted: element.planted_quantity,
                      statusData: _.capitalise(element.status),
                      startDate: moment(element.start_date).format(
                        "DD-MM-YYYY"
                      ),
                      endDate: moment(element.end_date).format("DD-MM-YYYY"),
                      images: imageRow,
                    });
                  });

                  settotalProjects(rowData);
                  const count = {
                    plannedCount: response?.content?.reduce(
                      (currentValue: any, value: any) =>
                        +currentValue + +value.planned_quantity,
                      0
                    ),
                    plantedCount: response?.content?.reduce(
                      (currentValue: any, value: any) =>
                        +currentValue + +value.planted_quantity,
                      0
                    ),
                    budgetAmount: response?.content?.reduce(
                      (currentValue: any, value: any) =>
                        +currentValue + +value.estimated_amount,
                      0
                    ),
                    paidAmount: response?.content?.reduce(
                      (currentValue: any, value: any) =>
                        +currentValue + +value.total_paid,
                      0
                    ),
                  };
                  setCounts((prevState) => ({ ...prevState, 4: count }));
                  settotalProjectCount(response.totalElements);
                  setTotalPagination({
                    pageNumber: response.page || 1,
                    pageSize: response.size || 10,
                    totalPages: response.totalPages || 1,
                    totalElements: response.totalElements || 10,
                  });
                }
              } else {
                settotalProjects([]);
                const count = {
                  plannedCount: 0,
                  plantedCount: 0,
                  budgetAmount: 0,
                  paidAmount: 0,
                };
                setCounts((prevState) => ({ ...prevState, 4: count }));
                settotalProjectCount(0);
                setTotalPagination({
                  pageNumber: response.page || 1,
                  pageSize: response.size || 10,
                  totalPages: response.totalPages || 1,
                  totalElements: 0 || 10,
                });
                // showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error?.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const getAllCompletedprojects = (
    page: number = 1,
    pageSize: number = 10,
    key: string = "",
    parent: string = ""
  ) => {
    try {
      projectService
        .getAllProjectList(
          page,
          pageSize,
          key,
          projectService.COMPLETED,
          parent
        )
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (response.content && Array.isArray(response.content)) {
                  let rowData: any = [];
                  let imageRow: any = [];
                  response.content.forEach((element: any) => {
                    imageRow = element.images?.map(
                      (element: any, index: number) => ({
                        key: index,
                        src: `${constants.app.baseURL}/files/download?file_id=${element.image_id}`,
                      })
                    );
                    rowData.push({
                      id: element.id,
                      projectName: element.name,
                      nameofPlant: element.plant_name,
                      planned: element.planned_quantity,
                      planted: element.planted_quantity,
                      statusData: _.capitalise(element.status),
                      startDate: moment(element.start_date).format(
                        "DD-MM-YYYY"
                      ),
                      endDate: moment(element.end_date).format("DD-MM-YYYY"),
                      images: imageRow,
                    });
                  });

                  setcompletedProjects(rowData);
                  const count = {
                    plannedCount: response?.content?.reduce(
                      (currentValue: any, value: any) =>
                        +currentValue + +value.planned_quantity,
                      0
                    ),
                    plantedCount: response?.content?.reduce(
                      (currentValue: any, value: any) =>
                        +currentValue + +value.planted_quantity,
                      0
                    ),
                    budgetAmount: response?.content?.reduce(
                      (currentValue: any, value: any) =>
                        +currentValue + +value.estimated_amount,
                      0
                    ),
                    paidAmount: response?.content?.reduce(
                      (currentValue: any, value: any) =>
                        +currentValue + +value.total_paid,
                      0
                    ),
                  };
                  setCounts((prevState) => ({ ...prevState, 3: count }));

                  setcompletedProjectCount(response.totalElements);
                  setCompletedPagination({
                    pageNumber: response.page || 1,
                    pageSize: response.size || 10,
                    totalPages: response.totalPages || 1,
                    totalElements: response.totalElements || 10,
                  });
                }
              } else {
                setcompletedProjects([]);
                const count = {
                  plannedCount: 0,
                  plantedCount: 0,
                  budgetAmount: 0,
                  paidAmount: 0,
                };
                setCounts((prevState) => ({ ...prevState, 3: count }));
                setcompletedProjectCount(0);
                setCompletedPagination({
                  pageNumber: response.page || 1,
                  pageSize: response.size || 10,
                  totalPages: response.totalPages || 1,
                  totalElements: 0 || 10,
                });
                // showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error?.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };

  return (
    <main id="main">
      <section>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader className="modal_Bg" toggle={toggle} close={closeBtn}>
            <div className="modal_title"> Confirmation Alert</div>
          </ModalHeader>
          <ModalBody>Are you sure to Delete?</ModalBody>
          <ModalFooter>
            <Button
              label="No"
              className="ml-2 btn btn-outline-secondary"
              onClick={toggle}
            />
            <Button
              label="Yes"
              className="ml-2 btn btn-theme-primary"
              onClick={changeStatus}
            />
          </ModalFooter>
        </Modal>

        <div className="container my-3">
          <div className="row" style={{ maxHeight: "35px" }}>
            {/* <div className="col-lg-12 disp-flex">
              <h1 className="heading-secondary">Projects</h1>
              <Link to="/createProject">
                <Button
                  label="Create"
                  className="ml-2 btn btn-outline-secondary"
                />
              </Link>
            </div> */}
          </div>

          <div className="row mb-2 gy-2">
            <div className="col-md-4">
              {bannerImage && bannerImage !== "0" && (
                <img
                  src={`${constants.app.baseURL}/files/download?file_id=${bannerImage}`}
                  alt="logo"
                  width="100%"
                  // height="100"
                />
              )}

              {((bannerImage && bannerImage === "0") || !bannerImage) && !isLoadingBannerImage && (
                <img
                  src={`${process.env.PUBLIC_URL}/static/media/BGnew.6210d419.jpg`}
                  alt="logo"
                  width="400"
                  // height="100"
                />
              )}
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div className="Project_Dt_date">
                <ul>
                  <li>
                    <div
                      className="mr-2 mb-2 card p-1 text-center"
                      style={{ backgroundColor: "#dddcf5 " }}
                    >
                      <span>Total Planned</span>
                      <h6>
                        {counts[+activeTab as 1 | 2 | 3 | 4].plannedCount}
                      </h6>
                    </div>
                  </li>
                  <li>
                    <div
                      className="mr-1 card p-1 text-center"
                      style={{ backgroundColor: "#dddcf5 " }}
                    >
                      <span>Total Planted</span>
                      <h6>
                        {counts[+activeTab as 1 | 2 | 3 | 4].plantedCount}
                      </h6>
                    </div>
                  </li>
                </ul>
              </div>

              {!UserHelper.hasRole([userRoles.EXTERNAL]) && (
                <div className="Project_Dt_date">
                  <ul>
                    <li>
                      <div
                        className="mr-2 mb-2 card p-1 text-center"
                        style={{ backgroundColor: "#dddcf5 " }}
                      >
                        <span>Estimated Amount</span>
                        <h6>
                          {counts[+activeTab as 1 | 2 | 3 | 4].budgetAmount}
                        </h6>
                      </div>
                    </li>
                    <li>
                      <div
                        className="mr-1 card p-1 text-center"
                        style={{ backgroundColor: "#dddcf5 " }}
                      >
                        <span>Total Paid</span>
                        <h6>
                          {counts[+activeTab as 1 | 2 | 3 | 4].paidAmount}
                        </h6>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="row gy-2">
            <div className="col-lg-4 search-project-card-responsive">
              <FieldItem
                onChange={(event) => onChange(event)}
                name="search"
                placeholder="Search"
                value={keyword}
                type={FIELD_TYPES.SEARCH}
                clearSearch={() => setKeyword("")}
                className={`form-control ${""}`}
              />
            </div>
            <div className="col-lg-8">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={
                      activeTab === "1" ? "navActive notHover" : "notHover"
                    }
                    onClick={() => {
                      setActiveTab("1");
                    }}
                  >
                    <span className="text-secondary fs-20">
                      {totalActiveProjectCount}
                    </span>
                    <h3
                      className={
                        activeTab === "1"
                          ? "heading-tertiary mb-1 colorVruksha"
                          : "heading-tertiary mb-1"
                      }
                    >
                      Active
                    </h3>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      activeTab === "2" ? "navActive notHover" : "notHover"
                    }
                    onClick={() => {
                      setActiveTab("2");
                    }}
                  >
                    <span className="text-secondary fs-20">
                      {totalInactiveProjectCount}
                    </span>
                    <h3
                      className={
                        activeTab === "2"
                          ? "heading-tertiary mb-1 colorVruksha"
                          : "heading-tertiary mb-1"
                      }
                    >
                      Inactive
                    </h3>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      activeTab === "3" ? "navActive notHover" : "notHover"
                    }
                    onClick={() => {
                      setActiveTab("3");
                    }}
                  >
                    <span className="text-secondary fs-20">
                      {completedProjectCount}
                    </span>
                    <h3
                      className={
                        activeTab === "3"
                          ? "heading-tertiary mb-1 colorVruksha"
                          : "heading-tertiary mb-1"
                      }
                    >
                      Completed
                    </h3>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      activeTab === "4" ? "navActive notHover" : "notHover"
                    }
                    onClick={() => {
                      setActiveTab("4");
                    }}
                  >
                    <span className="text-secondary fs-20">
                      {totalProjectCount}
                    </span>
                    <h3
                      className={
                        activeTab === "4"
                          ? "heading-tertiary mb-1 colorVruksha"
                          : "heading-tertiary mb-1"
                      }
                    >
                      Total Projects
                    </h3>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <div className="col-lg-4 search-project-card">
              <FieldItem
                onChange={(event) => onChange(event)}
                name="search"
                placeholder="Search"
                value={keyword}
                type={FIELD_TYPES.SEARCH}
                clearSearch={() => {
                  setKeyword("");
                  handleSearch("");
                }}
                className={`form-control ${""}`}
              />
            </div>
          </div>
          <TabContent activeTab={activeTab}>
            {/* <TabPane tabId="1">
            <div className="row mt-2 gy-3 gx-3">
              <div className="col-lg-4 col-md-6 pro-3-">
                {activeProjects?.map((element: any, index: number) => (
                  // <div className="col-lg-4">
                  //   <div
                  //     className="project-card-container"
                  //     onClick={() =>
                  //       redirectToDashboard(element.id, element.projectName)
                  //     }
                  //   >
                  //     <div className="grid">
                  //       <div>
                  //         {element.images.length === 1 ? (
                  //           <img
                  //             src={element.images[0].src}
                  //             alt="project img"
                  //             className="w-100"
                  //           />
                  //         ) : (
                  //           <UncontrolledCarousel
                  //             controls={true}
                  //             indicators={false}
                  //             items={element.images}
                  //           />
                  //         )}
                  //       </div>

                  //       <div className="relative">
                  //         <div className="mt-2 p-2">
                  //           <div className="word-break-title">
                  //             {element.projectName}
                  //           </div>
                  //         </div>

                  //         <div className="date-grid-container">
                  //           <div className="p-2 date-grid mt-4">
                  //             <div>
                  //               <div className="text-secondary fs-13">
                  //                 Start Date
                  //               </div>
                  //               <div className="text-black fs-13 bold">
                  //                 {element.startDate}
                  //               </div>
                  //             </div>

                  //             <div className="text-end">
                  //               <div className="text-secondary fs-13">
                  //                 {" "}
                  //                 End Date{" "}
                  //               </div>
                  //               <div className="text-black fs-13 bold text-end">
                  //                 {element.endDate}
                  //               </div>
                  //             </div>
                  //           </div>
                  //         </div>
                  //         <div className="status">{element.statusData}</div>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                  <div className="ProjectView_box">
                    <div className="Project_img_h">
                      <div>
                        {element.images.length === 1 ? (
                          <img
                            src={element.images[0].src}
                            alt="project img"
                            className="w-100"
                          />
                        ) : (
                          <UncontrolledCarousel
                            controls={true}
                            indicators={false}
                            items={element.images}
                          />
                        )}
                      </div>
                    </div>
                    <div className="Project_Short_Dt">
                      <div className="Project_Short_Dt_inner">
                        <div
                          className="Project_Short_Dt_header pb-3 handSymbol"
                          onClick={() =>
                            redirectToDashboard(element.id, element.projectName)
                          }
                        >
                          {element.projectName}
                        </div>
                        <div className="Project_Dt_date">
                          <ul>
                            <li>
                              <span>Start Date</span>
                              <h6>{element.startDate}</h6>
                            </li>
                            <li>
                              <span>End Date</span>
                              <h6>{element.endDate}</h6>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="Project_Short_dt_footer">
                        <div>
                          <b>{element.statusData}</b>
                        </div>
                        <div>
                          <ul>
                            <li>
                              <i
                                className="fa fa-trash-o handSymbol"
                                aria-hidden="true"
                                onClick={toggle}
                              ></i>
                            </li>
                            <li>
                              <i
                                className="fa fa-pencil handSymbol"
                                aria-hidden="true"
                                onClick={() => handleRoute(element.id)}
                              ></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-12 col-sm-12 ">
                <Pagination
                  page={activePagination.pageNumber}
                  pageSize={activePagination.pageSize}
                  handlePageChange={handlePageClickForActiveProjects}
                  totalPage={activePagination.totalPages}
                />
              </div>
            </div>
          </TabPane> */}
            <TabPane tabId="1">
              <div className="row mt-2 gy-3 gx-3">
                {activeProjects?.map((element: any, index: number) => (
                  <div className="col-lg-4 col-md-6 pro-3-">
                    <div className="ProjectView_box">
                      <div className="Project_img_h">
                        <div>
                          {element.images.length === 1 ? (
                            <img
                              src={element.images[0].src}
                              alt="project img"
                              className="w-100 "
                            />
                          ) : (
                            <UncontrolledCarousel
                              controls={true}
                              indicators={false}
                              items={element.images}
                            />
                          )}
                        </div>
                      </div>
                      <div className="Project_Short_Dt">
                        <div className="Project_Short_Dt_inner">
                          <div
                            className="Project_Short_Dt_header pb-3- handSymbol"
                            onClick={() =>
                              redirectToDashboard(
                                element.id,
                                element.projectName
                              )
                            }
                          >
                            {element.projectName}
                          </div>
                          <div className="Project_Dt_date">
                            <ul>
                              <li>
                                <span>Start Date</span>
                                <h6>{element.startDate}</h6>
                              </li>
                              <li>
                                <span>End Date</span>
                                <h6>{element.endDate}</h6>
                              </li>
                            </ul>
                          </div>
                          <div className="Project_Dt_date">
                            <ul>
                              <li>
                                <span>Total Planned</span>

                                <h6>{element.planned}</h6>
                              </li>
                              <li>
                                <span>Total Planted</span>
                                {/* <h6>2000</h6> */}
                                <h6>{element.planted}</h6>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="Project_Short_dt_footer ">
                          <div>
                            <div className="container ">
                              <b>{element.statusData}</b>
                            </div>
                          </div>
                          {/* <div>
                            <ul>
                              <li>
                                <i
                                  className="fa fa-trash-o handSymbol"
                                  aria-hidden="true"
                                  onClick={toggle}
                                ></i>
                              </li>
                              <li>
                                <i
                                  className="fa fa-pencil handSymbol"
                                  aria-hidden="true"
                                  onClick={() => handleRoute(element.id)}
                                ></i>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  // <div className="col-lg-4">
                  //   <div
                  //     className="project-card-container"
                  //     onClick={() =>
                  //       redirectToDashboard(element.id, element.projectName)
                  //     }
                  //   >
                  //     <div className="grid">
                  //       <div>
                  //         {element.images.length === 1 ? (
                  //           <img
                  //             src={element.images[0].src}
                  //             alt="project img"
                  //             className="w-100"
                  //           />
                  //         ) : (
                  //           <UncontrolledCarousel
                  //             controls={true}
                  //             indicators={false}
                  //             items={element.images}
                  //           />
                  //         )}
                  //       </div>

                  //       <div className="relative">
                  //         <div className="mt-2 p-2">
                  //           <div className="word-break-title">
                  //             {element.projectName}
                  //           </div>
                  //         </div>
                  //         <div className="date-grid-container">
                  //           <div className="p-2 date-grid mt-4">
                  //             <div>
                  //               <div className="text-secondary fs-13">
                  //                 Start Date
                  //               </div>
                  //               <div className="text-black fs-13 bold">
                  //                 {element.startDate}
                  //               </div>
                  //             </div>

                  //             <div className="text-end">
                  //               <div className="text-secondary fs-13">
                  //                 {" "}
                  //                 End Date{" "}
                  //               </div>
                  //               <div className="text-black fs-13 bold text-end">
                  //                 {element.endDate}
                  //               </div>
                  //             </div>
                  //           </div>
                  //         </div>

                  //         <div className="status">{element.statusData}</div>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                ))}
                <div className="col-md-12 col-sm-12 ">
                  <Pagination
                    page={activePagination.pageNumber}
                    pageSize={activePagination.pageSize}
                    handlePageChange={handlePageClickForActiveProjects}
                    totalPage={activePagination.totalPages}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="row mt-2 gy-3 gx-3">
                {inActiveProjects?.map((element: any, index: number) => (
                  <div className="col-lg-4 col-md-6 pro-3-">
                    <div className="ProjectView_box">
                      <div className="Project_img_h">
                        <div>
                          {element.images.length === 1 ? (
                            <img
                              src={element.images[0].src}
                              alt="project img"
                              className="w-100"
                            />
                          ) : (
                            <UncontrolledCarousel
                              controls={true}
                              indicators={false}
                              items={element.images}
                            />
                          )}
                        </div>
                      </div>
                      <div className="Project_Short_Dt">
                        <div className="Project_Short_Dt_inner">
                          <div
                            className="Project_Short_Dt_header pb-3- handSymbol"
                            onClick={() =>
                              redirectToDashboard(
                                element.id,
                                element.projectName
                              )
                            }
                          >
                            {element.projectName}
                          </div>
                          <div className="Project_Dt_date">
                            <ul>
                              <li>
                                <span>Start Date</span>
                                <h6>{element.startDate}</h6>
                              </li>
                              <li>
                                <span>End Date</span>
                                <h6>{element.endDate}</h6>
                              </li>
                            </ul>
                          </div>

                          <div className="Project_Dt_date">
                            <ul>
                              <li>
                                <span>Total Planned</span>
                                {/* <h6>2000</h6> */}
                                <h6>{element.planned}</h6>
                              </li>
                              <li>
                                <span>Total Planted</span>
                                {/* <h6>2000</h6> */}
                                <h6>{element.planted}</h6>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="Project_Short_dt_footer ">
                          <div>
                            <div className="container ">
                              <b>{element.statusData}</b>
                            </div>
                          </div>
                        </div>
                        {/* <div>
                            <ul>
                              <li>
                                <i
                                  className="fa fa-trash-o handSymbol"
                                  aria-hidden="true"
                                  onClick={toggle}
                                ></i>
                              </li>
                              <li>
                                <i
                                  className="fa fa-pencil handSymbol"
                                  aria-hidden="true"
                                  onClick={() => handleRoute(element.id)}
                                ></i>
                              </li>
                            </ul>
                          </div> */}
                      </div>
                    </div>
                  </div>

                  // <div className="col-lg-4">
                  //   <div
                  //     className="project-card-container"
                  //     onClick={() =>
                  //       redirectToDashboard(element.id, element.projectName)
                  //     }
                  //   >
                  //     <div className="grid">
                  //       <div>
                  //         {element.images.length === 1 ? (
                  //           <img
                  //             src={element.images[0].src}
                  //             alt="project img"
                  //             className="w-100"
                  //           />
                  //         ) : (
                  //           <UncontrolledCarousel
                  //             controls={true}
                  //             indicators={false}
                  //             items={element.images}
                  //           />
                  //         )}
                  //       </div>

                  //       <div className="relative">
                  //         <div className="mt-2 p-2">
                  //           <div className="word-break-title">
                  //             {element.projectName}
                  //           </div>
                  //         </div>
                  //         <div className="date-grid-container">
                  //           <div className="p-2 date-grid mt-4">
                  //             <div>
                  //               <div className="text-secondary fs-13">
                  //                 Start Date
                  //               </div>
                  //               <div className="text-black fs-13 bold">
                  //                 {element.startDate}
                  //               </div>
                  //             </div>

                  //             <div className="text-end">
                  //               <div className="text-secondary fs-13">
                  //                 {" "}
                  //                 End Date{" "}
                  //               </div>
                  //               <div className="text-black fs-13 bold text-end">
                  //                 {element.endDate}
                  //               </div>
                  //             </div>
                  //           </div>
                  //         </div>

                  //         <div className="status">{element.statusData}</div>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                ))}
                <div className="col-md-12 col-sm-12 ">
                  <Pagination
                    page={inActivePagination.pageNumber}
                    pageSize={inActivePagination.pageSize}
                    handlePageChange={handlePageClickForInActiveProjects}
                    totalPage={inActivePagination.totalPages}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane tabId="3">
              <div className="row mt-2 gy-3 gx-3">
                {completedProjects?.map((element: any, index: number) => (
                  <div className="col-lg-4 col-md-6 pro-3-">
                    <div className="ProjectView_box">
                      <div className="Project_img_h">
                        <div>
                          {element.images.length === 1 ? (
                            <img
                              src={element.images[0].src}
                              alt="project img"
                              className="w-100"
                            />
                          ) : (
                            <UncontrolledCarousel
                              controls={true}
                              indicators={false}
                              items={element.images}
                            />
                          )}
                        </div>
                      </div>
                      <div className="Project_Short_Dt">
                        <div className="Project_Short_Dt_inner">
                          <div
                            className="Project_Short_Dt_header pb-3- handSymbol"
                            onClick={() =>
                              redirectToDashboard(
                                element.id,
                                element.projectName
                              )
                            }
                          >
                            {element.projectName}
                          </div>
                          <div className="Project_Dt_date">
                            <ul>
                              <li>
                                <span>Start Date</span>
                                <h6>{element.startDate}</h6>
                              </li>
                              <li>
                                <span>End Date</span>
                                <h6>{element.endDate}</h6>
                              </li>
                            </ul>
                          </div>

                          <div className="Project_Dt_date">
                            <ul>
                              <li>
                                <span>Total Planned</span>
                                {/* <h6>2000</h6> */}
                                <h6>{element.planned}</h6>
                              </li>
                              <li>
                                <span>Total Planted</span>
                                {/* <h6>2000</h6> */}
                                <h6>{element.planted}</h6>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="Project_Short_dt_footer ">
                          {/* <div>
                            <div className="container ">
                              <div className="row  ">
                                <div className="col-8 ">
                                  <b>8-Farmers</b>
                                </div>
                                <div className="col-2 "> */}
                          <b>{element.statusData}</b>
                          {/* </div>
                              </div>
                            </div>
                          </div> */}
                          {/* <div>
                            <ul>
                              <li>
                                <i
                                  className="fa fa-trash-o handSymbol"
                                  aria-hidden="true"
                                  onClick={toggle}
                                ></i>
                              </li>
                              <li>
                                <i
                                  className="fa fa-pencil handSymbol"
                                  aria-hidden="true"
                                  onClick={() => handleRoute(element.id)}
                                ></i>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  // <div className="col-lg-4">
                  //   <div
                  //     className="project-card-container"
                  //     onClick={() =>
                  //       redirectToDashboard(element.id, element.projectName)
                  //     }
                  //   >
                  //     <div className="grid">
                  //       <div>
                  //         {element.images.length === 1 ? (
                  //           <img
                  //             src={element.images[0].src}
                  //             alt="project img"
                  //             className="w-100"
                  //           />
                  //         ) : (
                  //           <UncontrolledCarousel
                  //             controls={true}
                  //             indicators={false}
                  //             items={element.images}
                  //           />
                  //         )}
                  //       </div>

                  //       <div className="relative">
                  //         <div className="mt-2 p-2">
                  //           <div className="word-break-title">
                  //             {element.projectName}
                  //           </div>
                  //         </div>

                  //         <div className="date-grid-container">
                  //           <div className="p-2 date-grid mt-4">
                  //             <div>
                  //               <div className="text-secondary fs-13">
                  //                 Start Date
                  //               </div>
                  //               <div className="text-black fs-13 bold">
                  //                 {element.startDate}
                  //               </div>
                  //             </div>

                  //             <div className="text-end">
                  //               <div className="text-secondary fs-13">
                  //                 {" "}
                  //                 End Date{" "}
                  //               </div>
                  //               <div className="text-black fs-13 bold text-end">
                  //                 {element.endDate}
                  //               </div>
                  //             </div>
                  //           </div>
                  //         </div>

                  //         <div className="status">{element.statusData}</div>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                ))}
                <div className="col-md-12 col-sm-12 ">
                  <Pagination
                    page={completedPagination.pageNumber}
                    pageSize={completedPagination.pageSize}
                    handlePageChange={handlePageClickForCompletedProjects}
                    totalPage={completedPagination.totalPages}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane tabId="4">
              <div className="row mt-2 gy-3 gx-3">
                {totalProjects?.map((element: any, index: number) => (
                  <div className="col-lg-4 col-md-6 pro-3-">
                    <div className="ProjectView_box">
                      <div className="Project_img_h">
                        <div>
                          {element.images.length === 1 ? (
                            <img
                              src={element.images[0].src}
                              alt="project img"
                              className="w-100"
                            />
                          ) : (
                            <UncontrolledCarousel
                              controls={true}
                              indicators={false}
                              items={element.images}
                            />
                          )}
                        </div>
                      </div>
                      <div className="Project_Short_Dt">
                        <div className="Project_Short_Dt_inner">
                          <div
                            className="Project_Short_Dt_header pb-3- handSymbol"
                            onClick={() =>
                              redirectToDashboard(
                                element.id,
                                element.projectName
                              )
                            }
                          >
                            {element.projectName}
                          </div>
                          <div className="Project_Dt_date">
                            <ul>
                              <li>
                                <span>Start Date</span>
                                <h6>{element.startDate}</h6>
                              </li>
                              <li>
                                <span>End Date</span>
                                <h6>{element.endDate}</h6>
                              </li>
                            </ul>
                          </div>

                          <div className="Project_Dt_date">
                            <ul>
                              <li>
                                <span>Total Planned</span>
                                {/* <h6>2000</h6> */}
                                <h6>{element.planned}</h6>
                              </li>
                              <li>
                                <span>Total Planted</span>
                                {/* <h6>2000</h6> */}
                                <h6>{element.planted}</h6>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="Project_Short_dt_footer ">
                          {/* <div>
                            <div className="container ">
                              <div className="row  ">
                                <div className="col-8 ">
                                  <b>8-Farmers</b>
                                </div>
                                <div className="col-2 "> */}
                          <b>{element.statusData}</b>
                          {/* </div>
                              </div>
                            </div>
                          </div> */}
                          {/* <div>
                            <ul>
                              <li>
                                <i
                                  className="fa fa-trash-o handSymbol"
                                  aria-hidden="true"
                                  onClick={toggle}
                                ></i>
                              </li>
                              <li>
                                <i
                                  className="fa fa-pencil handSymbol"
                                  aria-hidden="true"
                                  onClick={() => handleRoute(element.id)}
                                ></i>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  // <div className="col-lg-4">
                  //   <div
                  //     className="project-card-container"
                  //     onClick={() =>
                  //       redirectToDashboard(element.id, element.projectName)
                  //     }
                  //   >
                  //     <div className="grid">
                  //       <div>
                  //         {element.images.length === 1 ? (
                  //           <img
                  //             src={element.images[0].src}
                  //             alt="project img"
                  //             className="w-100"
                  //           />
                  //         ) : (
                  //           <UncontrolledCarousel
                  //             controls={true}
                  //             indicators={false}
                  //             items={element.images}
                  //           />
                  //         )}
                  //       </div>

                  //       <div className="relative">
                  //         <div className="mt-2 p-2">
                  //           <div className="word-break-title">
                  //             {element.projectName}
                  //           </div>
                  //         </div>

                  //         <div className="date-grid-container">
                  //           <div className="p-2 date-grid mt-4">
                  //             <div>
                  //               <div className="text-secondary fs-13">
                  //                 Start Date
                  //               </div>
                  //               <div className="text-black fs-13 bold">
                  //                 {element.startDate}
                  //               </div>
                  //             </div>

                  //             <div className="text-end">
                  //               <div className="text-secondary fs-13">
                  //                 {" "}
                  //                 End Date{" "}
                  //               </div>
                  //               <div className="text-black fs-13 bold text-end">
                  //                 {element.endDate}
                  //               </div>
                  //             </div>
                  //           </div>
                  //         </div>

                  //         <div className="status">{element.statusData}</div>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                ))}
                <div className="col-md-12 col-sm-12 ">
                  <Pagination
                    page={totalPagination.pageNumber}
                    pageSize={totalPagination.pageSize}
                    handlePageChange={handlePageClickForTotalProjects}
                    totalPage={totalPagination.totalPages}
                  />
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </section>
    </main>
  );
}

export default ProjectListDashboard;
