import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { map, Subscription } from "rxjs";
import { showToaster, toasterTypes } from "../..";
import { authenticationService, projectService } from "../../../service";
import {
  projectChangeSubscriber,
  projectListSubscriber,
  parentProjectSubscriber,
} from "../../../utils/subscriber";
import { ChangeProjectRequestModal } from "./interface/change-project-request";
import Nested from "./nested";
import { useLocation } from "react-router-dom";
import { Async } from "../../../utils/pipes/async.pipe";

const ChangeProjectModal = (props: any) => {
  const location = useLocation();
  const [projectOptions, setProjectOptions] = useState([]);
  const [parentProjectOptions, setParentProjectOptions] = useState([]);
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [project, setProject] = useState({});

  useEffect(() => {
    const subscription: Subscription = projectListSubscriber.subscribe(
      (subscriptionRes) => {
        setProjectOptions(subscriptionRes);
        getParentList();
      }
    );

    const parentSubscription: Subscription = parentProjectSubscriber.subscribe(
      (res) => {
        if (res) {
          setProject(res);
        }
      }
    );

    return () => {
      subscription.unsubscribe();
      parentSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/projectListDashboard") {
      setDropDownOptions(parentProjectOptions);
      parentProjectSubscriber.subscribe((parent: any) => {
        if (parent) {
          parentProjectOptions.forEach((list: any) => {
            if (list.value === parent.value) {
              setProject({ label: list.label, value: list.value });
            }
          });
        }
      });
    } else {
      setDropDownOptions(projectOptions);
      projectChangeSubscriber.subscribe((proj: any) => {
        projectOptions.forEach((list: any) => {
          list?.options?.forEach((sub: any) => {
            if (sub.label === proj) {
              parentProjectSubscriber.next({
                label: list.label,
                value: list.value,
              });
              setProject({ label: sub.label, value: sub.value });
            }
          });
        });
      });
    }
  }, [parentProjectOptions, projectOptions, location.pathname]);

  useEffect(() => {
    const data: any = localStorage.getItem("id");

    if (data) {
      authenticationService.getProfileDetails(data).subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            getProjectData(response.data.active_project_id);
            parentProjectSubscriber.next({
              label: response.data.active_parent_project_name,
              value: response.data.active_parent_project_id,
            });
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    }
  }, []);

  useEffect(() => {
    getParentList();
  }, []);

  const getParentList = () => {
    try {
      projectService.getAllParentList().subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            try {
              if (
                Array.isArray(response.content) &&
                response.content?.length > 0
              ) {
                let rowData: any = [];
                response.content.forEach(
                  (parent: { id: string; name: string }) => {
                    rowData.push({ label: parent?.name, value: parent?.id });
                  }
                );

                setParentProjectOptions(rowData);
              }
            } catch (error) {
              console.log(error?.message);
            }
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const getProjectData = (projectId: string) => {
    try {
      projectService.getAllProjectListSelect().subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            try {
              if (
                Array.isArray(response.content) &&
                response.content?.length > 0
              ) {
                let rowData: any = [];
                response.content.forEach((element: any) => {
                  if (element.child_project.length > 0) {
                    let options: any = [];
                    element.child_project.forEach((subElement: any) => {
                      options.push({
                        label: subElement.name,
                        value: subElement.id,
                      });
                    });
                    rowData.push({
                      label: element.name,
                      value: element.id,
                      options,
                    });
                  } else {
                    rowData.push({
                      label: element.name,
                      value: element.id,
                      options: [],
                    });
                  }
                });

                let tempProject: any = null;
                rowData.forEach((obj: any) => {
                  if (obj.value === projectId) {
                    tempProject = obj;
                    return false;
                  } else if (obj.options?.length > 0) {
                    obj.options.forEach((subObj: any) => {
                      if (subObj.value === projectId) {
                        tempProject = subObj;
                        return false;
                      }
                    });
                  }
                });

                /** select default project */
                if (!tempProject) {
                  const subProjects = rowData.filter(
                    (x: any) => x.options.length > 0
                  );
                  const requestData: ChangeProjectRequestModal = {
                    project_id: subProjects[0].options[0].value,
                  };

                  const selectedProject = subProjects[0].options[0];
                  projectService.changeProjectOfUser(requestData).subscribe(
                    (data: any) => {
                      if (data.status && data.statusCode === 200) {
                        setProject(selectedProject);
                        projectChangeSubscriber.next(selectedProject.label);
                        rowData.forEach((parent: any) => {
                          parent?.options?.forEach((sub: any) => {
                            if (sub.value === selectedProject.value) {
                              parentProjectSubscriber.next({
                                label: parent.label,
                                value: parent.value,
                              });
                            }
                          });
                        });
                        // window.location.reload();
                      } else {
                        showToaster(toasterTypes.ERROR, data.message);
                      }
                    },
                    (error: any) => {
                      console.log(error);
                      showToaster(toasterTypes.ERROR, error.message);
                    }
                  );
                }
                setProject(tempProject);
                setProjectOptions(rowData);
                projectChangeSubscriber.next(tempProject.label);
                projectListSubscriber.next(rowData);
              }
            } catch (error) {
              console.log(error?.message);
            }
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleChange = (project: any) => {
    try {
      if (!project) {
        return false;
      }

      if (location.pathname === "/projectListDashboard") {
        projectOptions.forEach((parent: any) => {
          if (parent.value === project.value && parent?.options[0]?.label) {
            projectChangeSubscriber.next(parent.options[0].label);
          }
        });
        parentProjectSubscriber.next(project);

        return;
      }
      const requestData: ChangeProjectRequestModal = {
        project_id: project.value,
      };

      projectService.changeProjectOfUser(requestData).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            // showToaster(toasterTypes.SUCCESS, data.message);
            setProject(project);
            projectChangeSubscriber.next(project.label);
            projectOptions.forEach((parent: any) => {
              parent?.options?.forEach((sub: any) => {
                if (sub.value === project.value) {
                  parentProjectSubscriber.next({
                    label: parent.label,
                    value: parent.value,
                  });
                }
              });
            });
            // window.location.reload();
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const parentLabel$ = useMemo(() => {
    return parentProjectSubscriber.pipe(
      map((x) => {
        if (x) {
          return x.label;
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentProjectSubscriber]);

  return (
    <>
      <Row className="container topChangeProject">
        <Col>
          <div className="d-flex flex-row bd-highlight justify-content-start mb-3">
            <div className="p-2 bd-highlight mt-2">
              <h1>
                <Link to="/" className="linktext">
                  Vruksha{" "}
                  <span style={{ fontSize: "13px" }}>
                    - <Async observable={parentLabel$} />
                  </span>
                </Link>
              </h1>
            </div>
          </div>
        </Col>

        <Col className="sp-project text-end" md={12}>
          {" "}
          Switch Project{" "}
        </Col>
        <Nested
          placeholder="Select Project"
          options={dropDownOptions}
          value={project}
          onChange={handleChange}
        />
      </Row>
    </>
  );
};

export default ChangeProjectModal;
