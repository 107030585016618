import { Component } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import EditRow from "./partials/EditRow";
import { showToaster, toasterTypes } from "../../widgets";
import { projectService } from "../../service/projectService";
import { ParentProjectListProps, ParentProjectListState } from "./interface";
import { UncontrolledTooltip } from "reactstrap";
// import Pagination from "../../widgets/pagination";
// import Tablefeature from "../../widgets/tableFeature";

class ParentProjectList extends Component<
  ParentProjectListProps,
  ParentProjectListState
> {
  constructor(props: ParentProjectListProps) {
    super(props);
    this.state = {
      tableRecords: [],
      statusOptions: [],
      dataTableKey: 0,
      pageSize: 10,
      page: 1,
      gridApi: "",
      context: {
        componentParent: this,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
      modal: false,
    };
  }

  reFreshTable = () => {
    this.setState({ dataTableKey: this.state.dataTableKey + 1 });
  };
  getReactContainerClasses() {
    return ["custom-tooltip"];
  }

  handlePageClick = (
    data: { selected: number },
    pageSize: number = this.state.pagination.pageSize
  ) => {
    this.getAllRowData(data.selected, pageSize);
  };

  handleSearch = (e: any) => {
    this.getAllRowData(1, 10, e.target.value);
  };

  goBack = () => {
    this.props.history.goBack();
  };

  changeStatusHandler = (data: any) => {
    try {
      const payload = {
        id: data.id,
        status: "deleted",
      };
      projectService.parentProjectChangeStatus(payload).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            this.getAllRowData();
            showToaster(toasterTypes.SUCCESS, data.message);
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  getAllRowData = (
    page: number = 1,
    pageSize: number = 10,
    key: string = ""
  ) => {
    try {
      projectService.getAllParentList().subscribe(
        (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              if (response.content && Array.isArray(response.content)) {
                let rowData: any = [];

                response.content.forEach(
                  (parent: { id: string; name: string }) => {
                    rowData.push({ ProjectName: parent?.name, id: parent?.id });
                  }
                );

                this.state.gridApi.setRowData(rowData);
                // this.setState({
                //     pagination: {
                //         pageNumber: response.page || 1,
                //         pageSize: response.size || 10,
                //         totalPages: response.totalPages || 1,
                //         totalElements: response.totalElements || 0,
                //     },
                // });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error?.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  onGridReady = (params: any) => {
    this.getAllRowData();
    this.setState({
      gridApi: params.api,
    });
  };

  render() {
    // const {
    //     state: { pagination },
    //     handlePageClick,
    // } = this;
    return (
      <main id="main">
        <section id="ViewProject" className="ViewProject">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters">
              <div className="col-lg-12 d-flex flex-column justify-content-center">
              <div className="row">
                  <div className="col-md-11 col-sm-11"></div>

                  <div className="col-md-1 col-sm-1">
                    <div
                      onClick={this.goBack}
                      className="text-decoration-none text-dark"
                    >
                      <i className="fa fa-times float-right" id="close"></i>
                    </div>
                    <UncontrolledTooltip target={`close`}>
                      Close
                    </UncontrolledTooltip>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-start mb-4 ">
                  <h3 className="secondary-heading-normal m-0 table-header-label">
                    Parent Project List
                  </h3>
                </div>
                {/* <Tablefeature
                                    totalElements={pagination.totalElements}
                                    handleSearch={this.handleSearch}
                                /> */}
                <div className="grid">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      key={this.state.dataTableKey}
                      domLayout={"autoHeight"}
                      cacheBlockSize={10}
                      context={this.state.context}
                      rowData={this.state.tableRecords}
                      enableCellChangeFlash={true}
                      onGridReady={this.onGridReady}
                      enableBrowserTooltips={true}
                      frameworkComponents={{
                        editColumn: EditRow,
                      }}
                      suppressDragLeaveHidesColumns={true}
                    >
                      <AgGridColumn
                        field="ProjectName"
                        sortable={true}
                        filter={true}
                        flex={3}
                        tooltipField="ProjectName"
                        suppressMovable={true}
                      />
                      <AgGridColumn
                        field="Action"
                        flex={1}
                        suppressMovable={true}
                        cellRenderer="editColumn"
                        cellRendererParams={{
                          reFreshTable: this.state.dataTableKey,
                        }}
                      />
                    </AgGridReact>
                    {/* <div className="col-md-12 mt-2">
                                            <Pagination
                                                page={pagination.pageNumber}
                                                pageSize={pagination.pageSize}
                                                handlePageChange={handlePageClick}
                                                totalPage={pagination.totalPages}
                                            />
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default ParentProjectList;
