import React, { Component, Fragment } from "react";
import { FieldItem, FIELD_TYPES } from "../../../widgets/fields";
import Button from "../../../widgets/button";
import { showToaster, toasterTypes } from "../../../widgets";
import { procurementService, projectService } from "../../../service";
import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { Validation } from "../../../utils";
import { constants } from "../../../constants";
import { CreateProcurementProps, CreateProcurementState } from "./interface";
import { Col, Row } from "reactstrap";
import ConfirmationPopup from "../../../widgets/confirmationPopup";

const acceptedExtensions = [
  ".pdf",
  ".csv",
  ".docx",
  ".jpeg",
  ".png",
  ".jpg",
  "xlsx",
  "xls",
];
// const acceptedExtensions = [".jpeg", ".png", ".jpg"];docx|doc|csv|xls|xlsx
class CreateProcurement extends Component<
  CreateProcurementProps,
  CreateProcurementState
> {
  constructor(props: CreateProcurementProps) {
    super(props);
    this.state = {
      procurementQuantity: "0",
      perSaplingRate: 0,
      manureCost: 0,

      labourCost: 0,
      otherCost: 0,
      remarks: "",
      procurementDate: new Date(),
      procurementType: null,
      // orderDate: "",
      deliveryDate: "",
      orderQuantity: "",
      deliverQuantity: "",
      estimatedAmount: 0,
      paidAmount: "",
      procurementTypeOptions: [
        { value: "seed", label: "Seed" },
        { value: "sapling", label: "Sapling" },
      ],
      isEdit: false,
      supplierName: "",
      attachment: [
        {
          selectedFile: null,
          fileDescription: "",
          isUploaded: true,
          tempUrl: "",
        },
      ],
      attachedFiles: [],
      isDeleteCurrentAttachmentPopupConfirmation: false,
      currentAttachmentDeleteIndex: 0,
      isDeletedAlreadyAttachedpopupConfirmation: false,
      alreadyAttchedDocumentDeleteIndex: 0,
      ispaidAmountGreaterThanEstimatedAmount: false,
    };
  }

  componentDidMount() {
    if (this.props.match.params.edit && this.props.match.params.edit > 0) {
      this.setState({ isEdit: true });
      this.getProcurementDetail();
    }
  }

  getProcurementDetail = () => {
    try {
      procurementService
        .getProcurementDetailById(this.props.match.params.edit)
        .subscribe(
          (response: any) => {
            if (response?.status && response?.statusCode === 200) {
              if (
                response.data &&
                Array.isArray(response.data.procurement_documents)
              ) {
                const selectedType = this.state.procurementTypeOptions?.filter(
                  (data) => data?.value === response?.data?.type
                );
                let procurementData: any = {
                  procurementQuantity: response.data.seed_quantity,
                  remarks: response.data.remarks,
                  perSaplingRate: response.data.per_sapling_rate,
                  manureCost: response.data.manure_cost,
                  labourCost: response.data.labour_cost,
                  otherCost: response.data.other_cost,
                  procurementDate: new Date(response.data.procurement_date),
                  // moment(
                  //   response.data.procurement_date
                  // ).format("YYYY-MM-DD"),
                  procurementType:
                    Array.isArray(selectedType) && selectedType.length > 0
                      ? selectedType[0]
                      : {},
                  // orderDate: new Date(response.data.order_date),
                  // moment(
                  //   response.data.order_date
                  // ).format("YYYY-MM-DD"),
                  orderQuantity: response.data.order_quantity,
                  estimatedAmount: response.data.estimated_amount,
                  paidAmount: response.data.paid_amount,
                  supplierName: response.data.supplier_name,
                };
                if (response?.data?.procurement_documents.length > 0) {
                  // populate image data
                  const fileData = response?.data?.procurement_documents.map(
                    (data: any) => ({
                      id: data.id,
                      fileId: data.file_id,
                      fileDescription: data.file_description,
                    })
                  );
                  procurementData = {
                    ...procurementData,
                    attachedFiles: fileData,
                  };
                }
                this.setState({ ...procurementData });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error.message);
    }
  };

  onSubmit = () => {
    try {
      if (
        Validation.isRequired(
          this.state.procurementQuantity,
          "procurement quantity"
        )
      ) {
        return false;
      }
      if (
        Validation.isValidNumber(
          this.state.procurementQuantity,
          "Procurement Quantity"
        )
      ) {
        return false;
      }
      if (
        Validation.isRequired(
          this.state.procurementDate.toString(),
          "procurement Date"
        )
      ) {
        return false;
      }
      // if (
      //   Validation.isRequired(this.state.orderDate, "order Date")
      // ) {
      //   return false;
      // }
      if (
        Validation.isNotNull(this.state.procurementType, "procurement Type")
      ) {
        return false;
      }
      if (Validation.isTextRequired(this.state.supplierName, "supplier Name")) {
        return false;
      }
      if (
        Validation.isAmountValidation(
          this.state.estimatedAmount,
          "Please enter valid Estimated Amount"
        )
      ) {
        return false;
      }
      if (
        Validation.isAmountValidation(
          this.state.paidAmount,
          "Please enter valid Paid Amount"
        )
      ) {
        return false;
      }

      if (
        this.state.attachment.some(
          (x: any) => !x.fileDescription && x.selectedFile
        )
      ) {
        showToaster(
          toasterTypes.ERROR,
          "Please Enter Description for Selected Image"
        );
        return false;
      }

      if (
        this.state.attachment.some(
          (x: any) => x.fileDescription && !x.selectedFile
        )
      ) {
        showToaster(
          toasterTypes.ERROR,
          "Please Choose File for the Description"
        );
        return false;
      }

      if (+this.state.paidAmount > +this.state.estimatedAmount) {
        this.setState({ ispaidAmountGreaterThanEstimatedAmount: true });
        return false;
      }
      // if (Validation.isValidNumber(this.state.orderQuantity, "Order Quantity")) {
      //   return false;
      // }
      // if (Validation.isValidNumber(this.state.estimatedAmount, "Estimated Amount")) {
      //   return false;
      // }
      // if (Validation.isValidNumber(this.state.paidAmount, "Paid Amount")) {
      //   return false;
      // }
      /**
       * if image is there upload file and save else directly save
       */
      this.uploadDocumentsAndSubmit();
    } catch (error) {
      console.error(error?.message);
    }
  };

  uploadDocumentsAndSubmit = () => {
    if (!this.state.attachment[0].isUploaded) {
      Promise.all(this.uploadImages()).then((uploadFileResponse) => {
        if (
          uploadFileResponse?.length === this.state.attachment?.length ||
          this.state.isEdit
        ) {
          let status = true;
          const error: string[] = [];
          // validate all upload are success
          uploadFileResponse.forEach((response) => {
            if (!(response?.status && response.statusCode === 200)) {
              status = false;
              error.push(response?.message);
            }
          });
          if (status) {
            this.save(uploadFileResponse);
          } else {
            showToaster(toasterTypes.ERROR, error.join(", "));
          }
        } else {
          showToaster(
            toasterTypes.ERROR,
            "Unable to upload images, please try again letter"
          );
        }
      });
    } else {
      this.save();
    }
  };

  //   /**
  //    * save project
  //    * @param uploadImagesResponse
  //    */

  save = (uploadFileResponse: any[] = []) => {
    try {
      const {
        procurementQuantity,
        perSaplingRate,
        manureCost,
        labourCost,
        otherCost,
        procurementDate,
        procurementType,
        supplierName,
        // orderDate,
        estimatedAmount,
        paidAmount,
        remarks,
      } = this.state;

      let requestDocumentFormat = uploadFileResponse?.map(
        (element: any, index: number) => ({
          file_description: this.state.attachment[index].fileDescription,
          file_id: element?.file_id,
        })
      );

      const requestData: any = {
        procurement_date: procurementDate,
        procurement_quantity: procurementQuantity,
        procurement_type: procurementType?.value,
        supplier_name: supplierName,
        per_sapling_rate: perSaplingRate,
        manure_cost: manureCost,
        labour_cost: labourCost,
        other_cost: otherCost,
        // order_date: orderDate,
        // order_quantity: orderQuantity,
        estimated_amount: this.totalAmount(),
        paid_amount: paidAmount,
        remarks: remarks,
        // documents: format
      };

      if (this.props.match.params.edit >= 0) {
        requestData.id = this.props.match.params.edit;
        let requestDocument = this.state.attachedFiles?.map(
          (element: any, index: number) => ({
            id: element?.id,
            file_description: element?.fileDescription,
            file_id: element?.fileId,
          })
        );
        requestData.documents = [...requestDocumentFormat, ...requestDocument];
      } else {
        requestData.documents = [...requestDocumentFormat];
      }
      procurementService.createProcurementRequest(requestData).subscribe(
        (data: any) => {
          if (data?.status && data?.statusCode === 200) {
            showToaster(toasterTypes.SUCCESS, data.message);
            this.props.history.push("/listProcurement");
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  uploadImages = (): Promise<any>[] => {
    const uploadPromise: Promise<any>[] = [];
    this.state.attachment.forEach((file: any) => {
      if (!file.isUploaded) {
        const data: FormData = new FormData();
        data.append("file", file.selectedFile);
        const request = projectService
          .projectImageUploadRequest(data)
          .toPromise();
        uploadPromise.push(request);
      }
    });
    return uploadPromise;
  };

  addRow = () => {
    let row = [
      ...this.state.attachment,
      {
        selectedFile: null,
        fileDescription: "",
        isUploaded: false,
      },
    ];
    this.setState({ attachment: row });
  };
  removeRow = (index: number) => {
    try {
      let row = [...this.state.attachment];

      if (row.length >= 1) {
        row.splice(index, 1);
        this.setState({ attachment: row });
      }
    } catch (error) {
      console.log(error);
    }
  };

  removeAttachment = (index: number) => {
    try {
      let row = [...this.state.attachedFiles];
      if (row.length >= 1) {
        row.splice(index, 1);
        this.setState({ attachedFiles: row });
      }
    } catch (error) {
      console.log(error);
    }
  };
  handleDynamicChange = (event: any, index: number) => {
    try {
      const { name, value } = event.currentTarget;
      const state: any = [...this.state.attachment];
      if (event.currentTarget.name === "selectedFile") {
        try {
          const file = event.target.files[0];

          let isValidFile = false;
          if (!file) {
            // if its not a file skip the iteration
            return;
          } else {
            acceptedExtensions.forEach((extension) => {
              if (file["name"].toLowerCase().includes(extension)) {
                isValidFile = true;
              }
            });
          }
          if (isValidFile) {
            if (file.size && file.size / 1024 / 1024 > 10) {
              showToaster(
                toasterTypes.ERROR,
                "The File you are attempting to upload is larger than the permitted size of 10MB"
              );
              return true;
            }
          } else {
            showToaster(
              toasterTypes.ERROR,
              "File format .jpeg, .png, .jpg only supported"
            );
            return true;
          }

          state[index][name] = event.target.files[0];
          state[index]["isUploaded"] = false;
          var reader = new FileReader();
          var url = reader.readAsDataURL(file);
          const that = this;
          reader.onloadend = function (e: any) {
            state[index]["tempUrl"] = reader.result;
            that.setState(state);
          }.bind(this);

          this.setState(state);
        } catch (error) {
          console.log(error.message);
        }
      } else {
        state[index][name] = value;
        this.setState(state);
      }
    } catch (error) {
      console.log(error?.message);
    }
  };
  handleOnChange = (event: any) => {
    try {
      const { name, value } = event.currentTarget;
      const state: any = { ...this.state };
      state[name] = value;
      this.setState(state);
    } catch (error) {
      console.log(error?.message);
    }
  };
  handleChange = (procurementType: any) => {
    this.setState({ procurementType });
  };
  changeprocurementDate = (procurementDate: any) => {
    this.setState({ procurementDate });
  };

  totalAmount = () => {
    let totalAmount = 0;
    totalAmount =
      this.state.perSaplingRate * parseInt(this.state.procurementQuantity) +
      this.state.manureCost * parseInt(this.state.procurementQuantity) +
      this.state.labourCost * parseInt(this.state.procurementQuantity) +
      this.state.otherCost * parseInt(this.state.procurementQuantity);
    return totalAmount || 0;
  };
  // changeOrderDate = (orderDate: any) => {
  //   this.setState({ orderDate });
  // };
  render() {
    const {
      handleOnChange,
      handleDynamicChange,
      changeprocurementDate,
      state: {
        isEdit,
        procurementQuantity,
        perSaplingRate,
        manureCost,
        labourCost,
        otherCost,
        remarks,
        procurementDate,
        procurementType,
        estimatedAmount,
        paidAmount,
        procurementTypeOptions,
        supplierName,
        attachment,
      },
    } = this;

    return (
      <main id="main">
        <section id="MyProfile" className="MyProfile">
          <div className="container" data-aos="fade-up">
            <div className="row ">
              <div className="col-lg-12 d-flex flex-column justify-content-center ">
                <div className="row ">
                  <div className="col-lg-8 ">
                    <Row>
                      <Col md={11}>
                        <h3 className="heading-secondary">
                          Create/Update a Procurement
                        </h3>
                      </Col>
                      <Col md={1}>
                        <Link
                          to="/listProcurement"
                          className="text-decoration-none text-dark"
                        >
                          <i className="fa fa-times float-right" id="close"></i>
                        </Link>
                        <UncontrolledTooltip target={`close`}>
                          Close
                        </UncontrolledTooltip>
                      </Col>
                    </Row>
                    <div className="row  mb-5">
                      <div className="col-lg-6 col-sm-12">
                        <FieldItem
                          onChange={handleOnChange}
                          label="Procurement Quantity "
                          name="procurementQuantity"
                          value={procurementQuantity}
                          placeholder="Procurement Quantity "
                          type={FIELD_TYPES.TEXT}
                          className="form-control required"
                          required
                        />
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        {/* <FieldItem
                          onChange={handleOnChange}
                          label="Procurement Date "
                          name="procurementDate"
                          value={procurementDate}
                          placeholder="Procurement Date "
                          type={FIELD_TYPES.DATE_RANGE}
                          minDate={moment(new Date()).format("YYYY-MM-DD")}
                          className="form-control required"
                          required
                        /> */}
                        <FieldItem
                          onDateChange={changeprocurementDate}
                          label="Procurement Date "
                          name="procurementDate"
                          value={procurementDate}
                          placeholder="Procurement Date "
                          type={FIELD_TYPES.DATE_PICKER}
                          // minDate={
                          //   isEdit ? new Date(procurementDate) : new Date()
                          // }
                          className="form-control required"
                          required
                        />
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <FieldItem
                          onChange={this.handleChange}
                          name="procurementType"
                          value={procurementType}
                          label="Procurement Type"
                          options={procurementTypeOptions}
                          placeholder="Procurement Type"
                          type={FIELD_TYPES.DROP_DOWN}
                          className="required"
                          required
                        />
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <FieldItem
                          onChange={handleOnChange}
                          label="Supplier Name "
                          name="supplierName"
                          value={supplierName}
                          placeholder="Supplier Name"
                          type={FIELD_TYPES.TEXT}
                          className="form-control required"
                          required
                        />
                      </div>
                      {/* <div className="col-lg-6 col-sm-12">
                        <FieldItem
                          onDateChange={changeOrderDate}
                          label="Order Date "
                          name="orderDate"
                          value={orderDate}
                          placeholder=" Order Date "
                          type={FIELD_TYPES.DATE_PICKER}
                          minDate={isEdit ? new Date(orderDate) : new Date()}
                          className="form-control required"
                          required
                        />

                        <FieldItem
                          onChange={handleOnChange}
                          label="Order Date "
                          name="orderDate"
                          value={orderDate}
                          placeholder=" Order Date "
                          type={FIELD_TYPES.DATE_RANGE}
                          minDate={moment(new Date()).format("YYYY-MM-DD")}
                          className="form-control"

                        />
                      </div> */}
                      <div className="col-lg-6 col-sm-12">
                        <FieldItem
                          onChange={handleOnChange}
                          label="Per Sapling Rate"
                          name="perSaplingRate"
                          value={perSaplingRate}
                          placeholder="Per Sapling Rate "
                          type={FIELD_TYPES.TEXT}
                          className="form-control required"
                          required
                        />
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <FieldItem
                          onChange={handleOnChange}
                          label="Manure Cost (Per Sapling) "
                          name="manureCost"
                          value={manureCost}
                          placeholder="Manure Cost (Per Sapling) "
                          type={FIELD_TYPES.TEXT}
                          className="form-control "
                        />
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <FieldItem
                          onChange={handleOnChange}
                          label="Labour Cost (Per Sapling) "
                          name="labourCost"
                          value={labourCost}
                          placeholder="Labour Cost (Per Sapling) "
                          type={FIELD_TYPES.TEXT}
                          className="form-control "
                        />
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <FieldItem
                          onChange={handleOnChange}
                          label="Other Cost (Per Sapling) "
                          name="otherCost"
                          value={otherCost}
                          placeholder="Other Cost (Per Sapling) "
                          type={FIELD_TYPES.TEXT}
                          className="form-control "
                        />
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <FieldItem
                          onChange={handleOnChange}
                          label="Estimated Amount "
                          name="estimatedAmount"
                          value={this.totalAmount()}
                          placeholder="Estimated Amount "
                          type={FIELD_TYPES.TEXT}
                          className="form-control "
                        />
                      </div>
                      {/* <div className="col-lg-6 col-sm-12">
                        <FieldItem
                          onChange={handleOnChange}
                          label="Paid Amount "
                          name="paidAmount"
                          value={paidAmount}
                          placeholder="Paid Amount "
                          type={FIELD_TYPES.TEXT}
                          className="form-control "
                        />
                      </div> */}
                      <div className="col-lg-6 col-sm-12">
                        <FieldItem
                          onTextAreaChange={handleOnChange}
                          label="Remarks "
                          name="remarks"
                          value={remarks}
                          placeholder="Remarks "
                          type={FIELD_TYPES.TEXT_AREA}
                          className="form-control"
                        />
                      </div>
                      <label className="form-label">
                        <b>Supporting Documents</b>
                      </label>

                      <div className="col-lg-6 ">
                        <div className="form-group ">
                          <label className="form-label">File Description</label>
                        </div>
                      </div>
                      <div className="col-lg-5 ">
                        <div className="form-group ">
                          <label className="form-label">Select File</label>
                        </div>
                      </div>

                      {this.state.attachment.map(
                        (element: any, index: number, arr: any) => (
                          <Fragment key={index}>
                            <div className="col-lg-6">
                              <div className="form-group mb-3">
                                <FieldItem
                                  onChange={(e) =>
                                    handleDynamicChange(e, index)
                                  }
                                  name="fileDescription"
                                  value={attachment[index].fileDescription}
                                  placeholder="File Description "
                                  type={FIELD_TYPES.TEXT}
                                  className="form-control "
                                />
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="form-group mb-3">
                                <input
                                  type="file"
                                  multiple
                                  // accept="image/*"
                                  name="selectedFile"
                                  className="form-control "
                                  onChange={(e) =>
                                    handleDynamicChange(e, index)
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-lg-1 d-flex mb-3 justify-content-between align-items-center p-0">
                              {index === arr.length - 1 ? (
                                <>
                                  {" "}
                                  <i
                                    onClick={() => this.addRow()}
                                    id={`addcomment${index}`}
                                    className="bi bi-plus-circle stroked"
                                  ></i>
                                  <UncontrolledTooltip
                                    target={`addcomment${index}`}
                                  >
                                    Add More
                                  </UncontrolledTooltip>
                                </>
                              ) : (
                                ""
                              )}

                              {index > 0 ? (
                                <>
                                  {" "}
                                  <i
                                    onClick={() =>
                                      this.setState({
                                        isDeleteCurrentAttachmentPopupConfirmation:
                                          !this.state
                                            .isDeleteCurrentAttachmentPopupConfirmation,
                                        currentAttachmentDeleteIndex: index,
                                      })
                                    }
                                    id={`removeImage${index}`}
                                    className="bi bi-trash stroked"
                                  ></i>
                                  <UncontrolledTooltip
                                    target={`removeImage${index}`}
                                  >
                                    Delete
                                  </UncontrolledTooltip>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            <Row className="previewImage">
                              <Col md={12}>
                                <img
                                  className="mh-100"
                                  src={
                                    element.tempUrl
                                      ? element.tempUrl
                                      : "/assets/img/Referred_icon.png"
                                  }
                                />
                              </Col>
                            </Row>
                          </Fragment>
                        )
                      )}

                      {this.state.currentAttachmentDeleteIndex ? (
                        <ConfirmationPopup
                          message={`Are you sure to Delete?`}
                          isOpen={
                            this.state
                              .isDeleteCurrentAttachmentPopupConfirmation
                          }
                          confirmedEvent={() => {
                            this.setState(
                              {
                                isDeleteCurrentAttachmentPopupConfirmation:
                                  !this.state
                                    .isDeleteCurrentAttachmentPopupConfirmation,
                              },
                              () => {
                                this.removeRow(
                                  this.state.currentAttachmentDeleteIndex
                                );
                              }
                            );
                          }}
                          toggle={() => {
                            this.setState({
                              isDeleteCurrentAttachmentPopupConfirmation:
                                !this.state
                                  .isDeleteCurrentAttachmentPopupConfirmation,
                            });
                          }}
                        ></ConfirmationPopup>
                      ) : (
                        ""
                      )}

                      <div className="col-lg-12 col-sm-12 text-right">
                        <Link to="/listProcurement">
                          <Button
                            label="Cancel"
                            className="mr-2 btn btn-outline-secondary"
                          />
                        </Link>
                        <Button
                          onClick={this.onSubmit}
                          className="btn btn-theme-primary"
                          label={isEdit ? "Update" : "Save"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    {this.state.attachedFiles?.length > 0 ? (
                      <label className="form-label">
                        <b>Supporting Documents</b>
                      </label>
                    ) : (
                      ""
                    )}

                    {this.state.attachedFiles?.map(
                      (element: any, index: number) => (
                        <Fragment key={index}>
                          <div className="row">
                            <div className="col-lg-1 ">
                              <label className="form-label">{index + 1}.</label>
                            </div>
                            <div className="col-lg-4 ">
                              <label className="form-label">
                                {element.fileDescription}
                              </label>
                            </div>
                            <div className="col-lg-2 ">
                              {/* <label className="form-label">{element.fileId}</label> */}
                              <a
                                href={`${constants.app.baseURL}/files/download?file_id=${element.fileId}`}
                                id={`UncontrolledTooltipView${index}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="bi bi-eye"></i>
                              </a>

                              <UncontrolledTooltip
                                target={`UncontrolledTooltipView${index}`}
                              >
                                View
                              </UncontrolledTooltip>
                            </div>
                            <div className="col-lg-1 ">
                              <i
                                onClick={() =>
                                  this.setState({
                                    isDeletedAlreadyAttachedpopupConfirmation:
                                      !this.state
                                        .isDeletedAlreadyAttachedpopupConfirmation,
                                    alreadyAttchedDocumentDeleteIndex: index,
                                  })
                                }
                                id={`removeAttachment${index}`}
                                className="bi bi-trash"
                              ></i>
                              <UncontrolledTooltip
                                target={`removeAttachment${index}`}
                              >
                                Delete
                              </UncontrolledTooltip>
                            </div>
                          </div>
                        </Fragment>
                      )
                    )}

                    <ConfirmationPopup
                      message="Are you sure to Delete?"
                      isOpen={
                        this.state.isDeletedAlreadyAttachedpopupConfirmation
                      }
                      confirmedEvent={() => {
                        this.setState(
                          {
                            isDeletedAlreadyAttachedpopupConfirmation:
                              !this.state
                                .isDeletedAlreadyAttachedpopupConfirmation,
                          },
                          () => {
                            this.removeAttachment(
                              this.state.alreadyAttchedDocumentDeleteIndex
                            );
                          }
                        );
                      }}
                      toggle={() => {
                        this.setState({
                          isDeletedAlreadyAttachedpopupConfirmation:
                            !this.state
                              .isDeletedAlreadyAttachedpopupConfirmation,
                        });
                      }}
                    ></ConfirmationPopup>

                    {/* confirmationPopup for Estimated Amount and Validation Amount */}

                    <ConfirmationPopup
                      message={`Paid amount is ₹${
                        parseInt(this.state.paidAmount) - this.totalAmount()
                      }  more than the estimated amount. Are you sure to continue?`}
                      isOpen={this.state.ispaidAmountGreaterThanEstimatedAmount}
                      confirmedEvent={() => {
                        this.setState(
                          {
                            ispaidAmountGreaterThanEstimatedAmount:
                              !this.state
                                .ispaidAmountGreaterThanEstimatedAmount,
                          },
                          () => {
                            this.uploadDocumentsAndSubmit();
                          }
                        );
                      }}
                      toggle={() => {
                        this.setState({
                          ispaidAmountGreaterThanEstimatedAmount:
                            !this.state.ispaidAmountGreaterThanEstimatedAmount,
                        });
                      }}
                    ></ConfirmationPopup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default CreateProcurement;
