import React, { Component } from "react";
import { FieldItem, FIELD_TYPES } from "../../../widgets/fields";
import Button from "../../../widgets/button";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import { constants } from "../../../constants";
import { showToaster, toasterTypes } from "../../../widgets";
import {
  plantService,
  projectService,
  authenticationService,
} from "../../../service";
import moment from "moment";
import { Validation } from "../../../utils";
import { createPlantProps, createPlantState } from "./interface";
import Confirmation from "./partials/confirmation";
import { Col, Row } from "reactstrap";

const acceptedExtensions = [".jpeg", ".png", ".jpg"];
class CreatePlant extends Component<createPlantProps, createPlantState> {
  constructor(props: createPlantProps) {
    super(props);
    this.state = {
      location: "",
      pincode: "",
      plantCount: "",
      pinCodeOptions: [],
      deadCount: "",
      date: new Date(),
      remarks: "",
      status: null,
      selectedFile: [],
      previewImages: [],
      statusOption: [
        { value: "Sapling", label: "Sapling" },
        { value: "Mature", label: "Mature" },
        // { value: "Dead", label: "Dead" },

        // { value: "Seed", label: "Seed" },
        // { value: "Seedling", label: "Seedling" },
        // { value: "Snag", label: "Snag" },
        // { value: "Sprout", label: "Sprout" },
      ],
      statusOptionEdit: [
        // { value: "Sapling", label: "Sapling" },
        // { value: "Mature", label: "Mature" },
        { value: "Dead", label: "Dead" },

        // { value: "Seed", label: "Seed" },
        // { value: "Seedling", label: "Seedling" },
        // { value: "Snag", label: "Snag" },
        // { value: "Sprout", label: "Sprout" },
      ],
      images: [],
      modal: false,
      comment: "",
      selectedImageCommentId: "",
      selectedImageIndex: "",
      isEdit: false,
      mapLocation: "",
      minDate: moment(new Date()).format("YYYY-MM-DD"),
      imageIsDelete: false,
      currentImageIndex: "",
    };
  }

  componentDidMount() {
    this.getPinCodes();
    if (this.props.match.params.edit && this.props.match.params.edit > 0) {
      this.setState({ isEdit: true });
      this.getEditData();
    } else {
      this.initMap();
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.projectId !== this.props.projectId) {
      this.getPinCodes();
    }
  }

  getPinCodes = () => {
    try {
      let rowData: any = [];
      authenticationService.getAllVolunteersPinCodes().subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            if (response.data && Array.isArray(response.data)) {
              response.data.forEach((x: any) => {
                rowData.push({ label: x.name, value: x.pincode_id });
              });

              if (rowData.length === 1 && !this.state.pincode) {
                this.setState({ pinCodeOptions: rowData, pincode: rowData[0] });
              } else {
                this.setState({ pinCodeOptions: rowData });
              }
            }
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
      this.setState({ pinCodeOptions: rowData });
    } catch (error) {
      console.log(error?.message);
    }
  };

  getEditData = () => {
    try {
      const responseData: any = this.props.location.state;
      console.log(responseData);
      // const selectedStatus = this.state.statusOptionEdit?.filter(
      //   (data) => data?.value === responseData.plant_status
      // );
      let editData: any = {
        plant_count: responseData.plant_count,
        dead_count: responseData.dead_count,

        pincode: responseData.pincode_data,
        date: new Date(responseData.date_of_planting),
        remarks: responseData.remarks,
        mapLocation: responseData.geo_location,
        // status:
        //   Array.isArray(selectedStatus) && selectedStatus.length > 0
        //     ? selectedStatus[0]
        //     : {},
      };
      if (responseData?.images.length > 0) {
        // populate image data
        const trees = responseData?.images.map((data: any) => ({
          file: data.image_id,
          isUploaded: true,
          url: "",
          comment: data?.comment,
          geo_location: responseData.geo_location,
        }));
        editData = {
          ...editData,
          selectedFile: trees,
        };
      }
      this.setState({ ...editData }, () => this.initMap());
    } catch (error) {
      console.log(error?.message);
    }
  };

  handleOnChange = (event: any) => {
    try {
      // handle file change
      let pattern = new RegExp(/^[0-9]*$/); // accept only numbers
      const { name, value } = event.currentTarget;
      if (name === "pincode" && !pattern.test(event.currentTarget.value)) {
        return false;
      }

      if (event.currentTarget.name === "selectedFile") {
        const files = [];
        if (event.currentTarget.files && event.currentTarget.files.length > 0) {
          for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i];
            let isValidFile = false;
            if (!file) {
              // if its not a file skip the iteration
              continue;
            } else {
              acceptedExtensions.forEach((extension) => {
                if (file["name"].toLowerCase().includes(extension)) {
                  isValidFile = true;
                }
              });
            }
            if (isValidFile) {
              if (file.size && file.size / 1024 / 1024 > 10) {
                showToaster(
                  toasterTypes.ERROR,
                  "The File you are attempting to upload is larger than the permitted size of 10MB"
                );
                return true;
              }
            } else {
              showToaster(
                toasterTypes.ERROR,
                "File format .jpeg, .png, .jpg only supported"
              );
              return true;
            }
            files.push({
              file: file,
              isUploaded: false,
              url: URL.createObjectURL(file),
              comment: "",
              geo_location: "",
            });
          }
        }
        this.setState({ selectedFile: [...this.state.selectedFile, ...files] });
      } else {
        const { name, value } = event.currentTarget;
        const state: any = { ...this.state };
        state[name] = value;
        this.setState(state);
      }
    } catch (error) {
      console.log(error?.message);
    }
  };
  handlestatusChange = (status: any) => {
    this.setState({ status });
  };

  handlePinCodeChange = (pincode: any) => {
    this.setState({ pincode });
  };

  removeImages = (index: number) => {
    try {
      const selectedFile = [...this.state.selectedFile];
      selectedFile.splice(index, 1);
      this.setState({ selectedFile });
    } catch (error) {
      console.log(error?.message);
    }
  };

  togglePlant = () => {
    this.setState({ imageIsDelete: !this.state.imageIsDelete });
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  openPopup = (index: any) => {
    this.toggle();
    if (this.props.match.params.edit <= 0) {
      this.setState({
        selectedImageCommentId: index,
        comment: "",
      });
    } else {
      this.setState({
        selectedImageCommentId: index,
        comment: this.state.selectedFile[index]?.comment,
      });
    }
  };

  addComment = () => {
    try {
      let selectedFile = [...this.state.selectedFile];
      let index: number = this.state.selectedImageCommentId;
      let isCommentUpdated: boolean = false;
      if (this.state.selectedFile?.length > 0) {
        isCommentUpdated = !!selectedFile[index]["comment"];
        selectedFile[index]["comment"] = this.state.comment;
        this.setState({ selectedFile });
      }

      showToaster(
        toasterTypes.SUCCESS,
        isCommentUpdated
          ? "Your comment has been updated successfully"
          : "Your comment has been added successfully"
      );
      this.toggle();
    } catch (error) {
      console.log(error?.message);
    }
  };

  getUploadedImage = (uploadImageId: number) => {
    try {
      authenticationService.getProfilephoto(uploadImageId).subscribe(
        (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              this.setState({ images: URL.createObjectURL(response) });
              showToaster(toasterTypes.SUCCESS, response.message);
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  onSubmit = () => {
    try {
      if (
        Validation.isRequiredForDropdown(this.state.pincode.value, "pin code")
      ) {
        return false;
      }
      if (
        Validation.isRequired(this.state.date.toString(), "date of planting")
      ) {
        return false;
      }
      if (Validation.isSpecialCharacter(this.state.remarks, "remarks")) {
        return false;
      }
      if (
        this.state.selectedFile.length <= 0
        //&& this.state.imageRequestData?.length <= 0
      ) {
        showToaster(toasterTypes.ERROR, "Please select the Image");
        return false;
      }

      if (this.state.mapLocation.length <= 0) {
        showToaster(toasterTypes.ERROR, "Please select the location");
        return false;
      }

      /**
       * if image is there upload images and save else directly save
       */
      if (this.state.selectedFile?.length > 0) {
        Promise.all(this.uploadImages()).then((uploadImagesResponse) => {
          if (
            uploadImagesResponse?.length === this.state.selectedFile?.length ||
            this.state.isEdit
          ) {
            let status = true;
            const error: string[] = [];
            // validate all upload are success
            uploadImagesResponse?.forEach((response) => {
              if (!(response?.status && response.statusCode === 200)) {
                status = false;
                error.push(response?.message);
              }
            });
            if (status) {
              this.save(uploadImagesResponse);
            } else {
              showToaster(toasterTypes.ERROR, error.join(", "));
            }
          } else {
            showToaster(
              toasterTypes.ERROR,
              "Unable to upload images, please try again letter"
            );
          }
        });
      } else {
        this.save();
      }
    } catch (error) {
      console.error(error?.message);
    }
  };
  uploadImages = (): Promise<any>[] => {
    const uploadPromise: Promise<any>[] = [];
    try {
      this.state.selectedFile?.forEach((file: any) => {
        if (!file.isUploaded) {
          const data: FormData = new FormData();
          data.append("file", file.file);
          const request = projectService
            .projectImageUploadRequest(data)
            .toPromise();
          uploadPromise.push(request);
        }
      });
    } catch (error) {
      console.log(error?.message);
    }
    return uploadPromise;
  };

  /**
   * save project
   * @param uploadImagesResponse
   */
  save = (uploadImagesResponse: any[] = []) => {
    try {
      const {
        pincode,
        date,
        remarks,
        status,
        mapLocation,
        plantCount,
        deadCount,
      } = this.state;

      const requestImageFormat = uploadImagesResponse?.map(
        (element: any, index: number) => ({
          comment: this.state.selectedFile[index]?.comment,
          geo_location: mapLocation,
          image_id: element?.file_id,
        })
      );
      const requestData: any = {
        geo_location: mapLocation,
        date_of_planting: date,
        remarks: remarks,
        pin_code: pincode.value,
        plant_status: status?.value,
        images: requestImageFormat,
        plant_count: plantCount,
        dead_count: deadCount,
      };
      // update images and project id
      if (this.props.match.params.edit >= 0) {
        requestData.id = this.props.match.params.edit;
        if (Array.isArray(this.state.selectedFile)) {
          this.state.selectedFile?.forEach((file: any, index: number) => {
            if (file.isUploaded) {
              requestData.images.push({
                comment: this.state.selectedFile[index]?.comment,
                geo_location: mapLocation,
                image_id: file.file,
              });
            }
          });
        }
      }
      plantService.createPlantRequest(requestData).subscribe(
        (data: any) => {
          if (data?.status && data?.statusCode === 200) {
            showToaster(toasterTypes.SUCCESS, data.message);
            this.props.history.push("/listPlants");
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  initMap = (): void => {
    const that = this;

    let latLong = this.state.mapLocation.toString().split(",");
    let originalMapCenter = new google.maps.LatLng(
      latLong[0] ? latLong[0] : 23.365258275521274,
      latLong[1] ? latLong[1] : 77.64100725390664
    );

    if (!this.state.isEdit) {
      navigator["geolocation"].getCurrentPosition(
        function (position) {
          originalMapCenter = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );
          that.getmap(originalMapCenter, that);
        },
        () => {},
        { enableHighAccuracy: true }
      );
    }
    this.getmap(originalMapCenter, this, 5);
  };

  getmap = (originalMapCenter: any, that: any, zoom: number = 10) => {
    let markers: google.maps.Marker[] = [];
    let map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: zoom,
        center: originalMapCenter,
      }
    );

    if (this.state.isEdit) {
      markers = [];
      const marker = new google.maps.Marker({
        position: originalMapCenter,
        map: map,
      });
      markers.push(marker);
    }
    map.addListener("click", (e) => {
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
      }
      markers = [];
      const marker = new google.maps.Marker({
        position: e.latLng,
        map: map,
      });

      let selectedPostCode = "";
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: e.latLng }, (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
          showToaster(toasterTypes.ERROR, status);
        }
        // This is checking to see if the Geoeode Status is OK before proceeding
        if (status === google.maps.GeocoderStatus.OK) {
          var result = results[0].address_components;

          for (var i = 0; i < result.length; ++i) {
            if (result[i].types[0] === "postal_code") {
              selectedPostCode = result[i].long_name;
            }
          }

          if (!this.state.pincode) {
            const pincode = this.state.pinCodeOptions.find(
              (x) => x.label === selectedPostCode
            );
            this.setState({ pincode });
          }
        }
      });

      markers.push(marker);
      that.setState({
        mapLocation: `${e.latLng.toJSON().lat},${e.latLng.toJSON().lng}`,
      });
    });
  };

  changeDate = (date: any) => {
    this.setState({ date });
  };
  closeBtn = (
    <button className="close" title="Close" onClick={this.toggle}>
      &times;
    </button>
  );
  render() {
    const {
      handleOnChange,
      handlestatusChange,
      onSubmit,
      changeDate,
      handlePinCodeChange,

      state: {
        pincode,
        pinCodeOptions,
        plantCount,
        deadCount,
        date,
        remarks,
        status,
        statusOption,
        statusOptionEdit,
        comment,
        isEdit,
      },
    } = this;

    return (
      <main id="main">
        <section id="MyProfile" className="MyProfile">
          <div className="container" data-aos="fade-up">
            <div className="row ">
              <div className="col-lg-12 d-flex flex-column justify-content-center ">
                <div>
                  <div className="row ">
                    <div className="col-lg-8 ">
                      <Row>
                        <Col md={11}>
                          <h3 className="heading-secondary">
                            Create/Update My Plantings
                          </h3>
                        </Col>
                        <Col md={1}>
                          <Link
                            to="/listPlants"
                            className="text-decoration-none text-dark"
                          >
                            <i
                              className="fa fa-times float-right"
                              id="close"
                            ></i>
                          </Link>
                          <UncontrolledTooltip target={`close`}>
                            Close
                          </UncontrolledTooltip>
                        </Col>
                      </Row>

                      <div className="row  mb-5">
                        <div className="col-lg-6 col-sm-12">
                          {/* <FieldItem
                            onChange={handleOnChange}
                            label="Pin Code"
                            name="pincode"
                            value={pincode}
                            placeholder="Pin code"
                            type={FIELD_TYPES.TEXT}
                            maxLength={6}
                            isNumber={true}
                            required
                            className="form-control required"
                          /> */}

                          <FieldItem
                            onChange={handlePinCodeChange}
                            label="Pin Code"
                            name="pincode"
                            value={pincode}
                            options={pinCodeOptions}
                            placeholder="Pin code"
                            type={FIELD_TYPES.DROP_DOWN}
                            className="required"
                            required
                          />
                        </div>

                        <div className="col-lg-6 col-sm-12">
                          <FieldItem
                            onDateChange={changeDate}
                            label="Date Of Planting"
                            name="date"
                            value={date}
                            placeholder="Date of planting"
                            type={FIELD_TYPES.DATE_PICKER}
                            maxDate={new Date()}
                            // minDate={isEdit ? new Date(date) : new Date()}
                            className="form-control required"
                            required
                          />
                        </div>

                        <div className="col-lg-6 col-sm-12">
                          <FieldItem
                            onChange={handlestatusChange}
                            label="Plant Status"
                            name="selectedStatus"
                            value={status}
                            options={isEdit ? statusOptionEdit : statusOption}
                            placeholder="Plant Status"
                            type={FIELD_TYPES.DROP_DOWN}
                            className="required"
                            required
                          />
                        </div>
                        {status?.value && status?.value !== "Dead" ? (
                          <div className="col-lg-6 col-sm-12">
                            <FieldItem
                              onChange={handleOnChange}
                              // onDateChange={changeDate}
                              label="Plant Count"
                              name="plantCount"
                              value={plantCount}
                              placeholder="Plant Count"
                              type={FIELD_TYPES.TEXT}
                              maxLength={6}
                              isNumber={true}
                              required
                              className="form-control required"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {isEdit && status?.value == "Dead" ? (
                          <div className="col-lg-6 col-sm-12">
                            <FieldItem
                              onChange={handleOnChange}
                              // onDateChange={changeDate}
                              label="Dead Count"
                              name="deadCount"
                              value={deadCount}
                              placeholder="Dead Count"
                              type={FIELD_TYPES.TEXT}
                              maxLength={6}
                              isNumber={true}
                              required
                              className="form-control required"
                            />
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="col-lg-6 col-sm-12">
                          <label className="form-label">Images</label>
                          <label className="specialcharacter-required">*</label>
                          <input
                            type="file"
                            multiple
                            accept="image/*"
                            name="selectedFile"
                            className="form-control required"
                            onChange={handleOnChange}
                          />
                          <label className="form-label">
                            File format .jpeg, .png only supported.
                          </label>
                          <label> Max Size: 1MB</label>
                        </div>
                        <div className="col-lg-12 col-sm-12">
                          <FieldItem
                            onTextAreaChange={handleOnChange}
                            name="remarks"
                            label="Remarks "
                            value={remarks}
                            placeholder="Remarks"
                            type={FIELD_TYPES.TEXT_AREA}
                            className="form-control"
                          />
                        </div>
                        <div className="col-lg-12 col-sm-12 mt-3">
                          <label className="form-label">Location</label>
                          <label className="specialcharacter-required">*</label>

                          <div
                            id="map"
                            style={{ height: 300, width: "50%" }}
                          ></div>
                        </div>
                        <div className="col-lg-12 col-sm-12 text-right">
                          <Link to="/listPlants">
                            <Button
                              label="Cancel"
                              className="mr-2 btn btn-outline-secondary"
                            />
                          </Link>
                          <Button
                            onClick={onSubmit}
                            // onClick={this.save}
                            className="btn btn-theme-primary"
                            // label={"Save"}
                            label={isEdit ? "Update" : "Save"}
                          />
                        </div>
                      </div>
                    </div>
                    <Modal isOpen={this.state.modal} toggle={this.toggle}>
                      <ModalHeader
                        className="modal_Bg"
                        toggle={this.toggle}
                        close={this.closeBtn}
                      >
                        <div className="modal_title"> Add Comment</div>
                      </ModalHeader>
                      <ModalBody>
                        <FieldItem
                          onChange={this.handleOnChange}
                          label="Comment "
                          name="comment"
                          value={comment}
                          placeholder="Comment "
                          type={FIELD_TYPES.TEXT}
                          className="form-control"
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          label="Cancel"
                          className="ml-2 btn btn-outline-secondary"
                          onClick={this.toggle}
                        />
                        <Button
                          label="Save"
                          className="ml-2 btn btn-theme-primary"
                          onClick={this.addComment}
                        />
                      </ModalFooter>
                    </Modal>
                    <div className="col-lg-4 ">
                      <div className="planted_ImgBox">
                        <ul>
                          {this.state.selectedFile?.map(
                            (image: any, index: number) => (
                              <li key={index}>
                                <div className="planted_ImgBox_view">
                                  <img
                                    id={`renderFile${index}`}
                                    src={
                                      image.isUploaded
                                        ? `${constants.app.baseURL}/files/download?file_id=${image.file}`
                                        : image?.url
                                    }
                                    alt="project img"
                                  />
                                  <div>
                                    <button
                                      className="Action_btn comment "
                                      id={`addcomment${index}`}
                                      onClick={() => this.openPopup(index)}
                                    >
                                      <i className="bi  bi-chat-text"></i>
                                    </button>
                                    <button
                                      className="Action_btn"
                                      id={`removeImage${index}`}
                                      onClick={() => {
                                        this.togglePlant();
                                        this.setState({
                                          selectedImageIndex: index,
                                        });
                                      }}
                                    >
                                      <i className="bi bi-trash"></i>
                                    </button>
                                    <UncontrolledTooltip
                                      target={`removeImage${index}`}
                                    >
                                      Delete
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip
                                      target={`addcomment${index}`}
                                    >
                                      Add Comment
                                    </UncontrolledTooltip>
                                  </div>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                        <Confirmation
                          toggle={() => this.togglePlant()}
                          isOpen={this.state.imageIsDelete}
                          removeImages={() => {
                            this.removeImages(this.state.selectedImageIndex);
                            this.togglePlant();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default CreatePlant;
