import React, { useState, useEffect, useMemo } from "react";
import { Link, RouteComponentProps, useLocation } from "react-router-dom";
import { UserHelper } from "../../../utils";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
  Col,
} from "reactstrap";
import { constants, userRoles } from "../../../constants";
import { ChangePasswordPopup } from "../../../components/forgetPassword/changePassword";
import {
  logoutSubscriber,
  parentProjectSubscriber,
  projectChangeSubscriber,
} from "../../../utils/subscriber";
// import ChangeProjectModal from "./change-project";
import { Async } from "../../../utils/pipes/async.pipe";
import { projectService } from "../../../service/projectService";
import { showToaster, toasterTypes } from "../../toaster";
import { map } from "rxjs";
interface navigationBarProps extends RouteComponentProps {
  links?: navLink[];
  username?: string;
  isLogged?: boolean;
}

interface navLink {
  target: string;
  name: string;
  role: any[];
}
const defaultProps = {
  history: undefined,
  location: undefined,
  match: undefined,
  username: "",
  isLogged: false,
  links: [
    {
      target: "",
      name: "ABOUT US",
      role: [],
    },
    {
      target: "",
      name: " CONTACT US",
      role: [],
    },
  ],
};
const NavigationBar: React.FunctionComponent<navigationBarProps> = (props) => {
  const location = useLocation();
  const logout = () => {
    localStorage.clear();
    props.history.push("/login");
    window.location.reload();
  };
  useEffect(() => {
    getAllTotalprojects();
    logoutSubscriber.subscribe({
      error: () => logout(),
      next: (value) => {
        if (value === true) {
          logout();
        }
      },
      complete: () => logout(),
    });
  }, []);
  const [modal, setModal] = useState<boolean>(false);
  const [totalProjects, settotalProjects] = useState("");
  // const [changeProjectModal, setChangeProjectModal] = useState<boolean>(false);
  const toggle = () => setModal(!modal);
  // const toggleChangeProject = () => {
  //   setChangeProjectModal(!changeProjectModal);
  // };
  const getAllTotalprojects = (
    page: number = 1,
    pageSize: number = 10,
    key: string = ""
  ) => {
    try {
      projectService
        .getAllProjectList(page, pageSize, key, projectService.BOTHSTATUS)
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (response.content && Array.isArray(response.content)) {
                  settotalProjects(response.parent);
                }
              } else {
                settotalProjects("");

                // showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error?.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const responsiveMenu = () => {
    let navbarToggle = select(".mobile-nav-toggle");
    navbarToggle.classList.toggle("bi-list");
    navbarToggle.classList.toggle("bi-x");
    select("#navbar").classList.toggle("navbar-mobile");
  };

  const closeMobileMenuNav = () => {
    if (select("#navbar").classList.contains("navbar-mobile")) {
      let navbarToggle = select(".mobile-nav-toggle");
      navbarToggle.classList.toggle("bi-list");
      navbarToggle.classList.toggle("bi-x");
      select("#navbar").classList.remove("navbar-mobile");
    }
  };

  const select = (el: any, all = false) => {
    el = el.trim();
    return document.querySelector(el);
  };

  const parentLabel$ = useMemo(() => {
    return parentProjectSubscriber.pipe(
      map((x) => {
        if (x) {
          return x.label;
        }
      })
    );
  }, [parentProjectSubscriber, location.pathname]);

  return (
    <header id="header" className="d-flex align-items-center">
      <div className="container d-flex align-items-center">
        <div className="logo me-auto">
          {/* <h1>
            <Link to="/" className="linktext">
              Vruksha
          <img
                src={`${process.env.PUBLIC_URL}/assets/img/Vruksha_logo.png`}
                alt="logo"
              />
          </Link>
          </h1> */}

          <div
            className="h6 mt-3 ml-2 nav-projec-title"
            style={{ whiteSpace: "nowrap" }}
          >
            {/* <b>Project Signature</b> */}
            {location.pathname === "/projectListDashboard" ? (
              <Async observable={parentLabel$} />
            ) : (
              <Async observable={projectChangeSubscriber} />
            )}
            {/* <div
              className="h6 ml-2 nav-projec-sub"
              style={{ whiteSpace: "nowrap", overflow: "hidden" }}
            > */}
            {/* <Async observable={projectChangeSubscriber} /> */}
            {/* </div> */}
            {/* {totalProjects} */}
            {/* {parentName} */}
          </div>
        </div>

        <nav id="navbar" className="navbar">
          <ul>
            <li className="ml-2 c-hover d-flex align-items-center">
              <Link to="/projectListDashboard">
                <i className="fa fa-home fs-18"></i>
              </Link>
            </li>
            {props.links?.map((link: navLink, index: number) =>
              UserHelper.hasRole(link.role) ? (
                <li key={index}>
                  <Link
                    className={`nav-link scrollto ${
                      props.history.location.pathname == link.target
                        ? "active"
                        : ""
                    }`}
                    to={link.target}
                    onClick={() => {
                      closeMobileMenuNav();
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    {link.name}
                  </Link>
                </li>
              ) : (
                ""
              )
            )}
            {props.isLogged ? (
              <li className="ml-2 c-hover d-flex align-items-center">
                &nbsp;
                <UncontrolledDropdown direction="left" size="sm">
                  <DropdownToggle
                    tag="i"
                    className="bi bi-person-circle c-hove d-flex user-menu-padding  "
                  >
                    <span className="mobile-user-name ml-2 px-2 ">
                      {/* {" "}
                      {props.username}{" "} */}
                    </span>
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem>
                      <div className="link-user-options user-dropdown desktop-user name__overflow">
                        {props.username}
                      </div>
                    </DropdownItem>

                    {/* <DropdownItem>
                      <div
                        className="link-user-options"
                        onClick={toggleChangeProject}
                      >
                        Switch Project
                      </div>
                    </DropdownItem> */}
                    <DropdownItem divider />
                    <DropdownItem onClick={closeMobileMenuNav}>
                      <Link to="/profile" className="link-user-options">
                        Profile
                      </Link>
                    </DropdownItem>

                    {UserHelper.hasRole([userRoles.SUPER_ADMIN]) && (
                      <DropdownItem onClick={closeMobileMenuNav}>
                        <Link
                          to="/category-settings"
                          className="link-user-options "
                        >
                          Change Category Settings
                        </Link>
                      </DropdownItem>
                    )}

                    {UserHelper.hasRole([userRoles.VOLUNTEER]) ? (
                      <>
                        <DropdownItem onClick={closeMobileMenuNav}>
                          <Link
                            to="/inviteFriends"
                            className="link-user-options "
                          >
                            Invite Friends
                          </Link>
                        </DropdownItem>
                        <DropdownItem onClick={closeMobileMenuNav}>
                          <Link to="/photoView" className="link-user-options">
                            My Plants
                          </Link>
                        </DropdownItem>
                        <DropdownItem divider />
                      </>
                    ) : (
                      ""
                    )}
                    <DropdownItem>
                      <NavLink className="link-user-options" onClick={toggle}>
                        Change Password
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink className="link-user-options" onClick={logout}>
                        Logout
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown direction="left" size="sm">
                  <DropdownToggle
                    tag="i"
                    className=" c-hove d-flex user-menu-padding  "
                  >
                    {/* <span className="mobile-user-name ml-2 px-2 ">
                      {" "}
                      {props.username}{" "}
                    </span> */}
                    <div
                      className="link-user-options  px-2"
                      style={{ textTransform: "uppercase" }}
                    >
                      {props.username}
                    </div>
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem>
                      <div className="link-user-options user-dropdown desktop-user name__overflow">
                        {props.username}
                      </div>
                    </DropdownItem>
                    {/* <DropdownItem>
                      <div
                        className="link-user-options"
                        onClick={toggleChangeProject}
                      >
                        Switch Project
                      </div>
                    </DropdownItem> */}
                    <DropdownItem divider />
                    <DropdownItem onClick={closeMobileMenuNav}>
                      <Link to="/profile" className="link-user-options">
                        Profile
                      </Link>
                    </DropdownItem>
                    {UserHelper.hasRole([userRoles.SUPER_ADMIN]) && (
                      <DropdownItem onClick={closeMobileMenuNav}>
                        <Link
                          to="/category-settings"
                          className="link-user-options "
                        >
                          Category Settings
                        </Link>
                      </DropdownItem>
                    )}

                    {UserHelper.hasRole([userRoles.VOLUNTEER]) ? (
                      <>
                        <DropdownItem onClick={closeMobileMenuNav}>
                          <Link
                            to="/inviteFriends"
                            className="link-user-options "
                          >
                            Invite Friends
                          </Link>
                        </DropdownItem>
                        <DropdownItem onClick={closeMobileMenuNav}>
                          <Link to="/photoView" className="link-user-options">
                            My Plants
                          </Link>
                        </DropdownItem>
                        <DropdownItem divider />
                      </>
                    ) : (
                      ""
                    )}
                    <DropdownItem>
                      <NavLink className="link-user-options" onClick={toggle}>
                        Change Password
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink className="link-user-options" onClick={logout}>
                        Logout
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            ) : (
              <li>
                <Link
                  to="/login"
                  className="nav-link scrollto active"
                  style={{ textTransform: "uppercase" }}
                >
                  Login
                </Link>
              </li>
            )}
          </ul>
          <i
            className="bi bi-list mobile-nav-toggle"
            onClick={responsiveMenu}
          ></i>
        </nav>
      </div>
      <ChangePasswordPopup modal={modal} toggle={toggle} />
      {/* <ChangeProjectModal
        modal={changeProjectModal}
        toggle={() => {
          setChangeProjectModal(!changeProjectModal);
        }}
        onSavedEvent={() => {}}
      ></ChangeProjectModal> */}
    </header>
  );
};
NavigationBar.defaultProps = defaultProps;

export { NavigationBar };
