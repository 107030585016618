import { Component } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import Pagination from "../../../widgets/pagination";
import Tablefeature from "../../../widgets/tableFeature";
import { FarmerListProps, FarmerListState } from "../interface";
import FarmerActionColumn from "../partials/farmer-action";
import { authenticationService } from "../../../service";
import { showToaster, toasterTypes } from "../../../widgets";
import { UncontrolledTooltip } from "reactstrap";

class FarmerList extends Component<FarmerListProps, FarmerListState> {
  constructor(props: FarmerListProps) {
    super(props);
    this.state = {
      tableRecords: [],
      modal: false,
      dataTableKey: 0,
      gridApi: "",
      context: {
        componentParent: this,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
    };
  }

  componentDidMount() {
    this.getRowData();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.projectId !== this.props.projectId) {
      this.getRowData();
    }
  }

  handlePageClick = (
    data: { selected: number },
    pageSize: number = this.state.pagination.pageSize
  ) => {
    this.getRowData(data.selected, pageSize);
  };

  handleSearch = (e: any) => {
    this.getRowData(1, 10, e.target.value);
  };

  getRowData = (page: number = 1, pageSize: number = 10, key: string = "") => {
    try {
      let rowData: any = [];
      const stateId = this.props.match.params.stateId || "";
      const districtId = this.props.match.params.districtId || "";
      const pinCodeId = this.props.match.params.pincodeId || "";
      if (this.props.projectId) {
        authenticationService
          .getAllVolunteerDetailsForStatistics(
            this.props.projectId,
            stateId,
            districtId,
            pinCodeId,
            page,
            pageSize,
            key
          )
          .subscribe(
            (response: any) => {
              if (response.status && response.statusCode === 200) {
                if (response.data && Array.isArray(response.data)) {
                  rowData = response.data.map((element: any) => ({
                    id: element.user_id,
                    FirstName: element.first_name,
                    LastName: element.last_name,
                    MobileNumber: element.mobile_no_1,
                    Email: element.email_id,
                    Address: element.address,
                    Pincode: element.pincodes_name,
                    District: element.district_name,
                    State: element.state_name,
                    Status: element.status,
                    roleId: element.user_role_id,
                    selectedProject: element?.projects,
                    plannedCount: element?.user_planning,
                    plantedCount: element?.plant_count,
                    liveCount: +element?.plant_count - +element?.dead_count,
                    deadCount: element?.dead_count,
                  }));
                  this.setState({ tableRecords: rowData });
                  this.setState({
                    pagination: {
                      pageNumber: response.page || 1,
                      pageSize: response.size || 10,
                      totalPages: response.totalPages || 1,
                      totalElements: response.totalElements || 10,
                    },
                  });
                }
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            },
            (error: any) => {
              console.log(error);
              showToaster(toasterTypes.ERROR, error.message);
            }
          );
        this.setState({ tableRecords: rowData });
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  onGridReady = (params: any) => {
    this.setState({
      gridApi: params.api,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      handlePageClick,
      state: { pagination },
    } = this;

    return (
      <main id="main">
        <section id="ViewProject" className="ViewProject">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters">
              <div className="col-lg-12 d-flex flex-column justify-content-center">
                <div className="row">
                  <div className="col-md-11 col-sm-11"></div>

                  <div className="col-md-1 col-sm-1">
                    <div
                      onClick={this.goBack}
                      className="text-decoration-none text-dark"
                    >
                      <i className="fa fa-times float-right" id="close"></i>
                    </div>
                    <UncontrolledTooltip target={`close`}>
                      Close
                    </UncontrolledTooltip>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-start mb-4 ">
                  <h3 className="secondary-heading-normal m-0 table-header-label">
                    View Farmers List
                  </h3>
                </div>

                <Tablefeature
                  totalElements={pagination.totalElements}
                  handleSearch={this.handleSearch}
                />
                <div className="ag-theme-alpine">
                  <AgGridReact
                    domLayout="autoHeight"
                    context={this.state.context}
                    onGridReady={this.onGridReady}
                    rowData={this.state.tableRecords}
                    enableBrowserTooltips={true}
                    defaultColDef={{
                      resizable: true,
                    }}
                    frameworkComponents={{
                      farmerActionColumn: FarmerActionColumn,
                    }}
                    suppressDragLeaveHidesColumns={true}
                  >
                    <AgGridColumn
                      field="FirstName"
                      sortable={true}
                      filter={true}
                      tooltipField="FirstName"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="LastName"
                      sortable={true}
                      filter={true}
                      tooltipField="LastName"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="MobileNumber"
                      sortable={true}
                      filter={true}
                      tooltipField="MobileNumber"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="plannedCount"
                      sortable={true}
                      filter={true}
                      tooltipField="Planned Count"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="plantedCount"
                      sortable={true}
                      filter={true}
                      tooltipField="Planted Count"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="liveCount"
                      sortable={true}
                      filter={true}
                      tooltipField="Live Count"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="deadCount"
                      headerName="Dead Count"
                      sortable={true}
                      filter={true}
                      tooltipField="Dead Count"
                      suppressMovable={true}
                    />
                    <AgGridColumn
                      field="Action"
                      width={200}
                      cellRenderer="farmerActionColumn"
                      suppressMovable={true}
                    />
                  </AgGridReact>
                </div>
                <div className="col-md-12 mt-2">
                  <Pagination
                    page={pagination.pageNumber}
                    pageSize={pagination.pageSize}
                    handlePageChange={handlePageClick}
                    totalPage={pagination.totalPages}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default FarmerList;
