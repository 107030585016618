import React from "react";
import { Link } from "react-router-dom";
import { constants } from "../../../../../constants";
import Button from "../../../../../widgets/button";

interface RecentPlantingsProps {
  selectedFile: any;
  userId: string;
}
const RecentPlantings: React.FunctionComponent<RecentPlantingsProps> = (
  props
) => {
  return (
    <div className="recentPlantings">
      <div className="d-flex align-items-center justify-content-between w-100">
        <h6 className="m-0">Recent Plantings</h6>
        {/* <Link to="/createPlant">
          <Button
            label="Create New"
            className="btn-sm ml-2 btn btn-outline-secondary"
          />
        </Link> */}
        <div className=" text-right mr-3">
          <Link
            to={`/photoView/${props.userId || ""}`}
            className=" create-link text-theme p2-2 "
          >
            View All Plantings
          </Link>
        </div>
      </div>
      <div className="row">
        {props.selectedFile.slice(0, 15).map((tree: any, index: number) => (
          <div className="col-md-3" key={index}>
            <div className="plantView">
              {Array.isArray(tree.dsplayimage) &&
              tree.dsplayimage?.length > 0 ? (
                <img
                  src={`${constants.app.baseURL}/files/download?file_id=${tree.dsplayimage[0].id}`}
                  alt="plant"
                />
              ) : (
                ""
              )}
            </div>
            <div className="plantView-footer d-flex flex-column  justify-content-end  p-2 pb-1">
              {/* <h6>{tree.name}</h6> */}
              <div className="row">
                <div className="col-sm-5">
                  <p className="m-0 pindate">Planted Date</p>
                </div>
                <div className="col-sm-7 text-right">
                  <p className="m-0 pindate">{tree.plantedDate}</p>
                </div>
              </div>
            </div>
            <div className="plantView-footer d-flex flex-column  justify-content-end  p-2 pb-1">
              {/* <h6>{tree.name}</h6> */}
              <div className="row">
                <div className="col-sm-5">
                  <p className="m-0 pindate">Total</p>
                </div>
                <div className="col-sm-7 text-right">
                  <p className="m-0 pindate">{tree.totalPlantsCount}</p>
                </div>
              </div>
            </div>
            <div className="plantView-footer d-flex flex-column  justify-content-end  p-2 pb-1">
              {/* <h6>{tree.name}</h6> */}
              <div className="row">
                <div className="col-sm-5">
                  <p className="m-0 pindate">Live</p>
                </div>
                <div className="col-sm-7 text-right">
                  <p className="m-0 pindate">{tree.totalLiveCount}</p>
                </div>
              </div>
            </div>
            <div className="plantView-footer d-flex flex-column  justify-content-end  p-2 pb-1">
              {/* <h6>{tree.name}</h6> */}
              <div className="row">
                <div className="col-sm-5">
                  <p className="m-0 pindate">Dead</p>
                </div>
                <div className="col-sm-7 text-right">
                  <p className="m-0 pindate">{tree.totalDeadCount}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentPlantings;
