import React, { Component, Fragment } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import Button from "../../../widgets/button";
import { FieldItem, FIELD_TYPES } from "../../../widgets/fields";

import { addDeliveryPopupProps, addDeliveryPopupState } from "./interface";
import { showToaster, toasterTypes } from "../../../widgets";
import { Validation } from "../../../utils";
import { procurementService, projectService } from "../../../service";
import { constants } from "../../../constants";

// const acceptedExtensions = [".pdf", ".csv", ".docx"];
const acceptedExtensions = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "pdf",
  "docx",
  "doc",
  "csv",
  "xls",
  "xlsx",
];

export class AddDeliveryPopup extends Component<
  addDeliveryPopupProps,
  addDeliveryPopupState
> {
  constructor(props: addDeliveryPopupProps) {
    super(props);
    this.state = {
      attachment: [
        {
          selectedFile: null,
          fileDescription: "",
          isUploaded: true,
        },
      ],
      remarks: "",
      deliveryDate: "",
      deliveryQuantity: "",
      isEdit: false,
      attachedFiles: [],
    };
  }

  resetForm = () => {
    this.setState({
      attachment: [
        {
          selectedFile: null,
          fileDescription: "",
          isUploaded: true,
        },
      ],
      remarks: "",
      deliveryDate: "",
      deliveryQuantity: "",
    });
  };
  toggle = () => {
    this.resetForm();
    this.props.toggle();
  };
  getDeliveryDetail = () => {
    try {
      procurementService
        .getProcurementDeliveryDetailById(this.props.deliveryId)
        .subscribe(
          (response: any) => {
            if (response?.status && response?.statusCode === 200) {
              if (
                response.data &&
                Array.isArray(response.data.procurement_delivery_documents)
              ) {
                let deliveryData: any = {
                  remarks: response.data.remarks,
                  deliveryDate: new Date(response.data.delivered_date),
                  //  moment(
                  //   response.data.delivered_date
                  // ).format(),
                  // deliveryDate: moment(
                  //   response.data.delivered_date
                  // ).format("YYYY-MM-DD"),
                  deliveryQuantity: response.data.delivered_quantity,
                  isEdit: true,
                };
                if (response?.data?.procurement_delivery_documents.length > 0) {
                  // populate image data
                  const fileData =
                    response?.data?.procurement_delivery_documents.map(
                      (data: any) => ({
                        id: data.id,
                        fileId: data.file_id,
                        fileDescription: data.file_description,
                      })
                    );
                  deliveryData = {
                    ...deliveryData,
                    attachedFiles: fileData,
                  };
                }
                this.setState({ ...deliveryData });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error.message);
    }
  };
  handleOnChange = (event: any) => {
    try {
      const { name, value } = event.currentTarget;
      const state: any = { ...this.state };
      state[name] = value;
      this.setState(state);
    } catch (error) {
      console.log(error?.message);
    }
  };
  handleDynamicChange = (event: any, index: number) => {
    try {
      const { name, value } = event.currentTarget;
      const state: any = [...this.state.attachment];
      if (event.currentTarget.name === "selectedFile") {
        try {
          const file = event.target.files[0];
          let isValidFile = false;
          if (!file) {
            // if its not a file skip the iteration
            return;
          } else {
            acceptedExtensions.forEach((extension) => {
              if (file["name"].toLowerCase().includes(extension)) {
                isValidFile = true;
              }
            });
          }
          if (isValidFile) {
            if (file.size && file.size / 1024 / 1024 > 10) {
              showToaster(
                toasterTypes.ERROR,
                "The File you are attempting to upload is larger than the permitted size of 10MB"
              );
              return true;
            }
          } else {
            showToaster(
              toasterTypes.ERROR,
              "Unsupported file format. Supported file formats are - jpg, jpeg, png, gif, bmp, pdf, docx, doc, csv, xls, xlsx"
            );
            return true;
          }
          state[index][name] = event.target.files[0];
          state[index]["isUploaded"] = false;
          this.setState(state);
        } catch (error) {
          console.log(error.message);
        }
      } else {
        state[index][name] = value;
        this.setState(state);
      }
    } catch (error) {
      console.log(error?.message);
    }
  };
  addRow = () => {
    let row = [
      ...this.state.attachment,
      {
        materialId: null,
        fileDescription: "",
        isUploaded: false,
      },
    ];
    this.setState({ attachment: row });
  };
  removeRow = (index: number) => {
    try {
      let row = [...this.state.attachment];
      if (row.length > 1) {
        row.splice(index, 1);
        this.setState({ attachment: row });
      }
    } catch (error) {
      console.log(error);
    }
  };
  onSubmit = () => {
    try {
      if (Validation.isRequired(this.state.deliveryDate, "delivery date")) {
        return false;
      }
      if (
        Validation.isRequired(this.state.deliveryQuantity, "delivery quantity")
      ) {
        return false;
      }
      if (
        Validation.isValidNumber(
          this.state.deliveryQuantity,
          "Delivery quantity"
        )
      ) {
        return false;
      }

      if (
        +this.props.totalQuantity.totalProcured -
          +this.props.totalQuantity.totalReceived <
          +this.state.deliveryQuantity &&
        !this.props.isEdit
      ) {
        showToaster(
          toasterTypes.ERROR,
          `Delivery limit exceeded by ${
            +this.state.deliveryQuantity -
            (+this.props.totalQuantity.totalProcured -
              +this.props.totalQuantity.totalReceived)
          } quantity`
        );
        return false;
      }

      if (
        +this.props.totalQuantity.totalProcured <
          +this.props.totalQuantity.totalReceived -
            +this.props.totalQuantity.currentDeliveredValue +
            +this.state.deliveryQuantity &&
        this.props.isEdit
      ) {
        showToaster(
          toasterTypes.ERROR,
          `Delivery limit exceeded by ${
            +this.props.totalQuantity.totalReceived -
            +this.props.totalQuantity.currentDeliveredValue +
            +this.state.deliveryQuantity -
            +this.props.totalQuantity.totalProcured
          } quantity`
        );
        return false;
      }

      if (
        this.state.attachment.some(
          (x: any) => !x.fileDescription && x.selectedFile
        )
      ) {
        showToaster(
          toasterTypes.ERROR,
          "Please Enter Description for Selected File"
        );
        return false;
      }

      if (
        this.state.attachment.some(
          (x: any) => x.fileDescription && !x.selectedFile
        )
      ) {
        showToaster(
          toasterTypes.ERROR,
          "Please Choose File for the Description"
        );
        return false;
      }

      /**
       * if image is there upload file and save else directly save
       */
      if (!this.state.attachment[0].isUploaded) {
        Promise.all(this.uploadImages()).then((uploadFileResponse) => {
          if (
            uploadFileResponse?.length === this.state.attachment?.length ||
            this.state.isEdit
          ) {
            let status = true;
            const error: string[] = [];
            // validate all upload are success
            uploadFileResponse.forEach((response) => {
              if (!(response?.status && response.statusCode === 200)) {
                status = false;
                error.push(response?.message);
              }
            });
            if (status) {
              this.save(uploadFileResponse);
            } else {
              showToaster(toasterTypes.ERROR, error.join(", "));
            }
          } else {
            showToaster(
              toasterTypes.ERROR,
              "Unable to upload images, please try again letter"
            );
          }
        });
      } else {
        this.save();
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  //   /**
  //    * save project
  //    * @param uploadImagesResponse
  //    */

  save = (uploadFileResponse: any[] = []) => {
    try {
      const { remarks, deliveryDate, deliveryQuantity } = this.state;

      let requestDocumentFormat = uploadFileResponse?.map(
        (element: any, index: number) => ({
          file_description: this.state.attachment[index].fileDescription,
          file_id: element?.file_id,
        })
      );

      const requestData: any = {
        procurement_id: this.props.procurementId,
        delivered_date: deliveryDate,
        delivered_quantity: deliveryQuantity,
        remarks: remarks,
        // documents: requestDocumentFormat
      };

      if (this.props.isEdit) {
        requestData.id = this.props.deliveryId;
        let requestDocument = this.state.attachedFiles?.map(
          (element: any, index: number) => ({
            id: element?.id,
            file_description: element?.fileDescription,
            file_id: element?.fileId,
          })
        );
        requestData.documents = [...requestDocumentFormat, ...requestDocument];
      } else {
        requestData.documents = [...requestDocumentFormat];
      }
      procurementService.createDeliveryRequest(requestData).subscribe(
        (data: any) => {
          if (data?.status && data?.statusCode === 200) {
            showToaster(toasterTypes.SUCCESS, data.message);
            this.toggle();
            this.props.refresh();
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  //   removeImages = (index: number) => {
  //     try {
  //       const selectedFile = [...this.state.selectedFile];
  //       selectedFile.splice(index, 1);
  //       this.setState({ selectedFile });
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };

  uploadImages = (): Promise<any>[] => {
    const uploadPromise: Promise<any>[] = [];
    this.state.attachment.forEach((file: any) => {
      if (!file.isUploaded) {
        const data: FormData = new FormData();
        data.append("file", file.selectedFile);
        const request = projectService
          .projectImageUploadRequest(data)
          .toPromise();
        uploadPromise.push(request);
      }
    });
    return uploadPromise;
  };

  removeAttachment = (index: number) => {
    try {
      let row = [...this.state.attachedFiles];
      if (row.length > 1) {
        row.splice(index, 1);
        this.setState({ attachedFiles: row });
      }
    } catch (error) {
      console.log(error);
    }
  };
  changeDate = (deliveryDate: any) => {
    this.setState({ deliveryDate });
  };
  closeBtn = (
    <button className="close" title="Close" onClick={this.toggle}>
      &times;
    </button>
  );

  render() {
    const {
      handleOnChange,
      changeDate,
      // onSubmit,
      handleDynamicChange,
      state: {
        remarks,
        deliveryDate,
        deliveryQuantity,
        attachment,

        // fileDescription
      },
      props: { isEdit },
    } = this;
    return (
      <>
        <Modal isOpen={this.props.modal} toggle={this.toggle} size={"lg"}>
          <ModalHeader
            className="modal_Bg"
            close={this.closeBtn}
            toggle={this.toggle}
          >
            <div className="modal_title">
              {isEdit ? "Update" : "Add"} Delivery
            </div>
          </ModalHeader>

          <ModalBody>
            <>
              <div className="row  mb-5">
                <div className="col-lg-6 col-sm-12">
                  <FieldItem
                    onDateChange={changeDate}
                    label="Delivery Date "
                    name="deliveryDate"
                    value={deliveryDate}
                    placeholder="Delivery Date "
                    type={FIELD_TYPES.DATE_PICKER}
                    // minDate={isEdit ? new Date(deliveryDate) : new Date()}
                    className="form-control required"
                    required
                  />
                </div>
                {/* <div className="row  mb-5">
                <div className="col-lg-6 col-sm-12">
                  <FieldItem
                    onChange={handleOnChange}
                    label="Delivery Date "
                    name="deliveryDate"
                    value={deliveryDate}
                    placeholder="Delivery Date "
                    type={FIELD_TYPES.DATE_RANGE}
                    minDate={moment(new Date()).format("YYYY-MM-DD")}
                    className="form-control required"
                    required
                  />

                </div> */}
                <div className="col-lg-6 col-sm-12">
                  <FieldItem
                    onChange={handleOnChange}
                    label="Delivery Quantity "
                    name="deliveryQuantity"
                    value={deliveryQuantity}
                    placeholder="Delivery Quantity "
                    type={FIELD_TYPES.TEXT}
                    className="form-control required"
                    required
                  />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <FieldItem
                    onTextAreaChange={handleOnChange}
                    label="Remarks "
                    name="remarks"
                    value={remarks}
                    placeholder="Remarks "
                    type={FIELD_TYPES.TEXT_AREA}
                    className="form-control"
                  />
                </div>

                {this.state.attachedFiles.length > 0 ? (
                  <label className="form-label">
                    <b> Added Attachments</b>
                  </label>
                ) : (
                  ""
                )}
                {this.state.attachedFiles?.map(
                  (element: any, index: number) => (
                    <>
                      <div className="row">
                        <div className="col-lg-1 ">
                          <label className="form-label">{index + 1}.</label>
                        </div>
                        <div className="col-lg-4 ">
                          <label className="form-label">
                            {element.fileDescription}
                          </label>
                        </div>
                        <div className="col-lg-1 ">
                          <a
                            href={`${constants.app.baseURL}/files/download?file_id=${element.fileId}`}
                            id={`UncontrolledTooltipView${index}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="bi bi-eye"></i>
                          </a>
                          <UncontrolledTooltip
                            target={`UncontrolledTooltipView${index}`}
                          >
                            View
                          </UncontrolledTooltip>
                        </div>
                        <div className="col-lg-1 ">
                          <i
                            onClick={() => this.removeAttachment(index)}
                            id={`removeAttachment${index}`}
                            className="bi bi-trash"
                          ></i>
                          <UncontrolledTooltip
                            target={`removeAttachment${index}`}
                          >
                            Delete
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    </>
                  )
                )}
                <label className="form-label">
                  <b>Attachments</b>
                </label>
                <div className="col-lg-6 ">
                  <div className="form-group">
                    <label className="form-label">File Description</label>
                  </div>
                </div>
                <div className="col-lg-5 ">
                  <div className="form-group ">
                    <label className="form-label">Select File</label>
                  </div>
                </div>
                {this.state.attachment.map(
                  (element: any, index: number, arr: any) => (
                    <Fragment key={index}>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <FieldItem
                            onChange={(e) => handleDynamicChange(e, index)}
                            name="fileDescription"
                            value={attachment[index].fileDescription}
                            placeholder="Description "
                            type={FIELD_TYPES.TEXT}
                            className="form-control required"
                          />
                        </div>
                      </div>
                      <div className="col-lg-5 ">
                        <div className="form-group mb-3">
                          <input
                            type="file"
                            multiple
                            // accept="image/*"
                            name="selectedFile"
                            className="form-control "
                            onChange={(e) => handleDynamicChange(e, index)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-1 d-flex mb-3 justify-content-between align-items-center p-0">
                        {index === arr.length - 1 ? (
                          <>
                            {" "}
                            <i
                              onClick={() => this.addRow()}
                              id={`addcomment${index}`}
                              className="bi bi-plus-circle stroked"
                            ></i>
                            <UncontrolledTooltip target={`addcomment${index}`}>
                              Add More
                            </UncontrolledTooltip>
                          </>
                        ) : (
                          ""
                        )}

                        {index > 0 ? (
                          <>
                            {" "}
                            <i
                              onClick={() => this.removeRow(index)}
                              id={`removeImage${index}`}
                              className="bi bi-trash stroked"
                            ></i>
                            <UncontrolledTooltip target={`removeImage${index}`}>
                              Delete
                            </UncontrolledTooltip>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Fragment>
                  )
                )}
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button
              label="Cancel"
              className="ml-2 btn btn-outline-secondary"
              onClick={this.toggle}
            />
            <Button
              label={isEdit ? "Update" : "Save"}
              onClick={this.onSubmit}
              className="ml-2 btn btn-theme-primary"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
