import React, { Component, Fragment } from "react";
import { Col, Row, UncontrolledTooltip } from "reactstrap";

import { Link } from "react-router-dom";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { ActionRow } from "./partials/actionRow";
import { listDistributionProps, listDistributionState } from "./interface";
import { authenticationService, distributionService } from "../../../service";
import { showToaster, toasterTypes } from "../../../widgets";
import moment from "moment";
import Pagination from "../../../widgets/pagination";

export class ListDistribution extends Component<
  listDistributionProps,
  listDistributionState
> {
  constructor(props: listDistributionProps) {
    super(props);
    this.state = {
      modal: false,
      gridApi: "",
      tableRecords: [],
      usersOptions: [],
      totalQuantity: {},
      context: {
        componentParent: this,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
    };
  }

  handlePageClick = (
    data: { selected: number },
    pageSize: number = this.state.pagination.pageSize
  ) => {
    this.getDistributionList(data.selected, pageSize);
  };

  componentDidMount() {
    this.getAllUserData();
    this.getDistributionCount();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.projectId !== this.props.projectId) {
      this.getAllUserData();
      this.getDistributionCount();
    }
  }

  getDistributionCount = () => {
    try {
      distributionService.getAllPincodeDistributionCount().subscribe(
        (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              this.setState({ totalQuantity: response.data });
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error?.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  getDistributionList = (page: number = 1, pageSize: number = 10) => {
    try {
      distributionService
        .getAllDistributionList(
          this.props.match.params.pincodeId,
          "",
          page,
          pageSize
        )
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (
                  Array.isArray(response.content) &&
                  response.content?.length > 0
                ) {
                  let rowData: any = response.content.map((element: any) => ({
                    id: element.id,
                    DistributionQuantity: element.sapling_quantity,
                    Remark: element.dispatch_remarks,
                    DistributionDate: moment(element.distribution_date).format(
                      "DD-MM-YYYY"
                    ),
                    PincodeId: element.pincode_id,
                    distributionType: element.distribution_type,
                    assigneeName: element.user_name,
                  }));
                  this.state.gridApi.setRowData(rowData);
                  this.setState({
                    pagination: {
                      pageNumber: response.page || 1,
                      pageSize: response.size || 10,
                      totalPages: response.totalPages || 1,
                      totalElements: response.totalElements || 10,
                    },
                  });
                }
              } else {
                this.state.gridApi.setRowData([]);
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error?.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };

  onGridReady = (params: any) => {
    this.getDistributionList();
    this.setState({
      gridApi: params.api,
    });
  };

  getAllUserData = () => {
    try {
      authenticationService
        .getAllUsersListForProjectVolunteers(this.props.projectId || "")
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (
                  Array.isArray(response.content) &&
                  response.content?.length > 0
                ) {
                  let rowData: any = response.content.map((element: any) => ({
                    value: element.id,
                    label: element.first_name,
                  }));
                  this.setState({ usersOptions: rowData });
                }
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };

  changeStatusHandler = (data: any) => {
    try {
      const requestData = {
        id: data.id,
        status: "deleted",
      };
      distributionService.DistributionChangeStatus(requestData).subscribe(
        (data: any) => {
          try {
            if (data.status && data.statusCode === 200) {
              this.getDistributionList();
              this.getDistributionCount();
              this.getAllUserData();
              showToaster(toasterTypes.SUCCESS, data.message);
            } else {
              showToaster(toasterTypes.ERROR, data.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  render() {
    const { pagination } = this.state;
    return (
      <>
        <section id="ViewProject" className="ViewProject">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters">
              <div className="col-lg-12 d-flex flex-column justify-content-center ">
                <Row>
                  <Col md={11}>
                    <h3 className="heading-secondary">List Distribution</h3>
                  </Col>
                  <Col md={1}>
                    <Link to="/pincodeLevelDistribution">
                      <i
                        className="fa fa-times  text-dark float-right"
                        id="close"
                      ></i>
                    </Link>
                    <UncontrolledTooltip target={`close`}>
                      Close
                    </UncontrolledTooltip>
                  </Col>
                </Row>

                <div className="section-title">
                  <div className="grid">
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        domLayout="autoHeight"
                        onGridReady={this.onGridReady}
                        context={this.state.context}
                        rowData={this.state.tableRecords}
                        pagination={false}
                        paginationPageSize={10}
                        cacheBlockSize={10}
                        enableBrowserTooltips={true}
                        frameworkComponents={{
                          ActionColumn: ActionRow,
                        }}
                        suppressDragLeaveHidesColumns={true}
                      >
                        <AgGridColumn
                          field="DistributionDate"
                          sortable={true}
                          filter={true}
                          tooltipField="DistributionDate"
                          suppressMovable={true}
                        />

                        <AgGridColumn
                          field="DistributionQuantity"
                          sortable={true}
                          filter={true}
                          type="rightAligned"
                          tooltipField="DistributionQuantity"
                          suppressMovable={true}
                        />

                        <AgGridColumn
                          field="assigneeName"
                          sortable={true}
                          filter={true}
                          tooltipField="Assignee Name"
                          suppressMovable={true}
                        />

                        <AgGridColumn
                          field="Remark"
                          sortable={true}
                          filter={true}
                          tooltipField="Remark"
                          suppressMovable={true}
                        />
                        <AgGridColumn
                          cellRendererParams={{
                            userOptions: this.state.usersOptions,
                            totalDistributed:
                              this.state.totalQuantity.total_dispatched || 0,
                            totalQuantity:
                              this.state.totalQuantity.total_received_sapling ||
                              0,
                          }}
                          field="Action"
                          cellRenderer="ActionColumn"
                          suppressMovable={true}
                        />
                      </AgGridReact>
                      <div className="col-md-12 mt-2">
                        <Pagination
                          page={pagination.pageNumber}
                          pageSize={pagination.pageSize}
                          handlePageChange={this.handlePageClick}
                          totalPage={pagination.totalPages}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

//  default EditRow;
