import React from "react";
import { Validation } from "../../../utils";
import ChangeProjectModal from "./change-project";

interface addressBarProps {
  mobileNumber: string;
  email: string;
  twitterAccountLink?: string;
  facebookAccountLink?: string;
  linkedInAccountLink?: string;
  instagramAccountLink?: string;
  toggle?: () => void;
  history: any,
}

const defaultProps: addressBarProps = {
  history: undefined,
  mobileNumber: "",
  email: "",
  twitterAccountLink: "",
  facebookAccountLink: "",
  linkedInAccountLink: "",
  instagramAccountLink: "",
};

const AddressBar: React.FunctionComponent<addressBarProps> = (props) => {

  return (
    <section id="topbar" className="d-flex align-items-center section-overflow">

      {Validation.isUserLogged() ?
        <ChangeProjectModal {...props} />
        : ""}
      {/* <section id="topbar">
      <div className="w-100 row">
        <div className="contact-info d-flex justify-content-center align-items-center col-md-4">
          Contact : {props.mobileNumber}, {props.email}
        </div>
        <div className="col-md-4" style={{ position: "relative" }}>
          {Validation.isUserLogged() ? <ChangeProjectModal /> : ""}
        </div>

        <div className="social-links d-none d-md-block mt-half col-md-4 text-end">
          <Link
            target={"_blank"}
            to={props.twitterAccountLink || "/"}
            className="twitter"
          >
            <i className="bi bi-twitter"></i>
          </Link>
          <Link to={props.facebookAccountLink || "/"} className="facebook">
            <i className="bi bi-facebook"></i>
          </Link>
          <Link to={props.instagramAccountLink || "/"} className="instagram">
            <i className="bi bi-instagram"></i>
          </Link>
          <Link to={props.linkedInAccountLink || "/"} className="linkedin">
            <i className="bi bi-linkedin"></i>
          </Link>
        </div> */}

    </section>
  );
};

AddressBar.defaultProps = defaultProps;
export { AddressBar };
