import React, { Component } from "react";
import { AddDispatchPopup } from "../../components/AddDispatchPopup";
import { showToaster, toasterTypes } from "../../../../widgets";
import { authenticationService } from "../../../../service";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "../../../../widgets/button";
import { UserHelper } from "../../../../utils";

interface editRowProps {
  data: any;
  context: any;
  totalDelivered: string;
  totalDispatched: string;
  dispatchedCount?: string;
}
interface editRowStateProps {
  modal: boolean;
  usersOptions: any;
  deleteModal: boolean;
}
export class ActionRow extends Component<editRowProps, editRowStateProps> {
  childRef: any;
  constructor(props: editRowProps) {
    super(props);
    this.state = {
      modal: false,
      usersOptions: [],
      deleteModal: false,
    };
    this.childRef = React.createRef();
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
    if (!this.state.modal) {
      this.getAllUserData();
      this.childRef.current.getDispatchDetail();
    }
  };
  refresh = () => {
    this.props.context.componentParent.getDispatchList();
  };
  getAllUserData = () => {
    try {
      let listuserRole = UserHelper.getUserListId();

      authenticationService
        .getAllDistrictAdminList(
          listuserRole.toString(),
          this.props.data.DistrictId
        )
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (
                  Array.isArray(response.content) &&
                  response.content?.length > 0
                ) {
                  let rowData: any = response.content.map((element: any) => ({
                    value: element.id,
                    label: element.first_name,
                  }));
                  this.setState({ usersOptions: rowData });
                }
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };
  changeStatus = () => {
    this.props.context.componentParent.changeStatusHandler(this.props.data);
    this.deleteToggle();
  };
  deleteToggle = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };
  closeBtn = (
    <button className="close" title="Close" onClick={this.deleteToggle}>
      &times;
    </button>
  );

  render() {
    return (
      <>
        <AddDispatchPopup
          totalQuantity={{
            totalDelivered: this.props.totalDelivered,
            totalDispatched: this.props.totalDispatched,
            currentDispatchValue: this.props.data.DispatchQuantity,
          }}
          isEdit={true}
          modal={this.state.modal}
          toggle={this.toggle}
          usersOptions={this.state.usersOptions}
          districtId={this.props.data.DistrictId}
          dispatchId={this.props.data.id}
          ref={this.childRef}
          refresh={this.refresh}
        />
        <button
          className="Action_btn"
          title="Edit"
          onClick={() => this.toggle()}
        >
          <i className="bi bi-pencil-square"></i>
        </button>
        <button
          onClick={() => this.deleteToggle()}
          className="Action_btn"
          title="Delete"
          style={{ marginLeft: "16px" }}
        >
          <i className="bi bi-trash"></i>
        </button>

        <Modal isOpen={this.state.deleteModal} toggle={this.deleteToggle}>
          <ModalHeader
            className="modal_Bg"
            toggle={this.deleteToggle}
            close={this.closeBtn}
          >
            <div className="modal_title"> Confirmation Alert</div>
          </ModalHeader>
          <ModalBody>Are you sure to Delete?</ModalBody>
          <ModalFooter>
            <Button
              label="No"
              className="ml-2 btn btn-outline-secondary"
              onClick={this.deleteToggle}
            />
            <Button
              label="Yes"
              className="ml-2 btn btn-theme-primary"
              onClick={this.changeStatus}
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
