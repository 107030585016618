import { userRoles } from "../../constants";
import { Userdata } from "../../model";
export default class UserHelper {
  static getUserDetails = (): null | Userdata => {
    const userString = localStorage.getItem("user");
    if (userString) {
      const user: Userdata = JSON.parse(userString);
      return user;
    } else {
      return null;
    }
  };

  static getUserToken = (): string | boolean => {
    const tokenString = localStorage.getItem("user");
    if (tokenString) {
      const token: string = JSON.parse(tokenString);
      return token;
    } else {
      return false;
    }
  };

  static getUserListId = (): string | boolean => {
    const userString = this.getUserDetails();
    console.log("usersting", userString);
    let role: string = "";
    if (userString) {
      switch (userString.user_role_id) {
        case "3":
          role = "4,5";
          break;
        case "2":
          role = "3";
          break;
        default:
          role = "";
          break;
      }
    }
    return role;
  };
  static getUserRole = (currentUserRole: any): string | null => {
    if (userRoles[currentUserRole]) {
      return userRoles[currentUserRole];
    }
    return null;
  };

  static hasRole = (role: any[]): boolean => {
    const user = UserHelper.getUserDetails();
    if (role.length <= 0) {
      return true;
    }
    if (user && user.hasOwnProperty("user_role_id")) {
      if (role.includes(user.user_role_id)) {
        return true;
      }
    }
    return false;
  };
}
