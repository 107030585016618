import React, { useState } from "react";
import { FieldItem, FIELD_TYPES } from "../../../widgets/fields";

interface Props {
    data: any;
    context: any;
}

const StatusChange: React.FunctionComponent<Props> = (props) => {

    const [value, setStatusValue] = useState({ label: props.data.status?.toUpperCase(), value: props.data.status })

    const handleChange = (e: any) => {
        setStatusValue(e)
        props.context.componentParent.onDeleteButtonClicked(props.data, e);
    }

    return (
        <>
            <FieldItem
                onChange={handleChange}
                options={[{
                    label: 'ACTIVE',
                    value: 'active'
                }, {
                    label: 'INACTIVE',
                    value: 'inactive'
                }]}
                name="status"
                value={value}
                placeholder="Select Status"
                type={FIELD_TYPES.DROP_DOWN}
                menuPortalTarget={document.querySelector("body")}
            />
        </>
    );
};

StatusChange.defaultProps = {};

export default StatusChange;
