import React, { Component } from "react";
import { signUpProps, signUpState } from "./interface";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import Button from "../../widgets/button";
import { AddressBar, showToaster, toasterTypes } from "../../widgets";
import { Validation } from "../../utils";
import { authenticationService } from "../../service";
import { signUpRequestModal } from "../../model/index";
import { Redirect, Link, useHistory } from "react-router-dom";
import { Dropdown } from "reactstrap";
import DropDown from "../../widgets/DropDown";

const acceptedExtensions = [".jpeg", ".png", ".jpg"];
class SignUp extends Component<signUpProps, signUpState> {
  constructor(props: signUpProps) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      email: "",
      address: "",
      pincode: "",
      profileImage: [],
      file: "",
      imageId: "",
      toLogin: false,
      termsAndCondition: false,
      refferalCode: "",
      stateOptions: [],
      selectedState: null,
      districtOptions: [],
      selectedDistrict: null,
      pincodeOptions: [],
      selectedPincode: null,
    };
  }

  componentDidMount() {
    if (this.props.match.params.refferalcode) {
      this.setState({ refferalCode: this.props.match.params.refferalcode });
    }
    this.getAllState();
  }

  // NavigateTo() {
  //   const history = useHistory();
  //   const navigateTo = () => history.push('login')
  // }
  getAllState = () => {
    try {
      authenticationService.getStateList().subscribe({
        next: (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: { value: number; label: string; count: string }[] =
                  response.data.map((element: any) => ({
                    label: element.name,
                    value: element.id,
                    count: "",
                  }));
                this.setState({ stateOptions: rowData });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error);
          }
        },
        error: (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        },
      });
    } catch (error) {
      console.log(error?.message);
    }
  };

  handlestateChange = (selectedState: any) => {
    this.setState(
      {
        selectedState,
        districtOptions: [],
        selectedDistrict: null,
        pincodeOptions: [],
        selectedPincode: null,
      },
      () => {
        if (selectedState) this.getDistrictByState();
      }
    );
  };
  getDistrictByState = () => {
    try {
      authenticationService
        .getAllDistrictByStateId(this.state.selectedState?.value)
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any) => ({
                    label: element.name,
                    value: element.id,
                  }));
                  this.setState({ districtOptions: rowData });
                }
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };
  handleDistrictChange = (selectedDistrict: any) => {
    this.setState(
      {
        selectedDistrict,

        pincodeOptions: [],
        selectedPincode: null,
      },
      () => {
        if (selectedDistrict) this.getAllPincode();
      }
    );
  };
  getAllPincode = () => {
    try {
      authenticationService
        .getAllPincodeByDistictId(this.state.selectedDistrict?.value)
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any) => ({
                    label: element.name,
                    value: element.id,
                  }));
                  this.setState({ pincodeOptions: rowData });
                }
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };
  handlePincodeChange = (selectedPincode: any) => {
    this.setState({ selectedPincode });
  };

  navigateTo = () => this.props.history.push("/login");

  handleOnChange = (event: React.FormEvent<HTMLInputElement> | any) => {
    try {
      if (event.currentTarget.name === "profileImage") {
        if (event.currentTarget.files.length > 0) {
          try {
            const file = event.currentTarget.files[0];
            let isValidFile = false;
            if (!file) {
              return;
            } else {
              acceptedExtensions.forEach((extension) => {
                if (file["name"].toLowerCase().includes(extension)) {
                  isValidFile = true;
                }
              });
            }
            if (isValidFile) {
              if (file.size && file.size / 1024 / 1024 > 10) {
                showToaster(
                  toasterTypes.ERROR,
                  "The File you are attempting to upload is larger than the permitted size of 10MB"
                );
                return true;
              }
            } else {
              showToaster(
                toasterTypes.ERROR,
                "File format .jpeg, .png, .jpg only supported"
              );
              return true;
            }
            this.setState({
              file: window.URL.createObjectURL(event.currentTarget.files[0]),
              profileImage: [event.currentTarget.files[0]],
            });
          } catch (error) {
            console.log(error?.message);
          }
        }
      } else {
        let pattern = new RegExp(/^[0-9]*$/); // accept only numbers
        const { name, value } = event.currentTarget;
        if (
          name === "mobileNumber" &&
          !pattern.test(event.currentTarget.value)
        ) {
          return false;
        }

        if (name === "pincode" && !pattern.test(event.currentTarget.value)) {
          return false;
        }

        const state: any = { ...this.state };
        state[name] = value;
        this.setState(state);
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  uploadImage = () => {
    try {
      if (
        Array.isArray(this.state.profileImage) &&
        this.state.profileImage.length > 0
      ) {
        const data: FormData = new FormData();
        data.append("file", this.state.profileImage[0]);
        authenticationService.profileImageUploadRequest(data).subscribe(
          (data: any) => {
            if (data.status && data.statusCode === 200) {
              this.setState({ imageId: data.file_id });
              this.save();
            } else {
              showToaster(toasterTypes.ERROR, data.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
      } else {
        this.save();
      }
    } catch (error) {
      console.log(error?.message);
    }
  };
  onSubmit = () => {
    if (Validation.isTextRequired(this.state.firstName, "first name")) {
      return false;
    }
    if (Validation.isTextRequired(this.state.lastName, "last name")) {
      return false;
    }
    if (Validation.isRequired(this.state.mobileNumber, "mobile number")) {
      return false;
    }

    if (
      Validation.isValidMobileNumber(this.state.mobileNumber, "mobile number")
    ) {
      return false;
    }
    if (Validation.isRequired(this.state.email, "email ")) {
      return false;
    }
    if (this.state.email.length >= 0) {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.email)) {
        showToaster(toasterTypes.ERROR, "Please enter valid Email Address.");
        return true;
      }
    }
    if (Validation.isRequired(this.state.address, "address")) {
      return false;
    }
    // if (Validation.isRequired(this.state.selectedState, "State")) {
    //   return false;
    // }
    // if (Validation.isRequired(this.state.selectedDistrict, "district")) {
    //   return false;
    // }
    if (Validation.isRequired(this.state.pincode, "pincode")) {
      return false;
    }

    if (Validation.isValidPinNumber(this.state.pincode, "pincode")) {
      return false;
    }

    if (!this.state.termsAndCondition) {
      showToaster(toasterTypes.ERROR, "Please accept Terms and Conditions");
      return false;
    }

    if (
      Array.isArray(this.state.profileImage) &&
      this.state.profileImage.length > 0
    ) {
      this.uploadImage();
    } else {
      this.save();
    }
  };
  save = () => {
    try {
      const {
        firstName,
        lastName,
        mobileNumber,
        email,
        address,
        pincode,
        imageId,
        refferalCode,
        // selectedDistrict,
        // selectedPincode,
        // selectedState,
      } = this.state;
      const userData: any = {
        first_name: firstName,
        last_name: lastName,
        mobile_number: mobileNumber,
        email_address: email,
        address: address,
        pincode: pincode,
        isUser: true,
        // stateid: selectedState.value,
        // districtid: selectedDistrict.value,
        profile_photo_id: imageId,
        referred_reference_code: refferalCode,
        user_terms: true,
      };
      authenticationService.signupRequest(userData).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            this.setState({
              toLogin: true,
            });
            if (Validation.isUserLogged()) {
              localStorage.clear();
              this.props.history.push("/login");
              window.location.reload();
            }
            showToaster(toasterTypes.SUCCESS, data.message);
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  responsiveMenu = () => {
    let navbarToggle = this.select(".mobile-nav-toggle");
    navbarToggle.classList.toggle("bi-list");
    navbarToggle.classList.toggle("bi-x");
    this.select("#navbar").classList.toggle("navbar-mobile");
  };

  closeMobileMenuNav = () => {
    if (this.select("#navbar").classList.contains("navbar-mobile text-black")) {
      let navbarToggle = this.select(".mobile-nav-toggle text-black");
      navbarToggle.classList.toggle("bi-list");
      navbarToggle.classList.toggle("bi-x");
      this.select("#navbar").classList.remove("navbar-mobile");
    }
  };

  select = (el: any, all = false) => {
    el = el.trim();
    return document.querySelector(el);
  };
  render() {
    if (this.state.toLogin) {
      return <Redirect to="/login" />;
    }
    const {
      handleOnChange,
      handleDistrictChange,
      handlePincodeChange,
      handlestateChange,

      state: {
        firstName,
        lastName,
        mobileNumber,
        email,
        address,
        pincode,
        districtOptions,
        stateOptions,
        pincodeOptions,
        selectedDistrict,
        selectedPincode,
        selectedState,
      },
    } = this;
    return (
      <div>
        <section id="">
          <div className="Container">
            <header id="headerNew" className="d-flex align-items-center">
              <div className="row m-3 pb-3">
                <Link to="/">
                  <h5 className=" text-black">Vruksha</h5>
                </Link>
              </div>
              <nav id="navbar" className="navbar">
                <div className="header-items">
                  <ul>
                    <li className="ml-2 c-hover d-flex align-items-center">
                      <Link
                        to="/"
                        onClick={() => {
                          this.closeMobileMenuNav();
                        }}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="ml-2 c-hover d-flex align-items-center">
                      <Link
                        to="/"
                        onClick={() => {
                          this.closeMobileMenuNav();
                        }}
                      >
                        Projects
                      </Link>
                    </li>
                    <li className="ml-2 c-hover d-flex align-items-center px-2">
                      <Button
                        label="Sign In"
                        onClick={this.navigateTo}
                        className="button "
                      />
                    </li>
                  </ul>
                </div>
              </nav>
            </header>

            <div className="row">
              {/* <div className="text-center Company_Logo mb-3">
                <img src="/assets/img/Vruksha_logo.png" alt="logo" />
              </div> */}
              <div className="col-md-4 col-lg-4">
                <div className="signupleftimage ">
                  <div className="leftsideb ">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 px-4">
                        <div className="text-style-heading text-white  mt-7 ">
                          Vruksha
                        </div>
                        <div className="text-style-secondheading text-white mt-3">
                          A Plant Today, A Life Tomorrow.
                        </div>

                        <div className="textContainer text-left text-white mt-3">
                          Join the effort to restore diverse green cover in
                          various parts of India. We can create a better and
                          brighter India together! Every volunteering effort
                          matters - whether you plant one tree or one thousand.
                          Each gets us closer to a healthier planet
                        </div>

                        <div className="text-style text-white mt-5">
                          <Button
                            label="Sign In"
                            onClick={this.navigateTo}
                            className="leftBtn "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-lg-7">
                <div className="rightsidecontainer ">
                  <div className="row m-0 p-0">
                    <h5 className="mb-4 mt-3 register">
                      VOLUNTEER/FARMER REGISTRATION
                    </h5>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="Sign-up_ImgBox mt-3 ">
                        <div className="Sign-up_ImgBox_inner">
                          {this.state.file === "" ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/img/user.png`}
                              alt="profile "
                            />
                          ) : (
                            <img src={this.state.file} alt="profile pic" />
                          )}

                          <input
                            type="file"
                            name="profileImage"
                            onChange={handleOnChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8" style={{'paddingLeft':'25px'}}>
                      <div className="Sign-up_FormBox ">
                        <div className="row m-0 p-0">
                          <div className="col-lg-6 col-sm-12">
                            <FieldItem
                              onChange={handleOnChange}
                              // label="First Name"
                              name="firstName"
                              value={firstName}
                              placeholder="First Name"
                              type={FIELD_TYPES.TEXT}
                              className="formFieldInput text-black"
                              required
                            />
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <FieldItem
                              onChange={handleOnChange}
                              // label="Last Name"
                              name="lastName"
                              value={lastName}
                              placeholder="Last Name"
                              type={FIELD_TYPES.TEXT}
                              className="formFieldInput text-black "
                              required
                            />
                          </div>
                        </div>
                        <div className="row m-0 p-0 mt-4">
                          <div className="col-lg-6 col-sm-12">
                            <FieldItem
                              onChange={handleOnChange}
                              // label="Mobile Number"
                              name="mobileNumber"
                              value={mobileNumber}
                              placeholder="Mobile Number"
                              type={FIELD_TYPES.TEXT}
                              className="formFieldInput  text-black "
                              required
                            />
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <FieldItem
                              onChange={handleOnChange}
                              // label="Email Address"
                              name="email"
                              value={email}
                              placeholder="Email Address "
                              type={FIELD_TYPES.TEXT}
                              className="formFieldInput text-black  "
                              required
                            />
                          </div>
                          <div className="col-lg-12 col-sm-12 mt-4">
                            <FieldItem
                              onChange={handleOnChange}
                              // label="Address"
                              name="address"
                              value={address}
                              placeholder="Address"
                              type={FIELD_TYPES.TEXT}
                              className="formFieldInput text-black"
                              required
                            />
                          </div>

                          <div className="col-lg-12 col-sm-12 mt-4">
                            <FieldItem
                              onChange={handleOnChange}
                              name="pincode"
                              value={pincode}
                              placeholder="PIN Code"
                              type={FIELD_TYPES.TEXT}
                              className="formFieldInput text-black"
                              required
                            />
                          </div>
                        </div>

                        {/* <div className="col-lg-12 col-sm-12 mt-4">
                        <FieldItem
                        
                          onChange={handlestateChange}
                          // label="Categary"
                          name="State"
                          value={selectedState}
                          options={stateOptions}
                          placeholder="Select Category"
                          type={FIELD_TYPES.DROP_DOWN}
                          className="formFieldInput text-black "
                          
                          required
                        />
                       
                      </div> */}
                        {/* <div className="col-lg-12 col-sm-12 mt-4">
                        <FieldItem
                          onChange={handleDistrictChange}
                          // label="Area"
                          name="Area"
                          value={selectedDistrict}
                          options={districtOptions}
                          placeholder="Select Area"
                          type={FIELD_TYPES.DROP_DOWN}
                          menuPortalTarget={document.querySelector("body")}
                          className="formFieldInput text-black"
                          required
                        />
                      </div> */}
                        {/* <div className="col-lg-12 col-sm-12 mt-4">
                        <FieldItem
                          onChange={handlePincodeChange}
                          // label="Pin Code"
                          name="selectedPincode"
                          value={selectedPincode}
                          options={pincodeOptions}
                          placeholder="Select Pin Code"
                          type={FIELD_TYPES.DROP_DOWN}
                          className="formFieldInput text-black"
                          // menuPortalTarget={document.querySelector("body")}
                        />
                      </div> */}

                        <div className="col-lg-12 col-sm-12 text-center">
                          <div className="form-group  mb-4">
                            <input
                              className="form-check-input "
                              type="checkbox"
                              value=""
                              id="defaultCheck1"
                              onClick={(
                                event: React.FormEvent<HTMLInputElement>
                              ) => {
                                this.setState({
                                  termsAndCondition:
                                    event.currentTarget.checked,
                                });
                              }}
                            />
                            <label className="form-check-label px-3">
                              I agree to Terms & Condition receiving marketing
                              and promotional materials
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-12 col-sm-12 text-center">
                          {/* <Link to="/">
                        {" "}
                        <Button
                          label="Cancel"
                          className="ml-2 btn btn-outline-secondary"
                          // onClick={this.props.toggle}
                        />{" "}
                      </Link> */}

                          <Button
                            label="Register"
                            onClick={this.onSubmit}
                            className="ml-2 mb-3 btn btn-theme-primary"
                          />

                          {/* <Button
                     onClick={onSubmit}
                      className="btn Btn_green"
                       label="Sign-up"
                        />  */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-content">
              <footer id="footer-new">
                <div className="container">
                  <div className="">
                    <div className="">
                      <div className="">
                        <div className="footText">
                          &copy; Copyright{" "}
                          <strong>
                            <span>Vruksha</span>
                          </strong>
                          . All Rights Reserved
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default SignUp;
