import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import InfiniteRowModelModule from "ag-grid-community";

interface createProject {
  gridApi: any;
  gridColumnApi: any;
}

interface HomeRouterParamsState {
  modules: any;
  columnDefs: any;
  defaultColDef: any;
  components: any;
  rowBuffer: any;
  rowSelection: any;
  rowModelType: any;
  paginationPageSize: any;
  cacheOverflowSize: any;
  infiniteInitialRowCount: any;
  maxConcurrentDatasourceRequests: any;
  maxBlocksInCache: any;
  gridApi: any;
  gridColumnApi: any;
}
export default class TableExample extends Component<
  createProject,
  HomeRouterParamsState
> {
  constructor(props: createProject) {
    super(props);

    this.state = {
      modules: [InfiniteRowModelModule],
      columnDefs: [
        {
          headerName: "ID",
          maxWidth: 100,
          valueGetter: "node.id",
          cellRenderer: "loadingRenderer",
        },
        {
          field: "athlete",
          minWidth: 150,
        },
        { field: "age" },
        {
          field: "country",
          minWidth: 150,
        },
        { field: "year" },
        {
          field: "date",
          minWidth: 150,
        },
        {
          field: "sport",
          minWidth: 150,
        },
        { field: "gold" },
        { field: "silver" },
        { field: "bronze" },
        { field: "total" },
      ],
      defaultColDef: {
        flex: 1,
        resizable: true,
        minWidth: 100,
      },
      components: {
        loadingRenderer: function (params: any) {
          if (params.value !== undefined) {
            return params.value;
          } else {
            return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
          }
        },
      },
      rowBuffer: 0,
      rowSelection: "multiple",
      rowModelType: "infinite",
      paginationPageSize: 100,
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 1,
      infiniteInitialRowCount: 1000,
      maxBlocksInCache: 10,
      gridApi: "",
      gridColumnApi: "",
    };
  }

  onGridReady = (params: any) => {
    this.setState({
      gridApi: params.api,
      gridColumnApi: params.columnApi,
    });
    // this.gridApi = params.api;
    // this.gridColumnApi = params.columnApi;

    const updateData = (data: any) => {
      var dataSource = {
        rowCount: null,
        getRows: function (params: any) {
          console.log("asking for " + params.startRow + " to " + params.endRow);
          setTimeout(function () {
            var rowsThisPage = data.slice(params.startRow, params.endRow);
            var lastRow = -1;
            if (data.length <= params.endRow) {
              lastRow = data.length;
            }
            params.successCallback(rowsThisPage, lastRow);
          }, 500);
        },
      };
      params.api.setDatasource(dataSource);
    };

    fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
      .then((resp) => resp.json())
      .then((data) => updateData(data));
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7">
            <div className="ag-theme-alpine">
              <AgGridReact
                // modules={this.state.modules}
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                components={this.state.components}
                rowBuffer={this.state.rowBuffer}
                rowSelection={this.state.rowSelection}
                rowModelType={this.state.rowModelType}
                paginationPageSize={this.state.paginationPageSize}
                cacheOverflowSize={this.state.cacheOverflowSize}
                maxConcurrentDatasourceRequests={
                  this.state.maxConcurrentDatasourceRequests
                }
                infiniteInitialRowCount={this.state.infiniteInitialRowCount}
                maxBlocksInCache={this.state.maxBlocksInCache}
                onGridReady={this.onGridReady}
                suppressDragLeaveHidesColumns={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
