import { useEffect, useState } from "react";
import { showToaster, toasterTypes } from "../../widgets";
import { plantService } from "../../service";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "../../widgets/button";
import { treeRequestModal } from "../../model";
import { Validation } from "../../utils";
import { CreatePlantProps } from "./interface/create-plant";

const CreatePlantModal = (props: CreatePlantProps) => {
  const [
    { plantName, plantDescription, plantType, plantTypeOptions },
    setState,
  ] = useState({
    plantName: "",
    plantDescription: "",
    plantType: null,
    plantTypeOptions: [],
  });

  useEffect(() => {
    getAllPlantTypes();
  }, []);

  const getAllPlantTypes = () => {
    try {
      plantService.getAllPlantType().subscribe(
        (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = [];

                rowData = response.data.map((element: any) => ({
                  label: element.name,
                  value: element.id,
                }));

                setState((prevState) => ({
                  ...prevState,
                  plantTypeOptions: rowData ? rowData : [],
                }));
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleOnChange = (event: any) => {
    try {
      const { name, value } = event.currentTarget;
      setState((prevState) => ({ ...prevState, [name]: value }));
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleChange = (plantType: any) => {
    setState((prevState) => ({ ...prevState, plantType: plantType }));
  };

  const reset = () => {
    setState((prevState) => ({
      ...prevState,
      plantName: "",
      plantDescription: "",
      plantType: null,
    }));
  };

  const onsubmit = () => {
    try {
      if (
        Validation.isRequiredForDropdown(
          plantType ? plantType : "",
          "plant type"
        )
      ) {
        return false;
      }
      if (Validation.isTextRequired(plantName, "plant name")) {
        return false;
      }
      if (Validation.isRequired(plantDescription, "plant description")) {
        return false;
      }
      const requestData: treeRequestModal = {
        name: plantName,
        description: plantDescription,
        item_type_id: +plantType!["value"],
      };

      plantService.createTreeRequest(requestData).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            props.onSavedEvent();
            props.toggle();
            reset();
            showToaster(toasterTypes.SUCCESS, data.message);
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const closeBtn = (
    <button className="close" title="Close" onClick={props.toggle}>
      &times;
    </button>
  );

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader className="modal_Bg" toggle={props.toggle} close={closeBtn}>
        <div className="modal_title">Create Plants</div>
      </ModalHeader>
      <ModalBody>
        <FieldItem
          onChange={handleChange}
          label="Plant Type "
          name="plantType"
          value={plantType}
          options={plantTypeOptions}
          placeholder="Select Plant Type "
          type={FIELD_TYPES.DROP_DOWN}
          className="required"
          required
        />
        <FieldItem
          onChange={handleOnChange}
          label="Plant Name "
          name="plantName"
          value={plantName}
          placeholder="Plant Name "
          type={FIELD_TYPES.TEXT}
          className="form-control required"
          required
        />
        <FieldItem
          onTextAreaChange={handleOnChange}
          label="Description "
          name="plantDescription"
          value={plantDescription}
          placeholder="Description "
          type={FIELD_TYPES.TEXT_AREA}
          className="form-control required"
          required
        />
      </ModalBody>
      <ModalFooter>
        <Button
          label="Cancel"
          className="ml-2 btn btn-outline-secondary"
          onClick={props.toggle}
        />
        <Button
          label="Save"
          className="ml-2 btn btn-theme-primary"
          onClick={onsubmit}
        />
      </ModalFooter>
    </Modal>
  );
};

export default CreatePlantModal;
