import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export type Props = {
  graphTitle: string;
  labels: string[];
  colors: string[];
  values: number[];
  hoverTextLabel: string;
};

const ProjectStatGraph = (props: Props) => {
  const [series, setSeries] = useState([
    {
      name: props.hoverTextLabel,
      data: props.values,
    },
  ]);
  const [treesProps, setTreeProps] = useState<any>({
    yaxis: [
      {
        axisBorder: {
          show: true,
        },
      },
    ],
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    labels: props.labels,
    colors: props.colors,
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
  });

  useEffect(() => {
    setSeries([
      {
        name: props.hoverTextLabel,
        data: props.values,
      },
    ]);
    setTreeProps({
      yaxis: [
        {
          axisBorder: {
            show: true,
          },
        },
      ],
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      labels: props.labels,
      colors: props.colors,
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
    });
  }, [props]);

  return (
    <div className="All_Graph_box">
      {treesProps.labels.length > 0 && (
        <div>
          <div style={{ fontSize: "14px", fontWeight: 600 }}>
            {props.graphTitle}
          </div>
          <ReactApexChart
            id="apexCharts"
            options={treesProps}
            series={series}
            type="area"
            height={250}
            width="100%"
          />
        </div>
      )}
    </div>
  );
};

export default ProjectStatGraph;
