import { useHistory } from "react-router-dom";
import Button from "../../../widgets/button";

function FarmerActionColumn(props: any) {
  const history = useHistory();

  const viewFamerDetails = () => {
    history.push(`/farmer-view/${props.data.id}`);
  };

  return (
    <>
      <Button
        label="View Details"
        className="ml-2 btn btn-outline-secondary"
        onClick={() => viewFamerDetails()}
      />
    </>
  );
}

export default FarmerActionColumn;
