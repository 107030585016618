import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "../../../widgets/button";
import { FieldItem, FIELD_TYPES } from "../../../widgets/fields";
import { distributionRowProps, distributionRowStateProps } from "./interface";
import { Validation } from "../../../utils";
import { showToaster, toasterTypes } from "../../../widgets";
import { distributionService } from "../../../service";
import _ from "lodash";

export class AddDistributionPopup extends Component<
  distributionRowProps,
  distributionRowStateProps
> {
  constructor(props: distributionRowProps) {
    super(props);
    this.state = {
      distributionDate: "",
      distributionQuantity: "",
      selectedUser: "",
      openListModal: false,
      listDispatchPage: false,
      remarks: "",
      type: "",
    };
  }

  getDistributionDetail = () => {
    try {
      distributionService
        .getDispatchDetailById(this.props.distributionId, this.props.districtId)
        .subscribe(
          (response: any) => {
            try {
              if (response?.status && response?.statusCode === 200) {
                if (response.data) {
                  const selectedArea = this.props.usersOptions?.find(
                    (data: any) => data?.value === response?.data?.dispatched_to
                  );

                  this.setState({
                    distributionDate: new Date(response.data.distribution_date),
                    distributionQuantity: response.data.sapling_quantity,
                    remarks: response.data.dispatch_remarks,
                    type: {
                      value: response.data.distribution_type,
                      label: _.capitalize(response.data.distribution_type),
                    },
                    selectedUser: selectedArea
                      ? selectedArea
                      : response?.data?.dispatched_to,
                  });
                }
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error.message);
    }
  };

  reset = () => {
    this.setState({
      distributionDate: "",
      distributionQuantity: "",
      selectedUser: "",
      openListModal: false,
      listDispatchPage: false,
      remarks: "",
      type: "",
    });
  };
  cancel = () => {
    this.reset();
    this.props.toggle();
  };
  handleOnChange = (event: any, fieldName: string = "") => {
    try {
      if (fieldName === "type") {
        this.setState({ type: event });
        return;
      }
      const { name, value } = event.currentTarget;
      const state: any = { ...this.state };
      state[name] = value;
      this.setState(state);
    } catch (error) {
      console.log(error?.message);
    }
  };
  onSubmit = () => {
    if (
      Validation.isRequired(
        this.state.distributionDate ? this.state.distributionDate : "",
        "distribution date"
      )
    ) {
      return false;
    }
    if (
      Validation.isRequired(
        this.state.distributionQuantity,
        "distribution quantity"
      ) ||
      Validation.isValidNumber(
        this.state.distributionQuantity,
        "distribution quantity"
      )
    ) {
      return false;
    }
    console.log(
      +this.props.totalQuantity.totalDispatched,
      +this.props.totalQuantity.totalDistributed, +this.state.distributionQuantity
    );
    if (
      +this.props.totalQuantity.totalDispatched -
        +this.props.totalQuantity.totalDistributed <
        +this.state.distributionQuantity &&
      !this.props.isEdit
    ) {
      showToaster(
        toasterTypes.ERROR,
        `Distribution limit exceeded by ${
          +this.state.distributionQuantity -
          (+this.props.totalQuantity.totalDispatched -
            +this.props.totalQuantity.totalDistributed)
        } quantity`
      );
      return false;
    }

    if (
      +this.props.totalQuantity.totalDispatched <
        +this.props.totalQuantity.totalDistributed -
          +this.props.totalQuantity.currentDistributed +
          +this.state.distributionQuantity &&
      this.props.isEdit
    ) {
      showToaster(
        toasterTypes.ERROR,
        `Distribution limit exceeded by ${
          +this.props.totalQuantity.totalDistributed -
          +this.props.totalQuantity.currentDistributed +
          +this.state.distributionQuantity -
          +this.props.totalQuantity.totalDispatched
        } quantity`
      );
      return false;
    }

    if (Validation.isNotNull(this.state.selectedUser, "User")) {
      return false;
    }
    const {
      remarks,
      distributionDate,
      distributionQuantity,
      selectedUser,
      type,
    } = this.state;
    const requestData: any = {
      distribution_date: distributionDate,
      sapling_quantity: distributionQuantity,
      pincode_id: this.props.pincodeId,
      remarks: remarks,
      volunteer_id: selectedUser?.value,
      distribution_type: type.value,
    };
    if (this.props.isEdit) {
      requestData.id = this.props.distributionId;
    }
    distributionService
      .createDistributionRequest(requestData, this.props.districtId || '')
      .subscribe(
        (data: any) => {
          if (data?.status && data?.statusCode === 200) {
            showToaster(toasterTypes.SUCCESS, data.message);
            this.props.toggle();
            this.props.refresh();
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
  };
  changeDistributionDate = (distributionDate: any) => {
    this.setState({ distributionDate });
  };
  handleChange = (selectedUser: any) => {
    this.setState({ selectedUser });
  };

  closeBtn = (
    <button className="close" title="Close" onClick={this.cancel}>
      &times;
    </button>
  );

  render() {
    const {
      handleOnChange,
      onSubmit,
      handleChange,
      changeDistributionDate,
      state: { distributionDate, distributionQuantity, selectedUser, remarks },
      props: { isEdit },
    } = this;

    return (
      <>
        <Modal isOpen={this.props.modal} toggle={this.cancel}>
          <ModalHeader
            className="modal_Bg"
            toggle={this.cancel}
            close={this.closeBtn}
          >
            <div className="modal_title">
              {isEdit ? "Update" : "Add"} Distribution
            </div>
          </ModalHeader>
          <ModalBody>
            <>
              <div className="row  mb-5">
                <div className="col-lg-12 col-sm-12">
                  <FieldItem
                    onDateChange={changeDistributionDate}
                    label="Distribution Date "
                    name="distributionDate"
                    value={distributionDate}
                    placeholder="Distribution Date "
                    type={FIELD_TYPES.DATE_PICKER}
                    // minDate={new Date()}
                    className="form-control required"
                    required
                  />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <FieldItem
                    onChange={handleOnChange}
                    label="Distribution Quantity "
                    name="distributionQuantity"
                    value={distributionQuantity}
                    placeholder="Distribution Quantity "
                    type={FIELD_TYPES.TEXT}
                    className="form-control required"
                    required
                  />
                </div>
                {/* <div className="col-lg-12 col-sm-12">
                  <FieldItem
                    onChange={(e) => handleOnChange(e,"type")}
                    label="Distribution Type"
                    name="type"
                    value={type}
                    options={[
                      {value:"seed", label:"Seed"},
                      {value:"sapling", label:"Sapling"}
                    ]}
                    placeholder="Distribution Type"
                    type={FIELD_TYPES.DROP_DOWN}
                    className="required"
                    required
                  />
                </div> */}
                <div className="col-lg-12 col-sm-12">
                  <FieldItem
                    onChange={handleChange}
                    label="User"
                    name="selectedUser"
                    value={selectedUser}
                    options={this.props.usersOptions}
                    placeholder="User"
                    type={FIELD_TYPES.DROP_DOWN}
                    className="required"
                    required
                  />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <FieldItem
                    onTextAreaChange={handleOnChange}
                    label="Remarks "
                    name="remarks"
                    value={remarks}
                    placeholder="Remarks "
                    type={FIELD_TYPES.TEXT_AREA}
                    className="form-control"
                  />
                </div>
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button
              label="Cancel"
              className="ml-2 btn btn-outline-secondary"
              onClick={this.cancel}
            />
            <Button
              onClick={onSubmit}
              label={isEdit ? "Update" : "Save"}
              className="ml-2 btn btn-theme-primary"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
