const FIELD_TYPES = {
  TEXT: 1,
  DROP_DOWN: 2,
  FILE: 3,
  SELECT: 4,
  PASSWORD: 5,
  TEXT_AREA: 6,
  DATE_RANGE: 7,
  MULTI_SELECT: 8,
  SELECT_All_MULTI: 9,
  DATE_PICKER: 10,
  TEXT_EYE: 11,
  SEARCH: 12,
  NUMBER: 13,
  // TIME_PICKER: 22,
  // URL_INPUT: 23,
  // NUMBER_INPUT: 24,
  // CREATABLE_MULTI_SELECT: 25,
  // CREATABLE_SELECT: 26,
  // SINGLE_RADIO_BUTTON: 27,
  // SINGLE_CHECK_BOX: 28,
  // CREATABLE_SELECT: 29,
  // ASYNC_DROPDOWN: 30,
  // CREATABLE_MULTI_SELECT: 31,
  // ASYNC_MULTI_SELECT: 32,
  // ASYNC_CREATABLE_MULTI_SELECT: 33,
  // DROP_DOWN: 34,
};

export default FIELD_TYPES;
