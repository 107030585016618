import { fetchCall, requestMethod } from "../../utils/ajax";

const getUserInvites = (pageNumber: number = 1, pageSize: number = 10) =>
  fetchCall(
    `/users/list_invites?page=${pageNumber}&size=${pageSize}&sort=id&order=desc&status=active,inactive&keyword=`,
    requestMethod.GET,
    {}
  );

const userService = {
  getUserInvites,
};
export { userService };
