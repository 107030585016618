import { fetchCall, requestMethod } from "../../utils/ajax";
import {
    badgeRequestModal,
    badgeChangeStatusRequestModal
} from "../../model"

const getAllBadgeList = (page:number=1, pageSize:number=10, key:string="") =>
    fetchCall(`/badge/list?page=${page}&size=${pageSize}&sort=id&order=desc&status=active,inactive&keyword=${key}`, requestMethod.GET, {});

const createBadgeRequest = (requestData: badgeRequestModal) =>
    fetchCall("/badge/save", requestMethod.POST, requestData);

    const badgeChangeStatus = (requestData: badgeChangeStatusRequestModal) =>
    fetchCall("/badge/change_status", requestMethod.POST, requestData);
  
  
const badgeservice = {
    createBadgeRequest,
    getAllBadgeList,
    badgeChangeStatus



};

export { badgeservice };


