import { fetchCall, requestMethod } from "../../utils/ajax";
import {
  signUpRequestModal,
  changePasswordRequestModal,
  forgetPasswordRequestModal,
  RoleRequestModal,
  inviteFriendsRequestModal,
} from "../../model/index";
import { profileRequestModal } from "../../model/index";

const loginRequest = (username: string, password: string) =>
  fetchCall("/auth/login", requestMethod.POST, {
    login_id: username,
    password: password,
  });

const signupRequest = (userData: signUpRequestModal) =>
  fetchCall("/auth/register", requestMethod.POST, userData);

const profileImageUploadRequest = (data: FormData) =>
  fetchCall("/files/upload", requestMethod.POST, data, {}, true);

const getProfileDetails = (data: any) =>
  fetchCall(`/users/details?user_id=${JSON.parse(data)}`, requestMethod.GET);

const addEditProfile = (profileData: profileRequestModal) =>
  fetchCall("/users/save", requestMethod.POST, profileData);

const getProfilephoto = (imageId: any) =>
  fetchCall(`/files/download?file_id=${imageId}`, requestMethod.GET);

const getAllUsersList = (
  page: number = 1,
  pageSize: number = 10,
  key: string = ""
) =>
  fetchCall(
    `/users/list?page=${page}&size=${pageSize}&sort=id&order=desc&status=active,inactive&keyword=${key}`,
    requestMethod.GET
  );

const getAllVolunteerDetailsForStatistics = (
  projectId: string,
  stateId: string,
  districtId: string,
  pinCodeId: string,
  page: number = 1,
  pageSize: number = 10,
  key: string = ""
) =>
  fetchCall(
    `/statistics/volunteer_statistics?page=${page}&size=${pageSize}&sort=id&order=desc&status=active,inactive&keyword=${key}&project_id=${projectId}&state_id=${stateId}&district_id=${districtId}&pincode_id=${pinCodeId}`,
    requestMethod.GET
  );

const getAllVolunteersList = (
  page: number = 1,
  pageSize: number = 10,
  key: string = ""
) =>
  fetchCall(
    `/users/project_volunteer_list_paginated?page=${page}&size=${pageSize}&sort=id&order=desc&status=active,inactive&keyword=${key}`,
    requestMethod.GET
  );

const getAllVolunteersPinCodes = () =>
  fetchCall(`/users/load_pincodes`, requestMethod.GET);

const getAllUsersListForProjectVolunteers = (
  projectId: string,
  districtId: string = ""
) =>
  fetchCall(
    `/users/project_volunteer_list?project_id=${projectId}&district_id=${districtId}`,
    requestMethod.GET
  );

const getAllUsersListOptions = (role: string = "", districtId: string = "") =>
  fetchCall(
    `/users/list?page=1&size=1000&sort=id&order=desc&status=active,inactive&role_id_list=${role}&district_id=${districtId}&keyword=`,
    requestMethod.GET
  );

const getAllDistrictAdminList = (role: string = "", districtId: string = "") =>
  fetchCall(
    `/users/district_admin_list?page=1&size=1000&sort=id&order=desc&status=active,inactive&role_id_list=${role}&district_id=${districtId}&keyword=`,
    requestMethod.GET
  );

const userChangeStatus = (userData: any) =>
  fetchCall("/users/change_status", requestMethod.POST, userData);

const getUsersRole = () => fetchCall("/users/roles", requestMethod.GET);

// const getStatus = () =>
//   fetchCall("projects/load_project_status", requestMethod.GET);

const changePasswordRequest = (requestData: changePasswordRequestModal) =>
  fetchCall("/auth/change_password", requestMethod.POST, requestData);

const changeProjectDetailsForUser = (requestData: any) =>
  fetchCall("/users/update_user_projects", requestMethod.POST, requestData);

const forgetPasswordRequest = (requestData: forgetPasswordRequestModal) =>
  fetchCall("/auth/forgot_password", requestMethod.POST, requestData);

const changeUsersRole = (requestData: RoleRequestModal) =>
  fetchCall("/users/change_role", requestMethod.POST, requestData);
const changeStatus = (userData: any) =>
  fetchCall("/projects/change_project_status", requestMethod.POST, userData);

const inviteFriends = (requestData: inviteFriendsRequestModal) =>
  fetchCall("/users/invite", requestMethod.POST, requestData);

const getMainPageData = () => fetchCall("/cms", requestMethod.GET);

const getStateList = () =>
  fetchCall("/users/registered_states", requestMethod.GET, {}, false);

const getAllDistrictByStateId = (
  stateId: string | number,
  projectId: any = ""
) =>
  fetchCall(
    `/users/registered_districts_by_state?state_id=${stateId}&project_id=${projectId}`,
    requestMethod.GET,
    {}
  );

const getAllPincodeByDistictId = (districtId: string | number) =>
  fetchCall(
    `/users/registered_pincodes_by_district?district_id=${districtId}`,
    requestMethod.GET,
    {}
  );

const authenticationService = {
  loginRequest,
  signupRequest,
  profileImageUploadRequest,
  getProfileDetails,
  addEditProfile,
  getProfilephoto,
  getAllUsersList,
  userChangeStatus,
  getUsersRole,
  getAllDistrictByStateId,
  getAllPincodeByDistictId,
  changeProjectDetailsForUser,
  getAllDistrictAdminList,
  getAllUsersListForProjectVolunteers,
  changePasswordRequest,
  getAllVolunteersPinCodes,
  getAllVolunteerDetailsForStatistics,
  forgetPasswordRequest,
  getAllVolunteersList,
  changeUsersRole,
  changeStatus,
  inviteFriends,
  getMainPageData,
  getAllUsersListOptions,
  getStateList,
};

export { authenticationService };
