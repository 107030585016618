import React, { Component } from "react";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import Button from "../../widgets/button";
import DropDown from "../../widgets/DropDown";
import { showToaster, toasterTypes } from "../../widgets";
import { projectService } from "../../service/projectService";
import { authenticationService } from "../../service";
import { createProjectProps, createProjectState } from "./interface";
import { Link } from "react-router-dom";
import { constants } from "../../constants";
import { Validation } from "../../utils";
import { UncontrolledTooltip, Collapse } from "reactstrap";
import ConfirmationPopup from "../../widgets/confirmationPopup";
import { Row, Col } from "reactstrap";
import CreatePlantModal from "../plantMaster/create-plant-modal";
import { projectListSubscriber } from "../../utils/subscriber";
import CheckboxTreeModal from "./checkbox-tree";

const acceptedExtensions = [".jpeg", ".png", ".jpg"];

class CreateProject extends Component<createProjectProps, createProjectState> {
  constructor(props: createProjectProps) {
    super(props);
    this.state = {
      projectName: "",
      group: "",
      tree: null,
      parentproject: null,
      description: "",
      status: null,
      selectedFile: [],
      selectedBannerFile: undefined,
      treeOptions: [],
      previewImages: [],
      popup: false,
      statusOption: [
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "Inactive" },
        { value: "Completed", label: "Completed" },
      ],
      images: [],
      projectActivitydescription: "",
      projectActivitiesName: "",
      startDate: new Date(),
      endDate: "",
      numberPlanned: "",
      projectActivityId: "",
      imageRequestData: [],
      isEdit: false,
      isOpen: false,
      selectedState: null,
      stateOptions: [],
      parentOptions: [],
      plantedCount: [],
      deleteImageModal: false,
      deleteBannerImageModal: false,
      deleteImageIndex: 0,
      districtSelectionModel: false,
      selectedDistricts: undefined,
      projectTitle: "",
      createPlantModal: false,
      value: "parent",
      option: "yes",
    };
  }
  componentDidMount() {
    if (this.props.match.params.edit && this.props.match.params.edit > 0) {
      this.setState({ isEdit: true });
    }
    this.getTreeList();
    this.getParentList();
    this.getAllState();
  }

  getAllState = () => {
    try {
      projectService.getProjectCardData().subscribe({
        next: (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: { value: number; label: string; count: string }[] =
                  response.data.map((element: any) => ({
                    label: element.name,
                    value: element.id,
                    count: "",
                    isAll: true,
                    districts: [],
                  }));
                this.setState({ stateOptions: rowData });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error);
          }
        },
        error: (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        },
      });
    } catch (error) {
      console.log(error?.message);
    }
  };

  getTreeList = () => {
    try {
      projectService.getAllTreeList().subscribe({
        next: (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              if (Array.isArray(response.data) && response.data.length > 0) {
                let options: { value: number; label: string }[] =
                  response.data.map((element: any) => ({
                    value: element.id,
                    label: element.name,
                  }));
                this.setState({ treeOptions: options }, () => {
                  if (
                    this.props.match.params.edit &&
                    this.props.match.params.edit > 0
                  ) {
                    this.getProjectData();
                  }
                });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error);
          }
        },
        error: (error: any) => {
          showToaster(toasterTypes.ERROR, error.message);
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  getParentList = (type: any = "") => {
    try {
      projectService.getAllParentList().subscribe({
        next: (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              if (
                Array.isArray(response.content) &&
                response.content?.length > 0
              ) {
                let rowData: { value: number; label: string }[] =
                  response.content.map((element: any) => ({
                    label: element.name,
                    value: element.id,
                  }));
                if (type) {
                  const { projectName } = this.state;
                  let tempObj = rowData.find((obj) => {
                    return (
                      obj.label.toLowerCase() === projectName.toLowerCase()
                    );
                  });
                  this.setState({
                    parentproject: tempObj,
                    projectName: "",
                  });
                }

                this.setState({ parentOptions: rowData });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error);
          }
        },
        error: (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        },
      });
    } catch (error) {
      console.log(error?.message);
    }
  };

  getProjectData = () => {
    try {
      projectService
        .getprojectDetailById(this.props.match.params.edit)
        .subscribe({
          next: (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (
                  response.data &&
                  Array.isArray(response?.data?.activities) &&
                  Array.isArray(response?.data?.images) &&
                  Array.isArray(response?.data?.state_plans)
                ) {
                  // populate project data
                  this.setState({
                    option:
                      response.data.add_child_permission === "0" ? "no" : "yes",
                    value:
                      response.data.parent_project_id &&
                      parseInt(response.data.parent_project_id) > 0
                        ? "subProject"
                        : "parent",
                  });
                  let treeData: any = {
                    group: response?.data?.name,
                    projectName: response?.data?.name,
                    projectTitle: response?.data?.name,
                    description: response?.data?.description,
                    status: null,
                  };
                  this.handleParentChange({
                    label: response.data.parent_name,
                    value: response.data.parent_project_id,
                  });
                  if (response?.data?.activities?.length > 0) {
                    // populate activity data
                    // const selectedArea = this.state.treeOptions?.filter(
                    //   (data) =>
                    //     data?.value === response?.data?.activities[0].tree_id
                    // );
                    const selectedArea = response?.data?.activities.map(
                      (data: any) => ({
                        value: data.tree_id,
                        label: data.tree_name,
                      })
                    );

                    treeData = {
                      ...treeData,
                      tree:
                        Array.isArray(selectedArea) && selectedArea.length > 0
                          ? selectedArea[0]
                          : {},
                      projectActivityId: response?.data?.activities[0].id,
                      projectActivitydescription:
                        response?.data?.activities[0].description,
                      projectActivitiesName:
                        response?.data?.activities[0].description,
                      startDate: new Date(
                        response?.data?.activities[0].start_date
                      ),

                      endDate: new Date(response?.data?.activities[0].end_date),

                      numberPlanned:
                        response?.data?.activities[0].number_planned,
                    };
                  }
                  if (response?.data?.state_plans.length > 0) {
                    // populate image data
                    const stateData = response?.data?.state_plans.map(
                      (data: any) => ({
                        id: data.id,
                        label: data.state_name,
                        value: data.object_id,
                        count: data.plan,
                        isAll: data.isAll,
                        districts: data.districts || [],
                      })
                    );
                    treeData = {
                      ...treeData,
                      selectedState: stateData,
                    };
                  }
                  if (response?.data?.images.length > 0) {
                    // populate image data
                    const trees = response?.data?.images.map((data: any) => ({
                      file: data.image_id,
                      isUploaded: true,
                      url: "",
                      name: data?.remarks,
                    }));
                    treeData = {
                      ...treeData,
                      selectedFile: trees,
                    };
                  }

                  if (
                    response?.data?.banner_image_id &&
                    response?.data?.banner_image_id !== "0"
                  ) {
                    const selectedBannerFile = {
                      file: response?.data?.banner_image_id,
                      isUploaded: true,
                      url: "",
                      name: "",
                    };

                    treeData = {
                      ...treeData,
                      selectedBannerFile,
                    };
                  }
                  this.setState({ ...treeData });
                }
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          error: (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          },
        });
    } catch (error) {
      console.log(error?.message);
    }
  };

  getUploadedImage = (uploadImageId: number) => {
    try {
      authenticationService.getProfilephoto(uploadImageId).subscribe(
        (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              this.setState({ images: URL.createObjectURL(response) });
              showToaster(toasterTypes.SUCCESS, response.message);
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  handleOnChange = (event: any, stateId: any = 0) => {
    try {
      // handle file change
      if (event.currentTarget.name === "selectedFile") {
        const files = [];
        if (event.currentTarget.files && event.currentTarget.files.length > 0) {
          for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i];
            let isValidFile = false;
            if (!file) {
              // if its not a file skip the iteration
              continue;
            } else {
              acceptedExtensions.forEach((extension) => {
                if (file["name"].toLowerCase().includes(extension)) {
                  isValidFile = true;
                }
              });
            }
            if (isValidFile) {
              if (file.size && file.size / 1024 / 1024 > 10) {
                showToaster(
                  toasterTypes.ERROR,
                  "The File you are attempting to upload is larger than the permitted size of 10MB"
                );
                return true;
              }
            } else {
              showToaster(
                toasterTypes.ERROR,
                "File format .jpeg, .png, .jpg only supported"
              );
              return true;
            }
            files.push({
              file: file,
              isUploaded: false,
              url: URL.createObjectURL(file),
              name: file.name,
            });
          }
        }
        this.setState({ selectedFile: [...this.state.selectedFile, ...files] });
        event.target.value = null;
      } else if (event.currentTarget.name === "bannerFIle") {
        if (event.currentTarget.files && event.currentTarget.files.length > 0) {
          for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i];
            let isValidFile = false;
            if (!file) {
              // if its not a file skip the iteration
              continue;
            } else {
              acceptedExtensions.forEach((extension) => {
                if (file["name"].toLowerCase().includes(extension)) {
                  isValidFile = true;
                }
              });
            }
            if (isValidFile) {
              if (file.size && file.size / 1024 / 1024 > 10) {
                showToaster(
                  toasterTypes.ERROR,
                  "The File you are attempting to upload is larger than the permitted size of 10MB"
                );
                return true;
              }
            } else {
              showToaster(
                toasterTypes.ERROR,
                "File format .jpeg, .png, .jpg only supported"
              );
              return true;
            }
            this.setState({
              selectedBannerFile: {
                file: file,
                isUploaded: false,
                url: URL.createObjectURL(file),
                name: file.name,
              },
            });
          }
        }

        event.target.value = null;
      } else {
        const { name, value } = event.currentTarget;
        const state: any = { ...this.state };
        if (stateId) {
          let data = state[name];
          let index = this.state.plantedCount.findIndex(
            (x: any) => x.stateId === stateId
          );
          if (index !== -1) {
            data[index] = {
              stateId: stateId,
              count: value,
            };
          } else {
            let pattern = new RegExp(/^[0-9 ]+$/i);
            if (!pattern.test(value)) {
              showToaster(toasterTypes.ERROR, `Not a numeric value`);
              return;
            }
            data.push({ stateId: stateId, count: value });
          }
          state[name] = data;
        } else {
          state[name] = value;
        }

        this.setState(state);
      }
    } catch (error) {
      console.log(error?.message);
    }
  };
  handleChange = (tree: any) => {
    this.setState({ tree });
  };
  handleParentChange = (value: any) => {
    this.setState({ parentproject: value });
  };

  handleRadioChange = (e: any) => {
    this.setState({ value: e, option: this.state.isEdit ? "no" : "yes" });
  };

  handleSubRadioChange = (e: any) => {
    this.setState({ option: e });
  };

  handlestatusChange = (status: any) => {
    this.setState({ status });
  };
  onSubmit = () => {
    const { value, option } = this.state;
    if (value === "parent" && option === "yes") {
      this.uploadFilesAndSaveParent();
    }
    if (value === "subProject" || (value === "parent" && option === "no")) {
      try {
        if (value === "subProject") {
          if (this.state.parentproject === null) {
            showToaster(toasterTypes.ERROR, "Please select Parent Project");
            return false;
          }
        }
        if (Validation.isTextRequired(this.state.projectName, "project name")) {
          return false;
        }
        if (this.state.tree === null) {
          showToaster(toasterTypes.ERROR, "Please select the tree");
          return false;
        }
        if (
          Validation.isTextRequired(
            this.state.projectActivitiesName,
            "project activities name"
          )
        ) {
          return false;
        }
        if (Validation.isRequired(this.state.numberPlanned, "total planned")) {
          return false;
        }
        if (
          Validation.isValidNumber(this.state.numberPlanned, "total planned")
        ) {
          return false;
        }
        if (
          Validation.isRequired(
            this.state.startDate ? this.state.startDate : "",
            "start date"
          )
        ) {
          return false;
        }
        if (
          Validation.isRequired(
            this.state.endDate ? this.state.endDate : "",
            "end date"
          )
        ) {
          return false;
        }
        if (Validation.isRequired(this.state.description, "description")) {
          return false;
        }
        if (
          this.state.selectedFile.length <= 0 &&
          this.state.imageRequestData?.length <= 0
        ) {
          showToaster(toasterTypes.ERROR, "Please select the image");
          return true;
        }
        let sum = this.state.selectedState?.reduce(function (
          prev: any,
          current: any
        ) {
          return prev + +current.count;
        },
        0);
        if (sum > +this.state.numberPlanned) {
          showToaster(
            toasterTypes.ERROR,
            "Total planned count and Category wise planned count should be equal"
          );
          return true;
        }
        /**
         * if image is there upload images and save else directly save
         */
        if (this.state.selectedFile?.length > 0) {
          Promise.all(this.uploadImages()).then((uploadImagesResponse) => {
            if (
              uploadImagesResponse?.length ===
                this.state.selectedFile?.length ||
              this.state.isEdit
            ) {
              let status = true;
              const error: string[] = [];
              // validate all upload are success
              uploadImagesResponse.forEach((response) => {
                if (!(response?.status && response.statusCode === 200)) {
                  status = false;
                  error.push(response?.message);
                }
              });
              if (status) {
                this.save(uploadImagesResponse);
              } else {
                showToaster(toasterTypes.ERROR, error.join(", "));
              }
            } else {
              showToaster(
                toasterTypes.ERROR,
                "Unable to upload images, please try again letter"
              );
            }
          });
        } else {
          this.save();
        }
      } catch (error) {
        console.error(error?.message);
      }
    }
  };

  /**
   * save project
   * @param uploadImagesResponse
   */
  uploadFilesAndSaveParent = async (projectData: any = null) => {
    if (this.state.selectedBannerFile) {
      Promise.all(this.uploadBannerImage())
        .then((uploadImagesResponse) => {
          if (uploadImagesResponse?.length || this.state.isEdit) {
            console.log(uploadImagesResponse);
            let status = true;
            const error: string[] = [];
            // validate all upload are success
            uploadImagesResponse.forEach((response) => {
              if (!(response?.status && response.statusCode === 200)) {
                status = false;
                error.push(response?.message);
              }
            });
            if (status) {
              this.saveparent(projectData, uploadImagesResponse?.[0]?.file_id);
            } else {
              showToaster(toasterTypes.ERROR, error.join(", "));
            }
          } else {
            showToaster(
              toasterTypes.ERROR,
              "Unable to upload images, please try again letter"
            );
          }
        })
        .catch((err) => {
          showToaster(
            toasterTypes.ERROR,
            "Unable to upload images, please try again letter"
          );
        });
    } else {
      this.saveparent(projectData);
    }
  };

  refetchProjectListForSwitch = () => {
    try {
      projectService.getAllProjectListSelect().subscribe(
        (response: any) => {
          if (response.status && response.statusCode === 200) {
            try {
              if (
                Array.isArray(response.content) &&
                response.content?.length > 0
              ) {
                let rowData: any = [];
                response.content.forEach((element: any) => {
                  if (element.child_project.length > 0) {
                    let options: any = [];
                    element.child_project.forEach((subElement: any) => {
                      options.push({
                        label: subElement.name,
                        value: subElement.id,
                      });
                    });
                    rowData.push({
                      label: element.name,
                      value: element.id,
                      options,
                    });
                  } else {
                    rowData.push({
                      label: element.name,
                      value: element.id,
                      options: [],
                    });
                  }
                });

                projectListSubscriber.next(rowData);
              }
            } catch (error) {
              console.log(error?.message);
            }
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  saveparent = (projectData: any = null, banner_image_id: string = "") => {
    const { projectName } = this.state;
    const { value, option } = this.state;

    if (option === "yes") {
      projectData = {
        name: projectName,
        Project_type: value,
        add_child_permission: option === "yes" ? 1 : 0,
      };
    }

    if (banner_image_id) {
      projectData.banner_image_id = banner_image_id ? banner_image_id : "";
    }

    if (this.props.match.params.edit >= 0) {
      projectData.id = this.props.match.params.edit;
    }

    if (
      this.props.match.params.edit >= 0 &&
      !banner_image_id &&
      this.state.selectedBannerFile
    ) {
      projectData.banner_image_id = this.state.selectedBannerFile.file;
    }

    projectService.createProjectParent(projectData).subscribe(
      (data: any) => {
        if (data?.status && data?.statusCode === 200) {
          showToaster(toasterTypes.SUCCESS, data.message);
          // this.props.history.push("/listProject");
          this.getParentList("subProject");
          this.refetchProjectListForSwitch();
          if (option === "no") {
            this.props.history.push("/listProject");
            return;
          }
          if (this.state.isEdit && this.state.value === "parent") {
            this.props.history.push("/parent-project-list");
            return;
          }
          this.handleRadioChange("subProject");
        } else {
          showToaster(toasterTypes.ERROR, data.message);
        }
      },
      (error: any) => {
        console.log(error);
        showToaster(toasterTypes.ERROR, error.message);
      }
    );
  };

  save = (uploadImagesResponse: any[] = []) => {
    try {
      const {
        projectName,
        parentproject,
        tree,
        description,
        projectActivitiesName,
        startDate,
        endDate,
        numberPlanned,
        selectedState,
      } = this.state;
      const activity = {
        id: this.state.projectActivityId,
        tree_id: tree?.value,
        description: projectActivitiesName,
        start_date: startDate,
        end_date: endDate,
        number_planned: numberPlanned,
      };

      const requestImageFormat = uploadImagesResponse?.map(
        (element: any, index: number) => ({
          sequence: index,
          remarks: this.state.selectedFile[index].name,
          image_id: element?.file_id,
        })
      );
      const statePlanFormat = selectedState?.map(
        (element: any, index: number) => ({
          // id: ,
          id: element.id,
          state_id: element.value,
          plan: element.count,
          districts: element.districts,
          isAll: element.isAll,
        })
      );
      const { value } = this.state;
      const projectData: any = {
        name: projectName,
        Project_type: value,
        description: description,
        activities: [activity],
        images: requestImageFormat,
        state_plans: statePlanFormat,
        add_child_permission: this.state.option === "yes" ? 1 : 0,
      };
      if (value === "subProject") {
        projectData.parent_project_id = parentproject?.value;
      }
      // update images and project id
      if (this.props.match.params.edit >= 0) {
        projectData.id = this.props.match.params.edit;
        this.state.selectedFile.forEach((file, index) => {
          if (file.isUploaded) {
            projectData.images.push({
              sequence: index,
              remarks: file.name,
              image_id: file.file,
            });
          }
        });
      }
      if (value === "subProject") {
        projectService.createProjectRequest(projectData).subscribe(
          (data: any) => {
            if (data?.status && data?.statusCode === 200) {
              this.refetchProjectListForSwitch();
              showToaster(toasterTypes.SUCCESS, data.message);
              this.props.history.push("/listProject");
            } else {
              showToaster(toasterTypes.ERROR, data.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
      } else {
        this.uploadFilesAndSaveParent(projectData);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  removeImages = (index: number) => {
    try {
      const selectedFile = [...this.state.selectedFile];
      selectedFile.splice(index, 1);
      this.setState({ selectedFile });
    } catch (error) {
      console.log(error?.message);
    }
  };

  uploadImages = (): Promise<any>[] => {
    const uploadPromise: Promise<any>[] = [];
    this.state.selectedFile.forEach((file) => {
      if (!file.isUploaded) {
        const data: FormData = new FormData();
        data.append("file", file.file);
        const request = projectService
          .projectImageUploadRequest(data)
          .toPromise();
        uploadPromise.push(request);
      }
    });
    return uploadPromise;
  };

  uploadBannerImage = (): Promise<any>[] => {
    const uploadPromise: Promise<any>[] = [];
    if (this.state.selectedBannerFile) {
      if (!this.state.selectedBannerFile.isUploaded) {
        const data: FormData = new FormData();
        data.append("file", this.state.selectedBannerFile.file);
        const request = projectService
          .projectImageUploadRequest(data)
          .toPromise();
        uploadPromise.push(request);
      }
    }

    return uploadPromise;
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handlestateChange = (value: any) => {
    console.log(value);
    this.setState({ selectedState: value });
  };

  handleDynamicChange = (event: any, index: number) => {
    try {
      const { name, value } = event.currentTarget;
      console.log(value);
      if (value?.length > 0) {
        let pattern = new RegExp(/^[0-9]+$/i);
        if (!pattern.test(value)) {
          showToaster(toasterTypes.ERROR, `Not a numeric value`);
          return;
        }
      }
      const state: any = [...this.state.selectedState];
      state[index][name] = value;
      this.setState(state);
    } catch (error) {
      console.log(error?.message);
    }
  };
  changeStartDate = (startDate: any) => {
    this.setState({ startDate });
  };
  changeEndDate = (endDate: any) => {
    if (!this.state.startDate) {
      showToaster(toasterTypes.ERROR, "Please select start date");
      return false;
    }
    this.setState({ endDate });
  };

  deleteToggle = () => {
    this.setState({
      deleteImageModal: !this.state.deleteImageModal,
    });
  };

  deleteBannerToggle = () => {
    console.log("trigger");
    this.setState({
      deleteBannerImageModal: !this.state.deleteBannerImageModal,
    });
  };

  toggleCreatePlant = () => {
    this.setState({
      createPlantModal: !this.state.createPlantModal,
    });
  };

  toggleDistrictSelection = (districts: any) => {
    this.setState({
      selectedDistricts: districts,
      districtSelectionModel: !this.state.districtSelectionModel,
    });
  };

  render() {
    const { value, option } = this.state;
    const {
      handleOnChange,
      handleChange,
      handleParentChange,
      handleRadioChange,
      handleSubRadioChange,
      changeEndDate,
      changeStartDate,
      onSubmit,
      toggle,
      state: {
        isEdit,
        projectName,
        tree,
        parentproject,
        description,
        treeOptions,
        parentOptions,
        projectActivitiesName,
        startDate,
        endDate,
        numberPlanned,
        isOpen,
        selectedState,
        stateOptions,
      },
    } = this;
    if (value === "parent") {
      return (
        <main id="main">
          <section id="MyProfile" className="MyProfile">
            <div className="container" data-aos="fade-up">
              <div className="row ">
                <div className="col-lg-12 d-flex flex-column justify-content-center ">
                  <div className="row ">
                    <div className="col-lg-8 ">
                      <Row>
                        <Col md={11}>
                          <h3 className="heading-secondary ">
                            Create/Update a Project
                          </h3>
                        </Col>
                        <Col md={1}>
                          <Link
                            to="/listProject"
                            className="text-decoration-none text-dark"
                          >
                            <i
                              className="fa fa-times float-right"
                              id="close"
                            ></i>
                          </Link>
                          <UncontrolledTooltip target={`close`}>
                            Close
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                      <div className="row  mb-5">
                        {!isEdit && (
                          <>
                            {" "}
                            <div className="col-lg-3 col-sm-5  form-control required">
                              <label htmlFor="project" className="form-label">
                                Select Project
                                <label className="specialcharacter-required">
                                  *
                                </label>
                              </label>
                              <div className="d-flex m-0">
                                <div
                                  onClick={() => handleRadioChange("parent")}
                                  className="d-flex align-items-center m-0 c-pointer"
                                >
                                  <div className="selected-radio">
                                    <div className="selected-background"></div>
                                  </div>
                                  <div className="radio-text">Parent</div>
                                </div>
                                <div
                                  onClick={() =>
                                    handleRadioChange("subProject")
                                  }
                                  className="d-flex align-items-center m-0 c-pointer"
                                >
                                  <div className="unselected-radio"></div>
                                  <div className="radio-text">Sub Project</div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-sm-5 mt-4 mb-5 form-control required">
                              <label htmlFor="parent" className="form-label">
                                Do you want to create subprojects?
                                <label className="specialcharacter-required">
                                  *
                                </label>
                              </label>
                              <div className="d-flex m-0">
                                <div
                                  onClick={() => handleSubRadioChange("yes")}
                                  className="d-flex align-items-center m-0 c-pointer"
                                >
                                  {option === "yes" && (
                                    <div className="selected-radio">
                                      <div className="selected-background"></div>
                                    </div>
                                  )}
                                  {option === "no" && (
                                    <div className="unselected-radio"></div>
                                  )}
                                  <div className="radio-text">Yes</div>
                                </div>
                                <div
                                  onClick={() => handleSubRadioChange("no")}
                                  className="d-flex align-items-center m-0 c-pointer"
                                >
                                  {option === "no" && (
                                    <div className="selected-radio">
                                      <div className="selected-background"></div>
                                    </div>
                                  )}
                                  {option === "yes" && (
                                    <div className="unselected-radio"></div>
                                  )}
                                  <div className="radio-text">No</div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {option === "no" && (
                          <>
                            <div className="col-lg-6 col-sm-12">
                              <FieldItem
                                label="Project Name"
                                onChange={handleOnChange}
                                name="projectName"
                                value={projectName}
                                placeholder="Project Name"
                                type={FIELD_TYPES.TEXT}
                                className="form-control required"
                                required
                              />
                            </div>
                            <div className="col-lg-4 col-sm-12">
                              <FieldItem
                                label="Tree"
                                onChange={handleChange}
                                options={treeOptions}
                                name="tree"
                                value={tree}
                                placeholder="Tree"
                                type={FIELD_TYPES.DROP_DOWN}
                                className=" required"
                                required
                              />
                            </div>
                            <div className="col-lg-2 d-flex mb-3 justify-content-between align-items-center pt-2rem">
                              <Button
                                label="Add Tree"
                                onClick={this.toggleCreatePlant}
                                className="mr-2 btn btn-outline-secondary"
                              />
                            </div>
                            <div className="col-lg-6 col-sm-12">
                              <FieldItem
                                label="Activity Name"
                                onChange={handleOnChange}
                                name="projectActivitiesName"
                                value={projectActivitiesName}
                                placeholder="Project Activity Name"
                                type={FIELD_TYPES.TEXT}
                                className="form-control required"
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-sm-12">
                              <FieldItem
                                onChange={handleOnChange}
                                label="Total Trees Planned"
                                name="numberPlanned"
                                value={numberPlanned}
                                placeholder="Total Trees Planned"
                                type={FIELD_TYPES.TEXT}
                                className="form-control required"
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-sm-12">
                              <FieldItem
                                onDateChange={changeStartDate}
                                label="Start Date "
                                name="startDate"
                                value={startDate}
                                placeholder="Start Date "
                                type={FIELD_TYPES.DATE_PICKER}
                                className="form-control required"
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-sm-12">
                              <FieldItem
                                onDateChange={changeEndDate}
                                label="End Date "
                                name="endDate"
                                value={endDate}
                                placeholder="End Date "
                                type={FIELD_TYPES.DATE_PICKER}
                                minDate={new Date(startDate)}
                                className="form-control required"
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-sm-12">
                              <div className="form-group mb-3">
                                <FieldItem
                                  label="Description"
                                  onTextAreaChange={handleOnChange}
                                  name="description"
                                  value={description}
                                  placeholder="Description"
                                  type={FIELD_TYPES.TEXT_AREA}
                                  className="form-control required"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                              <div className="form-group mb-3">
                                <label className="form-label">
                                  Project Images
                                </label>
                                <label className="specialcharacter-required">
                                  *
                                </label>
                                <br></br>
                                <input
                                  type="file"
                                  id="files"
                                  multiple
                                  placeholder="Add Images"
                                  accept="image/*"
                                  name="selectedFile"
                                  className="form-control required  d-none "
                                  onChange={handleOnChange}
                                  required
                                />
                                <label
                                  htmlFor="files"
                                  className="mr-2 btn btn-outline-secondary"
                                >
                                  Add Project Images
                                </label>
                                <label className="form-label">
                                  File format .jpeg, .png only supported.
                                </label>
                                <label> Max Size: 1MB</label>
                              </div>
                            </div>

                            <div className="col-lg-6 col-sm-12">
                              <div className="form-group mb-3">
                                <label className="form-label">
                                  Project Banner
                                </label>
                                <br></br>
                                <input
                                  type="file"
                                  id="bannerFIle"
                                  placeholder="Add Images"
                                  accept="image/*"
                                  name="bannerFIle"
                                  className="form-control required  d-none "
                                  onChange={handleOnChange}
                                  required
                                />
                                <label
                                  htmlFor="bannerFIle"
                                  className="mr-2 btn btn-outline-secondary"
                                >
                                  Add Banner
                                </label>
                                <label className="form-label">
                                  File format .jpeg, .png only supported.
                                </label>
                                <label> Max Size: 1MB</label>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 ">
                                <div className="planted_ImgBox">
                                  {this.state.selectedFile?.length > 0 && (
                                    <label
                                      style={{ fontWeight: 600 }}
                                      className="form-label"
                                    >
                                      Project Images
                                    </label>
                                  )}
                                  <ul className="row">
                                    {this.state.selectedFile?.map(
                                      (image: any, index: number) => (
                                        <li className="col-md-4" key={index}>
                                          <div className="planted_ImgBox_view">
                                            <img
                                              id={`renderFile${index}`}
                                              src={
                                                image.isUploaded
                                                  ? `${constants.app.baseURL}/files/download?file_id=${image.file}`
                                                  : image?.url
                                              }
                                              alt="project img"
                                            />
                                            <button
                                              id={`removeImage${index}`}
                                              className="Action_btn"
                                              onClick={() => {
                                                this.setState({
                                                  deleteImageIndex: index,
                                                });
                                                this.deleteToggle();
                                              }}
                                            >
                                              <i className="bi bi-trash"></i>
                                            </button>
                                            <UncontrolledTooltip
                                              target={`removeImage${index}`}
                                            >
                                              Delete
                                            </UncontrolledTooltip>
                                          </div>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 ">
                                <div className="planted_ImgBox">
                                  {this.state.selectedBannerFile && (
                                    <label
                                      style={{ fontWeight: 600 }}
                                      className="form-label"
                                    >
                                      Banner Image
                                    </label>
                                  )}
                                  <ul className="row">
                                    {this.state.selectedBannerFile && (
                                      <li className="col-md-8">
                                        <div className="planted_ImgBox_view">
                                          <img
                                            id={`renderFileBanner`}
                                            src={
                                              this.state.selectedBannerFile
                                                .isUploaded
                                                ? `${constants.app.baseURL}/files/download?file_id=${this.state.selectedBannerFile.file}`
                                                : this.state.selectedBannerFile
                                                    ?.url
                                            }
                                            alt="project img"
                                          />
                                          <button
                                            id={`removeImageBanner`}
                                            className="Action_btn"
                                            onClick={() => {
                                              this.deleteBannerToggle();
                                            }}
                                          >
                                            <i className="bi bi-trash"></i>
                                          </button>
                                          <UncontrolledTooltip
                                            target={`removeImageBanner`}
                                          >
                                            Delete
                                          </UncontrolledTooltip>
                                        </div>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <ConfirmationPopup
                              message={`Are you sure to Delete?`}
                              isOpen={this.state.deleteImageModal}
                              confirmedEvent={() => {
                                this.setState(
                                  {
                                    deleteImageModal:
                                      !this.state.deleteImageModal,
                                  },
                                  () => {
                                    this.removeImages(
                                      this.state.deleteImageIndex
                                    );
                                  }
                                );
                              }}
                              toggle={() => {
                                this.setState({
                                  deleteImageModal:
                                    !this.state.deleteImageModal,
                                });
                              }}
                            ></ConfirmationPopup>
                            <div className="col-lg-12 col-sm-12 mb-5">
                              <button
                                className="Accordian customAgTextSapling d-flex justify-content-between "
                                onClick={toggle}
                                type="button"
                              >
                                Category Wise Count
                                {isOpen ? (
                                  <i className="bi bi-chevron-down"></i>
                                ) : (
                                  <i className="bi bi-chevron-right"></i>
                                )}
                              </button>
                              <div className="accordion-item">
                                <div className="container">
                                  <Collapse isOpen={isOpen}>
                                    <div className="row  mb-5">
                                      <div className="col-lg-12 col-sm-12 mb-5 mt-3 ml-4">
                                        <label className="form-label">
                                          Categories
                                        </label>
                                        <DropDown
                                          placeholder="Select Category"
                                          value={selectedState}
                                          options={stateOptions}
                                          handleChange={this.handlestateChange}
                                          multi={true}
                                        />
                                      </div>

                                      <div className="row">
                                        {this.state.selectedState?.length >
                                        0 ? (
                                          <div className="col-lg-6  border">
                                            <div
                                              className="row"
                                              style={{ alignItems: "center" }}
                                            >
                                              <div className="col-5">
                                                <ol className="form-label">
                                                  Category
                                                </ol>
                                              </div>
                                              <div className="col-3 form-label">
                                                Planned Count
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="col-lg-6"></div>
                                        )}

                                        {this.state.selectedState?.length >
                                        1 ? (
                                          <div className="col-lg-6 border  border">
                                            <div
                                              className="row"
                                              style={{ alignItems: "center" }}
                                            >
                                              <div className="col-5">
                                                <ol className="form-label">
                                                  Category
                                                </ol>
                                              </div>
                                              <div className="col-3 form-label">
                                                Planned Count
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="col-lg-6"></div>
                                        )}

                                        {this.state.selectedState?.map(
                                          (element: any, index: number) => (
                                            <div
                                              className="col-lg-6 col-offset-5 p-3 border"
                                              key={index}
                                            >
                                              <div className="row">
                                                <div className="col-5">
                                                  <label>{element.label}</label>
                                                </div>

                                                <div className="col-3 formAdjustment">
                                                  <FieldItem
                                                    onChange={(e) =>
                                                      this.handleDynamicChange(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                    name="count"
                                                    value={
                                                      selectedState[index].count
                                                    }
                                                    type={FIELD_TYPES.TEXT}
                                                    className="form-control text-right required"
                                                  />
                                                </div>

                                                <div className="col-4">
                                                  <Button
                                                    label="Select Areas"
                                                    onClick={() =>
                                                      this.toggleDistrictSelection(
                                                        selectedState[index]
                                                      )
                                                    }
                                                    className="mt-1 ctg-btn btn-outline-secondary"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </Collapse>
                                </div>
                              </div>
                            </div>
                            <CheckboxTreeModal
                              selectedElement={this.state.selectedDistricts}
                              modal={this.state.districtSelectionModel}
                              toggle={() => {
                                this.setState({
                                  selectedDistricts: undefined,
                                  districtSelectionModel:
                                    !this.state.districtSelectionModel,
                                });
                              }}
                              onSavedEvent={(payload) => {
                                const states = [...this.state.selectedState];
                                const index = states.findIndex(
                                  (x: any) => x.value === payload.value
                                );
                                states[index]["districts"] = payload.districts;
                                states[index]["isAll"] = payload.isAll;

                                this.setState({ selectedState: states });
                              }}
                            ></CheckboxTreeModal>
                          </>
                        )}
                        {option === "yes" && (
                          <>
                            <div className="col-lg-6 col-sm-12">
                              <FieldItem
                                label="Parent Project Name"
                                onChange={handleOnChange}
                                name="projectName"
                                value={projectName}
                                placeholder=" Parent Project Name"
                                type={FIELD_TYPES.TEXT}
                                className="form-control required"
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-sm-12">
                              <div className="form-group mb-3">
                                <label className="form-label">
                                  Project Banner
                                </label>
                                <br></br>
                                <input
                                  type="file"
                                  id="bannerFIle"
                                  placeholder="Add Images"
                                  accept="image/*"
                                  name="bannerFIle"
                                  className="form-control required  d-none "
                                  onChange={handleOnChange}
                                  required
                                />
                                <label
                                  htmlFor="bannerFIle"
                                  className="mr-2 btn btn-outline-secondary"
                                >
                                  Add Banner
                                </label>
                                <label className="form-label">
                                  File format .jpeg, .png only supported.
                                </label>
                                <label> Max Size: 1MB</label>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 ">
                                <div className="planted_ImgBox">
                                  {this.state.selectedBannerFile && (
                                    <label
                                      style={{ fontWeight: 600 }}
                                      className="form-label"
                                    >
                                      Banner Image
                                    </label>
                                  )}
                                  <ul className="row">
                                    {this.state.selectedBannerFile && (
                                      <li className="col-md-8">
                                        <div className="planted_ImgBox_view">
                                          <img
                                            id={`renderFileBanner`}
                                            src={
                                              this.state.selectedBannerFile
                                                .isUploaded
                                                ? `${constants.app.baseURL}/files/download?file_id=${this.state.selectedBannerFile.file}`
                                                : this.state.selectedBannerFile
                                                    ?.url
                                            }
                                            alt="project img"
                                          />
                                          <button
                                            id={`removeImageBanner`}
                                            className="Action_btn"
                                            onClick={() => {
                                              this.deleteBannerToggle();
                                            }}
                                          >
                                            <i className="bi bi-trash"></i>
                                          </button>
                                          <UncontrolledTooltip
                                            target={`removeImageBanner`}
                                          >
                                            Delete
                                          </UncontrolledTooltip>
                                        </div>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="col-lg-12 col-sm-12 text-right">
                          <Link to="/listProject">
                            <Button
                              label="Cancel"
                              className="mr-2 btn btn-outline-secondary"
                            />
                          </Link>
                          <Button
                            onClick={onSubmit}
                            className="btn btn-theme-primary"
                            label={isEdit ? "Update" : "Save"}
                          />
                        </div>
                      </div>
                    </div>
                    {/* {option === "no" && (
                      <div className="col-lg-4 ">
                        <div className="planted_ImgBox">
                          <ul>
                            {this.state.selectedFile?.map(
                              (image: any, index: number) => (
                                <li key={index}>
                                  <div className="planted_ImgBox_view">
                                    <img
                                      id={`renderFile${index}`}
                                      src={
                                        image.isUploaded
                                          ? `${constants.app.baseURL}/files/download?file_id=${image.file}`
                                          : image?.url
                                      }
                                      alt="project img"
                                    />
                                    <button
                                      id={`removeImage${index}`}
                                      className="Action_btn"
                                      onClick={() => {
                                        this.setState({
                                          deleteImageIndex: index,
                                        });
                                        this.deleteToggle();
                                      }}
                                    >
                                      <i className="bi bi-trash"></i>
                                    </button>
                                    <UncontrolledTooltip
                                      target={`removeImage${index}`}
                                    >
                                      Delete
                                    </UncontrolledTooltip>
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <ConfirmationPopup
            message={`Are you sure to Delete?`}
            isOpen={this.state.deleteBannerImageModal}
            confirmedEvent={() => {
              this.setState({
                deleteBannerImageModal: !this.state.deleteBannerImageModal,
                selectedBannerFile: undefined,
              });
            }}
            toggle={() => {
              this.setState({
                deleteBannerImageModal: !this.state.deleteBannerImageModal,
              });
            }}
          ></ConfirmationPopup>
        </main>
      );
    }
    if (value === "subProject") {
      return (
        <main id="main">
          <section id="MyProfile" className="MyProfile">
            <div className="container" data-aos="fade-up">
              <div className="row ">
                <div className="col-lg-12 d-flex flex-column justify-content-center ">
                  <div className="row ">
                    <div className="col-lg-8 ">
                      <Row>
                        <Col md={11}>
                          <h3 className="heading-secondary ">
                            Create/Update a Project{" "}
                            <span style={{ fontSize: "13px" }}>
                              {this.state.projectTitle &&
                                ` - ${this.state.projectTitle}`}
                            </span>
                          </h3>
                        </Col>
                        <Col md={1}>
                          <Link
                            to="/listProject"
                            className="text-decoration-none text-dark"
                          >
                            <i
                              className="fa fa-times float-right"
                              id="close"
                            ></i>
                          </Link>
                          <UncontrolledTooltip target={`close`}>
                            Close
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                      <div className="row  mb-5">
                        <div className="col-lg-6 col-sm-12  form-control required">
                          <label htmlFor="project" className="form-label">
                            Select Project
                            <label className="specialcharacter-required">
                              *
                            </label>
                          </label>
                          <div className="d-flex m-0">
                            <div
                              onClick={() => handleRadioChange("parent")}
                              className="d-flex align-items-center m-0 c-pointer"
                            >
                              <div className="unselected-radio"></div>
                              <div className="radio-text">Parent</div>
                            </div>
                            <div
                              onClick={() => handleRadioChange("subProject")}
                              className="d-flex align-items-center m-0 c-pointer"
                            >
                              <div className="selected-radio">
                                <div className="selected-background"></div>
                              </div>
                              <div className="radio-text">Sub Project</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 mt-3 ml-4">
                          <FieldItem
                            label="Select Parent Project"
                            onChange={handleParentChange}
                            options={parentOptions}
                            name="parent"
                            value={parentproject}
                            placeholder="Parent Project"
                            type={FIELD_TYPES.DROP_DOWN}
                            className=" required"
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <FieldItem
                            label="Project Name"
                            onChange={handleOnChange}
                            name="projectName"
                            value={projectName}
                            placeholder="Project Name"
                            type={FIELD_TYPES.TEXT}
                            className="form-control required"
                            required
                          />
                        </div>
                        <div className="col-lg-4 col-sm-12">
                          <FieldItem
                            label="Tree"
                            onChange={handleChange}
                            options={treeOptions}
                            name="tree"
                            value={tree}
                            placeholder="Tree"
                            type={FIELD_TYPES.DROP_DOWN}
                            className=" required"
                            required
                          />
                        </div>
                        <div className="col-lg-2 d-flex mb-3 justify-content-between align-items-center pt-2rem">
                          <Button
                            label="Add Tree"
                            onClick={this.toggleCreatePlant}
                            className="mr-2 btn btn-outline-secondary"
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <FieldItem
                            label="Activity Name"
                            onChange={handleOnChange}
                            name="projectActivitiesName"
                            value={projectActivitiesName}
                            placeholder="Project Activity Name"
                            type={FIELD_TYPES.TEXT}
                            className="form-control required"
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <FieldItem
                            onChange={handleOnChange}
                            label="Total Trees Planned"
                            name="numberPlanned"
                            value={numberPlanned}
                            placeholder="Total Trees Planned"
                            type={FIELD_TYPES.TEXT}
                            className="form-control required"
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <FieldItem
                            onDateChange={changeStartDate}
                            label="Start Date "
                            name="startDate"
                            value={startDate}
                            placeholder="Start Date "
                            type={FIELD_TYPES.DATE_PICKER}
                            className="form-control required"
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <FieldItem
                            onDateChange={changeEndDate}
                            label="End Date "
                            name="endDate"
                            value={endDate}
                            placeholder="End Date "
                            type={FIELD_TYPES.DATE_PICKER}
                            minDate={new Date(startDate)}
                            className="form-control required"
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group mb-3">
                            <FieldItem
                              label="Description"
                              onTextAreaChange={handleOnChange}
                              name="description"
                              value={description}
                              placeholder="Description"
                              type={FIELD_TYPES.TEXT_AREA}
                              className="form-control required"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group mb-3">
                            <label className="form-label">Project Images</label>
                            <label className="specialcharacter-required">
                              *
                            </label>
                            <br></br>
                            <input
                              type="file"
                              id="files"
                              multiple
                              placeholder="Add Images"
                              accept="image/*"
                              name="selectedFile"
                              className="form-control required  d-none "
                              onChange={handleOnChange}
                              required
                            />
                            <label
                              htmlFor="files"
                              className="mr-2 btn btn-outline-secondary"
                            >
                              Add Project Images
                            </label>
                            <label className="form-label">
                              File format .jpeg, .png only supported.
                            </label>
                            <label> Max Size: 1MB</label>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 ">
                            <div className="planted_ImgBox">
                              {this.state.selectedFile?.length > 0 && (
                                <label
                                  style={{ fontWeight: 600 }}
                                  className="form-label"
                                >
                                  Project Images
                                </label>
                              )}
                              <ul className="row">
                                {this.state.selectedFile?.map(
                                  (image: any, index: number) => (
                                    <li className="col-md-4" key={index}>
                                      <div className="planted_ImgBox_view">
                                        <img
                                          id={`renderFile${index}`}
                                          src={
                                            image.isUploaded
                                              ? `${constants.app.baseURL}/files/download?file_id=${image.file}`
                                              : image?.url
                                          }
                                          alt="project img"
                                        />
                                        <button
                                          id={`removeImage${index}`}
                                          className="Action_btn"
                                          onClick={() => {
                                            this.setState({
                                              deleteImageIndex: index,
                                            });
                                            this.deleteToggle();
                                          }}
                                        >
                                          <i className="bi bi-trash"></i>
                                        </button>
                                        <UncontrolledTooltip
                                          target={`removeImage${index}`}
                                        >
                                          Delete
                                        </UncontrolledTooltip>
                                      </div>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <ConfirmationPopup
                          message={`Are you sure to Delete?`}
                          isOpen={this.state.deleteBannerImageModal}
                          confirmedEvent={() => {
                            this.setState({
                              deleteBannerImageModal:
                                !this.state.deleteBannerImageModal,
                              selectedBannerFile: undefined,
                            });
                          }}
                          toggle={() => {
                            this.setState({
                              deleteBannerImageModal:
                                !this.state.deleteBannerImageModal,
                            });
                          }}
                        ></ConfirmationPopup>

                        <ConfirmationPopup
                          message={`Are you sure to Delete?`}
                          isOpen={this.state.deleteImageModal}
                          confirmedEvent={() => {
                            this.setState(
                              {
                                deleteImageModal: !this.state.deleteImageModal,
                              },
                              () => {
                                this.removeImages(this.state.deleteImageIndex);
                              }
                            );
                          }}
                          toggle={() => {
                            this.setState({
                              deleteImageModal: !this.state.deleteImageModal,
                            });
                          }}
                        ></ConfirmationPopup>

                        <div className="col-lg-12 col-sm-12 ">
                          <button
                            className="Accordian customAgTextSapling d-flex justify-content-between "
                            onClick={toggle}
                            type="button"
                          >
                            Category Wise Count
                            {isOpen ? (
                              <i className="bi bi-chevron-down"></i>
                            ) : (
                              <i className="bi bi-chevron-right"></i>
                            )}
                          </button>
                          <div className="accordion-item">
                            <div className="container">
                              <Collapse isOpen={isOpen}>
                                <div className="row  mb-5">
                                  <div className="col-lg-12 col-sm-12 mb-5 mt-3 ml-4">
                                    <label className="form-label">
                                      Categories
                                    </label>
                                    <DropDown
                                      placeholder="Select Category"
                                      value={selectedState}
                                      options={stateOptions}
                                      handleChange={this.handlestateChange}
                                      multi={true}
                                    />
                                  </div>

                                  <div className="row">
                                    {this.state.selectedState?.length > 0 ? (
                                      <div className="col-lg-6  border">
                                        <div
                                          className="row"
                                          style={{ alignItems: "center" }}
                                        >
                                          <div className="col-5">
                                            <ol className="form-label">
                                              Category
                                            </ol>
                                          </div>
                                          <div className="col-3 form-label">
                                            Planned Count
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="col-lg-6"></div>
                                    )}

                                    {this.state.selectedState?.length > 1 ? (
                                      <div className="col-lg-6 border  border">
                                        <div
                                          className="row"
                                          style={{ alignItems: "center" }}
                                        >
                                          <div className="col-5">
                                            <ol className="form-label">
                                              Category
                                            </ol>
                                          </div>
                                          <div className="col-3 form-label">
                                            Planned Count
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="col-lg-6"></div>
                                    )}

                                    {this.state.selectedState?.map(
                                      (element: any, index: number) => (
                                        <div
                                          className="col-lg-6 col-offset-5 p-3 border"
                                          key={index}
                                        >
                                          <div className="row">
                                            <div className="col-5">
                                              <label>{element.label}</label>
                                            </div>

                                            <div className="col-3 formAdjustment">
                                              <FieldItem
                                                onChange={(e) =>
                                                  this.handleDynamicChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                                name="count"
                                                value={
                                                  selectedState[index].count
                                                }
                                                type={FIELD_TYPES.TEXT}
                                                className="form-control text-right required"
                                              />
                                            </div>

                                            <div className="col-4">
                                              <Button
                                                label="Select Areas"
                                                onClick={() =>
                                                  this.toggleDistrictSelection(
                                                    selectedState[index]
                                                  )
                                                }
                                                className="mt-1 ctg-btn btn-outline-secondary"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12 text-right">
                        <Link to="/listProject">
                          <Button
                            label="Cancel"
                            className="mr-2 btn btn-outline-secondary"
                          />
                        </Link>
                        <Button
                          onClick={onSubmit}
                          className="btn btn-theme-primary"
                          label={isEdit ? "Update" : "Save"}
                        />
                      </div>
                    </div>

                    <CreatePlantModal
                      modal={this.state.createPlantModal}
                      toggle={() => {
                        this.setState({
                          createPlantModal: !this.state.createPlantModal,
                        });
                      }}
                      onSavedEvent={() => this.getTreeList()}
                    ></CreatePlantModal>

                    <CheckboxTreeModal
                      selectedElement={this.state.selectedDistricts}
                      modal={this.state.districtSelectionModel}
                      toggle={() => {
                        this.setState({
                          selectedDistricts: undefined,
                          districtSelectionModel:
                            !this.state.districtSelectionModel,
                        });
                      }}
                      onSavedEvent={(payload) => {
                        const states = [...this.state.selectedState];
                        const index = states.findIndex(
                          (x: any) => x.value === payload.value
                        );
                        states[index]["districts"] = payload.districts;
                        states[index]["isAll"] = payload.isAll;

                        this.setState({ selectedState: states });
                      }}
                    ></CheckboxTreeModal>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      );
    }
  }
}

export default CreateProject;
