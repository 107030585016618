import React from "react";

interface Props {
  data: any;
  context: any;
}

const EditRow: React.FunctionComponent<Props> = (props) => {

  const onEditButtonClicked = () => {
    props.context.componentParent.onEditButtonClicked(props.data);
  };

  return (
    <>
      <button
        className="Action_btn"
        title="Edit"
        onClick={() => onEditButtonClicked()}
      >
        <i className="bi bi-pencil-square"></i>
      </button>
    </>
  );
};

EditRow.defaultProps = {};

export default EditRow;
