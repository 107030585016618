import { BehaviorSubject } from "rxjs";
const loaderObservable = new BehaviorSubject(false);
const logoutSubscriber = new BehaviorSubject(false);
const projectChangeSubscriber = new BehaviorSubject("");
const projectListSubscriber = new BehaviorSubject([]);
const parentProjectSubscriber = new BehaviorSubject<any>(null);
const projectDetailsSubscriber = new BehaviorSubject([]);

export {
  loaderObservable,
  logoutSubscriber,
  projectChangeSubscriber,
  parentProjectSubscriber,
  projectListSubscriber,
  projectDetailsSubscriber,
};
