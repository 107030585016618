import React, { Component } from "react";
import { profileProps, profileState } from "./interface";
import { UncontrolledTooltip } from "reactstrap";
import moment from "moment";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import Button from "../../widgets/button";
import { showToaster, toasterTypes } from "../../widgets";
import { UserHelper, Validation } from "../../utils";
import { authenticationService, userService } from "../../service";
import { profileRequestModal } from "../../model/index";
import { constants } from "../../constants";

const acceptedExtensions = [".jpeg", ".png", ".jpg"];
class Profile extends Component<profileProps, profileState> {
  constructor(props: profileProps) {
    super(props);
    this.state = {
      firstName: " ",
      lastName: " ",
      mobileNumber: " ",
      email: " ",
      address: " ",
      pincode: " ",
      photoId: "",
      profileImage: [],
      file: "",
      uploadImageApi: false,
      badgeData: [],
      referral: "",
      invitedCount: 0,
    };
  }
  componentDidMount() {
    try {
      const userDetails = UserHelper.getUserDetails();
      this.getInvitesCount();

      this.setState({ badgeData: userDetails });
      const data: any = localStorage.getItem("id");
      if (data) {
        authenticationService.getProfileDetails(data).subscribe(
          (response: any) => {
            if (response.status && response.statusCode === 200) {
              this.setState({
                firstName: response.data.first_name,
                lastName: response.data.last_name,
                mobileNumber: response.data.mobile_no_1,
                email: response.data.email_id,
                address: response.data.address,
                pincode: response.data.volunteer_pincode,
                photoId: response.data.profile_photo_id,
                referral: response.data.reference_code,
              });
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
      }
    } catch (error) {
      console.log(error?.message);
    }
  }

  getInvitesCount = () => {
    userService.getUserInvites().subscribe((response: any) => {
      if (response.status && response.statusCode === 200) {
        if (response.content && Array.isArray(response.content)) {
          this.setState({ invitedCount: response.totalElements });
        }
      }
    });
  };

  handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.name === "profileImage") {
      try {
        if (event.currentTarget.files && event.currentTarget.files.length > 0) {
          const file = event.currentTarget.files[0];
          let isValidFile = false;
          if (!file) {
            return;
          } else {
            acceptedExtensions.forEach((extension) => {
              if (file["name"].toLowerCase().includes(extension)) {
                isValidFile = true;
              }
            });
          }
          if (isValidFile) {
            if (file.size && file.size / 1024 / 1024 > 10) {
              showToaster(
                toasterTypes.ERROR,
                "The File you are attempting to upload is larger than the permitted size of 10MB"
              );
              return true;
            }
          } else {
            showToaster(
              toasterTypes.ERROR,
              "File format .jpeg, .png, .jpg only supported"
            );
            return true;
          }
          this.setState({
            file: window.URL.createObjectURL(event.currentTarget.files[0]),
            profileImage: event.currentTarget.files[0],
            uploadImageApi: true,
          });
        }
      } catch (error) {
        console.log(error?.message);
      }
    } else {
      const { name, value } = event.currentTarget;
      // if(name ==='address' &&  !/^[a-zA-Z0-9\t\n .#@&,;:"'()_+=-]+$/i.test(event.currentTarget.value)){
      //     return false;
      // }
      const state: any = { ...this.state };
      state[name] = value;
      this.setState(state);
    }
  };

  onTextAreaHandleChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;
    // if(name ==='address' &&  !/^[a-zA-Z0-9\t\n .#@&,;:"'()_+=-]+$/i.test(event.currentTarget.value)){
    //     return false;
    // }
    const state: any = { ...this.state };
    state[name] = value;
    this.setState(state);
  };

  uploadImage = () => {
    try {
      const data: FormData = new FormData();
      data.append("file", this.state.profileImage);
      authenticationService.profileImageUploadRequest(data).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            this.setState({ photoId: data.file_id });
            this.save();
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };
  onSubmit = () => {
    try {
      if (Validation.isTextRequired(this.state.firstName, "first name")) {
        return false;
      }
      if (Validation.isTextRequired(this.state.lastName, "last name")) {
        return false;
      }
      if (Validation.isRequired(this.state.mobileNumber, "mobile number")) {
        return false;
      }

      if (
        Validation.isValidMobileNumber(this.state.mobileNumber, "mobile number")
      ) {
        return false;
      }
      if (Validation.isRequired(this.state.email, "email ")) {
        return false;
      }

      if (this.state.email.length >= 0) {
        let pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(this.state.email)) {
          showToaster(
            toasterTypes.ERROR,
            "Please enter a valid email address."
          );
          return true;
        }
      }
      if (Validation.isRequired(this.state.address, "address")) {
        return false;
      }
      if (Validation.isRequired(this.state.pincode, "pincode")) {
        return false;
      }
      if (Validation.isValidPinNumber(this.state.pincode, "pincode")) {
        return false;
      }

      if (this.state.uploadImageApi) {
        this.uploadImage();
      } else {
        this.save();
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  save = () => {
    try {
      const {
        firstName,
        lastName,
        mobileNumber,
        email,
        address,
        pincode,
        photoId,
      } = this.state;

      const profileData: profileRequestModal = {
        user_id: JSON.parse(localStorage.getItem("id") || ""),
        first_name: firstName,
        last_name: lastName,
        mobile_number: mobileNumber,
        email_address: email,
        address: address,
        pincode: pincode,
        profile_photo_id: photoId,
        isUser: true,
      };
      authenticationService.addEditProfile(profileData).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            let requestData = {
              first_name: firstName,
              last_name: lastName,
              mobile_no_1: mobileNumber,
              email_id: email,
              address: address,
              pincodes_name: pincode,
              profile_photo_id: photoId,
            };
            let userData = UserHelper.getUserDetails();
            let newData = { ...userData, ...requestData };
            localStorage.setItem("user", JSON.stringify(newData));
            showToaster(toasterTypes.SUCCESS, data.message);
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };
  render() {
    const {
      handleOnChange,
      onSubmit,
      onTextAreaHandleChange,
      state: {
        firstName,
        lastName,
        mobileNumber,
        email,
        address,
        pincode,
        profileImage,
      },
    } = this;
    return (
      <div>
        <section id="MyProfile" className="MyProfile">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters">
              <div className="col-lg-12 d-flex flex-column justify-content-center ">
                <h3 className="heading-secondary">My Profile</h3>
                <div className="row ">
                  <div className="col-lg-6 d-flex flex-column justify-content-center">
                    <div className="card mb-4">
                      <div className="card-body">
                        {/* <div className="row "> */}
                        <div className="Myprofile_add">
                          <div className="Myprofile_add_inner">
                            <div className="Myprofile_editer">
                              <FieldItem
                                onChange={handleOnChange}
                                name="profileImage"
                                value={profileImage}
                                type={FIELD_TYPES.FILE}
                                // className="form-control"
                              />
                              <i
                                className="bi bi-pencil-square"
                                id={`removeImage`}
                              ></i>
                            </div>
                            <UncontrolledTooltip target={`removeImage`}>
                              Edit
                            </UncontrolledTooltip>
                            <div className="My_Pro_icon">
                              {this.state.file ? (
                                <img src={this.state.file} alt="profile pic" />
                              ) : +this.state.photoId ? (
                                <img
                                  alt="profile pic"
                                  src={`${constants.app.baseURL}/files/download?file_id=${this.state.photoId}`}
                                />
                              ) : (
                                <img
                                  style={{ padding: "25px" }}
                                  src="assets/img/user_w.png"
                                  alt="profile pic"
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        {/* </div> */}
                        <div className="row m-0 p-0">
                          <div className="col-lg-6 col-sm-12 ">
                            <FieldItem
                              onChange={handleOnChange}
                              label="First Name"
                              name="firstName"
                              value={firstName}
                              placeholder="First name"
                              type={FIELD_TYPES.TEXT}
                              className="form-control required"
                              required
                            />
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <FieldItem
                              onChange={handleOnChange}
                              label="Last Name"
                              name="lastName"
                              value={lastName}
                              placeholder="Last name"
                              type={FIELD_TYPES.TEXT}
                              className="form-control required"
                              required
                            />
                          </div>

                          <div className="col-lg-12 col-sm-12">
                            <FieldItem
                              onChange={handleOnChange}
                              label="Mobile Number"
                              name="mobileNumber"
                              value={mobileNumber}
                              placeholder="Mobile number "
                              type={FIELD_TYPES.TEXT}
                              className="form-control required"
                              required
                            />
                          </div>
                          <div className="col-lg-12 col-sm-12">
                            <FieldItem
                              onChange={handleOnChange}
                              label="Email Address"
                              name="email"
                              value={email}
                              placeholder="Email address"
                              type={FIELD_TYPES.TEXT}
                              disabled={true}
                              className="form-control required"
                              required
                            />
                          </div>

                          <div className="col-lg-12 col-sm-12">
                            <FieldItem
                              label="Address"
                              name="address"
                              placeholder="Address"
                              value={address}
                              type={FIELD_TYPES.TEXT_AREA}
                              onTextAreaChange={onTextAreaHandleChange}
                              className="form-control required"
                              required
                            />
                          </div>

                          <div className="col-lg-12 col-sm-12">
                            <FieldItem
                              onChange={handleOnChange}
                              label="Pin Code"
                              name="pincode"
                              value={pincode}
                              placeholder="Pin Code"
                              type={FIELD_TYPES.TEXT}
                              className="form-control required"
                              isNumber={true}
                              maxLength={6}
                              required
                            />
                          </div>
                          <div className="d-grid gap-2">
                            <Button
                              onClick={onSubmit}
                              className="btn Btn_green"
                              label="Save"
                            />
                            {/* <button className="btn Btn_green"> Save</button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    {this.state.badgeData.achieved_badges
                      ?.slice(0, 2)
                      .map((element: any, index: number) => (
                        <div className="card mb-4" key={index}>
                          <div className="card-body">
                            <ul className="Recevied_trees_date d-flex flex-row ">
                              <li>
                                <img
                                  src={`${constants.app.baseURL}/files/download?file_id=${element.image_id}`}
                                  alt="received"
                                />
                              </li>
                              <li>
                                <h5>{element.badge_name}</h5>
                                <p>{element.badge_details}</p>
                                {/* <h5>Planting {element.achievement_count} trees</h5> */}
                                <p>Received on</p>
                                <p>
                                  {moment(element.created_at).format(
                                    "DD-MM-YYYY"
                                  )}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}

                    {/* <div className="card mb-4">
                        <div className="card-body">
                          <ul className="Recevied_trees_date d-flex flex-row ">
                            <li>
                              <img
                                src="assets/img/received.png"
                                alt="received pic"
                              />
                            </li>
                            <li>
                              <p>Recevied For</p>
                              <h5>Planting 2500 trees</h5>
                              <p>on</p>
                              <p>20.April.2021</p>
                            </li>
                          </ul>
                        </div>
                      </div> */}

                    <div className="card referred_Card">
                      <div className="card-body">
                        <img
                          src="assets/img/Referred_icon.png"
                          alt="reffer pic"
                        />
                        <p>
                          You have referred your {this.state.invitedCount}{" "}
                          friends <br />
                          as Volunteers
                          <br />
                        </p>
                      </div>
                      <div>
                        Reference Link : <strong> {this.state.referral}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Profile;
