import { GridApi } from "ag-grid-community";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { cloneDeep } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { Subscription } from "rxjs";
import { constants, userRoles } from "../../constants";
import { Userdata } from "../../model";
import { commonService, plantService, projectService } from "../../service";
import { dashboardService } from "../../service/dashboard";
import { UserHelper } from "../../utils";
import {
  projectDetailsSubscriber,
  projectListSubscriber,
} from "../../utils/subscriber";
import { showToaster, toasterTypes } from "../../widgets";
import DropDown from "../../widgets/DropDown";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import DistrictWiseDistribution from "./districtWiseDistribution";
import { dashboardProps, dashboardState } from "./interface";
import ProjectStatGraph from "./proj-stat-graph";
class AdminDashboard extends Component<dashboardProps, dashboardState> {
  public projectDetailsSubscription: Subscription = new Subscription();
  projectId: null;
  geocodeAddress: any = () => {};
  constructor(props: dashboardProps) {
    super(props);
    this.state = {
      c02Count: 0,
      startDate: new Date(),
      filterDate: new Date(),
      stateOptions: [],
      districtOptions: [],
      selectedDistrict: null,
      selectedState: null,
      mapRef: React.createRef<HTMLDivElement>(),
      viewOptions: [
        {
          label: "Dashboard",
          value: 1,
        },
        {
          label: "Distribution Overview",
          value: 2,
        },
        // {
        //   label: "Map View",
        //   value: 4,
        // },
        {
          label: "Category Map View",
          value: 3,
        },
      ],
      activeTab: {
        label: "Dashboard",
        value: 1,
      },
      plantedQuantity: 0,
      usersQuantity: 0,
      graphStats: {},
      deadQuantity: 0,
      rowData: [],
      stateWiseDistribution: [],
      selectedStateWise: null,
      currentUserRoleId: null,
      planned: 0,
      planted: 0,
      dead: 0,
      plannedState: 0,
      plantedState: 0,
      deadState: 0,
      plannedDistrict: 0,
      plantedDistrict: 0,
      deadDistrict: 0,
      volunteers: 0,
      topPerformer: [],
      topReferrals: [],
      stateData: null,
      distData: null,
      pinData: null,
      projectsData: [],
      project: {
        label: "",
        value: "",
      },
      projectId: "",
      date: new Date(),
      gridApi: GridApi,
      districtList: [],
      selectedDistrictWise: null,
      leaderWiseDistribution: [],
      user: null,
      galleryData: [],
    };
    this.projectId = null;
  }

  componentDidMount() {
    if (this.props.projectId) {
      this.getData(this.props.projectId);
      this.getGraphDetails(this.props.projectId);
    }
    this.getProjectCardDetails();
    const currentUser = UserHelper.getUserDetails();
    if (
      (currentUser?.user_role_id === (userRoles.STATE_ADMIN as any) ||
        currentUser?.user_role_id === (userRoles.DISTRICT_ADMIN as any)) &&
      this.props.projectId
    ) {
      this.setState(
        {
          currentUserRoleId: currentUser?.user_role_id,
          selectedState: {
            label: currentUser?.state_name,
            value: currentUser?.state_id,
          },
        },
        () => {
          this.getDistrictByState();
        }
      );
    }
  }
  getData(projectId: any, filterDate = this.getFormattedDate()) {
    this.projectDetailsSubscription = projectDetailsSubscriber.subscribe(
      (response: any) => {
        console.log(response);
        this.setState({ galleryData: response?.project?.images || [] });
      }
    );
    this.projectId = projectId;
    this.getStatistics();
    const userString: any = localStorage.getItem("user");
    if (userString) {
      const user: Userdata = JSON.parse(userString);
      this.setState({ user: user });
      if (user.role_name === "District Admin") {
        this.getPincodeData(
          user.state_id,
          user.district_id,
          "",
          this.getFormattedDate(filterDate)
        );
        const subscription: Subscription = projectListSubscriber.subscribe(
          (subscriptionRes) => {
            this.setState(
              {
                projectsData: subscriptionRes,
                project: subscriptionRes.filter((data: any) => {
                  return data.value === projectId;
                })[0],
              },
              () => {
                this.getDistrictWiseDistribution(user.state_id);
              }
            );
            return () => {
              subscription.unsubscribe();
            };
          }
        );
      } else if (user.role_name === "State Admin") {
        this.getDistrictData(user.state_id, "", filterDate);
        const subscription: Subscription = projectListSubscriber.subscribe(
          (subscriptionRes) => {
            this.setState(
              {
                projectsData: subscriptionRes,
                project: subscriptionRes.filter((data: any) => {
                  return data.value === projectId;
                })[0],
              },
              () => {
                this.getStateWiseDistribution();
              }
            );
            return () => {
              subscription.unsubscribe();
            };
          }
        );
      } else {
        this.getStateData("", this.props?.projectId, filterDate);
        const subscription: Subscription = projectListSubscriber.subscribe(
          (subscriptionRes) => {
            this.setState(
              {
                projectsData: subscriptionRes,
                project: subscriptionRes.filter((data: any) => {
                  return data.value === projectId;
                })[0],
              },
              () => {
                this.getStateWiseDistribution();
              }
            );
            return () => {
              subscription.unsubscribe();
            };
          }
        );
      }
      this.getPerformers("", "", "");
    }
  }

  shouldComponentUpdate(nextProps: any) {
    if (nextProps.projectId && nextProps.projectId !== this.projectId) {
      this.projectId = nextProps.projectId;
      this.getGraphDetails(nextProps.projectId);

      this.setState(
        { projectId: nextProps.projectId, filterDate: new Date() },
        () => {
          this.getData(nextProps.projectId);
        }
      );
      const currentUser = UserHelper.getUserDetails();
      if (
        (currentUser?.user_role_id === (userRoles.STATE_ADMIN as any) ||
          currentUser?.user_role_id === (userRoles.DISTRICT_ADMIN as any)) &&
        this.props.projectId
      ) {
        this.setState(
          {
            currentUserRoleId: currentUser?.user_role_id,
            selectedState: {
              label: currentUser?.state_name,
              value: currentUser?.state_id,
            },
          },
          () => {
            this.getDistrictByState();
          }
        );
      }
      return true;
    }
    return true;
  }

  getGraphDetails = (projectId: string = "") => {
    try {
      dashboardService.getGraphDetails(projectId).subscribe({
        next: (response: any) => {
          try {
            console.log(response.content);
            if (
              response?.status &&
              response.statusCode === 200 &&
              Array.isArray(response.content)
            ) {
              this.setState({ graphStats: response.content[0] || {} });
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        error: (error: any) => {
          showToaster(toasterTypes.ERROR, error.message);
        },
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  getStatistics = (projectId: string = "") => {
    try {
      dashboardService.getAmountStatistics().subscribe({
        next: (response: any) => {
          try {
            if (
              response?.statusCode &&
              response.statusCode === 200 &&
              response.data
            ) {
              this.setState({
                plantedQuantity: response.data?.estimated_amount,
                usersQuantity: response.data?.total_paid,
                deadQuantity: response.data?.dead_plants_quantity,
              });
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        error: (error: any) => {
          showToaster(toasterTypes.ERROR, error.message);
        },
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  getFormattedDate = (filterDate?: any) => {
    return moment(filterDate || this.state.filterDate).format("YYYY-MM-DD");
  };

  getStateData = (
    stateId: string = "",
    projectId: any = this.props.projectId,
    filterDate: any = this.getFormattedDate()
  ) => {
    try {
      dashboardService
        .getStateWiseData(stateId, projectId, filterDate)
        .subscribe({
          next: (response: any) => {
            try {
              if (
                response &&
                response?.statusCode &&
                response.statusCode === 200 &&
                response.data &&
                Array.isArray(response.data)
              ) {
                let rowData: any = [];
                let planned = 0;
                let planted = 0;
                let volunteers = 0;
                let dead = 0;
                response.data.forEach((obj: any) => {
                  rowData.push({
                    state_id: obj.state_id,
                    name: obj.state_name,
                    planned: obj.planned_quantity,
                    actual: obj.planted_quantity,
                    dead: obj.dead_quantity,
                    coordinator: obj.coordinator
                      ? `${obj.coordinator?.first_name} ${obj.coordinator?.last_name}`
                      : "",
                    totalVolunteers: obj.total_volunteers,
                  });
                  planned = planned + obj.planned_quantity;
                  planted = planted + obj.planted_quantity;
                  dead = dead + obj.dead_quantity;
                  volunteers = volunteers + obj.total_volunteers;
                });
                this.setState({ rowData, planned, planted, volunteers, dead });
                this.getprojectDetailById();
                localStorage.setItem("stateList", JSON.stringify(rowData));
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          error: (error: any) => {
            showToaster(toasterTypes.ERROR, error.message);
          },
        });
    } catch (error) {
      console.error(error.message);
    }
  };

  getPerformers = (
    stateId: string = "",
    districtId: string = "",
    pincodeId: string = ""
  ) => {
    try {
      dashboardService
        .getVolunteerWiseData(stateId, districtId, pincodeId)
        .subscribe(
          (response: any) => {
            if (response.statusCode === 200) {
              this.setState({ topPerformer: response.data });
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          },
          (error: any) => {
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
      dashboardService
        .getTopReferrals(stateId, districtId, pincodeId)
        .subscribe({
          next: (response: any) => {
            if (
              response &&
              response?.statusCode &&
              response.statusCode === 200 &&
              response.data &&
              Array.isArray(response.data)
            ) {
              this.setState({ topReferrals: response.data });
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          },
          error: (error: any) => {
            showToaster(toasterTypes.ERROR, error.message);
          },
        });
    } catch (error) {
      console.error(error.message);
    }
  };

  getprojectDetailById = () => {
    try {
      if (!this.props.projectId) {
        return;
      }

      projectService.getprojectDetailById(this.props.projectId).subscribe({
        next: (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              if (response.data && Array.isArray(response?.data?.activities)) {
                if (response?.data?.activities?.length > 0) {
                  const startDate = new Date(
                    response?.data?.activities[0].start_date
                  );
                  const endDate = new Date();

                  // To calculate the time difference of two dates
                  const Difference_In_Time =
                    endDate.getTime() - startDate.getTime();

                  // To calculate the no. of days between two dates
                  const Difference_In_Days =
                    Difference_In_Time / (1000 * 3600 * 24);

                  const plantedCount =
                    this.state.user?.role_name === "Super Admin" ||
                    this.state.user?.role_name === "External User"
                      ? this.state.planted
                      : this.state.user?.role_name === "State Admin"
                      ? this.state.plantedState
                      : this.state.user?.role_name === "District Admin"
                      ? this.state.plantedDistrict
                      : 0;

                  const c02Released =
                    ((plantedCount * 24) / 365) *
                    Math.floor(Difference_In_Days);
                  const convertToTons = c02Released * 0.001102;

                  if (
                    this.state.user?.role_name === "State Admin" &&
                    this.state.rowData.length <= 0 &&
                    !this.state.plannedState
                  ) {
                    this.setState({
                      plannedState:
                        response?.data?.state_plans?.find(
                          (x: any) =>
                            x.object_id ===
                            UserHelper.getUserDetails()?.state_id
                        )?.plan || 0,
                    });
                  }

                  this.setState({
                    startDate,
                    c02Count: convertToTons.toFixed(3),
                  });
                }
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        error: (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        },
      });
    } catch (error) {
      console.log(error?.message);
    }
  };

  getStateWiseDistribution = (stateId: string = "") => {
    try {
      if (!this.props.projectId) {
        return;
      }
      dashboardService
        .getStateLevelDistributionOverview(this.props.projectId)
        .subscribe({
          next: (response: any) => {
            try {
              if (
                response &&
                response?.statusCode &&
                response.statusCode === 200 &&
                response.data &&
                Array.isArray(response.data)
              ) {
                const stateList: any = response.data.map((data: any) => ({
                  id: data?.state_id,
                  name: data?.state_name,
                  procuredQuantity: data?.total_procured_qty || 0,
                  distributedQuantity: data?.total_delivered_qty || 0,
                }));
                this.setState(
                  {
                    stateWiseDistribution: stateList,
                    selectedStateWise: stateList[0],
                  },
                  () => {
                    this.getDistrictWiseDistribution(stateList[0].id);
                  }
                );
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          error: (error: any) => {
            showToaster(toasterTypes.ERROR, error.message);
          },
        });
    } catch (error) {
      console.error(error.message);
    }
  };

  getDistrictData = (
    stateId: string = "",
    districtId: string = "",
    filterdate = this.getFormattedDate()
  ) => {
    try {
      dashboardService
        .getDistrictWiseData(
          stateId,
          districtId,
          this.state.projectId,
          filterdate
        )
        .subscribe({
          next: (response: any) => {
            try {
              if (
                response &&
                response?.statusCode &&
                response.statusCode === 200 &&
                response.data &&
                Array.isArray(response.data)
              ) {
                let plannedState = 0;
                let plantedState = 0;
                let deadState = 0;
                response.data.forEach((obj: any) => {
                  plannedState = plannedState + obj.planned_quantity;
                  plantedState = plantedState + obj.planted_quantity;
                  deadState = deadState + obj.dead_quantity;
                });
                this.setState({
                  plannedState: plannedState,
                  plantedState: plantedState,
                  deadState: deadState,
                });
                const rowData = response.data.map((data: any) => ({
                  state_id: data.state_id,
                  district_id: data.district_id,
                  name: data.district_name,
                  actual: data.planted_quantity,
                  planned: data.planned_quantity,
                  coordinator: data.coordinator
                    ? `${data.coordinator?.first_name} ${data.coordinator?.last_name}`
                    : "",
                  totalVolunteers: data.total_volunteers,
                  dead: data.dead_quantity,
                }));

                this.setState({ rowData });
                this.getprojectDetailById();
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          error: (error: any) => {
            showToaster(toasterTypes.ERROR, error.message);
          },
        });
    } catch (error) {
      console.error(error.message);
    }
  };

  getPincodeData = (
    stateId: string = "",
    districtId: string = "",
    pincodeId: string = "",
    filterDate: any = this.getFormattedDate()
  ) => {
    try {
      dashboardService
        .getPincodeWiseData(
          stateId,
          districtId,
          pincodeId,
          this.state.projectId,
          filterDate
        )
        .subscribe({
          next: (response: any) => {
            try {
              if (
                response &&
                response?.statusCode &&
                response.statusCode === 200 &&
                response.data &&
                Array.isArray(response.data)
              ) {
                let plannedDistrict = 0;
                let plantedDistrict = 0;
                let deadDistrict = 0;
                response.data.forEach((obj: any) => {
                  plannedDistrict = plannedDistrict + obj.planned_quantity;
                  plantedDistrict = plantedDistrict + obj.planted_quantity;
                  deadDistrict = deadDistrict + obj.dead_quantity;
                });
                this.setState({
                  plannedDistrict: plannedDistrict,
                  plantedDistrict: plantedDistrict,
                  deadDistrict: deadDistrict,
                });
                const rowData = response.data.map((data: any) => ({
                  state_id: data.state_id,
                  district_id: data.district_id,
                  pincode_id: data.pincode_id,
                  name: data.pincode_name,
                  actual: data.planted_quantity,
                  planned: data.planned_quantity,
                  percentage: data.percentage,
                  coordinator: data.coordinator
                    ? `${data.coordinator?.first_name} ${data.coordinator?.last_name}`
                    : "",
                  totalVolunteers: data.total_volunteers,
                  dead: data.dead_quantity,
                }));

                console.log(rowData);
                this.setState({ rowData });
                this.getprojectDetailById();
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          error: (error: any) => {
            showToaster(toasterTypes.ERROR, error.message);
          },
        });
    } catch (error) {
      console.error(error.message);
    }
  };

  getDistrictWiseDistribution = (stateId: number | undefined | string) => {
    try {
      if (stateId) {
        dashboardService
          .getDistrictLevelDistributionOverview(stateId, this.state.projectId)
          .subscribe({
            next: (response: any) => {
              try {
                if (
                  response &&
                  response?.statusCode &&
                  response.statusCode === 200 &&
                  response.data &&
                  Array.isArray(response.data)
                ) {
                  const districtList = response.data.map((data: any) => ({
                    id: data?.district_id,
                    stateId: data?.state_id,
                    name: data?.district_name,
                    receivedQuantity: data?.total_received_qty,
                    saplingQuantity: data?.total_sapling_qty,
                    deliveredQuantity: data?.total_delivered_qty,
                  }));
                  this.setState({ districtList });
                } else {
                  showToaster(toasterTypes.ERROR, response.message);
                }
              } catch (error) {
                console.log(error.message);
              }
            },
            error: (error: any) => {
              showToaster(toasterTypes.ERROR, error.message);
            },
          });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  selectedState(obj: any) {
    this.setState({ stateData: obj });
    this.setState({
      planned: obj.planned,
      planted: obj.actual,
      dead: obj.dead,
    });
    if (this.state.user?.role_name === "State Admin") {
      this.setState({
        plannedState: obj.planned,
        plantedState: obj.actual,
        deadState: obj.dead,
      });
    }
    if (this.state.user?.role_name === "District Admin") {
      this.setState({
        plannedDistrict: obj.planned,
        plantedDistrict: obj.actual,
        deadDistrict: obj.dead,
      });
    }
    this.getPerformers(obj.state_id, "", "");
    this.getDistrictData(obj.state_id, "");
  }

  clearState() {
    this.setState({ stateData: null, distData: null, pinData: null });
    if (this.state.user.role_name === "State Admin") {
      this.getDistrictData(this.state.user.state_id, "");
    } else {
      this.getStateData("", this.state.projectId);
    }
    this.getPerformers("", "", "");
  }

  selectedDistrict(obj: any) {
    this.setState({ distData: obj });
    this.setState({
      planned: obj.planned,
      planted: obj.actual,
      dead: obj.dead,
    });
    if (this.state.user?.role_name === "State Admin") {
      this.setState({
        plannedState: obj.planned,
        plantedState: obj.actual,
        deadState: obj.dead,
      });
    }
    if (this.state.user?.role_name === "District Admin") {
      this.setState({
        plannedDistrict: obj.planned,
        plantedDistrict: obj.actual,
        deadDistrict: obj.dead,
      });
    }
    this.getPerformers(obj.state_id, obj.district_id, "");
    this.getPincodeData(obj.state_id, obj.district_id, "");
  }

  selectedPin(obj: any) {
    this.setState({ pinData: obj });
    this.setState({
      planned: obj.planned,
      planted: obj.actual,
      dead: obj.dead,
    });
    if (this.state.user?.role_name === "State Admin") {
      this.setState({
        plannedState: obj.planned,
        plantedState: obj.actual,
        deadState: obj.dead,
      });
    }
    if (this.state.user?.role_name === "District Admin") {
      this.setState({
        plannedDistrict: obj.planned,
        plantedDistrict: obj.actual,
        deadDistrict: obj.dead,
      });
    }
    this.getPerformers(obj.state_id, obj.district_id, obj.pincode_id);
  }

  changeProject = (e: any) => {
    this.setState(
      {
        projectId: e,
        stateData: null,
        distData: null,
        pinData: null,
        project: this.state.projectsData.filter((data: any) => {
          return data.value === e;
        })[0],
      },
      () => {
        this.getStateData("", e);
        this.getPerformers("", "", "");
      }
    );
  };

  getRowNodeId(data: any) {
    return data.id;
  }

  onFirstDataRendered(event: any) {
    let gridApi = this.state.gridApi;
    gridApi = event.api;
    setTimeout(() => {
      gridApi.forEachNode((node: any, index: any) => {
        if (index === 0) {
          node.setSelected(true);
        }
      });
      this.setState({ gridApi });
    }, 0);
  }

  onSelectState = (value: any) => {
    this.setState({
      selectedStateWise: value.data,
      selectedDistrictWise: null,
      leaderWiseDistribution: [],
    });
    this.getDistrictWiseDistribution(value.data.id);
  };

  onSelectDistrict = (value: any, index: any) => {
    this.setState({ selectedDistrictWise: value });
    this.getLeaderWiseData(value, index);
  };

  getLeaderWiseData = (value: any, index: any) => {
    try {
      dashboardService
        .getLeaderWiseData(
          value.stateId.toString(),
          value.id.toString(),
          this.state.projectId
        )
        .subscribe({
          next: (response: any) => {
            try {
              if (
                response &&
                response?.statusCode &&
                response.statusCode === 200 &&
                response.data &&
                Array.isArray(response.data)
              ) {
                const leaderWiseDistribution = response.data.map(
                  (data: any) => ({
                    distributedDate: data?.distribution_date
                      ? moment(
                          data?.distribution_date,
                          "YYYY-MM-DD HH:mm:ss"
                        ).format("DD-MM-yyyy")
                      : "-",
                    receivedDate: data?.received_date
                      ? moment(
                          data?.received_date,
                          "YYYY-MM-DD HH:mm:ss"
                        ).format("DD-MM-yyyy")
                      : "-",
                    distributedQuantity:
                      data?.distributed_qty > 0 ? data.distributed_qty : 0,
                    distributedType: data?.distribution_type || "-",
                    receivedQuantity:
                      data?.received_quantity > 0 ? data.received_quantity : 0,
                    receivedBy: `${data?.first_name ? data?.first_name : "-"} ${
                      data?.last_name ? data?.last_name : ""
                    }`,
                  })
                );
                let tempData = cloneDeep(this.state.districtList);
                tempData[index].leaderWise = leaderWiseDistribution;
                this.setState({ districtList: tempData });
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          error: (error: any) => {
            showToaster(toasterTypes.ERROR, error.message);
          },
        });
    } catch (error) {
      console.error(error.message);
    }
  };

  getProjectCardDetails = () => {
    projectService.getProjectCardData().subscribe(
      (response: any) => {
        try {
          if (response.status && response.statusCode === 200) {
            if (response.data?.length > 0) {
              let rowData: any = [];
              response.data.forEach((element: any) => {
                rowData.push({
                  label: element.name,
                  value: element.id,
                });
              });
              this.setState({ stateOptions: rowData });
            }
          } else {
            showToaster(toasterTypes.ERROR, response.message);
          }
        } catch (error) {
          console.log(error.message);
        }
      },
      (error: any) => {
        console.log(error);
        showToaster(toasterTypes.ERROR, error.message);
      }
    );
  };
  handlestateChange = (selectedState: any) => {
    this.setState(
      {
        selectedState,
        districtOptions: [],
        selectedDistrict: null,
      },
      () => {
        if (selectedState) this.getDistrictByState();
      }
    );
  };

  getDistrictByState = () => {
    console.log(this.props.projectId);
    try {
      commonService
        .getAllDistrictByStateId(
          this.state.selectedState?.value,
          this.projectId || ""
        )
        .subscribe(
          (response: any) => {
            try {
              console.log(response);
              if (response.status && response.statusCode === 200) {
                if (response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any) => ({
                    label: element.name,
                    value: element.id,
                  }));

                  const currentUser = UserHelper.getUserDetails();
                  const selectedDistrict = rowData.find(
                    (x: any) => x.value === currentUser?.district_id
                  );
                  this.setState({ districtOptions: rowData, selectedDistrict });
                }
              } else {
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };
  handleDistrictChange = (selectedDistrict: any) => {
    this.setState({
      selectedDistrict,
    });
  };

  onViewVolunteer = (view: string, object: any) => {
    const { state_id, pincode_id, district_id } = object;
    if (state_id && pincode_id && district_id) {
      this.props.history.push(
        `/farmer-list/${state_id}/${district_id}/${pincode_id}`
      );
    } else if (state_id && district_id) {
      this.props.history.push(`/farmer-list/${state_id}/${district_id}`);
    } else {
      this.props.history.push(`/farmer-list/${state_id}`);
    }
  };

  renderGoogleMap = () => {
    try {
      if (
        this.state.mapRef &&
        this.state.mapRef.current &&
        this.state.selectedDistrict
      ) {
        // remove all existing data
        this.state.mapRef.current.childNodes.forEach((element) =>
          element.remove()
        );
        if (window?.google) {
          let geocoder: any = "";
          let map: any = "";
          let bounds = new window.google.maps.LatLngBounds();
          const initialize = () => {
            map = new google.maps.Map(
              this.state.mapRef.current as HTMLElement,
              {
                center: new google.maps.LatLng(37.4419, -122.1419),
                zoom: 13,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
              }
            );
            geocoder = new google.maps.Geocoder();
            geocoder.geocode(
              {
                address: `${this.state.selectedDistrict.label}, ${this.state.selectedState.label}`,
              },
              (results: any, status: any) => {
                if (status === "OK") {
                  map.setCenter(results[0].geometry.location);
                  new google.maps.Marker({
                    map: map,
                    position: results[0].geometry.location,
                  });
                } else {
                  // showToaster(
                  //   toasterTypes.ERROR,
                  //   "Geocode was not successful for the following reason: " +
                  //     status
                  // );
                }
              }
            );

            var prev_infowindow: any;
            const infoWindow = (
              marker: any,
              map: any,
              title: any,
              address: any,
              url: any,
              data: any
            ) => {
              google.maps.event.addListener(marker, "click", function () {
                if (prev_infowindow) {
                  prev_infowindow.close();
                }
                var html = `<div style="display:flex; flex-direction:column;">
                  <img width="250px" alt="plant image" src="${
                    constants.app.renderFile
                  }?file_id=${
                  data?.images &&
                  Array.isArray(data?.images) &&
                  data.images.length > 0
                    ? data?.images[0]?.id
                    : 0
                }" />
                  <div>
                    <h5 style="margin-top:10px;">${data?.first_name} ${
                  data?.last_name
                }</h5>
                    <p style="margin:0px !important;"><b>Planted on :</b></p>
                    <p width="250px" style="overflow:auto margin:0px !important">${
                      data?.date_of_planting
                    }</p>
                    <p  style="margin:0px !important;"><b>Total Planted :</b> </p>
                    <p width="250px" style="overflow:auto margin:0px !important">${
                      data?.total_plants_count
                    }</p>
                    <p  style="margin:0px !important;"><b>Total Live :</b> </p>
                    <p width="250px" style="overflow:auto margin:0px !important">${
                      data?.live_plants_count
                    }</p>
                    <p  style="margin:0px !important;"><b>Total Dead :</b> </p>
                    <p width="250px" style="overflow:auto margin:0px !important">${
                      data?.dead_plants_count
                    }</p>
                  </div>
                  </div>`;
                const iw = new google.maps.InfoWindow({
                  content: html,
                  maxWidth: 350,
                });
                prev_infowindow = iw;
                iw.open(map, marker);
              });
            };

            this.geocodeAddress = (
              title: any,
              address: any,
              url: any,
              location: any,
              data: any
            ) => {
              var marker: any = new google.maps.Marker({
                icon: `${window.location.origin}/${
                  process.env.PUBLIC_URL ? process.env.PUBLIC_URL + "/" : ""
                }assets/img/tree.png`,
                map: map,
                position: location,
                title: title,
                animation: google.maps.Animation.DROP,
              });
              infoWindow(marker, map, title, address, url, data);
              bounds.extend(marker.getPosition());
              map.fitBounds(bounds);
            };
          };
          const handleApi = {
            next: (data: any) => {
              if (data && Array.isArray(data?.content)) {
                data.content.forEach((plant: any) => {
                  const location = plant?.geo_location?.split(",");
                  if (
                    location &&
                    Array.isArray(location) &&
                    location.length >= 2
                  ) {
                    this.geocodeAddress(
                      plant?.created_at,
                      plant.created_by,
                      plant.pincode,
                      {
                        lat: parseFloat(location[0].trim()),
                        lng: parseFloat(location[1].trim()),
                      },
                      plant
                    );
                  } else {
                    console.log("no geo_location found. skipping..", plant);
                  }
                });
              }
              console.log(data);
            },
            error: (error: any) => {
              console.error(error.message);
            },
          };
          plantService
            .stateDistrictPincodeListing(
              this.state.selectedState?.value,
              this.state.selectedDistrict.value
            )
            .subscribe(handleApi);
          initialize();
        } else {
          // show map not found error message
          showToaster(
            toasterTypes.ERROR,
            "Unable to initialize map. please refresh the page and try again"
          );
        }
      } else {
        console.log("district not found");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  getAllPlantList = (page: number = 1, pageSize: number = 10) => {
    plantService
      .getAllPlantList(
        this.state.selectedState?.value,
        this.state.selectedDistrict?.value,
        0,
        page,
        pageSize
      )
      .subscribe(
        (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              if (response.content?.length > 0) {
                let rowData: any = [];

                response.content.forEach((element: any) => {
                  let date = moment(element.date_of_planting).format(
                    "DD-MM-YYYY"
                  );
                  let first_name = element.first_name + " " + element.last_name;
                  let status = element.plant_status;
                  let pincode = element.pin_code;
                  let image: any = [];
                  element.images?.forEach((element: any, index: number) => {
                    image.push({
                      key: index,
                      src: `${constants.app.baseURL}/files/download?file_id=${element.image_id}`,
                    });
                  });
                  rowData.push({
                    plantedDate: date,
                    dsplayimage: image,
                    first_name: first_name,
                    status: status,
                    pincode: pincode,
                  });
                });
                // this.setState({ selectedFile: rowData });
                // this.setState({
                //   pagination: {
                //     pageNumber: response.page || 1,
                //     pageSize: response.size || 10,
                //     totalPages: response.totalPages || 1,
                //     totalElements: response.totalElements || 10,
                //   },
                // });
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
  };

  changeFilterDate = (filterDate: any) => {
    this.setState({ filterDate });

    if (filterDate instanceof Date && !isNaN(filterDate as any)) {
      this.getData(this.props.projectId, this.getFormattedDate(filterDate));
    } else {
      showToaster(toasterTypes.ERROR, "Enter valid date");
    }
  };

  calculatePercentageForGraph = (
    plannedCount: string,
    currentCount: string
  ) => {
    const percentage = (+currentCount / +plannedCount) * 100;
    console.log(percentage);
    if (percentage > 100) {
      return 100;
    }
    if (percentage < 20) {
      return 20 + percentage;
    } else if (percentage < 30) {
      return 30 + percentage;
    } else {
      return percentage;
    }
  };

  render() {
    const { projectName } = this.props;
    const {
      stateData,
      distData,
      stateOptions,
      districtOptions,
      selectedDistrict,
      selectedState,
    } = this.state;
    return (
      <div className="dashboard-page">
        <Row className="mb-2">
          <Col xs={6}>
            <Link to="/projectListDashboard" className="d-flex m-0">
              <img
                src="/assets/img/arrow.svg"
                alt="arrow"
                className="c-pointer"
              />
              <div className="view-text c-pointer">View</div>
            </Link>
          </Col>
          <Col lg={2} xs={6}>
            {/* <ReactDatePicker
						onChange={date => this.setState({date})}
						dateFormat="dd/MM/yyyy"
						name="date"
						selected={this.state.date}
						portalId="root"
						className="form-control required"
						required
					/> */}
          </Col>
          <Col lg={2} xs={6} className="dashboard-dropdown">
            {/* <DropDown
							placeholder="View"
							value={this.state.project}
							options={this.state.projectsData}
							handleChange={(e: any) => this.changeProject(e)}
						/> */}
          </Col>
          <Col lg={2} xs={6} className="dashboard-dropdown">
            <DropDown
              placeholder="View"
              value={this.state.activeTab}
              options={this.state.viewOptions}
              handleChange={(e: any) =>
                this.setState({
                  activeTab: this.state.viewOptions.filter((data: any) => {
                    return data.value === e;
                  })[0],
                })
              }
            />
          </Col>
        </Row>
        <Row className="gy-3">
          <Col lg={3} xs={12}>
            <div className="project-name">{projectName}</div>
            <FieldItem
              onDateChange={this.changeFilterDate}
              label="Filter By Date"
              minDate={this.state.startDate}
              name="filterByDate"
              value={this.state.filterDate}
              placeholder="Select Date "
              type={FIELD_TYPES.DATE_PICKER}
              className="form-control bold"
            />

            <div className="overall-text">Overall Status</div>

            {/* <Row> */}
            {/* <Col xs={9} className="p-0"> */}
            <div className="planned-card">
              <div className="planned-text">Planned</div>
              <div className="planned-count">
                {this.state.user?.role_name === "Super Admin" ||
                this.state.user?.role_name === "External User"
                  ? this.state.planned
                  : this.state.user?.role_name === "State Admin"
                  ? this.state.plannedState
                  : this.state.user?.role_name === "District Admin"
                  ? this.state.plannedDistrict
                  : 0}
              </div>
            </div>
            <Row className="m-0">
              <Col xs={6} className="p-1">
                <div className="planted-card">
                  <div className="planned-text">Planted</div>
                  <div className="planned-count">
                    {" "}
                    {this.state.user?.role_name === "Super Admin" ||
                    this.state.user?.role_name === "External User"
                      ? this.state.planted
                      : this.state.user?.role_name === "State Admin"
                      ? this.state.plantedState
                      : this.state.user?.role_name === "District Admin"
                      ? this.state.plantedDistrict
                      : 0}
                  </div>
                </div>
              </Col>
              <Col xs={6} className="p-1">
                <div className="dead-card">
                  <div className="planned-text">Dead</div>
                  <div className="planned-count">
                    {" "}
                    {this.state.user?.role_name === "Super Admin" ||
                    this.state.user?.role_name === "External User"
                      ? this.state.dead
                      : this.state.user?.role_name === "State Admin"
                      ? this.state.deadState
                      : this.state.user?.role_name === "District Admin"
                      ? this.state.deadDistrict
                      : 0}
                  </div>
                </div>
              </Col>
            </Row>
            {/* </Col> */}
            {/* <Col xs={6}>
                <div className="graph-border">
                  {this.state.planned > 0 && (
                    <div className="planned-graph"></div>
                  )}
                  {this.state.planned === 0 && this.state.planted > 0 ? (
                    <div
                      className="planted-graph"
                      style={{ height: `140px` }}
                    ></div>
                  ) : (
                    <div
                      className="planted-graph"
                      style={{
                        height: `${(10 * this.state.planted) / 100 || 0}px`,
                      }}
                    ></div>
                  )}
                  {this.state.dead > 0 && (
                    <div
                      className="dead-graph"
                      style={{
                        height: `${(140 * this.state.dead) / 100 || 0}px`,
                      }}
                    ></div>
                  )}
                </div>
              </Col> */}
            {/* </Row> */}
            <div className="percentage-card">
              <div className="percentage-text">Percentage</div>
              <div className="percentage-count">
                {this.state.user?.role_name === "Super Admin" ||
                this.state.user?.role_name === "External User"
                  ? this.state.planned === 0 && this.state.planted > 0
                    ? 100
                    : this.state.planned === 0 && this.state.planted === 0
                    ? 0
                    : ((this.state.planted * 100) / this.state.planned).toFixed(
                        2
                      ) || 0
                  : this.state.user?.role_name === "State Admin"
                  ? this.state.plannedState === 0 && this.state.plantedState > 0
                    ? 100
                    : this.state.plannedState === 0 &&
                      this.state.plantedState === 0
                    ? 0
                    : (
                        (this.state.plantedState * 100) /
                        this.state.plannedState
                      ).toFixed(2) || 0
                  : this.state.user?.role_name === "District Admin"
                  ? this.state.plannedDistrict === 0 &&
                    this.state.plantedDistrict > 0
                    ? 100
                    : this.state.plannedDistrict === 0 &&
                      this.state.plantedDistrict === 0
                    ? 0
                    : (
                        (this.state.plantedDistrict * 100) /
                        this.state.plannedDistrict
                      ).toFixed(2) || 0
                  : 0}
                %
              </div>
            </div>
            <div className="data-container">
              <Row className="data-height bottom-border">
                <Col xs={7} className="center-col">
                  <div className="data-text">
                    CO<sub>2</sub> Extinguished
                  </div>
                  <div className="co2-text">
                    {this.state.c02Count}
                    <span className="tons-text">Tonne</span>
                  </div>
                </Col>
              </Row>
              <Row className="data-height">
                <Col xs={6} className="right-border center-col  mt-2">
                  <div className="data-text">Estimated Amount</div>
                </Col>

                <Col xs={6} className="right-border center-col  mt-2">
                  <div className="data-text">Paid Amount</div>
                </Col>

                <Col xs={6} className="right-border center-col  mt-2">
                  <div className="plants-text">
                    {this.state.plantedQuantity}
                  </div>
                </Col>

                <Col xs={6} className="right-border center-col  mt-2">
                  <div className="plants-text ">{this.state.usersQuantity}</div>
                </Col>
              </Row>
            </div>
          </Col>
          {this.state.activeTab?.value === 1 && (
            <Col lg={6} xs={12}>
              <Row className="align-items-center">
                <Col xs={4}>
                  <div className="state-data">Category Wise data</div>
                </Col>
                <Col xs={8}>
                  <Row>
                    <Col xs={4}>
                      <div className="row m-0">
                        <div className="planned-legend p-0"></div>
                        <div className="legend-text">Planned</div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="row m-0">
                        <div className="planted-legend p-0"></div>
                        <div className="legend-text">Planted</div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="row m-0">
                        <div className="dead-legend p-0"></div>
                        <div className="legend-text">Dead</div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {this.state.stateData && !this.state.distData && (
                <Row className="selected-state-card c-pointer">
                  <Col xs={4}>
                    <div className="state-name">{stateData.name}</div>
                    <div className="state-coordinator">
                      {/* {stateData.coordinator} */}
                    </div>
                    <div
                      className="state-volunteer"
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={() => {
                        this.onViewVolunteer("state", this.state.stateData);
                      }}
                    >
                      {stateData.totalVolunteers} - Farmers/Volunteers
                      {/* View Farmers/Volunteers */}
                    </div>
                  </Col>
                  <Col xs={6} className="p-0">
                    {stateData.planned > 0 && (
                      <div className="planned-graph">{stateData.planned}</div>
                    )}

                    <div
                      className="planted-graph"
                      style={{
                        width: `${this.calculatePercentageForGraph(
                          stateData.planned,
                          stateData.actual
                        )}%`,
                      }}
                    >
                      {stateData.actual}
                    </div>
                    {stateData.dead > 0 && (
                      <div
                        className="dead-graph"
                        style={{
                          width: `${this.calculatePercentageForGraph(
                            stateData.planned,
                            stateData.dead
                          )}%`,
                        }}
                      >
                        {stateData.dead}
                      </div>
                    )}
                  </Col>
                  <Col xs={2}>
                    <div className="state-percentage">
                      {stateData.planned < stateData.actual
                        ? ((stateData.actual * 100) / stateData.actual).toFixed(
                            2
                          )
                        : (
                            (stateData.actual * 100) /
                            stateData.planned
                          ).toFixed(2)}
                      %
                    </div>
                  </Col>
                </Row>
              )}
              {this.state.stateData && this.state.distData && (
                <Row>
                  <Col xs={6}>
                    <Row className="selected-state-card c-pointer">
                      <div className="state-name">{stateData.name}</div>
                      <div className="state-coordinator py-1">
                        {/* {stateData.coordinator} */}
                      </div>
                      <div
                        className="state-volunteer"
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => {
                          this.onViewVolunteer("state", this.state.stateData);
                        }}
                      >
                        {stateData.totalVolunteers} - Farmers/Volunteers
                        {/* View Farmers/Volunteers */}
                      </div>

                      <Col xs={8} className="p-10 mt-2 ">
                        {stateData.planned > 0 && (
                          <div className="planned-graph">
                            {stateData.planned}
                          </div>
                        )}

                        <div
                          className="planted-graph"
                          style={{
                            width: `${this.calculatePercentageForGraph(
                              stateData.planned,
                              stateData.actual
                            )}%`,
                          }}
                        >
                          {stateData.actual}
                        </div>
                        {stateData.dead > 0 && (
                          <div
                            className="dead-graph"
                            style={{
                              width: `${this.calculatePercentageForGraph(
                                stateData.planned,
                                stateData.dead
                              )}%`,
                            }}
                          >
                            {stateData.dead}
                          </div>
                        )}
                      </Col>
                      <Col xs={2}>
                        <div className="state-percentage">
                          {stateData.planned < stateData.actual
                            ? (
                                (stateData.actual * 100) /
                                stateData.actual
                              ).toFixed(2)
                            : (
                                (stateData.actual * 100) /
                                stateData.planned
                              ).toFixed(2)}
                          %
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={6}>
                    <Row className="selected-state-card c-pointer">
                      <div className="state-name">{distData.name}</div>
                      <div className="state-coordinator ">
                        {/* {distData.coordinator} */}
                      </div>
                      <div
                        className="state-volunteer"
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => {
                          this.onViewVolunteer("state", this.state.distData);
                        }}
                      >
                        {distData.totalVolunteers} - Farmers/Volunteers
                        {/* View Farmers/Volunteers */}
                      </div>

                      <Col xs={8} className="p-10 mt-2">
                        {distData.planned > 0 && (
                          <div className="planned-graph">
                            {distData.planned}
                          </div>
                        )}

                        <div
                          className="planted-graph"
                          style={{
                            width: `${this.calculatePercentageForGraph(
                              distData.planned,
                              distData.actual
                            )}%`,
                          }}
                        >
                          {distData.actual}
                        </div>
                        {distData.dead > 0 && (
                          <div
                            className="dead-graph"
                            style={{
                              width: `${this.calculatePercentageForGraph(
                                distData.planned,
                                distData.dead
                              )}%`,
                            }}
                          >
                            {distData.dead}
                          </div>
                        )}
                      </Col>
                      <Col xs={2}>
                        <div className="state-percentage">
                          {distData.planned < distData.actual
                            ? (
                                (distData.actual * 100) /
                                distData.actual
                              ).toFixed(2)
                            : (
                                (distData.actual * 100) /
                                distData.planned
                              ).toFixed(2)}
                          %
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

              {!this.state.stateData &&
                this.state.distData &&
                this.state.user?.role_name === "State Admin" && (
                  <Row>
                    <Col xs={12}>
                      <Row className="selected-state-card c-pointer">
                        <div className="state-name">{distData.name}</div>
                        <div className="state-coordinator py-1">
                          {/* {stateData.coordinator} */}
                        </div>
                        <div
                          className="state-volunteer"
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            this.onViewVolunteer(
                              "district",
                              this.state.distData
                            );
                          }}
                        >
                          {distData.totalVolunteers} - Farmers/Volunteers
                          {/* View Farmers/Volunteers */}
                        </div>

                        <Col xs={8} className="p-10 mt-2 ">
                          {distData.planned > 0 && (
                            <div className="planned-graph">
                              {distData.planned}
                            </div>
                          )}

                          <div
                            className="planted-graph"
                            style={{
                              width: `${this.calculatePercentageForGraph(
                                distData.planned,
                                distData.actual
                              )}%`,
                            }}
                          >
                            {distData.actual}
                          </div>
                          {distData.dead > 0 && (
                            <div
                              className="dead-graph"
                              style={{
                                width: `${this.calculatePercentageForGraph(
                                  distData.planned,
                                  distData.dead
                                )}%`,
                              }}
                            >
                              {distData.dead}
                            </div>
                          )}
                        </Col>
                        <Col xs={2}>
                          <div className="state-percentage">
                            {distData.planned < distData.actual
                              ? (
                                  (distData.actual * 100) /
                                  distData.actual
                                ).toFixed(2)
                              : (
                                  (distData.actual * 100) /
                                  distData.planned
                                ).toFixed(2)}
                            %
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <div className="row m-0 justify-content-end">
                      <div
                        className="clear-text c-pointer p-0"
                        onClick={() => this.clearState()}
                      >
                        Clear
                      </div>
                    </div>
                  </Row>
                )}
              {(this.state.stateData ||
                this.state.user?.role_name === "State Admin") &&
                !this.state.distData && (
                  <div className="row m-0 justify-content-between">
                    <div className="state-data p-0">
                      {stateData?.name || this.state.user?.state_name} area data
                    </div>
                    {this.state.user?.role_name !== "State Admin" && (
                      <div
                        className="clear-text c-pointer p-0"
                        onClick={() => this.clearState()}
                      >
                        Clear
                      </div>
                    )}
                  </div>
                )}
              {(this.state.stateData ||
                this.state.user?.role_name === "District Admin") &&
                (this.state.distData ||
                  this.state.user?.role_name === "District Admin") && (
                  <div className="row m-0 justify-content-between">
                    <div className="state-data p-0">
                      {distData?.name || this.state.user.district_name} pincode
                      data
                    </div>
                    {this.state.user?.role_name !== "District Admin" && (
                      <div
                        className="clear-text c-pointer p-0"
                        onClick={() => this.clearState()}
                      >
                        Clear
                      </div>
                    )}
                  </div>
                )}
              <div
                className={
                  this.state.stateData ? "districts-scroll" : "states-scroll"
                }
              >
                {this.state.rowData.map((obj, index) => (
                  <Row className="state-card c-pointer" key={index}>
                    <Col xs={4}>
                      <div
                        onClick={() =>
                          this.state.distData ||
                          this.state.user.role_name === "District Admin"
                            ? this.selectedPin(obj)
                            : // this.
                            this.state.stateData ||
                              this.state.user.role_name === "State Admin"
                            ? this.selectedDistrict(obj)
                            : this.selectedState(obj)
                        }
                        className="state-name"
                      >
                        {obj.name}
                      </div>
                      <div className="state-coordinator py-1">
                        {/* {obj.coordinator} */}
                      </div>
                      <div
                        className="state-volunteer"
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => {
                          this.onViewVolunteer("state", obj);
                        }}
                      >
                        {obj.totalVolunteers} - Farmers/Volunteers
                        {/* View Farmers/Volunteers */}
                      </div>
                    </Col>
                    <Col
                      xs={6}
                      className="p-0"
                      onClick={() =>
                        this.state.distData ||
                        this.state.user.role_name === "District Admin"
                          ? this.selectedPin(obj)
                          : // this.
                          this.state.stateData ||
                            this.state.user.role_name === "State Admin"
                          ? this.selectedDistrict(obj)
                          : this.selectedState(obj)
                      }
                    >
                      {obj.planned > 0 && (
                        <div className="planned-graph">{obj.planned}</div>
                      )}

                      <div
                        className="planted-graph"
                        style={{
                          width: `${this.calculatePercentageForGraph(
                            obj.planned as any,
                            obj.actual as any
                          )}%`,
                        }}
                      >
                        {obj.actual}
                      </div>
                      {obj.dead > 0 && (
                        <div
                          className="dead-graph"
                          style={{
                            width: `${this.calculatePercentageForGraph(
                              obj.planned as any,
                              obj.dead as any
                            )}%`,
                          }}
                        >
                          {obj.dead}
                        </div>
                      )}
                    </Col>
                    <Col
                      xs={2}
                      onClick={() =>
                        this.state.distData ||
                        this.state.user.role_name === "District Admin"
                          ? this.selectedPin(obj)
                          : // this.
                          this.state.stateData ||
                            this.state.user.role_name === "State Admin"
                          ? this.selectedDistrict(obj)
                          : this.selectedState(obj)
                      }
                    >
                      <div className="state-percentage">
                        {obj.planned < obj.actual
                          ? ((obj.actual * 100) / obj.actual).toFixed(2)
                          : ((obj.actual * 100) / obj.planned).toFixed(2)}
                        %
                      </div>
                    </Col>
                  </Row>
                ))}
                {this.state.rowData.length === 0 && (
                  <div className="no-data h-100 text-center">No Data Found</div>
                )}
              </div>
              <div className="mt-4">
                <ProjectStatGraph
                  graphTitle={"Trees Planted"}
                  labels={
                    this.state.graphStats?.monthly_stat?.map(
                      (x: any) => x.month
                    ) || []
                  }
                  hoverTextLabel="planted"
                  values={
                    this.state.graphStats?.monthly_stat?.map(
                      (x: any) => x.plant_count
                    ) || []
                  }
                  colors={["#2CF55A"]}
                />
              </div>
            </Col>
          )}
          {this.state.activeTab?.value === 1 && (
            <Col lg={3} xs={12}>
              <div className="data-header">Top Performing Volunteers</div>
              <Col className="data-scroll">
                {this.state.topPerformer.map((obj, index) => (
                  <Col xs={6} key={index} className="p-0  w-100">
                    <div className="row m-0 data-card">
                      <Avatar
                        className="avatar-image"
                        name={
                          obj.profile_photo_id
                            ? ""
                            : obj.first_name.charAt(0) || "*"
                        }
                        src={
                          obj.profile_photo_id
                            ? `${constants.app.baseURL}/files/download?file_id=${obj.profile_photo_id}`
                            : ""
                        }
                      />
                      <div className="content-width">
                        <div className="data-name">
                          {obj.first_name || "-"} {obj.last_name}
                        </div>
                        <div className="data-place">
                          {obj.state_name || "-"}
                        </div>
                        <div className="data-count">
                          Planted - {obj.planted_count || 0}
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
                {this.state.topPerformer.length === 0 && (
                  <div className="no-data">No Volunteers Found</div>
                )}
              </Col>

              <div className="data-header">Gallery</div>
              <Row className="data-scroll">
                {this.state.galleryData?.map((obj, index) => (
                  <Col xs={6} key={index} className="p-0">
                    <div className="row m-0 image-card">
                      <img
                        className="image-tile"
                        alt={`${obj.id}`}
                        src={`${constants.app.baseURL}/files/download?file_id=${obj.image_id}`}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
              <div className="data-header">Most Referrals</div>
              <Row className="data-scroll">
                {this.state.topReferrals.map((obj, index) => (
                  <Col xs={6} key={index} className="p-0">
                    <div className="row m-0 data-card">
                      <Avatar
                        className="avatar-image"
                        name={
                          obj.profile_photo_id
                            ? ""
                            : obj.first_name.charAt(0) || "*"
                        }
                        src={
                          obj.profile_photo_id
                            ? `${constants.app.baseURL}/files/download?file_id=${obj.profile_photo_id}`
                            : ""
                        }
                      />
                      <div className="content-width">
                        <div className="data-name">
                          {obj.first_name || "-"} {obj.last_name}
                        </div>
                        <div className="data-place">
                          {obj.state_name || "-"}
                        </div>
                        <div className="data-count">
                          {obj.referred_count || 0}
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
                {this.state.topReferrals.length === 0 && (
                  <div className="no-data">No Referrals Found</div>
                )}
              </Row>
            </Col>
          )}
          {this.state.activeTab?.value === 2 &&
            this.state.user.role_name !== "District Admin" && (
              <Col lg={4} xs={12} className="distribution-view">
                <div className="state-data">Category Wise data</div>
                <div
                  style={{
                    minHeight: "300px",
                    // height: `${rowData.length * 20}px`,
                    height: `100%`,
                  }}
                >
                  <AgGridReact
                    gridOptions={{
                      rowHeight: 60,
                      rowStyle: { "border-top": "#FFFFFF 8px solid" },
                    }}
                    rowData={this.state.stateWiseDistribution}
                    rowSelection={"single"}
                    suppressCellSelection={true}
                    onGridReady={(e) => this.onFirstDataRendered(e)}
                    getRowNodeId={(data) => this.getRowNodeId(data)}
                    defaultColDef={{
                      flex: 1,
                      resizable: true,
                      wrapText: true,
                    }}
                    onRowClicked={(value) => {
                      this.onSelectState(value);
                    }}
                    suppressDragLeaveHidesColumns={true}
                  >
                    <AgGridColumn
                      headerName="State"
                      field="name"
                      width={150}
                      suppressMovable={true}
                    ></AgGridColumn>

                    <AgGridColumn
                      headerName="Total Procured Qty"
                      field="procuredQuantity"
                      width={150}
                      suppressMovable={true}
                    ></AgGridColumn>

                    <AgGridColumn
                      headerName="Total Delivered Qty"
                      field="distributedQuantity"
                      width={150}
                      suppressMovable={true}
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </Col>
            )}
          {this.state.activeTab?.value === 2 &&
            (this.state.stateWiseDistribution.length > 0 ||
              this.state.user?.role_name === "District Admin") && (
              <Col lg={5} xs={12} className="distribution-view">
                <div className="state-data">
                  {this.state.selectedStateWise?.name ||
                    this.state.user.state_name}{" "}
                  District Level Distribution
                </div>
                <DistrictWiseDistribution
                  districtList={this.state.districtList}
                  onSelectDistrict={this.onSelectDistrict}
                  selectedDistrictWise={this.state.selectedDistrictWise}
                ></DistrictWiseDistribution>
              </Col>
            )}
          {/* {this.state.activeTab?.value === 4 && (
            <Col md={9} xs={12}>
              <MapView project={this.props.projectId} />
            </Col>
          )} */}
          {this.state.activeTab?.value === 3 && (
            <Col lg={9} xs={12}>
              <div className="state-date">Map View</div>
              <div className="My_Plantings_PhotoViewBox_head">
                <div className="row  mb-5">
                  <div className="col-lg-3 col-sm-12">
                    <FieldItem
                      onChange={this.handlestateChange}
                      label="Category"
                      name="selectedState"
                      value={selectedState}
                      disabled={
                        this.state.currentUserRoleId ===
                          (userRoles.STATE_ADMIN as any) ||
                        this.state.currentUserRoleId ===
                          (userRoles.DISTRICT_ADMIN as any)
                      }
                      options={stateOptions}
                      placeholder="Select Category"
                      type={FIELD_TYPES.DROP_DOWN}
                      menuPortalTarget={document.querySelector("body")}
                      className="selectRequired"
                      required
                    />
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <FieldItem
                      onChange={this.handleDistrictChange}
                      label="Area"
                      name="selectedDistrict"
                      value={selectedDistrict}
                      disabled={
                        this.state.currentUserRoleId ===
                        (userRoles.DISTRICT_ADMIN as any)
                      }
                      options={districtOptions}
                      placeholder="Select Area"
                      type={FIELD_TYPES.DROP_DOWN}
                      menuPortalTarget={document.querySelector("body")}
                      className="selectRequired"
                      required
                    />
                  </div>

                  <div className="col-lg-3 col-sm-12">
                    <div className="mt-4 p-2">
                      <button
                        className="btn  btn-theme-primary"
                        onClick={() => {
                          if (!selectedState) {
                            showToaster(toasterTypes.ERROR, "Select Category");
                            return;
                          } else if (!selectedDistrict) {
                            showToaster(toasterTypes.ERROR, "Select District");
                            return;
                          }

                          this.renderGoogleMap();
                        }}
                        title="Search"
                      >
                        Search
                      </button>
                    </div>
                  </div>

                  <div
                    style={{ minHeight: "500px", minWidth: "100%" }}
                    className="col-lg-5 col-md-12  col-sm-12"
                    ref={this.state.mapRef}
                    id="map_canvas"
                  ></div>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default AdminDashboard;
