import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { FieldItem, FIELD_TYPES } from "../fields";
import { Col } from "reactstrap";
import { pagination as paginationConstant } from "../../constants/pagination";
interface paginationProps {
  page: number;
  pageSize: number;
  totalPage: number;
  handlePageChange: (data: { selected: number }, pageSize: number) => void;
}
const Pagination: React.FunctionComponent<paginationProps> = (
  props: paginationProps
) => {
  const [paginationSize, setpaginationSize] = useState(props.pageSize);
  const onChange = (e: any) => {
    props.handlePageChange({ selected: 1 }, e.value);
    setpaginationSize(e.value);
  };

  const pageChange = (data: { selected: number }) => {
    props.handlePageChange({ selected: data.selected + 1 }, paginationSize);
  };
  return (
    <div className="row">
      <div className="col-md-2 col-3 ">
        <FieldItem
          type={FIELD_TYPES.DROP_DOWN}
          value={{ label: paginationSize, value: paginationSize }}
          menuPortalTarget={document.querySelector("body")}
          options={paginationConstant.PAGE_COUNT_LIST}
          onChange={(e) => onChange(e)}
          notClearable={true}
        />
      </div>
      <div className="col-md-10  col-9 d-flex justify-content-end">
        <ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={props.totalPage}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={(data) => pageChange(data)}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          forcePage={props.page - 1}
        />
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  page: 1,
  pageSize: 0,
  totalPage: 0,
  handlePageChange: (data: { selected: number }, pageSize: number) => {},
};

export default Pagination;
