import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { commonService } from "../../../service";
import { showToaster, toasterTypes } from "../../../widgets";
import Button from "../../../widgets/button";

type Props = {
  selectedElement: {
    label: string;
    value: string;
    count: string;
    isAll: boolean;
    districts: [] | undefined;
  };
  modal: boolean;
  toggle: () => void;
  onSavedEvent: (payload: {
    districts: string[];
    isAll: boolean;
    label: string;
    value: string;
    count: string;
  }) => void;
};

const CheckboxTreeModal = (props: Props) => {
  const [districts, setDistricts] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState<string[]>([]);
  const [mode, setMode] = useState("true");

  useEffect(() => {
    if (props?.selectedElement?.value) {
      getDistrictByState(props?.selectedElement?.value);
    }
    if (props.selectedElement?.isAll) {
      setMode("true");
    } else {
      setMode("false");
    }

    if (props?.selectedElement?.districts) {
      setSelectedDistricts(props.selectedElement?.districts);
    }
  }, [props?.selectedElement]);

  const getDistrictByState = (districtId: string) => {
    try {
      commonService.getAllDistrictByStateId(districtId).subscribe(
        (response: any) => {
          try {
            if (response.status && response.statusCode === 200) {
              if (response.data?.length > 0) {
                let rowData: any = response.data.map((element: any) => ({
                  label: element.name,
                  value: element.id,
                }));

                setDistricts(rowData);
              }
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleRadioChange = (e: any) => {
    setMode(e.target.value);
  };

  const handleCheckBoxChange = (e: any) => {
    const existingValues: string[] = [...selectedDistricts];
    if (e.target.checked) {
      if (!existingValues.includes(e.target.value)) {
        existingValues.push(e.target.value);
      }
    } else {
      const index = existingValues.findIndex((x) => x === e.target.value);
      if (index > -1) {
        existingValues.splice(index, 1);
      }
    }
    setSelectedDistricts(existingValues);
  };

  const onsubmit = () => {
    /** Validate */
    if (mode === "false" && selectedDistricts.length === 0) {
      showToaster(
        toasterTypes.ERROR,
        "Atleast one district needs to be selected"
      );
      return;
    }

    const payload = {
      ...props?.selectedElement,
      districts: selectedDistricts,
      isAll: mode === "true",
    };
    props.onSavedEvent(payload);
    props.toggle();
  };

  const closeBtn = (
    <button className="close" title="Close" onClick={props.toggle}>
      &times;
    </button>
  );

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader className="modal_Bg" toggle={props.toggle} close={closeBtn}>
        <div className="modal_title">Select Areas</div>
      </ModalHeader>
      <ModalBody style={{ maxHeight: "450px", overflowY: "auto" }}>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            checked={mode === "true"}
            onChange={handleRadioChange}
            id="inlineRadio1"
            value="true"
          />
          <label htmlFor="inlineRadio1" className="form-check-label">
            All
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            checked={mode === "false"}
            name="inlineRadioOptions"
            onChange={handleRadioChange}
            id="inlineRadio2"
            value="false"
          />
          <label htmlFor="inlineRadio2" className="form-check-label">
            Choose Areas
          </label>
        </div>

        {mode === "false" && (
          <div className="row mt-4">
            {districts.map((x: any, index) => {
              return (
                <div key={`dist-${index}`} className="col-lg-12 col-sm-12">
                  <div className="form-group  mb-2">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value={x.value}
                      checked={selectedDistricts.includes(x.value)}
                      onChange={handleCheckBoxChange}
                      id={`checkbox-${index}`}
                      onClick={(event: React.FormEvent<HTMLInputElement>) => {}}
                    />
                    <label
                      htmlFor={`checkbox-${index}`}
                      className="form-check-label px-3"
                    >
                      {x.label}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {mode === "true" && (
          <div className="row mt-4">
            {districts.map((x: any, index) => {
              return (
                <div key={`dist-${index}`} className="col-lg-12 col-sm-12">
                  <div className="form-group  mb-2">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value={x.value}
                      checked={true}
                      disabled={true}
                      onChange={handleCheckBoxChange}
                      id={`checkbox-${index}`}
                    />
                    <label
                      htmlFor={`checkbox-${index}`}
                      className="form-check-label px-3"
                    >
                      {x.label}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          label="Cancel"
          className="ml-2 btn btn-outline-secondary"
          onClick={props.toggle}
        />
        <Button
          label="Save"
          className="ml-2 btn btn-theme-primary"
          onClick={onsubmit}
        />
      </ModalFooter>
    </Modal>
  );
};

export default CheckboxTreeModal;
