// /planting/list?page=1&size=1000&sort=id&order=desc&status=active,inactive&keyword=
import { fetchCall, requestMethod } from "../../utils/ajax";
import {
  plantRequestModal,
  plantChangeStatusRequestModal,
  treeRequestModal,
} from "../../model";

const createPlantRequest = (requestData: plantRequestModal) =>
  fetchCall("/planting/save", requestMethod.POST, requestData);

const getAllPlantList = (
  stateId: number = 0,
  districtId: number = 0,
  pincodeId: number = 0,
  page: number = 1,
  pageSize: number = 10,
  user_id: string = "",
  key: string = "",
) =>
  fetchCall(
    `/planting/list?page=${page}&size=${pageSize}&sort=id&order=desc&user_id=${user_id}&status=active,inactive&keyword=${key}&state_id_list=${stateId}&district_id_list=${districtId}&pincode_id_list=${pincodeId}`,
    requestMethod.GET
  );

const getAllPlantForUser = (
  user_id: string = "",
  page: number = 1,
  pageSize: number = 10,
  key: string = ""
) =>
  fetchCall(
    `/planting/list?page=${page}&size=${pageSize}&sort=id&order=desc&status=active,inactive&keyword=${key}&user_id=${user_id}`,
    requestMethod.GET
  );

const getAllPlantListForDistrict = (
  districtId: number = 0,
  page: number = 1,
  pageSize: number = 10
) =>
  fetchCall(
    `/planting/list?page=${page}&size=${pageSize}&sort=id&order=desc&status=active,inactive&keyword=&district_id_list=${districtId}`,
    requestMethod.GET
  );

const plantChangeStatus = (requestData: plantChangeStatusRequestModal) =>
  fetchCall("/planting/change_status", requestMethod.POST, requestData);

const deleteplant = (requestData: plantChangeStatusRequestModal) =>
  fetchCall("/master/change_status_plant", requestMethod.POST, requestData);

const getAllPlantType = () =>
  fetchCall("/master/get_plant_types", requestMethod.GET);

const getAllTreeList = (
  page: number = 1,
  pageSize: number = 10,
  key: string = ""
) =>
  fetchCall(
    `/master/list_plants?page=${page}&size=${pageSize}&sort=id&order=desc&status=active&keyword=${key}`,
    requestMethod.GET
  );

  const stateDistrictPincodeListing = (
    stateId: number = 0,
    districtId: number = 0,
    pincodeId: number = 0,
    page: number = 1,
    pageSize: number = 10,
    key: string = ""
  ) =>
    fetchCall(
      `/planting/state_district_pincode_planting?page=${page}&size=${pageSize}&sort=id&order=desc&status=active,inactive&keyword=${key}&state_id_list=${stateId}&district_id_list=${districtId}&pincode_id_list=${pincodeId}`,
      requestMethod.GET
    );

const createTreeRequest = (requestData: treeRequestModal) =>
  fetchCall("/master/save_plant", requestMethod.POST, requestData);

const getAllHistoryList = (historyId: any) =>
  fetchCall(
    `/planting/planting_history?batch_id=${historyId}`,
    requestMethod.GET,
    {}
  );

const plantService = {
  getAllPlantList,
  getAllPlantForUser,
  createPlantRequest,
  plantChangeStatus,
  getAllPlantType,
  getAllTreeList,
  createTreeRequest,
  getAllPlantListForDistrict,
  getAllHistoryList,
  deleteplant,
  stateDistrictPincodeListing
};

export { plantService };
