import React, { Component } from "react";
import { Button, Col, Row, UncontrolledTooltip } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { procurementService } from "../../../service";
import { showToaster, toasterTypes } from "../../../widgets";
import Pagination from "../../../widgets/pagination";
import Tablefeature from "../../../widgets/tableFeature";
import { listPaymentHistoryProps, listPaymentHistoryState } from "./interface";
import { ActionRow } from "./partials/actionRow";

export class ListPaymentHistory extends Component<
  listPaymentHistoryProps,
  listPaymentHistoryState
> {
  constructor(props: listPaymentHistoryProps) {
    super(props);
    this.state = {
      modal: false,
      gridApi: "",
      paidAmount: "",
      paymentDate: "",
      remarks: "",
      rowEditById: 0,
      procurementId: 0,
      tableRecords: [],
      context: {
        componentParent: this,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
        totalElements: 0,
      },
    };
  }

  //   handlePageClick = (data: { selected: number }, pageSize: number = this.state.pagination.pageSize) => {
  //     this.getDeliveryList(data.selected, pageSize);
  //   };
  handleSearch = (e: any) => {
    this.getPaymentHistoryList();
  };
  getPaymentHistoryList = () => {
    try {
      procurementService
        .getAllPaymentHistoryList(this.props.match.params.procurementId)
        .subscribe(
          (response: any) => {
            try {
              if (response.status && response.statusCode === 200) {
                if (
                  Array.isArray(response.content) &&
                  response.content?.length > 0
                ) {
                  let rowData: any = response.content.map((element: any) => ({
                    id: element.id,
                    PaidAmount: element.paid_amount,
                    Remark: element.remarks,
                    PaymentDate: moment(element.payment_date).format(
                      "DD-MM-YYYY"
                    ),
                    procurementId: this.props.match.params.procurementId,
                  }));
                  this.state.gridApi.setRowData(rowData);
                  this.setState({
                    tableRecords : rowData,
                    pagination: {
                      pageNumber: response.page || 1,
                      pageSize: response.size || 10,
                      totalPages: response.totalPages || 1,
                      totalElements: response.totalElements || 10,
                    },
                  });
                }
              } else {
                this.state.gridApi.setRowData([]);
                showToaster(toasterTypes.ERROR, response.message);
              }
            } catch (error) {
              console.log(error?.message);
            }
          },
          (error: any) => {
            console.log(error);
            showToaster(toasterTypes.ERROR, error.message);
          }
        );
    } catch (error) {
      console.log(error?.message);
    }
  };
  editRowData = (data: any) => {
    // this.toggle();
    let procurementId = this.props.match.params.procurementId;
    this.setState({
      paidAmount: data.paidAmount,
      remarks: data.Remarks,
      paymentDate: moment(data.unformatedDate).format("YYYY-MM-DD"),
      rowEditById: data.id,
      procurementId: this.props.match.params.procurementId,
    });
  };

  onGridReady = (params: any) => {
    this.getPaymentHistoryList();
    this.setState({
      gridApi: params.api,
    });
  };

  changeStatusHandler = (data: any) => {
    const requestData = {
      id: data.id,
      status: "deleted",
    };
    procurementService.DeliveryChangeStatus(requestData).subscribe(
      (data: any) => {
        if (data.status && data.statusCode === 200) {
          this.getPaymentHistoryList();
          showToaster(toasterTypes.SUCCESS, data.message);
        } else {
          showToaster(toasterTypes.ERROR, data.message);
        }
      },
      (error: any) => {
        console.log(error);
        showToaster(toasterTypes.ERROR, error.message);
      }
    );
  };
  render() {
    const { pagination } = this.state;
    return (
      <>
        <section id="ViewProject" className="ViewProject">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters">
              <div className="col-lg-10 d-flex flex-column justify-content-center ">
                <Row>
                  <Col md={11}>
                    <h3 className="heading-secondary">Payment History</h3>
                  </Col>
                  <Col md={1}>
                    <Link to="/listProcurement">
                      <i
                        className="fa fa-times  text-dark float-right"
                        id="close"
                      ></i>
                    </Link>
                    <UncontrolledTooltip target={`close`}>
                      Close
                    </UncontrolledTooltip>
                  </Col>
                </Row>

                <div className="col-lg-12 disp-flex">
                  <h3 className="secondary-heading-normal m-2">
                    Add Payment
                  </h3>
                  <Link
                    to={`/createPaymentHistoryprocurement/${this.props.match.params.procurementId}`}
                    style={{ height: "30px" }}
                    className="ml-2 btn btn-outline-secondary"
                  >
                    Add Payment
                  </Link>
                </div>
                <Tablefeature
                  totalElements={this.state.tableRecords?.length || 0}
                  handleSearch={this.handleSearch}
                />

                <div className="section-title">
                  <div className="grid">
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        domLayout="autoHeight"
                        enableBrowserTooltips={true}
                        onGridReady={this.onGridReady}
                        context={this.state.context}
                        rowData={this.state.tableRecords}
                        pagination={false}
                        paginationPageSize={10}
                        cacheBlockSize={10}
                        frameworkComponents={{
                          ActionColumn: ActionRow,
                        }}
                        suppressDragLeaveHidesColumns={true}
                      >
                        <AgGridColumn
                          field="PaymentDate"
                          sortable={true}
                          filter={true}
                          flex={1}
                          tooltipField="PaymentDate"
                          //   type="rightAligned"
                          suppressMovable={true}
                        />
                        <AgGridColumn
                          field="PaidAmount"
                          sortable={true}
                          flex={1}
                          filter={true}
                          //   type="rightAligned"
                          tooltipField="PaidAmount"
                          suppressMovable={true}
                        />
                        <AgGridColumn
                          field="Remark"
                          sortable={true}
                          filter={true}
                          flex={1}
                          tooltipField="Remark"
                          suppressMovable={true}
                          //   type="rightAligned"
                        />

                        {/* <AgGridColumn /> */}
                        <AgGridColumn
                          field="Action"
                          flex={1}
                          cellRenderer="ActionColumn"
                          suppressMovable={true}
                          //   type="rightAligned"
                        />
                      </AgGridReact>
                      <div className="col-md-12 mt-2">
                        {/* <Pagination
                        page={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        handlePageChange={this.handlePageClick}
                        totalPage={pagination.totalPages}
                      /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default ListPaymentHistory;

//  default EditRow;
