import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "../../../widgets/button";

interface actionRowProps {
  context: any;
  data: any;
}
interface actionRowStateProps {
  modal: boolean;
}
export class ActionRow extends Component<actionRowProps, actionRowStateProps> {
  constructor(props: actionRowProps) {
    super(props);
    this.state = {
      modal: false,
    };
  }
  editRow = () => {
    this.props.context.componentParent.editRowData(this.props.data);
  };
  changeStatus = () => {
    this.props.context.componentParent.changeStatusHandler(this.props.data);
  };
  deletePlant = () => {
    this.props.context.componentParent.deletePlant(this.props.data);
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  closeBtn = (
    <button className="close" title="Close" onClick={this.toggle}>
      &times;
    </button>
  );
  render() {
    return (
      <>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader
            className="modal_Bg"
            close={this.closeBtn}
            toggle={this.toggle}
          >
            <div className="modal_title"> Confirmation Alert</div>
          </ModalHeader>
          <ModalBody>Are you sure to Delete?</ModalBody>
          <ModalFooter>
            <Button
              label="No"
              className="ml-2 btn btn-outline-secondary"
              onClick={this.toggle}
            />
            <Button
              label="Yes"
              className="ml-2 btn btn-theme-primary"
              onClick={this.deletePlant}
            />
          </ModalFooter>
        </Modal>
        <button
          className="Action_btn"
          title="Edit"
          onClick={() => this.editRow()}
        >
          <i className="bi bi-pencil-square"></i>
        </button>
        <button
          className="Action_btn"
          title="Delete"
          style={{ marginLeft: "16px" }}
          onClick={this.toggle}
        >
          <i className="bi bi-trash"></i>
        </button>
      </>
    );
  }
}
