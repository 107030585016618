import React, { useEffect, useState } from "react";
import { constants } from "../../../constants";
import { paginationInterface } from "../../../model";
import { userService } from "../../../service";
import { showToaster, toasterTypes } from "../../../widgets";
import Pagination from "../../../widgets/pagination";

interface inviteListResponse {
  address: string;
  district_id: string;
  district_name: string;
  email_id: string;
  first_name: string;
  id: string;
  last_name: string;
  mobile_no_1: string;
  pincode_id: string;
  pincodes_name: string;
  profile_photo_id: string;
  reference_code: string;
  referred_reference_code: string;
  state_id: string;
  state_name: string;
  status: string;
  user_role_id: string;
  volunteer_id: string;
}

const VisiterList: React.FunctionComponent = (prop) => {
  const initialValue: inviteListResponse[] = [];
  const initialPagination: paginationInterface = {
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
    totalElements: 10,
  };
  const [visitorList, setVisitorList] = useState(initialValue);
  const [pagination, setPagination] = useState(initialPagination);

  const getVisitorList = (page: number, size: number) => {
    try {
      userService.getUserInvites(page, size).subscribe({
        next: (response: any) => {
          try {
            if (
              response &&
              response.statusCode === 200 &&
              Array.isArray(response.content)
            ) {
              setVisitorList(response.content);
              setPagination({
                pageNumber: response.page || 1,
                pageSize: response.size || 10,
                totalPages: response.totalPages || 1,
                totalElements: response.totalElements || 10,
              });
            } else {
              showToaster(toasterTypes.ERROR, response.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        },
        error: (error: any) => {
          showToaster(toasterTypes.ERROR, error.message);
        },
      });
    } catch (error) {
      console.log(error.message);
    }
  };
  const handlePageClick = (data: { selected: number }, pageSize:number=pagination.pageSize) => {
    getVisitorList( data.selected, pageSize);
  };
  useEffect(() => {
    getVisitorList(pagination.pageNumber, pagination.pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="container visitorList my-3">
      {visitorList.length <= 0 ? (
        <div className="d-flex">
          <p className="m-auto">No invites yet</p>
        </div>
      ) : (
        <div className="row">
          {visitorList.map((visitor, index: number) => (
            <div className="col-md-4 col-sm-12 topPerformers-card" key={index}>
              <div className="d-flex">
                <img
                  src={`${constants.app.renderFile}?file_id=${visitor.profile_photo_id}`}
                  alt="User"
                  style={{ width: "120px", height: "100px" }}
                />
                <div className="ml-2">
                  <label className="card-heading">{`${visitor.first_name} ${visitor.last_name}`}</label>
                  <br />
                  <label className="card-heading-sub">{visitor.email_id}</label>
                  <br />
                  <label className="card-heading-sub">
                    {visitor.district_name}
                  </label>
                </div>
              </div>
            </div>
          ))}
          <div className="col-md-12 col-sm-12">
            <Pagination
              page={pagination.pageNumber}
              pageSize={pagination.pageSize}
              handlePageChange={handlePageClick}
              totalPage={pagination.totalPages}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VisiterList;
