import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "../../../widgets/button";
import { FieldItem, FIELD_TYPES } from "../../../widgets/fields";
import { showToaster, toasterTypes } from "../../../widgets";
import { changePasswordProps, changePasswordState } from "./interface";
import { authenticationService } from "../../../service";
import { changePasswordRequestModal } from "../../../model";

export class ChangePasswordPopup extends Component<
  changePasswordProps,
  changePasswordState
> {
  constructor(props: changePasswordProps) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      conformPassword: "",
    };
  }

  resetForm = () => {
    this.setState({
      oldPassword: "",
      newPassword: "",
      conformPassword: "",
    });
  };
  toggle = () => {
    this.resetForm();
    this.props.toggle();
  };
  handleOnChange = (event: any) => {
    try {
      const { name, value } = event.currentTarget;
      const state: any = { ...this.state };
      state[name] = value;
      this.setState(state);
    } catch (error) {
      console.log(error?.message);
    }
  };

  onSubmit = () => {
    try {
      const { oldPassword, newPassword, conformPassword } = this.state;

      if (oldPassword.length <= 0) {
        showToaster(toasterTypes.ERROR, "Please enter the current password ");
        return true;
      }
      if (newPassword.length <= 0) {
        showToaster(toasterTypes.ERROR, "Please enter the new password");
        return true;
      }
      if (conformPassword.length <= 0) {
        showToaster(toasterTypes.ERROR, "Please enter the confirm password");
        return true;
      }
      if (oldPassword === newPassword) {
        showToaster(
          toasterTypes.ERROR,
          "Current password and new password can't be the same"
        );
        return true;
      }
      if (newPassword !== conformPassword) {
        showToaster(
          toasterTypes.ERROR,
          "New password and confirm password doesn't match."
        );
        return true;
      }
      const requestData: changePasswordRequestModal = {
        current_password: oldPassword,
        new_password: newPassword,
        retry_password: conformPassword,
      };
      // TODO Validation
      authenticationService.changePasswordRequest(requestData).subscribe(
        (data: any) => {
          if (data.status && data.statusCode === 200) {
            showToaster(toasterTypes.SUCCESS, data.message);
            this.toggle();
          } else {
            showToaster(toasterTypes.ERROR, data.message);
          }
        },
        (error: any) => {
          console.log(error);
          showToaster(toasterTypes.ERROR, error.message);
        }
      );
    } catch (error) {
      console.log(error?.message);
    }
  };
  closeBtn = (
    <button className="close" title="Close" onClick={this.toggle}>
      &times;
    </button>
  );

  render() {
    const {
      handleOnChange,
      // onSubmit,
      state: { oldPassword, newPassword, conformPassword },
    } = this;

    return (
      <Modal isOpen={this.props.modal} toggle={this.toggle}>
        <ModalHeader
          className="modal_Bg"
          close={this.closeBtn}
          toggle={this.toggle}
        >
          <div className="modal_title">Change Password</div>
        </ModalHeader>
        <ModalBody>
          <>
            <div className="email-login">
              <form>
                <FieldItem
                  onChange={handleOnChange}
                  value={oldPassword}
                  name="oldPassword"
                  label="Current Password"
                  placeholder="Current Password"
                  type={FIELD_TYPES.TEXT_EYE}
                  className="form-control required"
                  iconClassName="mt-2 changePasswordEye"
                  required
                />
                <FieldItem
                  onChange={handleOnChange}
                  value={newPassword}
                  name="newPassword"
                  label="New Password"
                  placeholder="New Password"
                  type={FIELD_TYPES.TEXT_EYE}
                  className="form-control required"
                  iconClassName="mt-2 changePasswordEye"
                  required
                />
                <FieldItem
                  onChange={handleOnChange}
                  value={conformPassword}
                  name="conformPassword"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  type={FIELD_TYPES.TEXT_EYE}
                  className="form-control required"
                  iconClassName="mt-2 changePasswordEye"
                  required
                />
              </form>
            </div>
          </>
        </ModalBody>
        <ModalFooter>
          <Button
            label="Cancel"
            className="ml-2 btn btn-outline-secondary"
            onClick={this.toggle}
          />
          <Button
            onClick={this.onSubmit}
            label="Apply"
            className="ml-2 btn btn-theme-primary"
          />
        </ModalFooter>
      </Modal>
    );
  }
}
