import React from "react";
import { constants } from "../../../../../constants";

interface topPerformerProps {
  topPerformer: {
    first_name: string;
    last_name: string;
    planted_count: string;
    address: string;
    profile_photo_id: string;
  }[];
}
const TopPerformers: React.FunctionComponent<topPerformerProps> = (props) => {
  return (
    <div className="topPerformers mt-2">
      <h6>Top Performers</h6>
      <div className="row no-gutter">
        {props?.topPerformer?.slice(0, 2).map((data, index) => (
          <div className="col topPerformers-card d-flex" key={index}>
            <img
              src={`${constants.app.baseURL}/files/download?file_id=${data.profile_photo_id}`}
              alt="top performer"
              className="topPerformers-card-img"
            />
            <div className="d-flex flex-column display-column px-3 justify-content-center w-100 ">
              <label className="topPerformers-card-name name__overflow">
                {data.first_name} {data.last_name}
              </label>
              <label className="topPerformers-card-sub">{data.address}</label>
              <label className="">{data.planted_count} Plants</label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopPerformers;
